import { Component, OnInit, NgZone, Inject, EventEmitter } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';
import { CustomerSettingsService } from '../services/customer-settings.service';
import { DialogsService } from '../dialog/dialog.service';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs/Subscription';


@Component({
  selector: 'app-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit {

  public user: any = null;
  public idToken: string = "";
  public authorizedUsers = [];
  public prohibitedItems = [];
  public prohibitedUserItems = [];
  public editingLowBalanceAlert = false;
  public showManageItems = false;
  public editItemsUser;


  public customerSettings = {
    lowBalanceAlert: 10
  }

  public newItem = {
      itemName: "",
      authorizedUserId: "",
      accountHolder: ""
    }

  public tokenSubscription: Subscription;

  constructor(
    public userService: UserService,
    public customerSettingsService: CustomerSettingsService,
    public dialogsService: DialogsService,
    public loadingService: LoadingService,
    public authService: AuthService
  ) { 

    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token customer-settings");
       /// console.log(token);
        this.idToken = token;
      }
    )

  }


  ngOnInit() {
    //get current user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
    .then(res => {
      this.loadingService.toggleLoadingScreen(false);
      this.user = res;
      if(res != null){
        if(this.user.lowBalanceThreshold){
          this.customerSettings.lowBalanceAlert = this.user.lowBalanceThreshold;
        }
        this.getMyAuthorizedUsers()
        .then(res => {
        })
      }
    })
  }





  getUser(): Promise<any>{
    return new Promise( (resolve,reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
      })
  }


  updateSettings(){
    console.log("settings:", this.customerSettings);
    this.customerSettingsService.updateCustomerSettings(this.idToken, this.customerSettings, this.user.email, this.user.organizationId)
    .then(res => {
      console.log("update settings res:", res);
      var body = JSON.parse(res._body);
      if(body.status == "200"){
        this.dialogsService.alert("Success!","Your settings have been updated!");
        this.editingLowBalanceAlert = false;
      }
    })
    .catch(err => {
      console.log("Update settings err:", err);
    })
  }


   /* get all authorized users on their account */
  getMyAuthorizedUsers(): Promise<any>{
    return new Promise((resolve,reject) => {
      this.userService.getAuthorizedUsers(this.idToken,this.user.userId)
      .then(res => {
          console.log("get auth users res:", res);
          var body = JSON.parse(res._body);
          if(body.status == "200"){
            this.authorizedUsers = body.message.Items;
            console.log("authorized users:", this.authorizedUsers);
            resolve(this.authorizedUsers);
          }
          reject(res._body);
        })
        .catch(err => {
          console.log("get auth users err:", err);
          reject(err);

        })
    })
  }

   //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }



}
