import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';
import { Ng2FileDropAcceptedFile, Ng2FileDropRejectedFile, Ng2FileDropModule, Ng2FileDropFiles } from "ng2-file-drop";
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
    selector: 'input-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        
        <div class="upload-image-container">
            <div class="ff-dialog-header">{{ title }}</div>


            <div id="file-drop" ng2FileDrop class="profile-picture-section-drop-zone"

                [ng2FileDropSupportedFileTypes]="supportedFileTypes"
                (ng2FileDropHoverEnd)="dragFileOverEnd()"
                (ng2FileDropFileAccepted)="dragFileAccepted($event)" (ng2FileDropFileRejected)="dragFileRejected($event)">
                

                <div *ngIf="!imageShown" class="profile-picture-section-request-image-group">
                    <p class="profile-picture-section-request-image-instructions">Drop image here</p>
                </div>

                <div *ngIf="imageShown" class=profile-picture-section-request-image-container>
                    <img class=profile-picture-section-request-image [src]="currentProfileImage">
                </div>


            </div>


            <div>
                <div class="add-camper-button-wrap">
                    <button mat-raised-button (click)="uploadImage()" class="submit-button">Upload</button>
                </div>
            </div>

        </div>

        




        `,
})
export class UploadImageDialog {

    public title: string;
    public message: string;
    public cancelText: string;
    public okText: string;
    public maxVal: number;
    public amountSelected: number = 0;
    public errorMessage: string = "";
    public imageUrl: string = "";
    public height: number = 0;
    public imageShown: boolean = false;
    public currentProfileImage: any = null;
    public file = null;

    public supportedFileTypes: string[] = ['image/png', 'image/jpeg'];


    constructor(
        public dialogRef: MatDialogRef<UploadImageDialog>,
        public ng2ImgMaxService: Ng2ImgMaxService) {
        setTimeout( () => {
            if(this.imageUrl != null){
                this.imageShown = true;
                this.currentProfileImage = this.imageUrl;
                console.log("curr prof img:", this.currentProfileImage);
            }
        },100);
        
    }

    public uploadImage(){
        if(this.currentProfileImage != null){
            this.dialogRef.close(this.file);
        }else{
            this.dialogRef.close(false);
        }
    }

  

  public dragFileOverEnd() {
      console.log("drag file over end...");
  }
 
  // File being dragged has been dropped and is valid
  public dragFileAccepted(acceptedFile: Ng2FileDropAcceptedFile) {
      console.log("accepted file:", acceptedFile);
      this.file = acceptedFile.file;
      
      // Load the image in
      let fileReader = new FileReader();
      fileReader.onload = () => {
          console.log("fileReader:", fileReader);
          // Set and show the image
          this.currentProfileImage = fileReader.result;

          

          console.log(fileReader.result);
          this.imageShown = true;
      };
      
      if (this.height > 0) {
        // resize the image to 200x200 (if ht passed in)
        console.log("invoking resize...");
        console.log("Pre resize:", acceptedFile.file);
        this.ng2ImgMaxService.resize([acceptedFile.file], this.height, this.height).subscribe
        ( res => {
            console.log("Image resized result:", res);
            fileReader.readAsDataURL(res);
            this.file = res;
        }, error => {
            console.log("image resize error:");
            console.log(error);
        });
      } else {
        // Read in the file
        fileReader.readAsDataURL(acceptedFile.file);
      }


  }
 
  // File being dragged has been dropped and has been rejected
  public dragFileRejected(rejectedFile: Ng2FileDropRejectedFile) {
  }
 
  // Files being dragged have been dropped.
  public dragFilesDropped(droppedFile: any) {

  }

    
  
}