import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { Subject } from 'rxjs/Subject';
import { BaseEndpoint, CognitoPoolId, AccessKey, SecAccessKey, UserPoolId, CognitoRegion, CognitoClientId } from '../app.constants';
import { DialogsService } from '../dialog/dialog.service';
import { TokenService } from './token.service';



//import * as AWSCognito from 'aws-sdk';

declare var AWSCognito: any;
declare var AWS: any;


/*Service for interfacing with Cognito methods*/
@Injectable()
export class AuthService {
  constructor(
    public dialogsService: DialogsService,
    public http: Http,
  ) { }

  public poolData: any;
  public userPool: any;

  public prevToken: string;

  public tokenSubscription = new Subject<any>();
  tokenSubscription$ = this.tokenSubscription.asObservable();

  initializeAWSCognito() {
    if (AWSCognito.config.region == undefined) {
      AWSCognito.config.region = CognitoRegion;
      AWSCognito.config.credentials = new AWSCognito.CognitoIdentityCredentials({
        IdentityPoolId: CognitoPoolId
      });
      this.poolData = {
        UserPoolId: UserPoolId,
        ClientId: CognitoClientId
      }
      this.userPool = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserPool(this.poolData);
    }
  }


  //creates a user in cognito
  createUser(credentials: AuthCredentials, isParent2: boolean): Promise<any> {
    var attributeList = [];
    var dataEmail = {
      Name: 'email',
      Value: credentials.email
    };
    
    var isParentString = "true";
    if(!isParent2){
      isParentString = "false";
    }

    var dataRole = {
      Name: 'custom:account-role',
      Value: isParentString //credentials.role
    }
    
    var dataRole2 = {
      Name: 'custom:parent2',
      Value: isParentString
    }



    var attributeEmail = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(dataEmail);
    var attributeRole = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(dataRole);
    //var attributeRole2 = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(dataRole2);
    console.log("credentials are:", credentials);
    attributeList.push(attributeEmail);
    attributeList.push(attributeRole);
    //attributeList.push(attributeRole2);
    return new Promise((resolve, reject) => {
      this.userPool.signUp(credentials.email, credentials.password, attributeList, null, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result.user);
        }
      });
    });


  }

  login(credentials: AuthCredentials): Promise<any> {
    credentials.email = credentials.email.toLowerCase();
    credentials.email = credentials.email.trim();
    credentials.password = credentials.password.trim();

    var authenticationData = {
      Username: credentials.email,
      Password: credentials.password,
    };
    var authenticationDetails = new AWSCognito.CognitoIdentityServiceProvider.AuthenticationDetails(authenticationData);
    var userData = {
      Username: credentials.email,
      Pool: this.userPool
    };
    var cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
    var self = this;
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          console.log("LOGIN SUCCESS RES:", result);
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },

        newPasswordRequired: function(userAttributes, requiredAttributes){
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            // the api doesn't accept this field back
            delete userAttributes.email_verified;
            console.log("NEW PASS REQUIRED");
            console.log("self is:", self);
            // Get these details and call
            self.dialogsService.changePasswordDialog("Change Password")
            .toPromise()
            .then( res => {
              console.log("res:", res);
              if(res != null && res != undefined && res['length'] >= 8){
                cognitoUser.completeNewPasswordChallenge(credentials.password, userAttributes, this);
              }
            })
        }
      });
    });
  }


  verifyAccount(credentials: AuthCredentials, text: string): Promise<any> {
    var userData = {
      Username: credentials.email,
      Pool: this.userPool
    };
    var cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(text, true, function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  resendCode(credentials: AuthCredentials): Promise<any> {
    var userData = {
      Username: credentials.email,
      Pool: this.userPool
    };
    var cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }

      });
    });
  }

  getUserFromStorage(): Promise<any> {
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      return new Promise((resolve, reject) => {
        cognitoUser.getSession(function (err, session) {
          if (err) {
            reject(err);
            return;
          } else {
            cognitoUser.getUserAttributes(function (err, result) {
              if (err) {
                reject(err);
              }
              var data = [cognitoUser, result];
              resolve(data);
            });
          }
        });
      });
    } else {
      var error = {
        code: "cognitoUser undefined"
      }
      return Promise.reject(error);
    }
  }



  refreshToken(){
    //every couple mins refresh the token
    this.getUserFromStorage().then( res=> {
      var token = res[0].signInUserSession.idToken.jwtToken;
      if(token != this.prevToken){
        this.prevToken = token;
        this.tokenSubscription.next(token);
        TokenService.setIdToken(token);
      }
    })
  }


  logout() {
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      var signoutRes = cognitoUser.signOut();
      window.localStorage.removeItem("actingAsUser");
      window.localStorage.removeItem("adminUser");
      return true;
    } else {
      return false;
    }
  }

  //for changing password via email
  forgotPassword(credentials: AuthCredentials): Promise<any> {
    var userData = {
      Username: credentials.email,
      Pool: this.userPool
    };
    var cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
    if (cognitoUser != null) {
      return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: function (result) {
            resolve(false);
          },
          onFailure: function (err) {
            console.log("error:", err);
            reject(err);
          },
          inputVerificationCode() {
            resolve(true);
          }
        });
      });
    } else {
      var error = {
        code: "cognitoUser undefined"
      }
      return Promise.reject(false);
    }
  }

  adminDeleteUser(oldEmail: string): Promise<any> {
    var params = {
      UserPoolId: UserPoolId, /* required */
      Username: oldEmail /* required */
    };
    var cognitoidentityserviceprovider = new AWSCognito.CognitoIdentityServiceProvider(
      {
        apiVersion: '2016-04-18',
        accessKeyId: AccessKey,
        secretAccessKey: SecAccessKey
      }
    );
    return new Promise((resolve, reject) => {
      cognitoidentityserviceprovider.adminDeleteUser(params, (err, data) => {
        if (err){
          reject(err);
        }else{
          resolve(data);
        }
      });
    })
  }


  //reset the password (will send email)
  adminChangePassword(info: any, parentNum: any): Promise<any> {
    var email = info.email;
    if(parentNum == 2 || parentNum == "2"){
      email = info.parent2Email;
    }

    console.log("info:", info);
    const data = {
      email,
      organizationId: info.organizationId
    };
    const options = undefined;
    return this.http
      .post(`${BaseEndpoint}/v2/ua/login/recovery`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
    // this.initializeAWSCognito();
    // var params = {
    //   UserPoolId: UserPoolId, /* required */
    //   Username: email /* required */
    // };
    // var cognitoidentityserviceprovider = new AWSCognito.CognitoIdentityServiceProvider(
    //   {
    //     apiVersion: '2016-04-18',
    //     accessKeyId: AccessKey,
    //     secretAccessKey: SecAccessKey
    //   }
    // );

    // console.log(params, cognitoidentityserviceprovider);
    // return new Promise((resolve, reject) => {
    //   cognitoidentityserviceprovider.adminResetUserPassword(params, (err, data) => {
    //     if (err) {
    //       console.log(err, err.stack); // an error occurred
    //       reject(err);
    //     } else {
    //       console.log(data);           // successful response
    //       resolve(data);
    //     }
    //   });
    // })
  }


  changePassword(credentials: AuthCredentials, verificationCode: string, newPassword: string): Promise<any> {
    var userData = {
      Username: credentials.email,
      Pool: this.userPool
    };
    var cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);
    if (cognitoUser != null) {
      return new Promise((resolve, reject) => {
        var changeHandler = {
          onSuccess: function (result) {
            console.log("success:", result);
            resolve(true);
          },
          onFailure: function (err) {
            console.log("error:", err);
            reject(false);
          },
        };
        var cpr = cognitoUser.confirmPassword(verificationCode, newPassword, changeHandler);
      })

    } else {
      return Promise.reject(false);
    }

  }

  //change password via the old password
  updatePassword(email: string, oldPassword: string, newPassword: string) {
    var cognitoUser = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      this.getUserFromStorage()
        .then(res => {
          var cognitoUser = res[0];
          if (cognitoUser != null) {
            cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
              console.log("change password");
              console.log(err, res);
              if (err) {
                reject(err);
              } else {
                resolve(res);
              }
            })
          }
        })
    })

  }


  isPhoneValid(phone) {
    var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    var digits = phone.replace(/\D/g, "");
    return phoneRe.test(digits);
  }

  validatePassword(password, passwordConfirm) {
    if (password.length < 8 || (password != passwordConfirm)) {
      return false;
    }
    return true;
  }


}
