import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CarePackage, CarePackageOrder } from '../models/care-package';
import { DialogsService } from '../dialog/dialog.service';
import { Subscription } from 'rxjs/Subscription';
import { CarePackagesService } from '../services/care-packages.service';
import { LoadingService } from '../services/loading.service';
import { SquareService } from '../services/square.service';
import { OrganizationService } from '../services/organization.service';
import { ContributorService } from '../../app/services/contributor.service';

declare var moment:any;

@Component({
  selector: 'app-care-packages',
  templateUrl: './care-package-orders.component.html',
  styleUrls: ['./care-package-orders.component.scss']
})
export class CarePackageOrdersComponent implements OnInit {

  public carePackages: CarePackage[] = [];
  public idToken: string;
  public tokenSubscription: Subscription;
  public user: any = null;
  public package: CarePackage = null;
  public order: CarePackageOrder;
  public selectedUser: any = null;
  public authorizedUsers: any[] = null;
  public orders: CarePackageOrder[] = [];
  public deliveryDate = null;
  public showOrderCard: boolean = false;
  public showPlaceOrder: boolean = false;
  public paymentMethod: string = 'credit';
  public organization;
  public ipAddress: string = '';
  public datePickerOptions = {
    singleDatePicker: true,
    drops: 'up',
    minDate: new Date()
  };

  public emptyPackagesMessage = {
    emptyMessage: 'No Packages found'
  }
  public emptyOrdersMessage = {
    emptyMessage: 'No Orders Found'
  }

  constructor(
    public userService: UserService,
    public dialogsService: DialogsService,
    public authService: AuthService,
    public carePackageService: CarePackagesService,
    public loadingService: LoadingService,
    public squareService: SquareService,
    public organizationService: OrganizationService,
    public contributorService: ContributorService
  ) { 
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log('Update token customer-componenet!');
        /// console.log(token);
        this.idToken = token;
      }
    )
    this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      console.log('got ip address:', ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ''){
        this.ipAddress = ipAddress;
      }
    })

  }
  
  //show / hide the sidenav menu
  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }

  ngOnInit() {
    //get user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
        .then(res => {
            this.loadingService.toggleLoadingScreen(false);
            this.user = res;
            this.loadOrganization();
            this.loadCampers();
            this.carePackageService.loadPackagesByOrganization(this.idToken, this.user.organizationId)
            .then( res => {
              console.log('care package:', res);
              var body = JSON.parse(res._body);
              if(body.status == '200'){
                this.carePackages = body.message.Items;
              }
            })
            .catch( err => {
              console.log('err:', err);
            })
            this.loadOrders();
            //this.loadOrganization();
        }); 
  }

  loadOrganization(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
        .then(res => {
          var body = JSON.parse(res._body);
          if (body.status == '200') {
            this.organization = body.message.Items[0];
            console.log('ORganization:', this.organization);
            if (this.organization.carePackageEmailUpdatesEnabled === undefined || this.organization.carePackageEmailUpdatesEnabled === null) {
              this.organization.carePackageEmailUpdatesEnabled = true;
            }
            console.log('ORganization:', this.organization);
          }
          resolve(this.organization);

        })
        .catch(err => {
          console.log('get organization err:', err);
          reject(err);
       })
    });
  }
  

  getUser(): Promise<any> {
      return new Promise((resolve,reject) => {
          this.userService.getUser()
          .then(res => {
              this.idToken = res.idToken;
              resolve(res);
          })
          .catch(err => {
              reject(err);
          });
      });
  }

  loadCampers(){
    let userId = this.user.userId;
    // if (this.user.role == 'account-contributor') {
    //   userId = this.user.accountHolder;
    // }

    let getAuthUsers = this.userService.getAuthorizedUsers(this.idToken, userId);
    let getContributees = this.contributorService.getAccountsContributingTo(this.idToken, userId, this.user.organizationId);
    let promises = [getAuthUsers, getContributees];
    Promise.all(promises)
        .then( (res: any) => {
          var body = JSON.parse(res[0]._body);
          if (body.status == '200') {
            let contributeesBody = JSON.parse(res[1]._body);
            let contributees = contributeesBody.contributees;
            contributees.forEach( camper => {
              camper.isContributingTo = true;
            });
            
            this.authorizedUsers = body.message.Items.concat(contributees);

            var authUserIds = [];
            var myContribution = [];
            this.authorizedUsers.forEach(user => {
              authUserIds.push(user.userId);
              myContribution[user.userId] = 0;
            });
              //get the amount of funds for each auth user
              this.userService.getAuthorizedUserBalances(this.idToken, authUserIds)
                .then(res => {
                  var body = JSON.parse(res._body);
                  if (body.message.Items.length > 0) {
                    console.log('get auth users balances:', body.message.Items);
                    body.message.Items.forEach(item => {
                      if (item.contributorId == this.user.userId) {
                        myContribution[item.authUserId] = item.currentBalance;
                      }
                    })
                    console.log('my contribution:', myContribution);
                    //now set the user's balance for displayinh
                    this.authorizedUsers.forEach(user => {
                      user.currentBalance = myContribution[user.userId];
                    });

                  }
                })
          }
        });
  }
  
  loadOrders(){
    this.carePackageService.loadOrders(this.idToken, this.user.userId, 'userId')
    .then(res => {
      console.log('res:', res);
      var body = JSON.parse(res._body);
      if(body.status == '200'){
        this.orders = body.message.Items;
        console.log('Orders:', this.orders);
      }
    })
  }

  closeCards(){
    this.showOrderCard = false;
    this.showPlaceOrder = false;
  }


  openPackageOrder(carePackage: CarePackage){
    this.package = carePackage;
    this.order = new CarePackageOrder();
    this.package.items.forEach( item => {
      if(item.modifiers && item.modifiers.length > 0){
        this.order.modifiersByItem[item.title] = {};
        item.modifiers.forEach( mod => {
          this.order.modifiersByItem[item.title][mod.name] = '';
        })
      }
    })
    this.showOrderCard = true;
  }
  
  openPlaceOrder(){

    console.log('place order:', this.order);
    // check that they've selected modifiers
    let isValid = true;
    this.package.items.forEach( item => {
      if(item.modifiers && item.modifiers.length > 0){
        item.modifiers.forEach( mod => {
          let selected = this.order.modifiersByItem[item.title][mod.name];
          if(!selected || selected == ''){
            isValid = false;
          }
        })
      }
    });
    if(!isValid){
      this.dialogsService.alert('Incomplete Selection', 'Make sure to select a value for each variation');
      return;
    }
    this.showPlaceOrder = true;
    this.showOrderCard = false;
  }


  isValid(carePackage: CarePackage): Boolean{
    let valid = true;
    console.log('validate');
    console.log(carePackage);
    if(!carePackage.price || carePackage.price <= 0){
      valid = false;
    }
    if(!carePackage.title || carePackage.title == ''){
      valid = false;
    }
    if(!carePackage.items || carePackage.items.length == 0){
      valid = false;
    }
    return valid;
  }


  //process the transaction
  checkout(){
    //validate it first
    if(!this.selectedUser || !this.paymentMethod){
      this.dialogsService.alert('No camper selected', 'You must select a camper to make this purchase for');
      return;
    }
    if(this.paymentMethod == 'balance'){
      //make sure this camper has enough money...
      if(this.selectedUser.currentBalance < this.package.price){
        this.dialogsService.alert('Insufficient Funds', 'There are insufficient funds on the selected camper account. You can either add funds to their account on the Campers page, or pay with Credit Card');
        return;
      }
      this.subtractFromBalance();
    }else if(this.paymentMethod == 'credit'){
      if(!this.user.squareInfo || !this.user.squareInfo.cards || this.user.squareInfo.cards.length === 0){
        this.dialogsService.alert('No card on file', 'You can add a credit card to your account from the Payments page');
        return;
      }
      console.log('process!');
      console.log('package:', this.order);
      this.processCreditTransaction();
    }
  }
  

  processCreditTransaction(){
    console.log('invoking charge customer...');
    this.loadingService.toggleLoadingScreen(true);
    let squareInfo = this.user.squareInfo;
    
    this.squareService.chargeCustomer(this.idToken, this.user.email, this.package.price, squareInfo.squareCustomerId, squareInfo.defaultCardId, this.user.organizationId, this.user.userId, this.user.firstName, this.user.lastName, this.ipAddress, null, false)
    .then(res => {
      console.log('charge res:')
      console.log(res);
      var body = JSON.parse(res._body);
      if(body.status == '200'){
        //success
        this.submitOrder();
      }else{
        this.loadingService.toggleLoadingScreen(false);
        this.dialogsService.alert('Processing Error', 'There was an error processing this transaction');
      }
    })
    .catch(err => {
      console.log('error processing credit transaction:', err);
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert('Processing Error', 'There was an error processing this transaction');
    })
  }

  subtractFromBalance(){
    console.log('package:', this.order);
    this.loadingService.toggleLoadingScreen(true);
    this.carePackageService.subtractFromBalance(this.idToken, this.package.price, this.user.userId, this.selectedUser.userId)
      .then(res => {
        console.log('subtract from balance res:', res);
        let body = JSON.parse(res._body);
        console.log('body:', body);
        if(body.status == '200'){
          //it worked in theory
          console.log('worked in theory...');
          this.submitOrder();
        }else{
          this.loadingService.toggleLoadingScreen(false);
          console.log(body);
          this.dialogsService.alert('Processing Error', 'There was an error processing this transaction. Check that the account has the necessary funds');
        }
        //"{"status":"200","message":{"Attributes":{"currentBalance":64.99}}}"
      })
      .catch(err => {
        console.log('subtract err:', err);
        this.loadingService.toggleLoadingScreen(false);
        this.dialogsService.alert('Processing Error', 'There was an error processing this transaction');
      })
  }


  submitOrder(){
    console.log('in submit order...');
    this.carePackageService.placeOrder(this.idToken, this.user.userId, this.selectedUser.userId, this.package.packageId, this.user.organizationId, 
      this.package.price, this.user.firstName, this.user.lastName, this.selectedUser.firstName, this.selectedUser.lastName, this.package.title, this.order.modifiersByItem,
      this.order.orderNotes, this.deliveryDate, this.selectedUser.cabinName)
      .then( res => {
        this.loadingService.toggleLoadingScreen(false);
        console.log('place order res:', res);
        var body = JSON.parse(res._body)
        if(body.status == '200'){
          this.showOrderCard = false;
          this.showPlaceOrder = false;
          this.dialogsService.alert('Order Placed!', 'Your order has been placed!');
          // send confirmation email
          console.log('now send the conf. email');
          if (this.organization.carePackageEmailUpdatesEnabled) {
            this.carePackageService.sendCarePackageEmail(this.idToken, this.user, this.package.title, this.organization, 'ordered')
            .then( res => {
              console.log('Care pacakge ordered res:', res);
            })
            .catch( err => {
              console.log('err:', err);
            });
          }
          this.ngOnInit();
        }else{
          this.dialogsService.alert('Processing Error', 'There was an error placing your order. Please contact support@funfangle.com');
        }
      })
      .catch( err => {
        this.dialogsService.alert('Processing Error', 'There was an error placing your order. Please contact support@funfangle.com');
      })
  }

  selectedDate(value: any, dateInput: any) {
    console.log(value);
    console.log(dateInput);
    this.deliveryDate = value.start;
    console.log(this.deliveryDate);
  }




}
