import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CustomerService } from '../services/customer.service';
import { LoadingService } from '../services/loading.service';
import { DialogsService } from '../dialog/dialog.service';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { OrganizationService } from '../services/organization.service';
import { Subscription } from 'rxjs/Subscription';
import { OrganizationSettingsService } from '../services/organization-settings.service'
import { ContributorService } from '../services/contributor.service';
import { TransactionsService } from '../services/transactions.service';
import { SquareService } from '../services/square.service';

declare var moment:any;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  public idToken: string;
  public user: any = null;
  public organization: any;
  public org: any;
  public orgData: any;
  public organizationId: any;
  public customers = [];
  public cust = [];
  public sCust= [];
  public originalCustomers = [];
  public authorizedUsers = [];
  public authUsers = [];
  public myCamper = [];
  public showAddCustomer: boolean = false;
  public showEditCustomer: boolean = false;
  public showAddUser: boolean = false;
  public showParentInfo: boolean = false;
  public pointOfSaleEnabled: boolean = true;
  public spendingAccountsEnabled: boolean = false;
  public credentials = new AuthCredentials();
  public custInfo: any;
  public searchText = "";
  public tokenSubscription: Subscription;
  public manageFundsUser: any = null;
  public totalFunds: number = 0;
  public showCheckBox: boolean = false;
  public showForwardButton: boolean = false;
  public showBatchOperation: boolean = false;
  public selected = [];
  public columns = [];
  public globalStatus: any;
  public correctedEmail = "";
  public selectedStatus: any = null;
  public newfilter: boolean = false;
  public newFilterStatus: string;
  public notVerified = [];
  public notInvited = [];
  public noFunds =[];
  public noImage = [];
  public allocateFund = [];
  public parentInfo = [];
  public assocCamper = [];
  public pInfo = {};
  public pTrans = [];
  public customerStatus: string;
  public showUserTransactions = false;
  public sessions = [];
  public allSessions = [];
  public startSeconds;
  public endSeconds;
  public displayTransactions = [];
  public ascendingSort: boolean = true;
  public refundDetails = {};
  public showRefundDetails: boolean = false;

  public newCustomer = {
    firstName: "",
    lastName: "",
    email: "",
    role: "account-holder",
    organizationId: "",
    relationship: "relative",
    phone: "",
    verified: false,
    parent2FirstName: "",
    parent2LastName: "",
    parent2Email: ""
  }

  newUser = {
    firstName: "",
    lastName: "",
    pin: "",
    confirmPin: "",
    dailySpendingLimit: "",
    userId: "",
    nickname: "",
    session: "",
    isStaff: false
  }

  status = [
    "All Status",
    "Complete",
    "Not Verified",
    "Not Invited",
    "No Funds",
    "No Image",
    "Allocate Funds"
  ]

  public singleDate: any;

  public dateInputs: any = [
      {
          start: moment().subtract(12, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(9, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(4, 'month'),
          end: moment()
      },
      {
          start: moment(),
          end: moment().add(5, 'month'),
      }
  ];

  public mainInput = {
      start: moment().subtract(90, 'days'), //moment(this.start.valueOf()),
      end: moment((new Date()).valueOf())
  }
  public ipAddress: string = "";

  constructor(
    public customerService: CustomerService,
    public userService: UserService,
    public dialogsService: DialogsService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public loadingService: LoadingService,
    public organizationSettingsService: OrganizationSettingsService,
    public contributorService: ContributorService,
    public transactionsService: TransactionsService,
    public squareService: SquareService

  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token customer-componenet!");
        /// console.log(token);
        this.idToken = token;
      }
    )

    this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      console.log("got ip address:", ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ""){
        this.ipAddress = ipAddress;
      }
    })

  }

  ngOnInit() {
    //this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        //this.loadingService.toggleLoadingScreen(false);
        this.user = res;
        this.columns = this.status;
        if (res != null) {
          // this.setStatus();
          this.getCustomer();
        }
      }).catch(err => {
     // this.loadingService.toggleLoadingScreen(false);

    })

  }

  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        })
        .catch(err => {
          console.log("Get Customer err", err);
        })
    })
  }

  reconcileBalance(user){
    this.loadingService.toggleLoadingScreen(true);
    this.transactionsService.reconcileBalances(this.idToken, user.userId)
    .then( res => {
      console.log("updated..");
      setTimeout( () => {
        this.getCustomer();
        this.loadingService.toggleLoadingScreen(false);
      },1000);
    })
    .catch( err => {
      console.log("updated..");
      setTimeout( () => {
        this.getCustomer();
        this.loadingService.toggleLoadingScreen(false);
      },1000);
    })
  }


  getCustomer(): Promise<any>{
    this.organizationId = this.user.organizationId;
    this.loadOrganization();
    return new Promise( (resolve, reject) => {
      if (this.cust.length > 0) {
        this.cust = [];
      }
      this.loadingService.toggleLoadingScreen(true);
      this.customerService.getAllCustomer(this.organizationId)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          // console.log('customer.getcustomer', res);
          var body = JSON.parse(res._body);
          var customer = body.items;
          // var newData: any;
          customer.forEach(user => {
             if (user.role == 'account-holder' || user.role == 'organization-manager' || user.role == 'organization-employee' || user.role == 'store-manager' || user.role == 'organization-counselor') {
               if (user.parentStatus && user.parentStatus.indexOf("Not Invited") !== -1 && user.inviteSent) {
                 user.parentStatus = ["Not Verified"];
                 console.log("Found a case...");
                 console.log(user);
               }
               this.cust.push(user);
            }
           })
          this.cust = this.cust.sort( (a,b) => {
            if (!a.lastName) {
              return 1;
            } else if (!b.lastName) {
              return -1;
            }
            if(a.lastName.toLowerCase() > b.lastName.toLowerCase()){
              return 1;
            }else if(b.lastName.toLowerCase() > a.lastName.toLowerCase()){
              return -1;
            }else{
              return 0;
            }
          });
          this.originalCustomers = this.cust;
          console.log(this.originalCustomers);
          resolve(true);
        }).catch(err => {
          this.loadingService.toggleLoadingScreen(false);
        console.log("Get All Customer Err", err);
        reject(err);
      })
    })
  }






  updateFilter(searchText) {
    console.log("filter:", searchText);
    console.log("New Filter", this.newfilter);
    // this.showCheckBox = true;
    const val =  searchText;//event.target.value;
    console.log(val);
    if(!this.newfilter){
      if(searchText == ""){
        this.cust = this.originalCustomers;
        this.showCheckBox = false;
        this.showForwardButton = false;
        this.selectedStatus = null;
        this.newfilter= false;
        return;
      }
      const temp = this.originalCustomers.filter(function (d) {
        //console.log("D:" , d);
        if(d.email && d.firstName && d.lastName ){
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        } else {
          return false;
        }
        // else if (d.email && d.firstName && d.lastName) {
        //   var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
        //   return k;
        // }
      })
      this.cust = temp;
    }
    else{
      if(this.newFilterStatus == "Not Verified"){
        const temp = this.notVerified.filter(function (d) {
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        })
        this.cust = temp;
      }
      else if(this.newFilterStatus == "Not Invited"){
        const temp = this.notInvited.filter(function (d) {
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        })
        this.cust = temp;
      }
      else if(this.newFilterStatus == "No Funds"){
        const temp = this.noFunds.filter(function (d) {
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        })
        this.cust = temp;
      }
      else if(this.newFilterStatus == "No Image"){
        const temp = this.noImage.filter(function (d) {
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        })
        this.cust = temp;
      }
      else if(this.newFilterStatus == "Allocate Funds"){
        console.log("Allocate Funds");
        const temp = this.allocateFund.filter(function(d){
          var k = d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.email.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
          return k;
        })
        this.cust = temp;
      }
    }
  }




  openInviteCustomer(){
    this.showAddCustomer = !this.showAddCustomer
  }

  inviteCustomer() {
    if (!this.validateCustomer(this.newCustomer)) {
      this.dialogsService.alert("Invalid Entry", "You must enter a value for each field");
      return;
    }
    this.organizationSettingsService.getSessionMessage(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        console.log(org);
        console.log("Session Message Response:", org);
        //this.organization = org.organizationName;
        this.org = org;
      })
    this.newCustomer.organizationId = this.user.organizationId;
    console.log("invoking add customer!");
    this.customerService.addCustomer(this.idToken, this.newCustomer)
      .then(res => {
        var body = JSON.parse(res._body);
        console.log("Add customer Reponse :",body);
        if (!res._body.includes('The conditional request failed')) {
          var customerId = body.message;
          console.log("Customer:", customerId);
          console.log(this.org);
          var orgId = this.organization.organizationId;
          console.log("sending invite!");
          this.customerService.sendInvite(this.idToken,this.newCustomer,this.user, customerId, this.organization, orgId)
            .then(res => {
              console.log("Sending Invite to new customer", res)
              console.log(res.status)
              if (!res._body.includes('The conditional request failed')) {
                this.newCustomer = {
                  firstName: "",
                  lastName: "",
                  email: "",
                  role: "account-holder",
                  organizationId: "",
                  relationship: "relative",
                  phone: "",
                  verified: false,
                  parent2FirstName: "",
                  parent2LastName: "",
                  parent2Email: ""
                }
              }
              this.dialogsService.alert("Invitation sent!", "Your email invitation has been sent");
              this.showAddCustomer = false;
              this.getCustomer();
            }).catch(err => {
            console.log("Send invitation error", err)
          })
        }else{
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Could not send invite", "This email is already registered with FunFangle");
        }
      })
  }



  sendInvite(customer: any) {
    this.newCustomer = customer;
      var orgId = this.organization.organizationId;
    this.customerService.sendInvite(this.idToken,this.newCustomer,this.user, customer.userId, this.organization, orgId)
      .then(res => {
        console.log("send invite res:", res);
        customer.inviteSent = true;
        this.dialogsService.alert("Invitation Sent!", "Your email invitation has been sent");
      }).catch(err => {
      console.log("Error resendind Cutomer Invitation", err);
    })

  }

  reSendInvite(customer: any) {

    this.newCustomer = customer;
    var orgId = this.organization.organizationId;
    this.customerService.sendReminderInvite(this.idToken,this.newCustomer,this.user, customer.userId, this.organization, orgId)
    .then(res =>{
      console.log("Sending Reminder Invitation Email", res);
      customer.inviteSent = true;
      this.dialogsService.alert("Invitation Sent!", "Your email invitation has been sent");
    }).catch(err => {
      console.log("Error resendind Cutomer Invitation", err);
    });
    
  }
  sendReminderUnallocEmail(customer){
    this.newCustomer = customer;
    this.customerService.sendReminderUnallocEmail(this.idToken,this.newCustomer,this.user, customer.userId, this.organization)
    .then(res =>{
      console.log(res);
      console.log("Sending Reminder Unallocate Email");
      this.dialogsService.alert("Reminder Sent!", "Your email reminder has been sent");
    }).catch(err => {
      console.log("Error resendind Cutomer reminder", err);
    })
  }

  removeInvitation(customer) {
    this.dialogsService.confirm("Remove " + customer.firstName + "?", "There will be no way to undo this")
      .toPromise()
      .then(res => {
        if (res) {
          this.customerService.removeInvitation(this.idToken, customer.email, this.user.organizationId)
            .then(res => {
              console.log("Customer invitation removed", res)
              this.getCustomer();
            }).catch(err => {
            console.log("Error removing Customer Invitation", err)
          })
        }
      })
  }

  removeAccount(account){
    console.log(account);
    this.dialogsService.confirm("Remove " + account.firstName + " " + account.lastName + " and all associated people?" , "There will by no way to undo this.")
    .toPromise()
    .then(res =>{
      if(res){
        this.customerService.removeAccount(account.organizationId, account.email, account.userId)
        .then(res => {
          console.log("Camper removed: ", res)
          this.getCustomer();
        }).catch(err =>{
          console.log("Error removing camper: ", err);
        })
      }
    })
  }

  validateCustomer(customer) {
    if (customer.firstName == "" || customer.lastName == "" || customer.email == "") {
      return false;
    }
    else {
      return true;
    }
  }

  closeCard() {
    this.showAddCustomer = false;
    this.showEditCustomer = false;
    this.showAddUser = false;
    this.showParentInfo = false;
    this.showUserTransactions = false;
    this.pInfo = {};
    this.refundDetails = {};
    this.showRefundDetails = false;
    this.displayTransactions = [];
  }

  closeBatchCard(){
    this.showBatchOperation = false;
    this.showCheckBox = false;
    this.cust = this.originalCustomers;
    this.selectedStatus = null;
    this.showForwardButton = false;
  }
  openEditCustomer(customer) {
    console.log(customer);
    this.custInfo = Object.assign({}, customer);
    this.custInfo.originalName = `${customer.firstName} ${customer.lastName}`;
    this.showEditCustomer = true;
  }

  adminChangePassword(parentNum) {
    var firstName = this.custInfo.firstName;
    var email = this.custInfo.email;
    if(parentNum == 2 || parentNum == "2"){
      firstName = this.custInfo.parent2FirstName;
      email = this.custInfo.parent2LastName;
    }
    this.dialogsService.confirm("Reset " + firstName + "'s password?", "This will send them an email with a reset code they will need to enter on login")
      .toPromise()
      .then(res => {
        if (res) {
          console.log("Changing User Password");
          console.log("info:", this.custInfo);
          this.authService.adminChangePassword(this.custInfo, parentNum)
            .then(res => {
              console.log("admin change password res:", res);
              this.dialogsService.alert("Success!", `A reset code was sent to ${email}`);
            })
            .catch(err => {
              this.dialogsService.alert("Could not reset", "An error ocurred and the user could not be reset. This user may not have activated their account. You may need to re-send them an activation email");
            })
        }
      })
  }

  adminUpdateEmail(oldEmail: string, newEmail: string) {
    console.log("customer:", this.custInfo);
    var orgId = this.organization.organizationId;
    if (newEmail == oldEmail) {
      this.dialogsService.alert("Must enter a new email", "The user already has that email registered");
      return;
    }
    if(newEmail == "" || newEmail == undefined){
      this.dialogsService.alert("Invalid email", "The email must not be empty");
      return;
    }
    console.log("update the email to:", newEmail);
    console.log("email was:", oldEmail);
    this.dialogsService.confirm("Update email for " + this.custInfo.firstName + "?", "This will send them an email to reset their password")
      .toPromise()
      .then(res => {
        if (res) {
          this.loadingService.toggleLoadingScreen(true);
          this.organizationService.updateCustomerEmail(this.idToken, oldEmail, newEmail, orgId)
            .then(res => {
              
              console.log("update customer email res:", res);
              var body = JSON.parse(res._body);
              //check for errors
              console.log("body:", body);

              if (body.hasOwnProperty('code') && body.code == "ConditionalCheckFailedException") {
                this.loadingService.toggleLoadingScreen(false);
                this.dialogsService.alert("Could not update email", "A user with this email already exists");
              } else if (body.status == "200") {
                console.log("it worked");
                //now remove them from cognito...
                if(this.custInfo.verified){
                  this.authService.adminDeleteUser(oldEmail)
                  .then(res => {
                    console.log("admin update email res:", res);
                    this.custInfo.email = newEmail;
                    //re-send the activation email...

                    this.customerService.sendInvite(this.idToken, this.custInfo, this.user, this.custInfo.userId, this.organization, orgId)
                      .then(res => {
                        this.loadingService.toggleLoadingScreen(false);
                        this.dialogsService.alert("Success", this.custInfo.firstName + "'s email has been updated");
                        this.correctedEmail = "";
                        this.showEditCustomer = false;
                        this.getCustomer();
                      }).catch(err => {
                        console.log("send invite err..");
                        this.loadingService.toggleLoadingScreen(false);
                    })
                  })
                  .catch(err => {
                    console.log("update email err:", err);
                    this.loadingService.toggleLoadingScreen(false);
                    this.dialogsService.alert("There was an error updating the email", err.toString());
                  })
                }else{
                  this.custInfo.email = newEmail;
                    //re-send the activation email...
                    this.customerService.sendInvite(this.idToken,this.custInfo, this.user, this.custInfo.userId, this.organization, orgId)
                      .then(res => {
                        this.dialogsService.alert("Success", this.custInfo.firstName + "'s email has been updated");
                        this.correctedEmail = "";
                        this.showEditCustomer = false;
                        this.getCustomer();
                      }).catch(err => {
                      console.log("send invite err..");
                      this.dialogsService.alert("There was an error sending a reset email", err.toString());
                    })
                }


              }

            })
            .catch(err => {
              console.log("update customer email err:", err);
              this.loadingService.toggleLoadingScreen(false);
            })
        }

      });


  }
  openAddUser() {
    console.log(this.custInfo);
    this.showAddUser = !this.showAddUser;
    this.showEditCustomer = false;
    this.showAddCustomer = false;
  }



  addUser() {
    var isValid: boolean = this.validateUser(this.newUser);
    if (!isValid) {
      return;
    }
    this.userService.createAuthorizedUser(this.idToken, this.newUser, this.custInfo.userId, this.custInfo.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        console.log("new user body:", body);
        if (body.status == "200") {
          this.dialogsService.alert("Success", "The user was successfully added to the account ");
          this.showAddUser = false;
          var userId = body.message;
          var addedUser = this.newUser;
          addedUser.userId = body.message;
          this.newUser = {
            nickname: "",
            firstName: "",
            lastName: "",
            pin: "",
            confirmPin: "",
            dailySpendingLimit: "",
            userId: "",
            session: "",
            isStaff: false
          }

          this.uploadImage(userId)
            .then(res => {
              if (res.hasOwnProperty('Location')) {
                var imageUrl = res.Location;
                this.userService.uploadUserImageDynamo(this.idToken, imageUrl, userId, this.user.organizationId)
                  .then(res => {
                    this.getCustomer()
                      .then(res => {
                        console.log("Get my auth users .then");
                        console.log(res);
                        //now bring them to the funds page
                        // this.showAddFunds = true;
                        //need to set the managefundsuser
                        this.authorizedUsers.forEach(user => {
                          if (user.userId == userId) {
                            this.manageFundsUser = user;
                          }
                        })
                        console.log("MGU:", this.manageFundsUser);
                      })
                  })
                  .catch(err => {
                    console.log("upload user db err:", err);
                  })
              } else {
                //there was no image to upload
                //now update the users
                this.getCustomer();
              }
            }).catch(err => {
            console.log("upload image err:", err);
          })
        }
      })
      .catch(err => {
        console.log("create user err:", err);
      })
  }


  validateUser(user: any): boolean {
    /*console.log("validating user:", user);
    if (user.pin.length != 0 && user.pin.length < 4) {
      this.dialogsService.alert("Processing error", "Your PIN must be at least 4 characters long");
      return false;
    }
    if (user.pin != user.confirmPin) {
      this.dialogsService.alert("Processing error", "Your PINs do not match!");
      return false;
    }*/
    if (user.firstName == "" || user.lastName == "") {
      this.dialogsService.alert("Processing error", "Your user must have a first and last name");
      return false;
    }

    // var files = document.getElementById('photoupload')['files'];
    // if(!files.length && this.organization.camperPhotosRequired && !user.imageUrl){
    //   this.dialogsService.alert("Photo Required!", "You must add a photo to this account!");
    //   return false;  
    // }

    return true;
  }

  uploadImage(userId): Promise<any> {
    var files = document.getElementById('photoupload')['files'];
    console.log("files is:", files);
    if (!files.length) {
      return Promise.resolve(true);
    }
    var file = files[0];
    var fileName = userId;
    var photoKey = encodeURIComponent(userId);
    return this.userService.uploadUserImageS3(photoKey, file)
  }


  //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }


  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        this.organization = body.message.Items[0];
        this.organization.camperPhotosRequired = (this.organization.camperPhotosRequired != undefined)? this.organization.camperPhotosRequired : true;
        if (this.organization && this.organization.features  && this.organization.features.pointOfSale)  {
          this.pointOfSaleEnabled = true;
        }
        if (this.organization && this.organization.features  && this.organization.features.spendingAccounts)  {
          this.spendingAccountsEnabled = true;
        }
        console.log("got organization:", this.organization);
        console.log("this.organization:", this.organization.organizationName);
      })

    this.organizationService.getOrganizationSessions(this.idToken, this.organizationId)
      .then(res => {
        console.log("get org sessions res:", res);
        var body = JSON.parse(res._body);
        //this.sessions = body.message.Items;
        this.allSessions = [];
        this.allSessions[0] = "All Sessions";



        body.message.Items = body.message.Items.sort( function(x, y){
          if(x.sessionString > y.sessionString){
            return 1;
          }else{
            return -1;
          }
        })

        body.message.Items.forEach( (session) => {
          this.sessions.push(session.sessionString);
          this.allSessions.push(session.sessionString);
        });
        
        console.log("sessions:", this.sessions);
      })
  }

  noFundsReminder(customer){
    // console.log(customer);
    this.userService.getOrgInfo(customer.organizationId)
    .then(res => {
      // console.log("Getting the organization Info:" , res);
      var response = JSON.stringify(res);
      var body = JSON.parse(response);
      var data = body._body;
      var dataItem = JSON.parse(data);
      var newData = dataItem.message.Items[0];
      console.log("Data from organization", newData);
      var orgId = newData.organizationId;
      this.customerService.sendReminderFundEmail(this.idToken,customer,newData, orgId)
      .then(res =>{
        console.log(res);
        this.dialogsService.alert("Success", "Successfuly sent")
      }).catch(err => {
        console.log("err ", err);
        this.dialogsService.alert("Error", "Error sending email to parent");
      })
    })
  }

  uploadPhotoReminder(customer){
    // console.log(customer);
    this.userService.getOrgInfo(customer.organizationId)
    .then(res => {
      console.log("Getting the organization Info:" , res);
      var response = JSON.stringify(res);
      var body = JSON.parse(response);
      var data = body._body;
      var dataItem = JSON.parse(data);
      var newData = dataItem.message.Items[0];
      console.log("Data from organization", newData);
      this.customerService.sendReminderPhotoEmail(this.idToken, customer, newData)
      .then(res => {
        this.dialogsService.alert("Success", "Successfuly sent")
        console.log(res);
      })
    })
  }

  refreshParents(){
    console.log("Refreshing Parent Table");
    this.showCheckBox = false;
    // this.selectedStatus = null;
    this.getCustomer()
      .then( res => {
        if (this.selectedStatus) {
          this.selectStatus({target: { valeu: this.selectedStatus} })
        } else if (this.sessionFilters && this.sessionFilters.length > 0) {
          this.selectSession({value: this.sessionFilters}, this.cust);
        }
      });
    
  }


  updateCust2Name(){
    if(this.custInfo.parent2FirstName == "" || this.custInfo.parent2LastName == ""){
      this.dialogsService.alert("Invalid format", "The first and last names cannot be blank!");
      return;
    }
    this.organizationService.updateCustomerName(this.idToken, this.custInfo, 2, this.user.organizationId)
    .then( res=> {
      console.log("update customer res:", res);
      this.dialogsService.alert("Success!", "The customer's name has been updated!");
      this.showEditCustomer = false;
      this.getCustomer();
    })
    .catch( err => {
      console.log("err:", err);
      this.dialogsService.alert("Error", "There was an error updating the customer's name: " + err.toString());
    })

  }

  updateCustName(){
    if(this.custInfo.firstName == "" || this.custInfo.lastName == ""){
      this.dialogsService.alert("Invalid format", "The first and last names cannot be blank!");
      return;
    }
    console.log("updating name to: " + this.custInfo.firstName + " " + this.custInfo.lastName);
    this.organizationService.updateCustomerName(this.idToken, this.custInfo, 1, this.user.organizationId)
    .then( res=> {
      console.log("update customer res:", res);
      this.dialogsService.alert("Success!", "The customer's name has been updated!");
      this.showEditCustomer = false;
      this.getCustomer();
    })
    .catch( err => {
      console.log("err:", err);
      this.dialogsService.alert("Error", "There was an error updating the customer's name: " + err.toString());
    })
  }

  onSelect({selected}){
    this.showForwardButton = true;
    console.log("Select Event", selected, this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    if(this.selected.length > 0 ){
      this.showForwardButton = true;
    }
    else{
      this.showForwardButton = false;
    }
    console.log("After splicing", this.selected);
  }
  onActivate(e){
    console.log("Activate event", e);
  }

  openBatchAction() {
    console.log("selected status is:", this.selectedStatus);
    switch(this.selectedStatus) {
      case 'No Image':
        this.globalStatus = "noImage";
        break;
      case 'Not Verified':
        this.globalStatus = "reSendInvite";
        break;
      case 'No Funds':
        this.globalStatus = "noFunds";
        break;
      case 'Not Invited':
        this.globalStatus = "sendInvite";
        break;
    }
    this.showBatchOperation = true;
    console.log("Opening Batch actions");
  }

  sendBatchInvite(){
    var orgId = this.organization.organizationId;
    console.log("Sending Invite to:", this.selected);
    this.organizationSettingsService.getSessionMessage(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        this.organization = org.organizationName;
        this.org = org;
        this.loadingService.toggleLoadingScreen(true);
        let numSent = 0;
        this.selected.forEach(user => {
          this.customerService.sendInvite(this.idToken,user,this.user, user.userId, this.org, orgId)
          .then( res => {
            numSent++;
            console.log("Sent:", numSent);
            console.log("out of:", this.selected.length);
            if (numSent === this.selected.length) {
              this.loadingService.toggleLoadingScreen(false);
              this.dialogsService.alert("Invitations sent!", "Your email invitations have been sent");
              this.refreshParents();
              this.closeBatchCard();
            }
          })
          .catch( err => {
            console.log("err", err);
            numSent++;
          })
        })
          
      })

  }

  reSendBatchInvite(){
    console.log("Resending Invite to:", this.selected);
    var orgId = this.organization.organizationId;
    console.log("Sending Invite to:", this.selected);
    this.organizationSettingsService.getSessionMessage(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        this.organization = org.organizationName;
        this.org = org;
         let numSent = 0;
        this.selected.forEach(user => {
          this.customerService.sendReminderInvite(this.idToken,user,this.user, user.userId, this.org, orgId)
          .then( res => {
            numSent++;
            console.log("Sent:", numSent);
            console.log("out of:", this.selected.length);
            if (numSent === this.selected.length) {
              this.loadingService.toggleLoadingScreen(false);
              this.dialogsService.alert("Invitations sent!", "Your email invitation reminders have been sent");
              this.refreshParents();
              this.closeBatchCard();
            }
          })
          .catch( err => {
            console.log("err", err);
            numSent++;
          })
          
        })
      })
  }


  uploadPhotoBatchReminder(){
    console.log("Upload Photo Batch Reminder email", this.selected);
    this.organizationSettingsService.getSessionMessage(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        this.organization = org.organizationName;
        this.org = org;
        let numSent = 0;
        this.selected.forEach(user => {
          this.customerService.sendReminderPhotoEmail(this.idToken, user, this.org)
          .then( res => {
            numSent++;
            console.log("Sent:", numSent);
            console.log("out of:", this.selected.length);
            if (numSent === this.selected.length) {
              this.loadingService.toggleLoadingScreen(false);
              this.dialogsService.alert("Reminders sent!", "Your photo reminders have been sent!");
              this.refreshParents();
              this.closeBatchCard();
            }
          })
          .catch( err => {
            console.log("err", err);
            numSent++;
          })
        });
        

      })

  }

  noFundsBatchReminder(){
    console.log("No Funds Batch Reminder Email", this.selected);
    this.organizationSettingsService.getSessionMessage(this.idToken, this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        this.organization = org.organizationName;
        this.org = org;
        var orgId = this.org.organizationId;
        let numSent = 0;
        this.selected.forEach(user => {
          this.customerService.sendReminderFundEmail(this.idToken,user,this.org, orgId)
          .then( res => {
            numSent++;
            console.log("Sent:", numSent);
            console.log("out of:", this.selected.length);
            if (numSent === this.selected.length) {
              this.loadingService.toggleLoadingScreen(false);
              this.dialogsService.alert("Reminders sent!", "Your reminders to add funds have been sent!");
              this.refreshParents();
              this.closeBatchCard();
            }
          })
          .catch( err => {
            console.log("err", err);
            numSent++;
          })
        })
         

      })
  }
  
  
selectStatus(e) {
  console.log("selected:");
  console.log(e.target.value);
  this.selectedStatus = e.target.value;
  var value: any;
  switch(e.target.value){
    case "All Status":
      this.cust = this.originalCustomers;
      this.showCheckBox = false;
      this.showForwardButton = false;
      this.newfilter= false;
      break;
    case "Not Verified":
      this.toggle(e.target.value);
      this.showCheckBox = true;
      this.newfilter = true;
      this.newFilterStatus = "Not Verified";
      break;
    case "Not Invited":
      this.toggle1(e.target.value);
      this.showCheckBox = true;
      this.newfilter = true;
      this.newFilterStatus = "Not Invited";
      break;
    case "No Image":
      this.toggle2(e.target.value);
      this.showCheckBox = true;
      this.newfilter = true;
      this.newFilterStatus = "No Image";
      break;
    case "No Funds":
      this.toggle3(e.target.value);
      this.showCheckBox = true;
      this.newfilter = true;
      this.newFilterStatus = "No Funds";
      break;
    case "Allocate Funds":
      this.toggle4(e.target.value);
      this.newfilter = true;
      this.newFilterStatus = "Allocate Funds"
      break;
    case "Complete":
      console.log("in complete!");
      this.toggle4(e.target.value);
      this.newfilter = true;
      this.newFilterStatus = "Complete"
      break;
  }
  if (this.sessionFilters && this.sessionFilters.length > 0) {
    console.log("Seession is selected...");
    console.log(this.cust.length);
    console.log(this.sessionFilters);
    this.selectSession({value: this.sessionFilters}, this.cust);
  }
 }
// Not verified
toggle(col){
  // console.log(col)
  const val = col;
  const isChecked = this.isChecked(col);
  console.log("Checked:", isChecked);
  if(isChecked){
    const t = this.originalCustomers.filter(function (k){
      if(k.parentStatus != undefined && k.parentStatus[0] == 'Not Verified'){
        return true;
      }
    })
    this.notVerified = t;
    this.cust = t;
  }
}
// send invite
toggle1(col){
  const val = col;
  const isChecked = this.isChecked(col);
  console.log("Checked:", isChecked);
  if(isChecked){
    const t = this.originalCustomers.filter(function (k){
      if(k.parentStatus != undefined && k.parentStatus[0] == 'Not Invited'){
        return true;
      }
    })
    this.notInvited = t;
    this.cust = t;
  }
}
// no image
toggle2(col){
  const val = col;
  const isChecked = this.isChecked(col);
  console.log("Checked:", isChecked);
  if(isChecked){
    const t = this.originalCustomers.filter(function (k){
      if(k.parentStatus != undefined && k.parentStatus[0] == 'No Image'){
        return true;
      }
    })
    this.noImage = t;
    this.cust = t;
  }
}
// no funds
toggle3(col){
  const val = col;
  const isChecked = this.isChecked(col);
  if(isChecked){
    const t = this.originalCustomers.filter(function (k){
      if(k.parentStatus != undefined && k.parentStatus[0] == 'No Funds'){
        return true;
      }
    })
    this.noFunds = t;
    this.cust = t;
  }
}
toggle4(col){
  console.log("toggl4:", col);
  const val = col;
  const isChecked = this.isChecked(col);
  console.log("new filter status:",col);
  if(isChecked){
    const t= this.originalCustomers.filter((k) => {
      console.log("new filter status:", col);
      if(k.parentStatus != undefined && k.parentStatus[0] == col){
        return true;
      }
    })
    this.allocateFund = t;
    this.cust = t;
  }
}

isChecked(col){
  return this.columns.find(j =>{
    return j === col;
  })
}


  addParent2(parent2First = "", parent2Last = "", parent2Email = ""){
    console.log("Add a second parent:", this.custInfo);
    //validate
    if(parent2First == "" || parent2Last == "" || parent2Email == ""){
      this.dialogsService.alert("Missing fields", "You must enter the first and last names and email address for any new parent");
      return;
    }

    //needs to add the parent to the db and send email only to 2nd parent..
    this.customerService.addParent2(this.idToken, this.custInfo, parent2First, parent2Last, parent2Email, this.user.organizationId)
    .then(res => {
      console.log("Added parent2 res:", res);
      //now email them
      this.user.parent2Email = parent2Email;
      this.user.parentNum = 2;
      this.user.parent2FirstName = parent2First;
      this.user.parent2LastName = parent2Last;
      this.customerService.sendInvite(this.idToken, this.user, this.user, this.user.userId, this.organization, this.organization.organizationId, true)
      .then(res => {
        console.log("send email res:", res);
        this.dialogsService.alert("Invite Sent!", "The parent has been added and invited successfully");
        this.closeCard();
        this.refreshParents();
      })
      .catch(err => {
        console.log("send email err:", err);
        this.dialogsService.alert("Processing Error", "There was an error sending an invitaiton email");
      })

    })
    .catch( err => {
      console.log("err addParent2 :", err);
      this.dialogsService.alert("Processing Error", "There was an error processing your request");
    })


  }

toggleParentInfo(parentInfo){
  this.showParentInfo = !this.showParentInfo;
  this.parentInfo = parentInfo;
  this.getParentCamperInfo();
}

getParentCamperInfo(){
  var response = JSON.stringify(this.parentInfo);
  var body = JSON.parse(response);
  var pInfo = body
  console.log(body.userId);
  var parentUserId = body.userId;
  this.customerService.getAuthorizedUsersAssoc(this.idToken, parentUserId)
  .then(res => {
    var body = JSON.parse(res._body);
    var message = body.message.Items;
    console.log(message)
    this.assocCamper = message;
    this.contributorService.getMyContributors(this.idToken,parentUserId)
    .then(res => {
      var body = JSON.parse(res._body);
      var contributors = body.message.Items;

      var contributorIds = [];
      contributors.forEach(contributor => {
        contributorIds.push(contributor.userId);
      })

      //add parent id;
      contributorIds.push(parentUserId);

      this.contributorService.getContributions(this.idToken,contributorIds)
      .then(res => {
        var body = JSON.parse(res._body);
        var contributions = body.message.Items;

        // set customer balance
        if(pInfo.currentBalance == undefined){
          if(pInfo.availableBalance != undefined){
            pInfo.currentBalance = pInfo.availableBalance;
            pInfo.totalFunds = pInfo.availableBalance;
          }else{
            pInfo.currentBalance = 0;
            pInfo.totalFunds = 0;
          }
        }
        console.log(this.parentInfo);
        contributions.forEach(contribution => {
              if(pInfo.userId == contribution.contributorId){
                // console.log("Here");
                pInfo.currentBalance += contribution.currentBalance;
                pInfo.totalFunds += contribution.currentBalance;
              }
            })

            console.log(pInfo);
            this.pInfo = pInfo;
      })
    })
  })

}



openUserTransactions(user) {
  console.log(user);
  this.showUserTransactions = !this.showUserTransactions;
  this.pInfo = user;
  this.getUserTransactions(user);
}

getUserTransactions(user) {
  console.log("get trans:", user);
  this.loadingService.toggleLoadingScreen(true);
  this.transactionsService.getParentTransactions(this.idToken, user.userId)
  .then( res => {
    console.log("res:", res);
    const body = JSON.parse(res._body);
    this.pTrans = body.message.Items;
    this.pTrans = this.pTrans.sort( (a,b) => {
      return b.timeStamp - a.timeStamp;
    });
    console.log("pTrans:", this.pTrans);
    this.loadingService.toggleLoadingScreen(false);
    let endSeconds = this.mainInput.end._d.getTime()/1000;
    let nowSeconds = Date.now() / 1000;
    // update the end time if it's w/in last few hours
    if (nowSeconds - endSeconds < 6*60*60) endSeconds = nowSeconds;
    this.filterTransactions(this.mainInput.start._d.getTime()/1000, endSeconds);
    this.displayTransactions.forEach( trans => {
      if (trans.timeStamp) {
        let date = new Date(trans.timeStamp*1000);
        let dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
        trans.deviceDate = dateString;
      }
      if (trans.type === 'deposit') {
        trans.amount = Math.abs(trans.amount);
      }
    })
  })
}

public refundDepositV2(transaction) {
  console.log("refund depositV2:", transaction);
  var amount = transaction.amount;
  if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
    amount = transaction.remainingBalance;
  }
  this.dialogsService.input("Refund this deposit?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
  .toPromise()
  .then( res => {
    console.log("res:", res);

    if(res[0]){
      var refundAmount = res[1];
      this.loadingService.toggleLoadingScreen(true);
      this.squareService.refundDepositV2(transaction.transactionId, refundAmount, this.user.organizationId)
      .then( res => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("refund res:", res);
        // var body = JSON.parse(res._body);

        this.dialogsService.alert("Success", "The refund has been initiated!");
        this.getUserTransactions(this.pInfo);
      })
      .catch( err => {
        console.log("refund err:", err);
        // var body = JSON.parse(err._body);
        // console.log("body:", body);
        // var errorDetail = body.errors[0].detail;
        this.loadingService.toggleLoadingScreen(false);
        // this.dialogsService.alert("Processing error", errorDetail);
        this.dialogsService.alert("Processing error", 'An error was encountered when initiating the refund. Please contact support');
      })
    }
  })
}

refundTransactionV1(transaction){
    console.log("refund transaction:", transaction);
    var amount = transaction.amount;
    if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
      amount = transaction.remainingBalance;
    }
    this.dialogsService.input("Refund this transaction?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
    .toPromise()
    .then( res => {
      console.log("res:", res);

      if(res[0]){
        var refundAmount = res[1];
        this.loadingService.toggleLoadingScreen(true);
        var timeZone = -4;
        if(this.organization.timeZoneOffset != undefined){
          timeZone = this.organization.timeZoneOffset;
        }
        
        this.squareService.refundTransaction(this.idToken, transaction.transactionId, transaction.squareTransactionId, transaction.squareTenderId, transaction.squareLocationId, refundAmount, timeZone, this.user.organizationId)
        .then( res => {
          this.loadingService.toggleLoadingScreen(false);
          console.log("refund res:", res);
          var body = JSON.parse(res._body);

          this.dialogsService.alert("Success", "The refund has been processed!");
          this.getUserTransactions(this.pInfo);
        })
        .catch( err => {
          console.log("refund err:", err);
          var body = JSON.parse(err._body);
          console.log("body:", body);
          var errorDetail = body.errors[0].detail;
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing error", errorDetail);
        })

      }
    
    })

  }

  downloadTransactions(){
    console.log("data to export is:", this.pTrans);

    this.loadingService.toggleLoadingScreen(true);
    var timeZone = -4;
    if(this.organization['timeZoneOffset'] != undefined){
      timeZone = this.organization['timeZoneOffset'];
    }
    this.transactionsService.parentTransactionReport(this.idToken, this.pInfo['userId'], timeZone )
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log("transaction report:", res);
      var body = JSON.parse(res._body);
      var csv = body.csv;
      var blob = new Blob([csv],{type:'text/csv'});
      var url = window.URL.createObjectURL(blob);
      var fileName = "Transactions for " + this.pInfo['firstName'] + " " + this.pInfo['lastName'] + ".csv";
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href= url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
      console.log("caught err:", err);
    })


  }

  importAccounts(){
    window.location.href = 'https://console.funfangle.com/account/import/upload'
  }

  public viewRefundDetails(refundDetails) {
    console.log("View refund details:", refundDetails);
    this.showRefundDetails = true;
    refundDetails.amount = refundDetails.amount_money.amount / 100;
    if (refundDetails.amount) refundDetails.amount = refundDetails.amount.toFixed(2);
    const created = new Date(refundDetails.created_at);
    const approved = new Date(refundDetails.approvedAt * 1000);
    refundDetails.createdAtDate = `${created.getMonth() + 1}/${created.getDate()}/${created.getFullYear()}`;
    refundDetails.approvedAtDate = `${approved.getMonth() + 1}/${approved.getDate()}/${approved.getFullYear()}`;
    this.refundDetails = refundDetails;
  }


  sortAlphabetically(){
    this.ascendingSort = !this.ascendingSort;
    console.log("sort alphabetically");
    if(this.ascendingSort){
      console.log("ascending scort!");
      this.cust = this.cust.sort( (a,b) => {
        if(a.lastName.toLowerCase() > b.lastName.toLowerCase()){
          return 1;
        }else if(b.lastName.toLowerCase() > a.lastName.toLowerCase()){
          return -1;
        }else{
          return 0;
        }
      });
    }else{
      console.log("descending sort!");
      this.cust = this.cust.sort( (a,b) => {
        if(a.lastName.toLowerCase() > b.lastName.toLowerCase()){
          return -1;
        }else if(b.lastName.toLowerCase() > a.lastName.toLowerCase()){
          return 1;
        }else{
          return 0;
        }
      });
    }
    this.cust = [...this.cust];
  }

  public sessionFilters = [];

  selectSession(e, cust = null){
      try{
        console.log("selected sessions:");
        console.log(e.value);
        this.sessionFilters = e.value;
        let temp = [];
        if (cust) {
            temp = cust.slice();
          } else {
            temp = this.originalCustomers.slice();
          }
        //console.log(e.value.length);
        if(e.value != undefined && e.value.length == 0){
          this.cust = temp.slice();
        }else if(e.value.indexOf("All Sessions") != -1){
          this.cust = temp.slice();
        }else{
          let sessionsNoSpaces = [];

          e.value.forEach( session => {
            sessionsNoSpaces.push(session.replace(/ /g, ""));
          })
          this.cust = temp.filter( customer => {
            let custSessionsNoSpaces = [];
            if(customer.sessions == undefined){
              return false;
            }
            customer.sessions.forEach( session => {
              custSessionsNoSpaces.push(session.replace(/ /g, ""));
            });
            if (customer.sessions.length === 1) {
              if (sessionsNoSpaces.indexOf(custSessionsNoSpaces[0]) !== -1) {
                return true;
              } else {
                return false;
              }
            } else {
              console.log("mult sessions:");
              console.log(custSessionsNoSpaces);
              console.log("sns:", sessionsNoSpaces);
              var intersection = this.intersect(custSessionsNoSpaces, sessionsNoSpaces);
              console.log("intersection:", intersection);
              if(intersection != undefined && intersection.length > 0){
                return true;
              }else{
                return false;
              }
            }
          })
        }
      }catch(err){
        console.log("selected session err...");
        console.log(err);
        console.log(e.value);
      }
    }


    intersect(a, b) {
      var t;
      if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
      try {
        return a.filter(function (e) {
            return b.indexOf(e) > -1;
        });
      } catch( err) {
        console.log("failed for conditions:");
        console.log(a, b);
        return [];
      }
  }



    //date range picker stuff

  public filterTransactions(start, end){
    console.log("filter trans!");
    this.startSeconds = start;
    this.endSeconds = end;
    
    console.log("start seconds:", this.startSeconds);
    console.log("this.endSeconds:", this.endSeconds);
    console.log("Ptrans:", this.pTrans.length);

    this.displayTransactions = this.pTrans.filter( (transaction) => {
      if(transaction.timeStamp >= start && transaction.timeStamp <= end){
        return true;
      }else{
        return false;
      }
      
    })

    console.log("display trans:", this.displayTransactions);
  }


  public selectedDate(value: any, dateInput: any) {
      dateInput.start = value.start;
      dateInput.end = value.end;

      console.log("in selected date...");
      console.log(value);
      console.log(dateInput);
      var startSeconds = value.start._d.getTime() / 1000;
      var endSeconds = value.end._d.getTime() / 1000;

      //now need to convert it to the organization's offset..

      var offset = this.organization.timeZoneOffset;

      startSeconds += 3600*offset;
      endSeconds += 3600*offset;
      console.log("start seconds:", startSeconds);
      console.log("end seconds:", startSeconds);


      this.filterTransactions(startSeconds,endSeconds);

      
  }
    
    public singleSelect(value: any) {
        this.singleDate = value.start;
    }

    public applyDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;

        console.log("in apply date...");
        console.log(value);
        console.log(dateInput);
    }

    public calendarEventsHandler(e:any) {
        console.log(e);
        //this.eventLog += '\nEvent Fired: ' + e.event.type;
    }


    public async reverseDonation(donation) {
      console.log("Reverse donation:", donation);
      let confirmRes = await this.dialogsService.confirm("Reverse Donation?", "This will push the money back on the account and create a donation reversal.").toPromise();
      if (confirmRes) {
        try {
          this.loadingService.toggleLoadingScreen(true);
          const { authUserId, timeStamp, organizationId, transactionId } = donation;
          const res = await this.transactionsService.reverseDonation(authUserId, timeStamp, organizationId, transactionId);
          console.log("res:", res);
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Success", "The donation has been reversed!");
          this.getUserTransactions(this.pInfo);
        } catch (err) {
          console.log("Err:", err);
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing Error", "An error occurred while attempting to reverse the deposit");
        }
      }
    }

  

}
