import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { ApiEndpoint , SquareEndpoint, EmailEndpoint } from '../app.constants';
import { TokenService } from './token.service';


declare var AWSCognito: any;
declare var AWS: any;

/*Service for interfacing with Cognito methods*/
@Injectable()
export class EmployeeService {

  public idToken: string = "";

  constructor(
    public http: Http
  ){}

  addEmployee(idToken: string, employee: any): Promise<any>{
    var options = TokenService.getHeaders();
    console.log(employee);
    var data = {
      email: employee.email,
      role: employee.role,
      firstName: employee.firstName,
      lastName: employee.lastName,
      organizationId: employee.organizationId,
      relationship: employee.relationship,
      phone: employee.phone,
      verified: employee.verified,
      summerStaff:employee.summerStaff
    }
    return this.http
      .post(ApiEndpoint + "/user/create/" , data, options)
      .toPromise()
  }


  updateEmployee(idToken: string, employee: any, organizationId): Promise<any>{
    const options = TokenService.getHeaders();
    console.log(employee);
    const data = {
      email: employee.email,
      role: employee.role,
      firstName: employee.firstName,
      lastName: employee.lastName,
      phone: employee.phone,
      verified: employee.verified,
      summerStaff: employee.summerStaff,
      organizationId
    }
    return this.http
      .post(`${EmailEndpoint}/employees/updateEmployee/` , data, options)
      .toPromise()
  }

  sendInvite(idToken: string, employee: any, manager:any, employeeId: string, organization): Promise<any>{
      console.log(manager)
      console.log(employeeId)
      console.log(employee)
      console.log(organization)
      var organizationName = organization.organizationName;
      var options = TokenService.getHeaders();
      var subject = "Invitation to Join " + organizationName + "'s Team ";
      var message = "<p>Hello " + employee.firstName + ",</p>"
      message += "<p>" + manager.firstName + " from " + organizationName + " has invited you to join their camp </p>";
      message += "<p> Activate your account here: " + (organization.domainUrl || 'https://portal.funfangle.com') + "/activate/activate.html?userId=" + employeeId;
      var data = {
          email: employee.email,
          subject: subject,
          message: message,
          orgInfo: organization
      }
      return this.http
        .post(ApiEndpoint + "/contributor/sendInvite", data,options)
        .toPromise();
  }

  removeInvitation(idToken: string, email:string, organizationId): Promise<any>{
      var options = TokenService.getHeaders();
      var data = {
        email: email,
        organizationId
      }
      return this.http
        .post(ApiEndpoint + "/contributors/remove", data, options)
        .toPromise();
  }

}
