import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { AuthService } from './auth.service';
import { ApiEndpoint, SquareEndpoint,  CognitoPoolId , EmailEndpoint, EmailBucketName, EmailBucketRegion, AWSConfig } from '../app.constants';
import { TokenService } from './token.service';



declare var AWSCognito: any;
declare var AWS: any;


/*Service for interfacing with Cognito methods*/
@Injectable()
export class ContributorService {


  constructor(
    public http: Http,
  ) { }


  public idToken: string = "";

  getContributorLinks(idToken: string, userId: string): Promise<any> {
    const options = TokenService.getHeaders();
    return this.http
      .get(`${EmailEndpoint}/contributors/get/${userId}`, options)
      .toPromise();
  }


  deleteContributorLink(idToken: string, accountHolderId: string, contributorId: string): Promise<any> {
    const options = TokenService.getHeaders();
    return this.http
      .get(`${EmailEndpoint}/contributors/delete/${accountHolderId}/${contributorId}`, options)
      .toPromise();
  }

  
  resendContributorInvite(idToken: string, contributorId: string, accountHolderId: string, organizationId: string, email: string, whitelistNames: string[]): Promise<any> {
    const options = TokenService.getHeaders();

    const data = {
      contributorId,
      accountHolderId,
      organizationId,
      email,
      whitelistNames
    }

    return this.http
     .post(`${EmailEndpoint}/contributors/resend`, data, options)
     .toPromise()
    
  }

  editContributorLink(idToken: string, accountHolderId: string, contributorId: string, contributor: any): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      accountHolderId,
      firstName: contributor.firstName,
      lastName: contributor.lastName,
      contributorId,
      // accountHolder: contributor.accountHolder,
      organizationId: contributor.organizationId,
      nickname: contributor.nickname,
      relationship: contributor.relationship,
    }
    console.log("Sending data:", data);
    return this.http
      .post(`${EmailEndpoint}/contributors/edit`, data, options)
      .toPromise();
  }

  // deprecated
  getMyContributors(idToken: string, userId: string): Promise<any>{
      var options = TokenService.getHeaders();
      return this.http
        .get(ApiEndpoint + "/contributors/get/" + userId, options)
        .toPromise()
  }

  getAccountsContributingTo(idToken: string, userId: string, organizationId: string): Promise<any> {
    const options = TokenService.getHeaders();
    return this.http
      .get(`${EmailEndpoint}/contributors/${userId}/campers/${organizationId}`, options)
      .toPromise();
  }

  linkContributor(idToken: string, userId: string, contributor: any, blackListIds: string[], whitelistNames: string[]) {
    const options = TokenService.getHeaders();
    const data = {
      userId,
      email: contributor.email,
      firstName: contributor.firstName,
      lastName: contributor.lastName,
      // accountHolder: contributor.accountHolder,
      organizationId: contributor.organizationId,
      nickname: contributor.nickname,
      relationship: contributor.relationship,
      blackListIds,
      whitelistNames
    }
    console.log("Sending data:", data);
    return this.http
      .post(EmailEndpoint + "/contributors/link", data, options)
      .toPromise();
  }

  // deprecated 1/10/2019
  addContributor(idToken: string, contributor: any): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      email: contributor.email,
      role: contributor.role,
      firstName: contributor.firstName,
      lastName: contributor.lastName,
      accountHolder: contributor.accountHolder,
      organizationId: contributor.organizationId,
      verified: false,
      nickname: contributor.nickname,
      relationship: contributor.relationship
    }
    return this.http
     .post(ApiEndpoint + "/user/create/", data, options)
     .toPromise()
  }



  // deprecated
  sendInvite(idToken: string, contributor: any, user: any, contributorId: string, campers:string[], organization: any, orgId: string): Promise<any>{
    var emailKey = orgId + "/contributorSendInvitationEmail.html";
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;

    AWS.config.update(AWSConfig(bucketRegion));

    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var orgInfo = organization
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");

    var params = {
      Bucket: EmailBucketName,
      Key: emailKey
    }
    return new Promise((resolve, reject) => {
      var email: any;
      var emailResponse: any;

      s3.getObject(params, (err, data)=>{
        if(err){
          reject(err)
        }else{
          email = data.Body.toString('ascii')
          email = email.replace(/{{contributorfName}}/g, contributor.firstName);
          email = email.replace(/{{campers}}/g, campers);
          email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
          email = email.replace(/{{orgPhone}}/g, organization.telephone);
          email = email.replace(/{{orgEmail}}/g, organization.email);
          email = email.replace(/{{webUrl}}/g, organization.domainUrl);
          email = email.replace(/{{cId}}/g,contributorId)
          console.log(email);
          var options = TokenService.getHeaders();
          var subject = "Invitation to Contribute";
          var d = {
            campers: campers,
            cId: contributorId,
            contributorfName: contributor.firstName,
            fname: user.firstName,
            lname: user.lastName,
            email: contributor.email,
            subject: subject,
            url: organization.domainUrl,
            webUrl: organization.domainUrl,
            t:email,
            organization
          }
          emailResponse = this.http
              .post(ApiEndpoint + "/contributor/sendInvite", d, options)
              .toPromise();
        }

      })
      resolve(emailResponse);
    })



  }

    //get contributor's contributions
  getContributions(idToken:string, contributorIds: string[]): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      contributorIds: contributorIds,
    }
    return this.http
      .post(SquareEndpoint + "/balance/getContributions", data, options)
      .toPromise();
  }


  //remove a contributor
  removeContributor(idToken: string, email: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      email: email,
      organizationId: organizationId
    }
    return this.http
      .post(ApiEndpoint + "/contributors/remove", data, options)
      .toPromise();
  }



  editContributor(idToken: string, contributor: any): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      contributorId: contributor.userId,
      email: contributor.email,
      firstName: contributor.firstName,
      lastName: contributor.lastName,
      nickname: contributor.nickname,
      relationship: contributor.relationship
    }
    return this.http
     .post(ApiEndpoint + "/contributor/edit/", data, options)
     .toPromise()
  }

}
