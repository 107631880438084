import { Component, OnInit, NgZone, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../services/user.service';
import { ContributorService } from '../services/contributor.service';
import { OrganizationService } from "../services/organization.service";
import { LingoService } from '../services/lingo.service';
import { DialogsService } from '../dialog/dialog.service';
import { LoadingService } from '../services/loading.service';

import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { TutorialService } from '../services/tutorial.service';


@Component({
  selector: 'app-contributors',
  templateUrl: './contributors.component.html',
  styleUrls: ['./contributors.component.scss']
})
export class ContributorsComponent implements OnInit {


  public idToken: string;
  public user: any;
  public orgLingo: { [id: string]: string } = {};
  public showAddContributors: boolean = false;
  public showEditContributor: boolean = false;
  public contributors = [];
  public contributions = [];
  public loadComplete = false;
  public campers =[];
  public organizationId = "";
  public organization: any;


  public newContributor = {
    firstName: "",
    lastName: "",
    email: "",
    role: "account-contributor",
    accountHolder: "",
    organizationId: "",
    nickname: "",
    relationship: "",
  }

  public editContributor = {
    contributorId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "account-contributor",
    accountHolder: "",
    organizationId: "",
    nickname: "",
    relationship: "",
  }

  public tokenSubscription: Subscription;
  public tutorialSubscription: Subscription;
  public showPopover9 = false;
  public unsubscribeTutorialSubscription: Subscription;

  constructor(
    public userService: UserService,
    public router: Router,
    public lingoService: LingoService,
    public contributorService: ContributorService,
    public dialogsService: DialogsService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public tutorialService: TutorialService,
    public organizationService: OrganizationService
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token contributors");
        /// console.log(token);
        this.idToken = token;
      }
    )

    this.tutorialSubscription = this.tutorialService.tutorialContributorsSubscription$.subscribe(
      popoverId => {
        if (popoverId == "popover9") {
          this.showPopover9 = true;
          setTimeout(() => {
            try {
              console.log("displaying it:");
              console.log("popover9:", document.getElementById('popover9'));
              document.getElementById("popover9").click();
            } catch (err) {
              console.log("tutorial error:", err);
            }
          }, 500)

        }
      }
    )

    this.unsubscribeTutorialSubscription = this.tutorialService.removeSubscriptions$
      .subscribe(res => {
        console.log("Unsubscribing!");
        this.tutorialSubscription.unsubscribe();
      })


  }

  ngOnInit() {
    //need to get the user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.user = res;
        if (res != null) {
          this.loadOrganization()
          this.loadCampers();
          this.getMyContributors()
            .then(res => {
              this.loadingService.toggleLoadingScreen(false);
            })
            .catch(err => {
              this.loadingService.toggleLoadingScreen(false);
            })
          this.lingoService.loadLingo(this.idToken, "camp")
            .then(res => {
              var body = JSON.parse(res._body);
              body.message.Items.forEach(item => {
                if (item.item_name != undefined) {
                  this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
                }
              })
              this.orgLingo['authorized_user'] += "s";
              console.log("orgLingo:", this.orgLingo);
            })
        }
      })
  }

  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          this.organizationId = res.organizationId;
          resolve(res);
        });
    })
  }

  getMyContributors(): Promise<any> {
    //reset the contributors if they exist
    if (this.contributors.length > 0) {
      this.contributors.forEach(contrib => {
        this.contributions[contrib.contributorId] = 0;
      })
    }
    return new Promise((resolve, reject) => {
      this.contributorService.getContributorLinks(this.idToken, this.user.userId)
        .then(res => {
          this.loadComplete = true;
          console.log("get contributors res:", res);
          var body = JSON.parse(res._body);
          this.contributors = body.message.Items;
          console.log("contributors:", this.contributors);
          var contributorIds = [];
          this.contributors.forEach(contributor => {
            contributorIds.push(contributor.contributorId);
          })
          console.log("cIds:", contributorIds);
          this.contributorService.getContributions(this.idToken, contributorIds)
            .then(res => {
              console.log("get contributons:", res);
              var body = JSON.parse(res._body);
              var contributons = body.message.Items;
              contributons.forEach(contrib => {
                if (this.contributions[contrib.contributorId] != undefined) {
                  this.contributions[contrib.contributorId] += contrib.currentBalance;
                } else {
                  this.contributions[contrib.contributorId] = contrib.currentBalance;
                }
              })
              console.log("Contributions:", contributons);
              resolve(true);
            }).catch(err => {
              console.log("GC err:", err);
              reject(false);
            })
        })
        .catch(err => {
          reject(false);
          console.log("get contributors err:", err);
        })
    })


  }

  reSendInvite(user: any) {
    this.newContributor = user;
    let whitelistNames = this.campers.filter( camper => {
      return user.blackListIds.indexOf(camper.userId) === -1;
    }).map( camper => " " + camper.firstName);
    this.contributorService.resendContributorInvite(this.idToken, user.contributorId, this.user.userId, this.user.organizationId, user.email, whitelistNames)
      .then(res => {
        this.dialogsService.alert("Invitation sent!", "Your email invitation has been sent");
      })
      .catch(err => {
        console.log("re-send invite err:", err);
      })
  }

  openInviteContributor() {
    this.showAddContributors = !this.showAddContributors;
    this.newContributor.organizationId = this.user.organizationId;
    this.newContributor.accountHolder = this.user.userId;
  }

  loadCampers() {
    this.userService.getAuthorizedUsers(this.idToken, this.user.userId)
    .then(res => {
      console.log("this should be all campers");
      var body = JSON.parse(res._body);
      this.campers = body.message.Items
      // cam.forEach(camper =>{
      //   this.campers.push(camper.firstName);
      // })
      console.log("Campers are:", this.campers);
      console.log(this.campers)
    })
  }

  inviteContributor() {
    if (!this.validateContributor(this.newContributor)) {
      this.dialogsService.alert("Invalid entry", "You must enter a value for every field and the email must be in a valid format");
      return;
    }
    
    console.log(this.organization);
      
    this.dialogsService.selectCampersDialog("Select Campers for them to contribute to", "", this.campers)
    .toPromise()
    .then( (res: any) => {
      if (res) {
        console.log("Blacklist is:", res.blacklist);
        console.log("Submit it now!");
        console.log(res.whitelist);
        console.log("Whitelist^");
        this.loadingService.toggleLoadingScreen(true);
        this.contributorService.linkContributor(this.idToken, this.user.userId, this.newContributor, res.blacklist, res.whitelist)
          .then( res => {
            console.log("Link contributor:", res);
            this.loadingService.toggleLoadingScreen(false);
            this.dialogsService.alert("Invitation sent!", "Your email invitation has been sent");
            this.showAddContributors = false;
            //re-grab the contributors
            this.newContributor = {
              firstName: "",
              lastName: "",
              email: "",
              role: "account-holder",
              accountHolder: "",
              organizationId: "",
              nickname: "",
              relationship: "",
            }
            this.getMyContributors();
          })
          .catch( err => {
            console.log("Contributor error:", err);
            this.loadingService.toggleLoadingScreen(false);
            let body = JSON.parse(err._body);
            this.dialogsService.alert("Invitation error", body.message || body.code);
          });
      }
    })
    .catch( err => {
      console.log("Select campers err:", err);
    })
    
  }



  validateContributor(contributor) {
    contributor.email = contributor.email.replace(/ /g, '');
    console.log("contributor:", contributor);
    if (contributor.firstName == "" || contributor.lastName == "" || contributor.email == ""
      || contributor.accountHolder == "" || contributor.organizationId == "" || contributor.nickname == "" || contributor.relationship == "") {
      return false;
    } else {
      if (this.validateEmail(contributor.email)) {
        return true;
      } else {
        return false;
      }
    }
  }

  validateEmail(email): boolean{
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  remove(contributor) {
    // remove the contributor
    this.dialogsService.confirm("Remove " + contributor.firstName + "?", "There will be no way to undo this")
      .toPromise()
      .then(res => {
        if (res) {
          this.contributorService.deleteContributorLink(this.idToken, this.user.userId, contributor.contributorId)
            .then(res => {
              console.log("remove contributor res:", res);
              this.getMyContributors();
            })
            .catch(err => {
              console.log("err:", err);
            })
        }
      })
  }



  nextStep(popoverId) {
    this.showPopover9 = false;
    this.tutorialService.completeTutorial(this.idToken, this.user.email, this.user.organizationId);
    setTimeout(() => {
      this.router.navigate(['home']);
    }, 1000);

  }

  //show / hide the sidenav menu
  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }

  openEditContributor(contributor?: any) {
    console.log("opening edit contributor:", contributor);
    if (this.showEditContributor) {
      this.showEditContributor = false;
    } else {
      this.showEditContributor = true;
      console.log("show it!");
      this.editContributor = {
        contributorId: contributor.userId,
        firstName: contributor.firstName,
        lastName: contributor.lastName,
        email: contributor.email,
        relationship: contributor.relationship,
        nickname: contributor.nickname,
        accountHolder: contributor.accountHolder,
        organizationId: contributor.organizationId,
        role: 'account-contributor'
      }
    }

  }


  submitEditContributor() {
    console.log(this.editContributor);
    let contributor = this.contributors.find( contrib => {
      return contrib.email === this.editContributor.email;
    });
    let contributorId = contributor.contributorId;
    console.log(contributorId);
    this.contributorService.editContributorLink(this.idToken, this.user.userId, contributorId, this.editContributor)
      .then(res => {
        console.log("edit contributor:", res);
        var body = JSON.parse(res._body);
        console.log("body:", body);
        if(body.status == "200"){
          this.dialogsService.alert("Success", "You have updated the info for " + this.editContributor.firstName);
          this.showEditContributor = false;
          this.getMyContributors();
        }

      });
  }

  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.organizationId)
    .then(res => {
      var body = JSON.parse(res._body);
      this.organization = body.message.Items[0];
      console.log("got organization:", this.organization);
      console.log("this.organization:", this.organization.organizationName);
    })
  }
}
