import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { AuthCredentials } from './auth-credentials.component';
import { DialogsService } from '../dialog/dialog.service';
import { LoadingService } from '../services/loading.service';
import { NgForm } from '@angular/forms';

import { Router } from '@angular/router'

import { LogoWebUrl, ContactMessage } from '../app.constants';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  public credentials = new AuthCredentials();
  public mode = "login";
  public verifyText: string = "";
  public newPassword: string = "";
  public cognitoUser: any;
  public showVerifyText: boolean = false;
  public showRecoverPassword: boolean = false;
  public logoUrl: string = "";
  public showLoginPage: boolean = false;
  public contactMessage: string = ContactMessage;


  constructor(
    public authService: AuthService,
    public userService: UserService,
    public router: Router,
    public dialogsService: DialogsService,
    public loadingService: LoadingService
  ) {
    this.logoUrl = LogoWebUrl;
    console.log("logo url:", this.logoUrl);
   }

  ngOnInit() {
    this.credentials.role = "account-holder";
    setTimeout( () => {
      this.authService.initializeAWSCognito();
      //check if they're logged in already
      this.authService.getUserFromStorage()
      .then(result => {
        this.router.navigate(['home']);
      })
      .catch(err => {
        console.log("get user from storage err:", err);
      });
      //check for login attempt..
      var emailCookie = this.getCookie("email");
      var passwordCookie = this.getCookie("password");

      console.log("cookies:");
      console.log(emailCookie, passwordCookie);
      console.log("all:");
      console.log(document.cookie);
      //if the cookies are present, don't show the UI and attempt to login...
      if(emailCookie && passwordCookie){
        this.showLoginPage = false;
        this.credentials.email = emailCookie;
        this.credentials.password = passwordCookie;
        this.setCookie("email", null, 1);
        this.setCookie("password", null, 1);
        this.login();
      }else{
        this.showLoginPage = true;
      }

      var forgotPassword = this.getCookie("forgotPassword");
      if(forgotPassword){
        this.forgotPassword();
      }


    },100);

  }


  setCookie(cname, cvalue, exseconds) {
      var d = new Date();
      d.setTime(d.getTime() + (exseconds*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  updateRole(){
    console.log("role:" + this.credentials.role);
  }

  /* create a new user with Cognito - not used currently*/
  createAccount(){
    if(!this.credentials.validateCredentials()){
      this.dialogsService.alert("Invalid credentials", "Make sure you've entered a valid email and password. Your password must be at least 8 characters long");
      return;
    }
    this.authService.createUser(this.credentials, false)
    .then(res => {
      this.cognitoUser = res.user;
      console.log("user:", this.cognitoUser);
      this.showVerifyText = true;
    }).catch(err => {
      console.log("create user err:", err);
      if(err.code == "UsernameExistsException"){
        alert("Account exists. Either login or create a new account");
      }else{
        alert("Unable to register your account. Please verify you've entered a valid email and password");
      }
    })
  }

onSubmit(f){
  console.log("submit:", f);
}


getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
}

/*authenticate a user with Cognito*/
login(f = null){
  console.log("f is:");
  console.log(f);
  console.log("calling login!!!");
  console.log(this.credentials);
  if(f != null){
    this.credentials.email = f.value.email;
    this.credentials.password = f.value.password;
  }
  this.loadingService.toggleLoadingScreen(true);
  this.authService.login(this.credentials)
    .then(res => {
      var directToChangePassword = false;
      if(res == "updatePassword"){
        directToChangePassword = true;
      }
      this.loadingService.toggleLoadingScreen(false);
    console.log("login res:", res);
    console.log("login succeeded");
    this.authService.getUserFromStorage()
    .then(result => {
      console.log("logged in result ~~~~~~~~~~~~~~", result);
      var token = result[0].signInUserSession.idToken.jwtToken;
      this.userService.getUserDynamo(token, result[0].username)
      .then(res => {
        var body = JSON.parse(res._body);
        var userData = body.Item;
        this.userService.refreshUserDynamo(userData);
      })
      if(!directToChangePassword){
        this.router.navigate(['home']);
      }else{
        console.log("navigate to changePassword");
        this.router.navigate(['user-info/changePassword']);
      }
      

    })
    .catch(err => {
      this.showLoginPage = true;
      console.log("err:", err);
      this.loadingService.toggleLoadingScreen(false);
    });
  }).catch(err => {
    this.showLoginPage = true;
    this.loadingService.toggleLoadingScreen(false);
    console.log("login err:", err);
    if(err.code == "UserNotConfirmedException"){
      this.showVerifyText = true;
    }else if( err.code == "PasswordResetRequiredException"){
      //this.forgotPassword();
      this.showRecoverPassword = true;
    }else{
      this.dialogsService.alert("Invalid credentials!","The email/password combination you entered had an error. To recover a lost password, hit click Forgot Password below");
    }
  })
}

/* pass in verification credentials */
verifyAccount(){
  this.authService.verifyAccount(this.credentials, this.verifyText)
  .then(res => {
    console.log("verify account res:", res);
    this.dialogsService.alert("Account confirmed!", "You can now log in normally");
    this.showVerifyText = false;
    this.login(null);
  }).catch(err => {
    console.log("verify account err:", err);
    this.dialogsService.alert("Invalid code", "Make sure you enter the correct code");
  })

}

/*Send a new email w/ verification code*/
resendVerificationCode(){
  this.authService.resendCode(this.credentials)
  .then(res => {
    console.log("resend code res:", res);
    if(res == "SUCCESS"){
      this.dialogsService.alert("Email sent","We have sent new credentials to your account");
    }
  }).catch( err => {
    console.log("resendcode err:", err);
    this.dialogsService.alert("Processing error!","Could not re-send confirmaion code");
  })
}


openCreate(){
  this.mode = "create";
  this.showVerifyText = false;
  this.showRecoverPassword = false;
}

openLogin(){
  this.mode = 'login';
  this.showVerifyText = false;
  this.showRecoverPassword = false;
}


/*setUserNavHome(result: any){
  if(result != null && result != null && !result.hasOwnProperty('code')){
    console.log("result:", result);
    //set the user for the session
    this.userService.setUser(result[0], result[1]);
    this.router.navigate(['home']);
  }else{
    console.log("error:", result);
  }
}*/

//send an email to recover the password
forgotPassword(){
  console.log("forgot password...");

 this.router.navigate(['resetPassword']); 
  
  // if(this.credentials.email == ""){
  //   this.dialogsService.alert("Email cannot be empty", "Please enter your email address");
  //   return;
  // }
  // this.authService.forgotPassword(this.credentials)
  // .then(res => {
  //   console.log("fp res:", res);
  //   if(res){
  //     this.showRecoverPassword = true;
  //     this.dialogsService.alert("Recovery email has been sent","A verification code has been sent to your inbox to update your password");
  //   }else{
  //     this.dialogsService.alert("Could not send email","Please check your inbox or try again in a few minutes");
  //   }
  // }).catch(err => {
  //   console.log("forgot password err:", err);
  //   this.dialogsService.alert("Could not send email","Please check your inbox or try again in a few minutes");
  // })
}



changePassword(){
  this.authService.changePassword(this.credentials, this.verifyText, this.newPassword)
  .then(res => {
    if(res){
      //it worked
      this.dialogsService.alert("Success!","Your password has been updated!");
      this.showRecoverPassword = false;
    }else{
      this.dialogsService.alert("Could not change password.","Make sure you've entered a valid password");
    }
  }).catch(err => {
    this.dialogsService.alert("Could not change password.","Make sure you've entered a valid password");
  })
}

passwordSubmit(){
  console.log("password submit:", this.mode);
  if(this.mode == 'login'){
    this.login(null);
  }else if(this.mode == 'create'){
    this.createAccount();
  }
}

}
