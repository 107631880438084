import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'confirm-dialog',
     styleUrls: ['./dialog.component.scss'],
    template: `
     <div class="ff-dialog-header">{{ title }}</div>
            <div class="ff-dialog-message">{{ message }}</div>
            <div class="ff-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
       
        <button class="ff-dialog-button-cancel" type="button" mat-button 
            (click)="dialogRef.close()">{{cancelText}}</button>
       
        <button class="ff-dialog-button" type="button" mat-raised-button 
            (click)="dialogRef.close(true)">{{okText}}</button>
       
            </div>
          `,
})
export class ConfirmDialog {

    public title: string;
    public message: string;
    public cancelText: string;
    public okText: string;

    constructor(public dialogRef: MatDialogRef<ConfirmDialog>) {

    }
}