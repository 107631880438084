import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'barcode-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="change-password-component">
            <div class="ff-dialog-header">
              Barcode Printer
              <button (click)="closeWindow()" style="left: 80px; top: -5px; right: 10px; cursor: pointer;" mat-icon-button><mat-icon>close</mat-icon></button>
            </div>
            <iframe [src]="safeUrl" width="100%" height="500"></iframe>
        </div>
        
        `,
})
export class BarcodeDialog {

    public title: string = "";
    public price: string = "";
    public gtin: string = "";
    public url: string = "";
    public safeUrl: SafeResourceUrl;

    constructor(
      public dialogRef: MatDialogRef<BarcodeDialog>,
      @Inject(MAT_DIALOG_DATA) public data: { title: string, price: string, gtin: string },
      private _sanitizer: DomSanitizer)
    {
      this.title = data.title;
      this.price = data.price;
      this.gtin = data.gtin;
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.buildUrl());
    }

    buildUrl(): string {
      this.url = "https://cdn.funfangle.com/dymo/print/barcode?ti=" + encodeURIComponent(this.title) + "&pr=$" + encodeURIComponent(this.price) + "&gtin=" + encodeURIComponent(this.gtin);
      // console.log("url:", this.url);
      return this.url;
    }

    closeWindow()
    {
        this.dialogRef.close();
    }   

    // openUrl()
    // {
    //   window.location.href = this.url;
    // }

}