import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { TransactionSearchTerms } from "../models/transaction-search";

declare var moment:any;

@Component({
    selector: 'transaction-search-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">

                <div class="modal-error-message" *ngIf="errorMessage.length > 0">
                    {{errorMessage}}
                </div>

                <div class="input-group date-range-input" daterangepicker (selected)="selectedDate($event, dateInput)" (cancelDaterangepicker)="calendarEventsHandler($event)"
                    (applyDaterangepicker)="calendarEventsHandler($event)" (hideDaterangepicker)="calendarEventsHandler($event)" (showDaterangepicker)="calendarEventsHandler($event)"
                    style="padding-bottom:10px;">
                    <span class="form-control uneditable-input" name="daterange">
                        {{ dateInput.start | date:'M/dd/y' }} - {{ dateInput.end| date:'M/dd/y' }}
                    </span>
                    <span class="input-group-btn" style="height:34px !important">
                        <a type="button" class="btn btn-default" style="height:34px !important">   <!--<i class="glyphicon glyphicon-calendar fa fa-calendar"></i>-->
                        <mat-icon class="date_range-icon">date_range</mat-icon>
                        </a>
                    </span>
                </div>
    

                <div>
                    <mat-form-field class="inventory-input-container">
                        <input type="number" matInput [(ngModel)]="searchTerms.amount" placeholder="Amount">
                    </mat-form-field>
                    &nbsp;&nbsp;
                    <mat-form-field class="inventory-input-container">
                        <input type="text" matInput [(ngModel)]="searchTerms.last4" placeholder="Card Last4 (i.e. 1234)">
                    </mat-form-field>
                    &nbsp;&nbsp;
                    <br />
                    <mat-form-field class="inventory-input-container">
                        <input type="text" matInput [(ngModel)]="searchTerms.name" placeholder="Name (i.e. John Doe)">
                    </mat-form-field>
                    &nbsp;&nbsp;
                    <mat-form-field class="inventory-input-container">
                        <input type="text" matInput [(ngModel)]="searchTerms.email" placeholder="Email (i.e. john.doe@gmail.xyz)">
                    </mat-form-field>
                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="search()" class="submit-button" style="margin-right:10px">Search</button>
                <button mat-raised-button (click)="clear()" class="clear-button">Clear</button>
            </div>
            
        </div>
        `,
})
export class TransactionSearchDialog implements OnInit {

    public title: string = "Search Terms";

    public searchTerms: TransactionSearchTerms;
    public errorMessage: string = "";

    public dateInputs: any = [];
  
    public dateInput = {
        start: moment().subtract(7, 'days'), //moment(this.start.valueOf()),
        end: moment((new Date()).valueOf() + 100000000) //+ 1000000000
    }

    constructor(public dialogRef: MatDialogRef<TransactionSearchDialog>) {
      console.log("dialog ref:");
      console.log(dialogRef);
    }

    ngOnInit(){
      console.log("on init...");
      console.log("modifier...");
      if(!this.searchTerms) {
        this.searchTerms = new TransactionSearchTerms();
      }
    }

    calendarEventsHandler(e:any) {
        // ...
    }

    clear(){
        this.searchTerms.amount = undefined;
        this.searchTerms.email = undefined;
        this.searchTerms.last4 = undefined;
        this.searchTerms.name = undefined;
        this.errorMessage = "";
        this.dialogRef.close(this.searchTerms);
    }

    isValid(): boolean {
        let isValid = true;
        // if(!this.option.name || this.option.name == ""){
        //     isValid = false;
        //     this.errorMessage = "You must enter a name for this modifier option";
        // }
        return isValid;
    }

    search(){
        if(this.isValid()){
            this.errorMessage = "";
            this.searchTerms.dateInput = this.dateInput;
            this.dialogRef.close(this.searchTerms);
        }
    }

    selectedDate(value: any, dateInput: any) {
        this.dateInput.start = value.start;
        this.dateInput.end = value.end;
    }
}