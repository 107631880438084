import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { CarePackageItem } from "../models/care-package";


@Component({
    selector: 'modifier-detail',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">


                <div class="modal-error-message" *ngIf="errorMessage.length > 0">
                    {{errorMessage}}
                </div>
                <mat-form-field>
                    <mat-select placeholder="Select" [(ngModel)]="selectedOption" class="dialog-select">
                        <mat-option *ngFor="let opt of options" [value]="opt" [id]="opt">{{opt}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="save()" class="submit-button">Save</button>
            </div>
            
        </div>
        `,
})
export class SelectDialog implements OnInit {

    public title: string = "";
    public options: string[];
    public selectedOption: string = "";
    public errorMessage: string = "";
    

    constructor(public dialogRef: MatDialogRef<SelectDialog>) {
       console.log("dialog ref:");
       console.log(dialogRef);
       
    }

    ngOnInit(){
        console.log("on init...");
    }




    save(){
        if(this.selectedOption == ""){
            this.errorMessage = "You must select an option!";
            return;
        }
        this.dialogRef.close(this.selectedOption);
    }

    


    

}