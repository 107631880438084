import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { OrganizationSettingsService } from '../services/organization-settings.service';
import { CarePackagesService } from '../services/care-packages.service';
import { DialogsService } from '../dialog/dialog.service';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { SessionPanel, FunFanglePanel } from '../../app/models/panel';
import { XCampbrainSessions, SessionJSON } from '../../app/models/sessions';

declare var Quill: any;

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit {

  public organization: any = null;
  public agreementStatus = false;
  public user: any = null;
  public idToken: string;
  public data: any;
  public showAddPOS = false;
  public editPOSMode = false;
  public showLocationList = false;
  public showConfigureTaxRate = false;
  public showEditAgreementMessage = false;
  public showAccountHolderPermissions = false;
  public showPhotographSettings = false;
  public showCarePackage = false;
  public showEmails = false;
  public posLocation = [];
  public userRole = '';
  public squareReader = {};
  public squareSettings = {};
  public timeZoneOffset = -4;
  // emails stuff
  public emailsLoaded = false;
  public emailKeys = [
    'parentSendInvitationEmail.html',
    'parentFirstEmailAfterActivation.html',
    'parentReminderInvitationEmail.html',
    'ExplanationEmailAboutContributors.html',
    'contributorSendInvitationEmail.html',
    'imageReminderEmail.html',
    'noFundsReminderEmail.html',
   // "unallocateFundReminderEmail.html"
  ]
  public nameByKey = {
   'contributorSendInvitationEmail.html': 'Contributor Invitation',
   // "ExplanationEmailAboutContributors.html": "Contributor Explanation",
    'imageReminderEmail.html': 'Image Reminder',
    'noFundsReminderEmail.html': 'No Funds Reminder',
    'parentFirstEmailAfterActivation.html': 'First Email After Activation',
    'parentReminderInvitationEmail.html': 'Invitation Reminder',
    'parentSendInvitationEmail.html': 'Invitation Email',
  //  "unallocateFundReminderEmail.html": "Unallocate Funds Reminder"
  }
  public tagsByEmail = {
    'contributorSendInvitationEmail.html': [
      '{{contributorfName}}',
      '{{campers}}',
      '{{orgName}}',
      '{{webUrl}}',
      '{{orgPhone}}',
      '{{orgEmail}}'
    ],
    'ExplanationEmailAboutContributors.html': [
      '{{firstName}}',
      '{{webUrl}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    'imageReminderEmail.html': [
      '{{firstName}}',
      '{{webUrl}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    'noFundsReminderEmail.html': [
      '{{firstName}}',
      '{{webUrl}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    'parentFirstEmailAfterActivation.html': [
      '{{firstName}}',
      '{{email}}',
      '{{webUrl}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    'parentReminderInvitationEmail.html': [
      '{{firstName}}',
      '{{lastName}}',
      '{{webUrl}}',
      '{{userId}}',
      '{{parentNum}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    'parentSendInvitationEmail.html': [
      '{{firstName}}',
      '{{lastName}}',
      '{{webUrl}}',
      '{{userId}}',
      '{{parentNum}}',
      '{{orgPhone}}',
      '{{orgEmail}}',
      '{{orgName}}'
    ],
    // "unallocateFundReminderEmail.html":[
    //   "{{firstName}}",
    //   "{{email}}",
    //   "{{webUrl}}",
    //   "{{orgPhone}}",
    //   "{{orgEmail}}",
    //   "{{orgName}}"
    // ],

  }
  public showEditor = false;


  public editorContent;
  public emailsByKey: any = {};
  public activeKey = '';

  public activePanel: FunFanglePanel;
  public sessionPanel: FunFanglePanel = SessionPanel;
  public selectedPanelData: any;
  public selectedData: any = [];


  public timeZones = [
    {
      name: 'UTC-10',
      offset: -10,
      value: 'Hawaiian Standard Time'
    },
    {
      name: 'UTC-8',
      offset: -8,
      value: 'Alaskan Standard Time'
    },
    {
      name: 'UTC-7',
      offset: -7,
      value: 'Pacific Standard Time'
    },
    {
      name: 'UTC-6',
      offset: -6,
      value: 'Mountain Standard Time'
    },
    {
      name: 'UTC-5',
      offset: -5,
      value: 'Central Standard Time'
    },
    {
      name: 'UTC-4',
      offset: -4,
      value: 'Eastern Standard Time'
    }]

  editSessionMessage = {
    endSessionMessage: '',
    showEndSessionMessage: ''
  }

  rates = {
    taxRate: 0,
    employeeDiscount: 0
  }
  POSLocation = {
    locationId: '',
    locationName: '',
    applyDailyLimits: false
  }
  columns = [
    { prop: 'locationName' }
  ];

  public tokenSubscription: Subscription;


  constructor(
    public userService: UserService,
    public organizationSettingsService: OrganizationSettingsService,
    public dialogsService: DialogsService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public carePackagesService: CarePackagesService,
    public router: Router
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        this.idToken = token;
      });
  }

  ngOnInit() {
    // get who is logged in
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
    .then(res => {
      this.loadingService.toggleLoadingScreen(false);
      this.userRole = res.role;
      if (res.role != 'organization-manager' && res.role != 'organization-employee' && res.role != 'store-manager') {
        this.router.navigate(['home']);
        return;
      }
      this.user = res;
      if (res != null) {
        this.getSessionMessage();
        this.getPOSLocations();
      }
    }).catch(err => {
      this.loadingService.toggleLoadingScreen(false);
      // console.log("Err")
    })
  }

  getUser(): Promise<any> {
    return new Promise( (resolve, reject ) => {
      this.userService.getUser()
        .then(res => {
          // console.log("Response: " , res)
          this.idToken = res.idToken;
          resolve(res)
        }).catch(err => {
        // console.log("Error Getting users", err);
        reject(err);
      })
    })
  }

  getSessionMessage() {
    let organizationId = this.user.organizationId;
    // console.log("~~~~~~~~~~~~org id~~~~~~~~~~~~~", organizationId);
    this.organizationSettingsService.getSessionMessage(this.idToken, organizationId)
      .then(res => {
        let body = JSON.parse(res._body);
        let org = body.message.Items[0];
        // console.log(org);
        // console.log("Session Message Response:", org);
        this.organization = org;
        // handle for camps that haven't configured this
        if (!this.organization.carePackageEnabled) {
          this.organization.carePackageEnabled = false;
        }
        if (this.organization.carePackageEmailUpdatesEnabled === undefined || this.organization.carePackageEmailUpdatesEnabled === null) {
          this.organization.carePackageEmailUpdatesEnabled = true;
        }
        if (this.organization.attendanceSettings && this.organization.attendanceSettings.isEnabled) {
          this.emailKeys.push('postEarlyPickupMessage.html');
          this.emailKeys.push('postContactUsMessage.html');
          this.emailKeys.push('attendanceInvite.html');
          this.nameByKey['postEarlyPickupMessage.html'] = 'Email after early pickup request';
          this.nameByKey['postContactUsMessage.html'] = 'Email after sending message to support';
          this.nameByKey['attendanceInvite.html'] = 'Mobile App Invitation';
          this.tagsByEmail['postEarlyPickupMessage.html'] = ['{{campers}}', '{{pickupTime}}'];
          this.tagsByEmail['postContactUsMessage.html'] = ['{{phone}}'];
          this.tagsByEmail['attendanceInvite.html'] = ['{{inviterName}}', '{{organizationName}}', '{{androidUrl}}', '{{iOSUrl}}', '{{customLoginText}}'];
        }
        this.squareReader = this.organization.squareReader || {};
        this.squareSettings = this.organization.squareSettings || {};
        this.organization.itemsColumnEnabled = (this.organization.itemsColumnEnabled != undefined) ? this.organization.itemsColumnEnabled : false;
        this.organization.camperPhotosRequired = (this.organization.camperPhotosRequired != undefined) ? this.organization.camperPhotosRequired : true;
        this.editSessionMessage.endSessionMessage = this.organization.portalSettings.endSessionMessage || this.organization.endSessionMessage;
        this.editSessionMessage.showEndSessionMessage = this.organization.portalSettings.displayEndSessionMessage || this.organization.showEndSessionMessage;
        this.rates.taxRate = (this.organization.taxRate) ? this.organization.taxRate : 0;
        this.rates.employeeDiscount = (this.organization.employeeDiscount) ? this.organization.employeeDiscount : 0;
       // // console.log(this.organization);
      }).catch(err => {
        // console.log("error getting organization" , err);
    })
  }

  changeSessionMessage() {
    let isValid: boolean = this.validateMessage(this.editSessionMessage);
    if (!isValid) {
      return
    }
    this.organizationSettingsService.updateSessionMessage(this.idToken, this.editSessionMessage, this.user.organizationId)
      .then(res => {
        // console.log(res);
        this.dialogsService.alert('Success!', 'You have successfully change the Agreement Message');
        this.getSessionMessage();
      }).catch(err => {
        // console.log("error changing agreement message", err);
        this.dialogsService.alert('Processing Error!', 'Error changing Agreement Message')
    })
  }
  configureTaxRate() {
    let isValid: boolean = this.validateRates(this.rates);
    if (!isValid) {
      this.dialogsService.alert('Invalid rates', 'All rates must be between 0.0 and 1.0');
      return;
    }
    this.organizationSettingsService.updateTaxRate(this.idToken, this.rates, this.user.organizationId)
    .then(res => {
      // console.log(res);
      this.dialogsService.alert('Success!', 'You have successfully update tax rate');
      this.getSessionMessage();
    }).catch(err => {
      // console.log("Error!", err);
    })
  }

  validateRates(rates: any): boolean {
    if (rates.taxRate > 1 || rates.taxRate < 0 || rates.employeeDiscount > 1 || rates.employeeDiscount < 0) {
      return false;
    } else {
      return true;
    }
  }

  validateMessage(message: any): boolean {
    // console.log(message)
    if (message.editSessionMessage == '') {
      return false;
    }
    if (message.editTaxRate == '') {
      return false;
    }
    return true;
  }

   // show / hide the sidenav menu
  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }
  showAddPOSLocation() {
    this.editPOSMode = false;
    this.showAddPOS = !this.showAddPOS;
    this.timeZoneOffset = this.organization.timeZoneOffset;
  }
  showconfigureTaxRate() {
    this.showConfigureTaxRate = !this.showConfigureTaxRate
  }
  showPOSLocationList() {
    this.showLocationList = !this.showLocationList;
  }

  openEditEmails() {
    this.showEmails = !this.showEmails;
    if (!this.emailsLoaded) {
      // load the emails now...
      this.loadEmails();
    }
  }


  loadEmails() {
    this.loadingService.toggleLoadingScreen(true);
      this.organizationSettingsService.getOrganizationEmails(this.organization.organizationId, this.emailKeys)
      .then( values => {
        this.loadingService.toggleLoadingScreen(false);
        // console.log("values:", values);
        let index = 0;
        this.emailKeys.forEach( emailKey => {
          this.emailsByKey[emailKey] = values[index];
          index++;
        })
        // console.log("final emails are:", this.emailsByKey);
      })
      .catch( err => {
        this.loadingService.toggleLoadingScreen(false);
      })
  }

  loadEmailEditor(emailKey: string) {
    // console.log("load email:", emailKey);
    this.activeKey = emailKey;
    this.showEditor = true;
    this.editorContent = this.emailsByKey[emailKey];
  }


  saveEmailContent() {
    const rawContent = this.editorContent;
    // console.log("content raw is:");
    // console.log(rawContent);
    const [hasChanged, tagsRemoved] = this.checkForTagChanges(rawContent);
    // console.log(hasChanged, tagsRemoved);
    if (hasChanged) {
      this.dialogsService.confirm('Tags Removed', 'You have removed the following tags: ' + tagsRemoved + '. Proceed anyway?')
      .toPromise()
      .then(res => {
        if (res) {
          this.uploadContent(rawContent);
        }
      })
    } else {
      this.uploadContent(rawContent);
    }

  }

  uploadContent(content) {
    // console.log("Content is:", content);
    this.loadingService.toggleLoadingScreen(true);
    this.organizationSettingsService.updateOrganizationEmail(this.activeKey, this.organization.organizationId, content)
    .then( res => {
      // console.log("update email res:", res);
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert('Success!', 'The email content has been updated!');
      this.loadEmails();
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
      // console.log("update email err:", err);
    })
  }


  checkForTagChanges(content: string): [boolean, string ] {
    let hasChanged = false;
    let tagsRemoved = '';
    this.tagsByEmail[this.activeKey].forEach( tag => {
      if (content.indexOf(tag) == -1) {
        tagsRemoved += tag + ', ';
        hasChanged = true;
      }
    });
    if (tagsRemoved != '') {
      tagsRemoved = tagsRemoved.slice(0, tagsRemoved.length - 2);
    }
    return [hasChanged, tagsRemoved];
  }

  closeCard() {
    this.showAddPOS = false;
    this.showLocationList = false;
    this.showConfigureTaxRate = false;
    this.showEditAgreementMessage = false;
    this.showCarePackage = false;
    this.showAccountHolderPermissions = false;
    this.showPhotographSettings = false;
    this.showEmails = false;
    this.showEditor = false;
  }

  closePOSCard() {
    this.showAddPOS = false;
  }

  addPOSLocation() {
    this.organizationSettingsService.addPOSLocation(this.idToken, this.POSLocation, this.user.organizationId, this.timeZoneOffset)
    .then(res => {
      let body = JSON.parse(res._body)
      // console.log(body)
      if (body.status == '200') {
        this.POSLocation = {
          locationName: '',
          locationId: '',
          applyDailyLimits: false,

        }
      }
      this.closePOSCard();
      this.getPOSLocations();
      this.dialogsService.alert('Success!', 'You have successfully added a new POS Location');
    }).catch(err => {
      // console.log("error adding new POS Location")
      this.dialogsService.alert('Error!', 'Error adding new POS Location')
    })
  }

  getPOSLocations() {
    if (this.posLocation.length > 0) {
      this.posLocation = [];
    }
    this.organizationSettingsService.getPOSLocations(this.idToken, this.user.organizationId)
    .then(res => {
      let body = JSON.parse(res._body);
      let message = body.items;
      message.forEach(location => {
        this.posLocation.push(location)
      })
      // console.log("POS LOcations: " , this.posLocation);
    })
  }

  removePOSLocation(location) {
    // console.log(location);
    this.dialogsService.confirm('Remove ' + location.locationName + '?', 'There will be no way to undo this')
    .toPromise()
    .then(res => {
      if (res) {
        this.organizationSettingsService.removeLocation(this.idToken, location.locationId, this.user.organizationId)
        .then(res => {
          this.getPOSLocations();
        }).catch(err => {
          // console.log("Error removing location");
        })
      }
    })
  }


  openEditAgreementMessage() {
    this.showEditAgreementMessage = !this.showEditAgreementMessage;
  }

  openEditPOS(location) {
    this.POSLocation = location;
    this.editPOSMode = true;
    this.showAddPOS = true;
    if (location.timeZoneOffset != undefined) {
      this.timeZoneOffset = location.timeZoneOffset;
    } else {
      this.timeZoneOffset = this.organization.timeZoneOffset;
    }

  }


  editPOSLocation(location) {
    this.organizationSettingsService.editLocation(this.idToken, this.POSLocation.locationId, this.POSLocation.locationName, this.POSLocation.applyDailyLimits, this.timeZoneOffset, this.user.organizationId)
    .then( res => {
      // console.log("edit location res:", res);
      this.editPOSMode = false;
      this.showAddPOS = false;
    })
  }


  updateCarePackageSettings() {
    // console.log(this.organization.carePackageEnabled);
    // invoke update function and subscription
    this.carePackagesService.updateCarePackageSettings(this.idToken, this.organization.organizationId, this.organization.carePackageEnabled, this.organization.carePackageEmailUpdatesEnabled)
    .then(res => {
      // console.log("care package enabeld res:", res);
      let body = JSON.parse(res._body);
      if (body.status == '200') {
        this.dialogsService.alert('Updated!', 'Your Care Package settings have been updated!');
        this.closeCard();
        // propagate subscription
        this.carePackagesService.toggleCarePackageSettings(this.organization.carePackageEnabled);
      } else {
        this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
      }
    })
    .catch(err => {
      // console.log("care package enabled err:", err);
      this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
    })
  }

  updatePhotoRequiredSettings() {
    this.organizationSettingsService.updatePhotoRequiredSettings(this.idToken, this.organization.organizationId, this.organization.camperPhotosRequired)
    .then(res => {
      // console.log("update items column res:", res);
      let body = JSON.parse(res._body);
      if (body.status == '200') {
        this.dialogsService.alert('Updated!', 'Your Spending Account settings have been updated!');
        this.closeCard();
      } else {
        this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
      }
    })
    .catch(err => {
      this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
    })
  }

  updateItemsColumnSettings() {
    this.organizationSettingsService.updateItemsColumnSettings(this.idToken, this.organization.organizationId, this.organization.itemsColumnEnabled)
    .then(res => {
      // console.log("update items column res:", res);
      let body = JSON.parse(res._body);
      if (body.status == '200') {
        this.dialogsService.alert('Updated!', 'Your Account Holder settings have been updated!');
        this.closeCard();
      } else {
        this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
      }
    })
    .catch(err => {
      this.dialogsService.alert('Error!', 'Unable to update your settings. Please try again later');
    })
  }


  openSquareApp() {
    window.location.replace('https://console.funfangle.com/setting/processor/square/oauth');

    // check if they're allowed
    // if (this.organization.squareReader && this.organization.squareReader.ready) {
    //   if (!this.organization.squareSettings || !this.organization.squareSettings.allowUpdateSquare) {
    //     return this.dialogsService.alert("Your Square is already setup!", "Please contact FunFangle support if you need to change your settings");
    //   }
    // }

    // // console.log("Open square app");
    // this.loadingService.toggleLoadingScreen(true);
    // this.organizationSettingsService.getSquareUrl(this.user.idToken, this.organization.organizationId, this.user.email)
    //   .then(url => {
    //     console.log('URL IS:', url);
    //     this.loadingService.toggleLoadingScreen(false);
    //     // console.log("Got url:", url);
    //     window.location.replace(url);
    //   })
    //   .catch(err => {
    //     // console.log("Got err:", err);
    //     this.loadingService.toggleLoadingScreen(false);
    //   })
  }

  public openPanel(panel: FunFanglePanel) {
    console.log('Open Panel:', panel);
    this.activePanel = panel;
    if (panel.loadFunction) {
      this[panel.loadFunction]();
    }
  }

  public closePanel() {
    this.activePanel = null;
  }

  public panelFunction(row: any, method: string) {
    this[method](row);
  }

  public async whitelistSession(row) {
    console.log('Whitelist session:', row);
    try {
      const whitelist = !row.Whitelisted;
      let message = 'Add to whitelist?';
      let title = 'This will allow data for this session to be automatically imported.';
      if (!whitelist) {
        title = 'Remove from whitelist?';
        message = 'This will prevent data for this session from being automatically reported.';
      }

      const confirm = await this.dialogsService.confirm(title, message).toPromise();
      if (!confirm) { return; }
      this.loadingService.toggleLoadingScreen(true);
      const res = await this.organizationSettingsService.whitelistXCampbrainSession(this.organization.organizationId, this.user.email, row.SessionIdKey, row.SessionEndKey, whitelist);
      console.log('Whitelist res:', res);
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert('Success', 'Your session whitelist has been updated!');
      // reload the data..
      this.loadXCampbrainSessions();
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Processing Error', 'There was an error processing the request. Please try again later');
    }
  }

  public onSelect( {selected } ) {
    this.selectedData = selected;
  }

  public async closeSessions(selectedData, override = false) {
    console.log('close sessions:', selectedData);
    if (selectedData.length === 0) { return this.dialogsService.alert("Invalid Selection", "You must select at least one session"); }
    const inactiveOrIneligibleSessions = selectedData.filter( (data: SessionJSON) => data.SessionStatus !== 'Active');
    if (inactiveOrIneligibleSessions.length > 0) {
      return this.dialogsService.alert('Invalid Selection', 'You can only close sessions that are currently Active!');
    } else if (inactiveOrIneligibleSessions.length > 4) {
      return this.dialogsService.alert('Too many selections', 'Please select four or less sessions at a time!');
    }
    try {
      this.loadingService.toggleLoadingScreen(true);
      const closeRes: any = await this.organizationSettingsService.closeSessions(this.user.organizationId, this.user.email, selectedData, override);
      console.log('Close res:', closeRes);
      this.selectedData = [];
      this.loadingService.toggleLoadingScreen(false);
      if (closeRes.status === 205) {
        const message = JSON.parse(closeRes._body).message;
        const doConfirm = await this.dialogsService.confirm('Confirm De-Activation', message).toPromise();
        if (doConfirm) {
          console.log('Confirmed!');
          this.closeSessions(selectedData, true);
        }
      } else {
        this.dialogsService.alert('Session(s) Closed', 'The selected sessions have been closed and the account holder and campers deactivated!');
        this.loadXCampbrainSessions();
      }
    } catch (err) {
      console.log('Err:', err);
      this.loadingService.toggleLoadingScreen(false);
      // get the status. If 350
      if (err.status === 350) {
        const message = JSON.parse(err._body).message;
        this.dialogsService.alert('Cannot Close Session', message);
      } else {
        this.dialogsService.alert('Processing Error', 'There was an error processing the request. Please try again later');
        this.selectedData = [];
      }
    }
  }

  public async loadXCampbrainSessions() {
    console.log('Load XCampbrainSessions...');
    try {
      this.loadingService.toggleLoadingScreen(true);
      const sessions: XCampbrainSessions[] = await this.organizationSettingsService.loadXCampbrainSessions(this.organization.organizationId);
      this.loadingService.toggleLoadingScreen(false);
      console.log('Loaded data:', sessions);
      const panelData: SessionJSON[] = sessions.map( session => {
        const rawJSON = session.RawJSON;
        rawJSON.Whitelisted = session.Whitelisted;
        rawJSON.SessionIdKey = session.SessionId;
        rawJSON.SessionEndKey = session.SessionEnd;
        return rawJSON;
      });
      panelData.forEach( row => {
        let activationEligibleDate: any = row.ActivationEligibleDate;
        if (!activationEligibleDate) {
          const startDate = new Date(row.SessionStart);
          const eligbleSeconds = startDate.getTime() / 1000 - 30 * 86400;
          activationEligibleDate = new Date(eligbleSeconds * 1000);
          row.ActivationEligibleDate = `${activationEligibleDate.getMonth() + 1}/${activationEligibleDate.getDate()}/${activationEligibleDate.getFullYear()}`
        } else {
          activationEligibleDate = new Date(row.ActivationEligibleDate);
        }
        const nowSeconds = Date.now() / 1000;
        const eligbleSeconds = activationEligibleDate.getTime() / 1000;
        if (nowSeconds < eligbleSeconds && row.SessionStatus !== 'Closed') {
          row.SessionStatus = 'Ineligible For Activation';
          row.IsInactive = true;
          row.ActionNote = `Eligible starting ${row.ActivationEligibleDate}`;
        }
        if (!row.SessionStatus) { row.SessionStatus = "Active"; }

        if (row.SessionStatus === 'Active') {
          if (row.Whitelisted) {
            row.displayButtons = {
              'De-Whitelist': true
            }
          } else {
            row.displayButtons = {
              'Whitelist': true
            }
          }
        }
      });
      this.selectedPanelData = panelData;
      console.log('Panel data:', this.selectedPanelData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('Load error:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your sessions. Please try again later');
    }
  }

}
