import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SquareService } from '../services/square.service';
import { UserService } from '../services/user.service';
import { DialogsService } from '../dialog/dialog.service';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { OrganizationService } from '../services/organization.service';
import { TutorialService } from '../services/tutorial.service';
import { SquareApplicationId } from '../app.constants';

import { AuthService } from '../services/auth.service';
import { Subscription }   from 'rxjs/Subscription';

import 'rxjs/add/operator/debounceTime';

declare var SqPaymentForm: any;

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit {


  public user: any = null;
  public idToken: string = "";
  public rechargeUserId: string = "";

  public rechargeUser: any = null;
  public showPopover4 = false;
  public showPopover5 = false;

  public squareUserInfo = {
    squareCardId: "",
    squareCardLast4: "",
    squareCardType: "",
    email: "",
    squareCustomerId: "",
    squareInfo: {}
  }
  public deposit_amount = 0;
  public defaultCard;

  public cardData = {};
  public paymentForm: any;
  public nameOnCard: string = "";
  public showAddCard: boolean = false;
  public showDepositFunds: boolean = false;
  public authorizedUsers = [];
  public organization = null;
  public agreeToTerms: boolean = false;
  public tutorialSubscription: Subscription;
  public unsubscribeTutorialSubscription: Subscription;
  public tokenSubscription: Subscription;
  public ipAddress: string = "";

  constructor(
    public userService: UserService,
    public squareService: SquareService,
    public dialogsService: DialogsService,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public router: Router,
    public organizationService: OrganizationService,
    public tutorialService: TutorialService,
    public authService: AuthService
  ) {
    this.tutorialSubscription = this.tutorialService.
      tutorialPaymentSettingsSubscription$.subscribe(
      popoverId => {
        console.log("subscription received!");
        console.log(popoverId);
        if (popoverId == "popover4") {
            this.showPopover4 = true;
            setTimeout(() => {
              try {
                console.log("displaying it:");
                //check if they have a card
                console.log("user is:", this.user);
                if(this.user != undefined){
                  if(!this.user.squareInfo || !this.user.squareInfo.cards || this.user.squareInfo.cards.length === 0){
                    //no card
                    document.getElementById("popover4a").click();
                  }else{
                    console.log("has a card!");
                    document.getElementById("popover4b").click();
                  }
                }
                
              } catch (err) {
                console.log("tutorial error:", err);
              }
            }, 500);
        }
      }
      )

    this.unsubscribeTutorialSubscription = this.tutorialService.removeSubscriptions$
    .subscribe( res => {
      console.log("Unsubscribing!");
      this.tutorialSubscription.unsubscribe();
    })

    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token payment-settings");
       /// console.log(token);
        this.idToken = token;
      }
    )

    this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      console.log("got ip address:", ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ""){
        this.ipAddress = ipAddress;
      }
    })

  }

  ngOnInit() {
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.user = res;
        this.getMyAuthorizedUsers();
        this.loadOrganization();
        this.loadingService.toggleLoadingScreen(false);
        if (!this.user.squareInfo || !this.user.squareInfo.squareCustomerId) {
          this.squareService.createCustomer(this.idToken, this.user)
            .then(res => {
              console.log("Create cust res:", res);
              var body = JSON.parse(res._body);
              var customerId = body.customer.id;
              console.log("Customer id:", customerId);
              this.squareService.addCustomerId(this.idToken, customerId, this.user.email, this.user.organizationId)
                .then(res => {
                  console.log("Add customer id res:", res);
                  this.user.squareInfo = {
                    squareCustomerId: body.customer.id
                  }
                })
                .catch(err => {
                  console.log("add customer id err:", err);
                })
            })
            .catch(err => {
              console.log("create customer err:", err);
            })
        }
      })
      .catch(err => {
        this.loadingService.toggleLoadingScreen(false);
      })
  }

  getMyAuthorizedUsers() {
    var userId = this.user.userId;
    //if the user is a contributor, get the users associated with the accountHolder
    if (this.user.role == 'account-contributor') {
      userId = this.user.accountHolder;
    }
    this.userService.getAuthorizedUsers(this.idToken, userId)
      .then(res => {
        var body = JSON.parse(res._body);
        if (body.status == "200") {
          this.authorizedUsers = body.message.Items;
          this.rechargeUserId = this.route.snapshot.params['userId'];
          console.log("rechargeUserId:", this.rechargeUserId);
          if (this.rechargeUserId) {
            this.authorizedUsers.forEach(user => {
              if (user.userId == this.rechargeUserId) {
                this.rechargeUser = user;
              }
            })
            console.log("recharge user:", this.rechargeUser);
          }


        }
      });

  }


  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          this.user = res;
          if (this.user.squareInfo && this.user.squareInfo.defaultCardId !== "none" && this.user.squareInfo.cards && this.user.squareInfo.cards.length > 0) {
            let defaultCard = this.user.squareInfo.cards.find( cd => {
              return cd.squareCardId === this.user.squareInfo.defaultCardId;
            });
            this.defaultCard = defaultCard;
            console.log("DEFAULT:", this.defaultCard);
          }

          resolve(res);
        });
    })
  }


  registerCard() {

    var requestCardNonce = this.paymentForm.requestCardNonce();
    console.log("RCN:", requestCardNonce);
    return false;
  }

  editCard(card) {
    this.dialogsService.confirm("Set Default?", "Do you want to make this your default card?")
      .toPromise()
      .then( res => {
        console.log("Set default:", res);
        if (res) {
          this.updateDefaultCard(card);
        }
      })
  }

  updateDefaultCard(card) {
    console.log("Set default card to:", card.squareCardId);
    this.squareService.setDefaultCard(this.idToken, this.user.email, card.squareCardId, this.user.organizationId)
        .then(res => {
          console.log("Add customer id res:", res);
          this.getUser()
            .then(res => {
              this.user = res;
            });
        })
        .catch(err => {
          console.log("add customer id err:", err);
          this.dialogsService.alert("Processing Error", "There was an error updating your default card");
        })

  }


  submitAddCard(nonce, cardData) {
    console.log("Calling submitAddCard!");
    if (this.nameOnCard == "") {
      this.dialogsService.alert("Processing error", "Please make sure you have entered every field correctly");
    }
    this.squareUserInfo.squareCardLast4 = cardData.last_4;
    this.squareUserInfo.squareCardType = cardData.card_brand;
    this.squareUserInfo.email = this.user.email;
    // this.squareUserInfo.squareCustomerId = this.user.squareInfo.squareCustomerId;
    this.squareUserInfo.squareInfo = this.user.squareInfo;
    this.loadingService.toggleLoadingScreen(true);
    var request_body = {
      nonce: nonce,
      nameOnCard: this.nameOnCard,
      zip: cardData.billing_postal_code,
      customerId: this.user.squareInfo.squareCustomerId,
      organizationId: this.user.organizationId
    }
    console.log("SquareUserInfo:", this.squareUserInfo);
    console.log("user:", this.user);
    console.log("request body:", request_body);
    this.squareService.createCustomerCard(this.idToken, request_body)
      .then(res => {
        console.log("create customer card res:", res);
        this.loadingService.toggleLoadingScreen(false);
        var body = JSON.parse(res._body);
        if (body.card != undefined) {
          //success
          this.squareUserInfo.squareCardId = body.card.id;
          this.squareService.addUserInfo(this.idToken, this.squareUserInfo, this.user.organizationId)
            .then(res => {
              this.dialogsService.alert("Success!", "Your card has been added to your account!");
              //re-grab the user
              this.getUser()
                .then(res => {
                  this.user = res;
                });
              this.closeCard();
            })
            .catch(err => {
              this.dialogsService.alert("Processing error", "There was an error adding the card to your account!");
              this.loadingService.toggleLoadingScreen(false);
            })
        } else if (body.errors != undefined) {
          this.dialogsService.alert(body.errors[0].category, body.errors[0].code);
        }
      })
      .catch(err => {
        console.log("create cust err:", err);
      })


  }

  deleteCard(card) {
    this.dialogsService.confirm("Remove card", "Remove card ending in " + card.squareCardLast4 + "?")
      .toPromise()
      .then(doDelete => {
        console.log("Delete:", doDelete);
        if (doDelete) {
          this.squareService.removeCustomerCard(this.idToken, this.user.email, this.user.squareInfo.squareCustomerId, card.squareCardId, this.user.organizationId, this.user.squareInfo)
            .then(res => {
              var body = JSON.parse(res._body);
              console.log("body:", body);
              if (body.status == "200") {
                this.getUser()
                  .then(res => {
                    this.user = res;
                  });
              }
            })
            .catch(err => {
              console.log("Remove card err:", err);
            })
        }
      })
  }


  closeCard() {
    this.showAddCard = false;
  }


  chargeCustomer(amount) {
    if (!this.agreeToTerms && this.organization != null && this.organization.showEndSessionMessage) {
      this.dialogsService.alert("Terms not accepted", "You must accept the agreement to continue");
      return;
    }
    if (amount == undefined || amount < 1) {
      this.dialogsService.alert("Invalid amount", "You must deposit an amount of at least $1.00");
      return;
    }
    this.dialogsService.confirm("Deposit funds", "Deposit $" + amount + "?")
      .toPromise()
      .then(res => {
        if (res) {
          //charge them
          this.loadingService.toggleLoadingScreen(true);
          var timeZone = -4;
          if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
            timeZone = this.organization.timeZoneOffset;
          }
          console.log("Square info pre charge:", this.user.squareInfo.defaultCardId);
          this.squareService.chargeCustomer(this.idToken, this.user.email, amount, this.user.squareInfo.squareCustomerId, this.user.squareInfo.defaultCardId, this.user.organizationId, this.user.userId, this.user.firstName, this.user.lastName,
          this.ipAddress, timeZone, true)
            .then(res => {
              this.deposit_amount = 0;
              this.loadingService.toggleLoadingScreen(false);
              console.log("charge the customer:", res);
              var body = JSON.parse(res._body);
              if (body.hasOwnProperty('status') && body.status == "200") {
                //if there's a 'recharge-user', ask if they want to fully allocate the funds to that user
                if (this.rechargeUser != null) {
                  this.dialogsService.confirm("Funds added!", "Would you like to allocate all $" + amount + " to " + this.rechargeUser.firstName + "'s account?", "Allocate later")
                    .toPromise()
                    .then(res => {
                      if (res) {
                        this.loadingService.toggleLoadingScreen(true);
                        this.squareService.fundsTransferToAuthUser(this.idToken, this.user.email, amount, this.rechargeUserId, this.user.userId, this.user.role, "Main Account", "main",
                        this.rechargeUser.firstName, this.rechargeUser.lastName, this.rechargeUser.accountHoder, this.rechargeUser.organizationId, this.user.userId, this.user.email,
                        this.ipAddress, timeZone)
                          .then(res => {
                            this.loadingService.toggleLoadingScreen(false);
                            this.dialogsService.confirm("Funds Transferred!", "The funds have been allocated to " + this.rechargeUser.firstName + "'s account!");
                            this.getUser()
                              .then(res => {
                                this.user = res;
                              });
                            this.showDepositFunds = false;
                          })
                          .catch(err => {
                            this.loadingService.toggleLoadingScreen(false);
                            console.log("FTTAU err:", err);
                          })
                      }
                    })
                  return;
                }

                //ask if they want to distribute the funds evenly
                // if (this.authorizedUsers.length > 0) {

                //   var splitMessage =  "The funds have been added to your account. Would you like to split the funds evenly between all of your campers?";


                //   if(this.authorizedUsers.length == 1){
                //     splitMessage = "The funds have been added to your account. Would you like to allocate it all to " + this.authorizedUsers[0].firstName + "?";
                //   }



                //   this.dialogsService.confirm("Success!", splitMessage, "Allocate later")
                //     .toPromise()
                //     .then(res => {
                //       if (res) {
                //         this.loadingService.toggleLoadingScreen(true);
                //         console.log("split funds evenly!");
                //         //get authUserIds
                //         var authUserIds = [];
                //         this.authorizedUsers.forEach(user => {
                //           authUserIds.push(user.userId);
                //         })
                //         var contributorName = "Main Account";
                //         var relationship = "main";
                //         if (this.user.role == 'account-contributor') {
                //           contributorName = this.user.nickname;
                //           relationship = this.user.relationship;
                //         }
                //         this.squareService.splitFundsEvenly(this.idToken, this.user.email, authUserIds, this.user.userId, amount, this.user.role, contributorName, relationship,
                //         this.ipAddress, timeZone, this.user.organizationId)
                //           .then(res => {
                //             this.loadingService.toggleLoadingScreen(false);
                //             console.log("split funds evenly res:", res);
                //             var body = JSON.parse(res._body);
                //             if (body.message.statusCode == "200") {
                //                var successMessage =  "Your funds have been evenly divided amongst all your campers";

                //               if(this.authorizedUsers.length == 1){
                //                 successMessage = "The funds have been allocated to " + this.authorizedUsers[0].firstName + "'s account";
                //               }
                //               this.dialogsService.alert("Success!", successMessage);
                //               //now regrab their balance
                //               this.getUser()
                //                 .then(res => {
                //                   this.user = res;
                //                 });
                //             }

                //           })
                //           .catch(err => {
                //             console.log("Split funds evenly err:", err);
                //             this.loadingService.toggleLoadingScreen(false);
                //           })
                //       }
                //     })

                // } else {
                  this.dialogsService.alert("Success!", "The funds have been added to your account. Please allocate them.");
                // }

                this.showDepositFunds = false;
                this.getUser()
                  .then(res => {
                    this.user = res;
                  });
              } else {
                //something went wrong
                console.log("body:", body);
                let errorMessage = "There was an error processing this transaction";
                if (body[0] && body[0].category) {
                  errorMessage = body[0].category + " - " + body[0].code + ": " + body[0].detail;  
                }
                this.dialogsService.alert("Processing Error", errorMessage);
              }

            })
            .catch(err => {
              console.log("charge customer err:", err);
              this.dialogsService.alert("Processing Error", "There was an error processing this transaction");
            })
        }
      })

  }


  openAddCard() {
    let paymentProcessor = this.organization.paymentProcessor || "square";
    if (paymentProcessor === "square") {
      if (this.organization.squareReader && this.organization.squareReader.ready) {
        this.generateSquareForm();
      } else {
        this.dialogsService.alert("Incomplete Setup", "Your organization is not yet setup to accept payments through the portal");
      }
    } else {
      this.dialogsService.alert("Incomplete Setup", "Your organization is not yet setup to accept payments through the portal");
    }
    
  }

  generateSquareForm() {
    console.log("showPopover4:", this.showPopover4);
    this.showAddCard = true;
    this.loadingService.toggleLoadingScreen(true);
    //give the DOM time to load the form
    
      var applicationId = SquareApplicationId;
      console.log("called ngViewAfterInit!!");
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://js.squareup.com/v2/paymentform";
      document.head.appendChild(script);
      var script = document.createElement("script");
      setTimeout(res => {
      console.log("loading form:");
      try {
        if (!SqPaymentForm) {
          this.loadingService.toggleLoadingScreen(true);
          this.dialogsService.alert("Square Error", "Error initializing form. Reloading...");
          window.location.reload();
        }
        console.log("SqPaymentForm is:", SqPaymentForm);
        this.paymentForm = new SqPaymentForm({
          applicationId: applicationId,
          inputClass: 'sq-input',
          inputStyles: [
            {
              fontSize: '15px'
            }
          ],
          cardNumber: {
            elementId: 'sq-card-number',
            placeholder: '•••• •••• •••• ••••'
          },
          cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV'
          },
          expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM/YY'
          },
          postalCode: {
            elementId: 'sq-postal-code',
            placeholder: '12345'
          },
          callbacks: {
            // Called when the SqPaymentForm completes a request to generate a card
            cardNonceResponseReceived: (errors, nonce, cardData) => {
              console.log("card nonce response received!");
              console.log(errors);
              console.log(nonce);
              console.log(cardData);
              this.loadingService.toggleLoadingScreen(false);
              if (errors) {
                console.log("Encountered errors:");
                var errorMessage = "";
                errors.forEach((error) => {
                  console.log('  ' + error.message);
                  errorMessage += "-" + error.message + "\n\n\n\n\n\n";
                });
                this.dialogsService.alert("Invalid Fields", errorMessage);

              } else {
                console.log("calling submit add card");
                this.submitAddCard(nonce, cardData);
              }
            },
            unsupportedBrowserDetected: (res) => {
              console.log("UBD");
              this.loadingService.toggleLoadingScreen(false);
              // Fill in this callback to alert buyers when their browser is not supported.
            },
            paymentFormLoaded: (res) => {
              console.log("form loaded!");
              this.loadingService.toggleLoadingScreen(false);
            }
          }
        });
        this.paymentForm.build();
      } catch(err) {
        console.log("Error loading form", err);
        this.dialogsService.alert("Square Error", "Unable to load form correctly. Please try reloading the page");
        this.loadingService.toggleLoadingScreen(true);
        this.showAddCard = false;
        
      }

    }, 500);

  }



  goToUsers() {
    this.router.navigate(['users']);
  }



  withdrawRemainingFunds(){
    var amount = this.user.availableBalance;
    console.log(amount);

    this.dialogsService.input("Withdraw funds from FunFangle?", "Enter an amount to refund back onto their card. Up to $" + amount, amount, "Cancel", "Withdraw")
    .toPromise()
    .then( res => {
      console.log("res:", res);

      if(res[0]){
        var refundAmount = res[1];
        
        console.log("refund amount:", refundAmount);
        var timeZone = -4;
        if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
          timeZone = this.organization.timeZoneOffset;
        }

        this.loadingService.toggleLoadingScreen(true);

        this.squareService.parentInitiatedRefund(this.idToken, this.user.email, refundAmount, timeZone, this.ipAddress, this.user.organizationId)
        .then( res => {
          this.loadingService.toggleLoadingScreen(false);
          console.log("res:", res);
          var body = JSON.parse(res._body);
          if(body.status == "200"){
            this.dialogsService.alert("Withdraw Succeded", "Your funds may take several business days to appear back on your card");
            this.ngOnInit();
          }
        })
        .catch( err => {
          this.loadingService.toggleLoadingScreen(false);
          console.log("error:", err);
          this.dialogsService.alert("Withdraw Failed", "You may have attempted to withdraw more than is in the account. Please refresh the page. If this issue persists, please contact FunFangle support");
        })

      }
    
    })
  }


  //pull down the organization
  loadOrganization() {
    this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
      .then(res => {
        console.log("Organization:", res);
        var body = JSON.parse(res._body);
        this.organization = body.message.Items[0];
        console.log("org:", this.organization);
      }).catch(err => {
        console.log("get org err:", err);
      })
  }



  skipSteps(popoverId) {
    console.log("skip!");
    this.showPopover4 = false;
    this.showPopover5 = false;
    this.tutorialService.skipSteps(this.idToken, this.user.email, this.user.organizationId);
  }

  nextStep(popoverId) {
    if (popoverId == 'popover4a') {
      this.showPopover4 = false;
      this.showPopover5 = true;
      //check if they have a card
      if (true) {
        //no card
        setTimeout(() => {
          try {
            document.getElementById("popover5a").click();
          } catch (err) {
            console.log("tutorial error:", err);
          }
        }, 250);
      } else {
        //yes card
      }
    } else if (popoverId == 'popover5a' || popoverId == 'popover4b') {
      this.showPopover5 = false;
      this.tutorialService.triggerSideNavPopover('popover6');
    }
  }

   //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }


}
