export class CarePackage{
    title: string;
    organizationId: string;
    packageId: string;
    imageUrl?: string;
    price: number;
    items: CarePackageItem[];
    itemsString?: string;

    constructor(){
        this.title = "";
        this.organizationId = "";
        this.packageId = "";
        this.price = 0;
        this.items = [];
        this.itemsString = "";
    }
}


export class CarePackageItem{
    title: string;
    description: string;
    modifiers: CarePackageModifier[];
    
    constructor(){
        this.title = "";
        this.description = "";
        this.modifiers = [];
    }
}


export class CarePackageOrder{
    orderId: string;
    placedAt: number;
    placedAtDate: string;
    packageId: string;
    cabinName: string;
    organizationId: string;
    status: string;
    accountHolderId?: string;
    userId?: string;
    authUserId: string;
    packageTitle: string;
    price: number;
    authUserFirstName: string;
    authUserLastName: string;
    accountHolderFirstName: string;
    accountHolderLastName: string;
    modifiersByItem: {};
    modifierString: string;
    orderNotes: string;
    deliverBy: Date;
    tax?: number;
    session?: string;

    constructor(){
        this.orderId = "";
        this.packageId = "";
        this.organizationId = "";
        this.status = "";
        this.accountHolderId = "";
        this.authUserId = "";
        this.modifiersByItem = {};
    }
}


export class CarePackageModifier{
    name: string;
    options: [{
        name: string
    }]
    multiSelect: boolean;
    selectRequired: boolean;

    constructor(){
        this.name = "";
        this.options = [{
            name: ""
        }];
        this.multiSelect = false;
        this.selectRequired = true;
    }

}