import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Subject }    from 'rxjs/Subject';
import { TutorialEndpoint } from '../app.constants';
import { TokenService } from './token.service';





/*Service for interfacing with Cognito methods*/
@Injectable()
export class TutorialService {

  constructor(
    public http: Http,
  ) { }


  public tutorialSideNavSubscription = new Subject<any>();
  public tutorialPaymentSettingsSubscription = new Subject<any>();
  public tutorialUsersSubscription = new Subject<any>();
  public tutorialOverlaySubscription = new Subject<any>();
  public tutorialContributorsSubscription = new Subject<any>();
  public tutorialStatusSubscription = new Subject<any>();


  public removeSubscriptions = new Subject<any>();


  tutorialSideNavSubscription$ = this.tutorialSideNavSubscription.asObservable();
  tutorialPaymentSettingsSubscription$ = this.tutorialPaymentSettingsSubscription.asObservable();
  tutorialUsersSubscription$ = this.tutorialUsersSubscription.asObservable();
  tutorialContributorsSubscription$ = this.tutorialContributorsSubscription.asObservable();
  tutorialOverlaySubscription$ = this.tutorialOverlaySubscription.asObservable();
  removeSubscriptions$ = this.removeSubscriptions.asObservable();
  tutorialStatusSubscription$ = this.tutorialStatusSubscription.asObservable();

  public idToken: string = "";

  public popoverId: string = "";

  getPopoverId(){
    return this.popoverId;
  }


  //trigger the popover on the left side menu
  triggerSideNavPopover(popoverId: any){
    this.popoverId = popoverId;
    this.tutorialSideNavSubscription.next(popoverId);
  }

  closeSideNavTutorials(){
    this.tutorialSideNavSubscription.next("close");
  }

  triggerPaymentSettingsPopover(popoverId: any){
      this.popoverId = popoverId;
      this.tutorialPaymentSettingsSubscription.next(popoverId);
  }

  triggerCampersPopover(popoverId: any){
    this.popoverId = popoverId;
    this.tutorialUsersSubscription.next(popoverId);
  }

  triggerContributorsPopover(popoverId: any){
    this.popoverId = popoverId;
    console.log("popover id is:", this.popoverId);
    this.tutorialContributorsSubscription.next(popoverId);
  }

  showOverlay(){
    this.tutorialOverlaySubscription.next(true);
  }

  hideOverlay(){
    this.tutorialOverlaySubscription.next(false);
  }

  removeExistingTutorialSubscriptions(){
    this.removeSubscriptions.next(true);
  }

  startTutorial(){
    this.tutorialStatusSubscription.next(true);
  }

  //skip all the steps
  skipSteps(idToken: string, email: string, organizationId: string): Promise<any>{
    this.hideOverlay();
    this.tutorialStatusSubscription.next(false);
    var options = TokenService.getHeaders();
    var data = {
      email,
      organizationId
    }
    console.log("skip steps posting!");
    return this.http.post(`${TutorialEndpoint}/tutorial/hide`, data, options)
    .toPromise();
  }

  //finished it 
  completeTutorial(idToken: string, email: string, organizationId: string): Promise<any>{
    this.hideOverlay();
    this.tutorialStatusSubscription.next(false);
    return this.skipSteps(idToken, email, organizationId);
  }


}