import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'select-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">
                <div  fxLayout="row" class="campers-wrap" *ngFor="let camper of authorizedUsers">
                    <div class="image-column">
                        <div *ngIf="camper.imageUrl == undefined || camper.imageUrl == null">
                            <mat-icon>person_outline</mat-icon>
                        </div>
                        <div class="user-image-wrap" *ngIf="camper.imageUrl != undefined && camper.imageUrl != null">
                            <img [src]="camper.imageUrl"/>
                        </div>
                    </div>
                    <div class="name-column">
                        {{camper.firstName}} {{camper.lastName}}
                    </div>

                    <div class="action-column staff-checkbox-wrap">
                        <mat-checkbox [(ngModel)]="camper.enableContributions"></mat-checkbox>
                    </div>
                    
                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="submit()" class="submit-button">Add Contributor</button>
            </div>
            
        </div>
        `,
})
export class SelectCampersDialog {

    public title: string;
    public message: string;
    public cancelText: string;
    public okText: string;
    public authorizedUsers = [];
    

    constructor(public dialogRef: MatDialogRef<SelectCampersDialog>) {
        console.log(dialogRef);
        console.log(dialogRef['componentInstance']);
        
        
    }

    ngOnInit() {
        console.log("Campers:");
        console.log(this.authorizedUsers);
        this.authorizedUsers.forEach( authUser => {
            authUser.enableContributions = true;
        });
    }


    submit(){
        const blacklistIds = this.authorizedUsers.filter( authUser => {
            return !authUser.enableContributions;
        }).map( user => user.userId )
        const whitelistNames = this.authorizedUsers.filter( authUser => {
            return authUser.enableContributions;
        }).map( authUser => " " + authUser.firstName );
        console.log("Whitelist names:", whitelistNames);
        this.dialogRef.close({ blacklist: blacklistIds, whitelist: whitelistNames });
    }

    


    

}