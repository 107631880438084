import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
// import { MaterialModule } from '@angular/material';
import { DialogsService } from '../dialog/dialog.service';
import { SquareService } from '../services/square.service';
import { CustomerSettingsService } from '../services/customer-settings.service';
import { LoadingService } from '../services/loading.service';
import { PhonePipe } from '../pipes/phone-pipe';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {

  public user: any = null;
  public firstName: string = "";
  public lastName: string = "";
  public phone: string = "";
  public idToken: string = "";
  public tokenSubscription: Subscription;
  public nickName: string = "";
  public oldPassword;
  public newPassword;
  public newPasswordConfirm;


  constructor(
    public userService: UserService,
    public router: Router,
    public dialogsService: DialogsService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public route: ActivatedRoute,
  ) {


    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token user-info");
       /// console.log(token);
        this.idToken = token;
      }
    )

   }

  ngOnInit() {
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        this.user = res;
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.phone = this.user.phone;
        this.nickName = this.user.nickname;
      })

    var changePass = this.route.snapshot['changePassword'];
    if(changePass == "changePassword"){
      this.dialogsService.alert("Change your password", "Your password has been manually set, please change it here");
    }
  }


  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
    })
  }


  updateUserInfo(){
    console.log("update info:");
    console.log(this.firstName);
    console.log(this.lastName);
    console.log(this.phone);
    if(this.phone != "" && this.phone != undefined){
      if(!this.authService.isPhoneValid(this.phone)){
        this.dialogsService.alert("Invalid number", "You must enter a valid phone number");
        return;
      }
    }
    //now update it
    this.loadingService.toggleLoadingScreen(true);
    this.userService.updateUserInfo(this.idToken, this.user.email, this.firstName, this.lastName, this.phone, this.nickName, this.user.organizationId)
    .then(res => {
      console.log("res ~~~~:", res);
      this.loadingService.toggleLoadingScreen(false);
      var body = JSON.parse(res._body);
      console.log("body:", body);
      if(body.status == "200"){
        this.dialogsService.alert("Success!", "Your information has been updated");
      }

    })
    .catch(err => {
      console.log("err:", err);
      this.loadingService.toggleLoadingScreen(false);
    })
  }


  changePassword(oldPass, newPass, newPassConfirm){
    //first validate the passwords
    if(!this.authService.validatePassword(newPass, newPassConfirm)){
      this.dialogsService.alert("Invalid password", "Make sure your passwords match and are at least 8 characters long!");
      return;
    }
    console.log("init aws cognito user-info??");
    this.authService.initializeAWSCognito();
    this.authService.updatePassword(this.user.email, oldPass, newPass)
    .then(res=> {
      console.log("cp res:", res);
      if(res == "SUCCESS"){
        this.dialogsService.alert("Success!", "You have successfully updated your password!");
      }
    }).catch(err => {
      console.log("cp err:", err);
      this.dialogsService.alert("Processing Error", "Please make sure you have entered your password correctly");
    })
  }

   //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }

}
