import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Subject } from 'rxjs/Subject';

import { SquareEndpoint, TutorialEndpoint, PosEndpoint, BaseEndpoint } from '../app.constants';
import { TokenService } from './token.service';

declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class TransactionsService{

  public organizationId: any = null;
  public idToken: string = "";

  constructor(
    public http: Http,

  ){}

  async getAllTransactions(idToken: string, organizationId: string, lastEvalKey = null, startISO: string, endISO: string, amount?: number, holderEmail?: string, holderName?: string, last4?: string): Promise<any>{
    var headers = TokenService.getHeaders();

    // try {
    //   let offset = -4;
    //     // if (this.organization.timeZoneOffset !== undefined) {
    //     //   offset = this.organization.timeZoneOffset;
    //     // }
    //     // let startSeconds = this.mainInput.start._d.getTime() / 1000;
    //     // let endSeconds = this.mainInput.end._d.getTime() / 1000;
    //   const startDate = new Date(2022,8,2);
    //   const endDate = new Date(2022,8,9);
    //   startDate.setHours(0, 0, 0, 0);
    //   endDate.setHours(23, 59, 59, 999);
    //   let startSeconds = startDate.getTime() / 1000;
    //   let endSeconds = endDate.getTime() / 1000;
    //   const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    //   const diff = offset - localOffset;
    //   startSeconds -= 3600 * diff;
    //   endSeconds -= 3600 * diff;
    //   console.log(startSeconds, endSeconds);

    //   var data = {
    //     organizationId,
    //     startSeconds,
    //     endSeconds
    //   }

    //   if(lastEvalKey != null){
    //     data['lastKey'] = lastEvalKey;
    //   }

    //   await this.http
    //     .post(SquareEndpoint + "/transactions/getTransactionsByOrganizationId", data, headers).toPromise().catch(e => {
    //       console.error(e);
    //     })
    // } catch (e) {
    //   console.error(e);
    // }

    let url = BaseEndpoint + `/v2/bank/transaction/search?organizationId=${encodeURIComponent(organizationId)}`;
    if (startISO !== undefined) url = `${url}&loweriso=${encodeURIComponent(startISO)}`
    if (endISO !== undefined) url = `${url}&upperiso=${encodeURIComponent(endISO)}`
    if (amount !== undefined) url = `${url}&amount=${encodeURIComponent(String(amount))}`;
    if (holderEmail !== undefined) url = `${url}&email=${encodeURIComponent(holderEmail)}`;
    if (holderName !== undefined) url = `${url}&name=${encodeURIComponent(holderName)}`;
    if (last4 !== undefined) url = `${url}&last4=${encodeURIComponent(last4)}`;
    const options: any = {
      // limit: 100,
      pageSize: 500
    };
    if (lastEvalKey !== undefined) options.startKey = lastEvalKey;
    url = `${url}&options=${encodeURIComponent(JSON.stringify(options))}`

    return this.http
      .get(url, headers)
      .toPromise();
  }

  voidTransaction(idToken: string, transactionId: string, groupId: string = null): Promise<any>{
    var options = TokenService.getHeaders();;
    console.log("idToken:", idToken);
    if (groupId) {
      return this.http
        .post(PosEndpoint + "/transactions/voidMultipartTransaction", { transactionId, groupId }, options)
        .toPromise();
    }
    return this.http
      .get(SquareEndpoint + "/transactions/void/" + transactionId, options)
      .toPromise();

  }


  getParentTransactions(idToken: string, accountHolder: string): Promise<any>{
    var options = TokenService.getHeaders();
    
    var data = {
      accountHolder: accountHolder
    }

    return this.http
    .post(TutorialEndpoint + "/transactions/getParentTransactions", data, options)
    .toPromise();
  }


  reverseDonation(authUserId: string | undefined, timeStamp: number | undefined, organizationId: string | undefined, transactionId: string | undefined): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      authUserId,
      timeStamp,
      organizationId,
      transactionId
    }
    return this.http
      .post(`${BaseEndpoint}/v2/bank/transaction/reverse/donation`, data, options)
      .toPromise();
  }


  transactionReport(idToken: string, organizationId: string, defaultTimeZone, startTime, endTime): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      organizationId: organizationId,
      defaultTimeZone: defaultTimeZone,
      startTime: startTime,
      endTime: endTime
    }
    return this.http
      .post(TutorialEndpoint + "/transactions/transactionReport", data, options)
      .toPromise();
  }

  userTransactionReport(idToken: string, authUserId: string, defaultTimeZone): Promise<any>{
    var options = TokenService.getHeaders();;
    var data = {
      authUserId: authUserId,
      defaultTimeZone: defaultTimeZone
    }
    return this.http
      .post(`${TutorialEndpoint}/transactions/userTransactionReport`, data, options)
      .toPromise();
  }


  parentTransactionReport(idToken: string, accountHolder: string, defaultTimeZone): Promise<any>{
    var options = TokenService.getHeaders();;
    var data = {
      accountHolder: accountHolder,
      defaultTimeZone: defaultTimeZone
    }
    return this.http
      .post(TutorialEndpoint + "/transactions/parentTransactionReport", data, options)
      .toPromise();
  }

    processIndividualDonation(userId: string, email: string, session: string, type: string, organizationId: string, hasCard = true, activeUserEmail: string = null){
      var data = {
        userId: userId,
        email:email,
        session:session,
        type: type,
        organizationId: organizationId,
        hasCard: hasCard,
        activeUserEmail: activeUserEmail
      }
      console.log("data:", data)
      return this.http
        .post( TutorialEndpoint + "/transactions/processIndividualDonation", data)
        .toPromise();
    }


    reconcileBalances(idToken: string, accountHolder: string){
      var options = TokenService.getHeaders();;
      var data = {
        accountHolder: accountHolder
      }
      return this.http
        .post( TutorialEndpoint + "/parents/reconcileBalances", data, options)
        .toPromise();
    }

}
