import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { ApiEndpoint, BaseEndpoint, CognitoPoolId, OrganizationEndpoint, SquareEndpoint } from '../app.constants';
import { DialogsService } from '../dialog/dialog.service';
import { Subject }    from 'rxjs/Subject';
import { OrganizationUser } from '../models/organization';
import { TokenService } from './token.service';

declare var AWSCognito: any;
declare var AWS: any;


/*Service for interfacing with Cognito methods*/
@Injectable()
export class OrganizationService {

  constructor(
    public http: Http,
    public authService: AuthService,
    public dialogsService: DialogsService
  ) { }

  public organization: any = null;

  public toggleSubscription = new Subject<any>();
  toggleSubscription$ = this.toggleSubscription.asObservable();
  forceAccountHolder: boolean = false;

  //load the organization associated with the user's id
  loadOrganization(idToken: string, userId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        userId: userId
    }
    console.log(options);
    return this.http
      .post(ApiEndpoint + "/organization/getByOwnerId/", data, options)
      .toPromise()
  }

  getOrganizationSessions(idToken:string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(OrganizationEndpoint + "/sessions/" + organizationId, options)
      .toPromise();
    
    
  } 

  getReportBelowThresholdWithCard(idToken: string, sessionString: string, threshold: number, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      sessionString: sessionString,
      threshold: threshold,
      organizationId: organizationId
    }
    return this.http
      .post(OrganizationEndpoint + "/reports/getReportBelowThresholdWithCard", data, options )
      .toPromise();
  } 


  getReportAboveThresholdWithCard(idToken: string, sessionString: string, threshold: number, organizationId: string, selectedSessions: string[]): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      sessionString: sessionString,
      threshold: threshold,
      organizationId: organizationId,
      sessionsList: selectedSessions
    }
    return this.http
      .post(OrganizationEndpoint + "/reports/getReportAboveThresholdWithCard", data, options )
      .toPromise();
  }




  getRemainingBalances(idToken: string, sessionString: string, organizationId: string, selectedSessions: string[]): Promise<any>{
    var options = TokenService.getHeaders();
    // var data = {
    //   sessionString,
    //   organizationId,
    //   sessionsList: selectedSessions
    // }

    if ((sessionString === undefined || sessionString == null) && selectedSessions !== undefined && selectedSessions.length === 1) {
      sessionString = selectedSessions[0];
    }

    let url = `${BaseEndpoint}/v2/profile/participants?organizationId=${organizationId}`;
    url = `${url}&fields=${encodeURIComponent('accountBalance,currentBalance,endOfSession,hasFutureSessions,session')}`;
    url = `${url}&balance=0.009&balanceOp=gt`;
    url = `${url}&sessionString=${encodeURIComponent(sessionString)}`;
    url = `${url}&sessionsList=${encodeURIComponent(selectedSessions.join(','))}`;
    return this.http
      .get(url, options )
      .toPromise();
  }


  manuallyManageRefunds(idToken: string, userIds: string[], organizationId: string, userId: string, email: string): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
      userIds,
      organizationId,
      activeUserEmail: email,
      activeUserId: userId
    }
    return this.http
      .post(`${SquareEndpoint}/refunds/manuallyManageRefunds`, data, options )
      .toPromise();
  }

  setTabLimit(authUserId: string, organizationId: string, tabLimit: number, ipAddress: string, activeUserEmail: string, activeUserId: string): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      authUserId,
      organizationId,
      tabLimit,
      ipAddress,
      activeUserEmail,
      activeUserId
    }
    return this.http
      .post(`${OrganizationEndpoint}/users/setTabLimit`, data, options)
      .toPromise();
  }

  setBalancesZero(idToken: string, userIds: any, type: string, userId: string, email: string, organizationId:string,
  userFirstNames, userLastNames, timeZone
  ): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      userIds: userIds,
      type: type,
      activeUserId: userId,
      activeUserEmail: email,
      organizationId: organizationId,
      userFirstNames: userFirstNames,
      userLastNames: userLastNames,
      timeZone: timeZone
    }
    return this.http
      .post(SquareEndpoint + "/refunds/setBalancesZero", data, options )
      .toPromise();
  }

  


  createOrganization(newOrganization: OrganizationUser): Promise<any>{
    var data = {
        firstName: newOrganization.firstName,
        lastName: newOrganization.lastName,
        email: newOrganization.email,
        organizationName: newOrganization.orgName,
        nickName: newOrganization.nickName,
        domainUrl: newOrganization.domainUrl,
        timeZoneOffset: newOrganization.timeZoneOffset,
        telephone: newOrganization.telephone
    }
    console.log("Data", data)
    return this.http
      .post(ApiEndpoint + "/user/userAndOrganizationCreate", data)
      .toPromise()
  }


  getOrganizationById(idToken:string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(OrganizationEndpoint + "/organization/" + organizationId, options)
      .toPromise();
  }


  updateCustomerEmail(idToken: string, oldEmail: string, newEmail: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      oldEmail: oldEmail,
      newEmail: newEmail,
      organizationId
    }
    return this.http
      .post(OrganizationEndpoint + "/organizations/updateCustomerEmail", data, options)
      .toPromise();
  }


  updateCustomerName(idToken: string, custInfo: any, parentNum: any, organizationId): Promise<any>{
    var options = TokenService.getHeaders();
    var firstName = custInfo.firstName;
    var lastName = custInfo.lastName;
    if(parentNum == 2 || parentNum == "2"){
      console.log("it's parent 2!");
      firstName = custInfo.parent2FirstName,
      lastName = custInfo.parent2LastName
    }
    
    var data = {
      email: custInfo.email,
      firstName: firstName,
      lastName: lastName,
      parentNum: parentNum,
      organizationId
    }
    console.log("Sending in data:", data);
    return this.http
      .post(OrganizationEndpoint + "/organizations/updateCustomerName", data, options)
      .toPromise();
  }



  propagateToggle(show){
    this.forceAccountHolder = show;
    this.toggleSubscription.next(show);
  }


}
