import { Observable } from 'rxjs/Rx';
import { ConfirmDialog } from './confirm-dialog.component';
import { AlertDialog } from './alert-dialog.component';
import { InputDialog } from './input-dialog.component';
import { UploadImageDialog } from './upload-image-dialog.component';
import { ItemFieldsDialog } from './item-type-fields.component';
import { BarcodeDialog } from './barcode-dialog.component';
import { CarePackageItemDialog } from './care-package-item-dialog.component';
import { ChangePasswordDialog } from './change-password-dialog.component'; 
import { ConfirmAddDialog } from './confirm-add-dialog.component';
import { ModifierDetailDialog } from './modifier-detail.component';
import { SelectDialog } from './select-dialog.component';
import { SelectCampersDialog } from './select-campers-dialog.component';
import { TransactionSearchDialog } from './transaction-search-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { CarePackageItem } from '../models/care-package';
import { ModifierOption } from "../models/item";
import { TransactionSearchTerms } from '../models/transaction-search';

@Injectable()
export class DialogsService {

    constructor(public dialog: MatDialog) { }

    public confirm(title: string, message: string, cancelText: string = "Cancel", okText: string = "OK"): Observable<boolean> {

        let dialogRef: MatDialogRef<ConfirmDialog>;

        dialogRef = this.dialog.open(ConfirmDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.cancelText = cancelText;
        dialogRef.componentInstance.okText = okText;


        return dialogRef.afterClosed();
    }


    public alert(title: string, message: string) {
        let dialogRef: MatDialogRef<AlertDialog>;
        dialogRef = this.dialog.open(AlertDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
    }


    public input(title: string, message: string, maxVal: number, cancelText: string = "Cancel", okText: string = "Refund", type: string = "number"): Observable<boolean>{
        let dialogRef: MatDialogRef<InputDialog>;
        dialogRef = this.dialog.open(InputDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.cancelText = cancelText;
        dialogRef.componentInstance.okText = okText;
        dialogRef.componentInstance.maxVal = maxVal;
        dialogRef.componentInstance.type = type;
        console.log("passed in type:", type);

        return dialogRef.afterClosed();
    }

    public selectCampersDialog(title: string, message: string, campers: any[]): Observable<boolean>{
        let dialogRef: MatDialogRef<SelectCampersDialog>;
        dialogRef = this.dialog.open(SelectCampersDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.authorizedUsers = campers;
        return dialogRef.afterClosed();
    }

    public transactionSearchTermDialog(searchTerms: TransactionSearchTerms, dateInput: any, dateInputs: any[]): Observable<TransactionSearchTerms> {
        let dialogRef: MatDialogRef<TransactionSearchDialog>;
        dialogRef = this.dialog.open(TransactionSearchDialog);
        dialogRef.componentInstance.title = 'Search Terms';
        dialogRef.componentInstance.searchTerms = searchTerms;
        dialogRef.componentInstance.dateInput = dateInput;
        dialogRef.componentInstance.dateInputs = dateInputs;
        return dialogRef.afterClosed();
    }


    public confirmAddDialog(title: string, message: string, campers: any): Observable<boolean>{
        let dialogRef: MatDialogRef<ConfirmAddDialog>;
        dialogRef = this.dialog.open(ConfirmAddDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;
        dialogRef.componentInstance.authorizedUsers = campers;
        return dialogRef.afterClosed();
    }


    public setItemFields(item: any): Observable<any>{
        let dialogRef: MatDialogRef<ItemFieldsDialog>;
        dialogRef = this.dialog.open(ItemFieldsDialog);
        dialogRef.componentInstance.item = item;
        return dialogRef.afterClosed();
    }


    public addModifier(option: ModifierOption = null): Observable<any>{
        let dialogRef: MatDialogRef<ModifierDetailDialog>;
        dialogRef = this.dialog.open(ModifierDetailDialog);
        dialogRef.componentInstance.option = option;
        return dialogRef.afterClosed();
    }


    public uploadImage(title:string, imageUrl: string, height: number = 0): Observable<boolean>{
        let dialogRef: MatDialogRef<UploadImageDialog>;
        dialogRef = this.dialog.open(UploadImageDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.imageUrl = imageUrl;
        dialogRef.componentInstance.height = height;
        return dialogRef.afterClosed();
    }


    public barcodeDialog(title: string, price: string, gtin: string): Observable<boolean> {
        let dialogRef: MatDialogRef<BarcodeDialog>;
        dialogRef = this.dialog.open(BarcodeDialog, { data: { title, price, gtin }, width: '450px' });
        return dialogRef.afterClosed();
    }

    public carePackageItemDialog(item: CarePackageItem): Observable<boolean>{
        let dialogRef: MatDialogRef<CarePackageItemDialog>;
        dialogRef = this.dialog.open(CarePackageItemDialog);
        dialogRef.componentInstance.itemRef = item;
        return dialogRef.afterClosed();
    }

    public changePasswordDialog(title: string): Observable<boolean>{
        let dialogRef: MatDialogRef<ChangePasswordDialog>;
        dialogRef = this.dialog.open(ChangePasswordDialog);
        dialogRef.componentInstance.title = title;
        return dialogRef.afterClosed();
    }
    
    public select(title: string, options: string[]): Observable<boolean>{
        let dialogRef: MatDialogRef<SelectDialog>;
        dialogRef = this.dialog.open(SelectDialog);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.options = options;
        return dialogRef.afterClosed();
    }
}