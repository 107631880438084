import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'alert-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
    <div class="ff-dialog-header">{{ title }}</div>
            <div class="ff-dialog-message">{{ message }}</div>
            <div class="ff-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
        <button class="ff-dialog-button" type="button" mat-raised-button 
            (click)="dialogRef.close()">OK</button>
            </div>
    `,
})
export class AlertDialog {

    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<AlertDialog>) {

    }
}