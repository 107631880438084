import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'input-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="change-password-component">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="ff-dialog-message">{{ message }}</div>
            
            <div class="ff-dialog-message" style="font-weight:bold;color:red" *ngIf="errorMessage != ''">
                {{errorMessage}}
            </div>

                
            <div  fxLayout="row" style="text-align:center !important; font-size:18px;">
                <mat-form-field>
                    <input matInput type="password" [(ngModel)]="password" placeholder="Password"/>
                </mat-form-field>
            </div>


            <div  fxLayout="row" style="text-align:center !important; font-size:18px;">
                <mat-form-field>
                    <input matInput type="password" [(ngModel)]="confirmPassword" min="0" max="{{maxVal}}"  placeholder="Confirm Password"/>
                </mat-form-field>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="changePassword()" class="submit-button">Update</button>
            </div>
        </div>
        
        `,
})
export class ChangePasswordDialog {

    public title: string;
    public message: string;
    public errorMessage: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public maxVal: number = 20;

    constructor(public dialogRef: MatDialogRef<ChangePasswordDialog>) {

    }

    validateAccept(){
        this.errorMessage = "";
        //this.dialogRef.close([true,this.amountSelected]);

    }


    changePassword()
        {
        if(this.password != this.confirmPassword){
            this.errorMessage = "Your passwords do not match";
            return;
        }
        if(this.password == null || this.password.length < 8){
            this.errorMessage = "Your password must be at least 8 characters long";
            return;
        }


        this.dialogRef.close(this.password);

    }


    

}