import { Http, Headers, RequestOptions } from '@angular/http';

export class TokenService {
    public static idToken: string;

    public static getIdToken(): string {
        return TokenService.idToken;
    }

    public static setIdToken(token: string) {
        this.idToken = token;
    }

    public static getHeaders(): RequestOptions {
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const idToken = TokenService.idToken;
        headers.append('Authorization', idToken);
        const options = new RequestOptions({ headers });
        return options;
    }

}