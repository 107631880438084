import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Subject }    from 'rxjs/Subject';

import { ApiEndpoint } from '../app.constants';
import { TokenService } from './token.service';




/*Service for interfacing with Cognito methods*/
@Injectable()
export class LingoService {

  constructor(
    public http: Http,
  ) { }


  public lingoSubscription = new Subject<any>();

  lingoSubscription$ = this.lingoSubscription.asObservable();

  public idToken: string = "";

  //load in the organization's specific lingo
  loadLingo(idToken: string, orgType: string): Promise<any>{
      var options = TokenService.getHeaders();
      var data = {
          organization_type: orgType
      }
      return this.http.
        post(ApiEndpoint + "/lingo/get", data, options)
        .toPromise();
  }



  //push the lingo to all subscribers
  refreshLingo(lingo: any){
    this.lingoSubscription.next(lingo);
  }

}