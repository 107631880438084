import { Component, OnInit } from '@angular/core';
import { LoadingService } from "../services/loading.service";
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { DialogsService } from '../dialog/dialog.service';
import { EmployeeService } from '../services/employee.service';
import { CustomerService } from '../services/customer.service';
import { Subscription }   from 'rxjs/Subscription';
import { OrganizationSettingsService } from '../services/organization-settings.service'
import { OrganizationService } from '../services/organization.service';
import { LingoService } from '../services/lingo.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})

export class EmployeesComponent implements OnInit {

  public organization: any;
  public manager: any;
  public showAddEmployees: boolean = false;
  public showEditEmployee: boolean = false;
  public employees = [];
  public idToken: string;
  public organizationId: string;
  public emp = [];
  public lingoSubscription: Subscription;
  public orgLingo: { [id: string]: string } = {};
  public sendingInvite: boolean = false;

  public newEmployee = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    organizationId : "",
    relationship: "employee",
    phone: "",
    verified: false,
    summerStaff:""
  }
  public roles = [
    {value: "organization-manager" , viewName:"Organization Manager"},
    {value: "store-manager", viewName: "Store Manager"},
    {value: "organization-employee", viewName: "Employee"},
    {value: "organization-counselor", viewName: "Counselor"},
  ]
  public tokenSubscription: Subscription;

  constructor(
    public userService: UserService,
    public dialogsService: DialogsService,
    public employeeService: EmployeeService,
    public customerService: CustomerService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public organizationSettingsService: OrganizationSettingsService,
    public organizationService: OrganizationService,
    public lingoService: LingoService
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token employee!");
       /// console.log(token);
        this.idToken = token;
      }
    )
    this.lingoSubscription = lingoService.lingoSubscription$.subscribe(
      response => {
        this.orgLingo = response;
        console.log("org lingo:", response);
      }
    )
  }

  ngOnInit() {
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Response", res);
        this.organizationId = res.organizationId;
        this.loadOrganization();
        this.manager = res;
        this.getEmployees();
        this.loadLingo();
        console.log(this.manager);
      });
  }


  getUser(): Promise<any>{
    return new Promise( ( resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
    })
  }

  loadLingo(){
    this.lingoService.loadLingo(this.idToken, "camp")
    .then(res => {
      console.log("load lingo:", res);
      var body = JSON.parse(res._body);
      body.message.Items.forEach(item => {
        this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
      });
    });
  }

  get allEmployees() {
    return this.emp.map( employee => {
      return {
        email: employee.email,
        firstName: employee.firstName,
        lastName: employee.lastName,
        verified: employee.verified,
        role: employee.role,
        summerStaff: employee.summerStaff,
        phone: employee.phone,
        userId: employee.userId
      }
    }).sort((a, b) => {
      if (a === undefined || a.firstName === undefined) return -1
      if (b === undefined || b.firstName === undefined) return 1;
      return (`${a.firstName}${a.lastName}` > `${b.firstName}${b.lastName}`) ? 1 : -1;
    });
  }

  getEmployees(){
    var organizationid  = this.organizationId;

    if(this.emp.length > 0){
      this.emp = [];
    }
    this.loadingService.toggleLoadingScreen(true);
    this.customerService.getAllCustomer(organizationid)
    .then(res => {
      this.loadingService.toggleLoadingScreen(false);
      // console.log('employee.getemployee', res);
      var body = JSON.parse(res._body)
      var em = body.items;
      // console.log(em)

      em.forEach(user => {
        if(user.role == 'organization-employee' || user.role == 'organization-manager' || user.role == 'store-manager' || user.role == 'organization-counselor'){
            this.emp.push(user)
        }
      })
    }).catch(err =>{
      console.log("Error getting employee", err);
      this.loadingService.toggleLoadingScreen(false);
    })
    console.log(this.emp);
  }


  updateEmployee(){
    if(!this.validateEmployee(this.newEmployee)){
      this.dialogsService.alert("Invalid Entry", "You must enter a value for each field");
      return;
    }
    
    this.employeeService.updateEmployee(this.idToken, this.newEmployee, this.organizationId)
    .then(res => {
      console.log("edit employee res;", res);
      this.closeCard();
      this.getEmployees();
    })

  }


  inviteEmployee(){
    if(!this.validateEmployee(this.newEmployee)){
      this.dialogsService.alert("Invalid Entry", "You must enter a value for each field");
      return;
    }
    this.sendingInvite = true;
    this.organizationSettingsService.getSessionMessage(this.idToken,this.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var org = body.message.Items[0];
        console.log(org);
        console.log("Session Message Response:", org);
        //this.organization = org.organizationName;
      })
    console.log(this.organizationId);
    this.newEmployee.organizationId = this.organizationId;
    if (!this.newEmployee.role || !this.newEmployee || !this.newEmployee.firstName || !this.newEmployee.lastName) {
      this.dialogsService.alert("Missing Fields!", "Name, email, and employee role are required fields");
      return;
    }
    this.employeeService.addEmployee(this.idToken, this.newEmployee)
      .then(res => {
        console.log("Inviting Employee: ", res);
        var body = JSON.parse(res._body);
        var employeeId = body.message;
        console.log("!Employee Id:" , employeeId);
        if (employeeId && employeeId.includes("The conditional")) {
          this.dialogsService.alert("Could not send invite", "This email may already be registered with FunFangle");
          return;
        }
        this.employeeService.sendInvite(this.idToken,this.newEmployee,this.manager, employeeId, this.organization)
          .then(res => {
            this.sendingInvite = false;
            console.log("Sending Invite", res);
            console.log(res.status);
            if(res.status == "200"){
              this.newEmployee = {
                firstName: "",
                lastName: "",
                email: "",
                role: "",
                organizationId : "",
                relationship: "employee",
                phone: "",
                verified: false,
                summerStaff:""
              }
            }
            this.dialogsService.alert("Invitation sent!","Your email invitation has been sent");
            this.showAddEmployees = false;
            this.getEmployees();
          }).catch(err => {
            this.sendingInvite = false;
            console.log("There was an error sending the invitation:", err);
            console.log("Send invitation error", err)
        })
      }).catch(err => {
        this.sendingInvite = false;
        console.log("Invite employee error", err);
    })
  }

  validateEmployee(employee){
    console.log("Employee", employee);
    if(employee.firstName == "" || employee.lastName == "" || employee.email == ""){
      return false;
    }
    else
      return true;
  }


  openEditInfo(employee){
    this.showEditEmployee = !this.showEditEmployee;

    this.newEmployee.email = employee.email;
    this.newEmployee.firstName = employee.firstName;
    this.newEmployee.lastName = employee.lastName;
    this.newEmployee.role = employee.role;
    this.newEmployee.phone = employee.phone;

  }

  openInviteEmployee(){
    this.showAddEmployees = !this.showAddEmployees;
    this.newEmployee.organizationId = this.manager.organizationId;
    // this.newEmployee.accountHolder = this.manager.userIs;
  }

  closeCard(){
    this.showAddEmployees = false;
    this.showEditEmployee = false;
  }

  reSendInvite(employee: any){
    this.newEmployee = employee;
    console.log('employee=', employee);
    console.log(this.manager)
    this.employeeService.sendInvite(this.idToken, this.newEmployee, this.manager, employee.userId, this.organization)
      .then(res => {
        this.dialogsService.alert("Invitation Sent!", "Your email invitation has been re-sent");
      }).catch(err => {
        console.log("Employee re-send Invitation error", err);
    })
  }

  removeInvitation(employee){
    this.dialogsService.confirm("Remove" + employee.firstName + "?","There will be no way to undo this")
    .toPromise()
      .then(res =>{
        if(res){
          this.employeeService.removeInvitation(this.idToken,employee.email, this.organizationId)
            .then(res => {
              console.log("Employee invitation removed", res)
              this.getEmployees();
            }).catch(err =>{
              console.log("Error removing employee invitation", err);
          })
        }
      })
  }

   //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }


  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.organizationId)
    .then(res => {
      var body = JSON.parse(res._body);
      this.organization = body.message.Items[0];
      console.log("got organization:", this.organization);
      console.log("this.organization:", this.organization.organizationName);
    })
  }
  refreshEmployees(){
    console.log("Refreshing Employees")
    this.loadOrganization();
    this.getEmployees();
  }

}
