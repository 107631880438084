import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { InventoryPurchaseDetail } from '../inventory/purchasedetail.component';
import { InventoryPurchaseOrder } from '../inventory/purchaseorder.component';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BaseEndpoint, } from "../app.constants";
import { TokenService } from './token.service';

@Injectable()
export class InventoryPurchaseService {

  constructor(public http: Http) {
  }

  insertPurchaseDetail(idToken: string, order: InventoryPurchaseDetail):  Promise<any>{
    let options = TokenService.getHeaders();
    console.log("Purchase Order Insert:", order);
    return this.http
        .post(BaseEndpoint + "/v3/inventory/purchase/detail/insert", order, options)
        .toPromise();
  }

  listPurchaseDetails(idToken: string, organizationId: string, purchaseId: string): Promise<any>{
      let options = TokenService.getHeaders();

      return this.http
        .get(BaseEndpoint + "/v3/inventory/purchase/details?orgid=" + organizationId + "&purchase_id=" + purchaseId, options)
        .toPromise();
  }

  updatePurchaseDetail(idToken: string, order: InventoryPurchaseDetail): Promise<any> {
    let options = TokenService.getHeaders();
    console.log("Purchase Order Update:", order);
    return this.http
        .post(BaseEndpoint + "/v3/inventory/purchase/detail/update", order, options)
        .toPromise();
  }

  insertPurchaseOrder(idToken: string, order: InventoryPurchaseOrder):  Promise<any>{
    let options = TokenService.getHeaders();
    console.log("Purchase Order Insert:", order);
    return this.http
        .post(BaseEndpoint + "/v3/inventory/purchase/order/insert", order, options)
        .toPromise();
  }

  listPurchaseOrders(idToken: string, organizationId: string): Promise<any>{
      let options = TokenService.getHeaders();

      return this.http
        .get(BaseEndpoint + "/v3/inventory/purchase/orders?orgid=" + organizationId, options)
        .toPromise();
  }

  updatePurchaseOrder(idToken: string, order: InventoryPurchaseOrder): Promise<any> {
    let options = TokenService.getHeaders();
    console.log("Purchase Order Update:", order);
    return this.http
        .post(BaseEndpoint + "/v3/inventory/purchase/order/update", order, options)
        .toPromise();
  }

}