import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'input-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="ff-dialog-header">{{ title }}</div>
        <div class="ff-dialog-message">{{ message }}</div>
        
        <div class="ff-dialog-message" style="font-weight:bold;color:red" *ngIf="errorMessage != ''">
            {{errorMessage}}
        </div>

        <div *ngIf="type != 'text'" fxLayout="row" style="text-align:center !important; font-size:18px;">
            <mat-form-field>
                <input matInput type="number" [(ngModel)]="amountSelected" min="0" max="{{maxVal}}"  placeholder="Amount"/>
            </mat-form-field>
        </div>

        <div *ngIf="type == 'text'" fxLayout="row" style="text-align:center !important; font-size:18px;">
            <mat-form-field>
                <input matInput type="text" [(ngModel)]="optionSelected" placeholder="Value"/>
            </mat-form-field>
        </div>

        <div class="ff-dialog-actions" fxLayout="row" fxLayoutAlign="end center">
            <button class="ff-dialog-button-cancel" type="button" mat-button 
                (click)="dialogRef.close([false])">{{cancelText}}</button>
            

            <button class="ff-dialog-button" type="button" mat-raised-button 
                (click)="validateAccept()">{{okText}}</button>
       
        </div>
        `,
})
export class InputDialog implements OnInit{

    public title: string;
    public message: string;
    public cancelText: string;
    public okText: string;
    public maxVal: number;
    public amountSelected: number = 0;
    public optionSelected: string = "";
    public errorMessage: string = "";
    public type: string = "";

    constructor(public dialogRef: MatDialogRef<InputDialog>) {
        console.log("type is:", this.type);
        console.log(dialogRef);
        
    }

    ngOnInit(){
        if(this.okText == "Add"){
            this.type = "text";
        }
    }

    validateAccept(){
        this.errorMessage = "";
        if(this.type == "number"){
            if(this.amountSelected > 0 && this.amountSelected <= this.maxVal){
                this.dialogRef.close([true,this.amountSelected]);
            }else if(this.amountSelected > this.maxVal){
                this.errorMessage = "You cannot refund more than $" + this.maxVal;
            }else{
                this.errorMessage = "You must specify an amount greater than zero to refund";
            }
        }else{
            if(this.optionSelected != ""){
                this.dialogRef.close([true,this.optionSelected]);
            }else{
                "You must enter a value!";
            }
            
        }
    }


    

}