import { Component, OnInit, NgZone, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { LingoService } from '../services/lingo.service';
import { Subscription } from 'rxjs/Subscription';
import { DialogsService } from '../dialog/dialog.service';
import { SquareService } from '../services/square.service';
import { CustomerSettingsService } from '../services/customer-settings.service';
import { LoadingService } from '../services/loading.service';
import { TutorialService } from '../services/tutorial.service';
import { AuthService } from '../services/auth.service';
import { OrganizationService } from '../services/organization.service';
import { ContributorService } from '../services/contributor.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public user: any = null;
  public userRole: string = null;
  public showAddUser: boolean = false;
  public showEditUser: boolean = false;
  public showManageFunds: boolean = false;
  public showAddFunds: boolean = false;
  public editFundsAmount: number = 0;
  public showSubtractFunds: boolean = false;
  public idToken: string = "";
  public authorizedUsers = [];
  public orgLingo: { [id: string]: string } = {};
  public manageFundsUser: any = null;
  public userSubscription: Subscription;
  public myContribution = [];
  public prohibitedItems = [];
  public prohibitedUserItems = [];
  public loadComplete: boolean = false;
  public balanceByUser = [];
  public showManageItems = false;
  public editItemsUser;
  public newItem = {
    itemName: "",
    authorizedUserId: "",
    accountHolder: ""
  }


  public terminology = {
    "accounts": "",
    "account": ""
  };


  public orgTerms = {
    "accounts": "Spending Accounts",
    "account": "Spending Account"
  };


  public parentTerms = {
    "accounts": "",
    "account": ""
  };


  newUser = {
    firstName: "",
    lastName: "",
    pin: "",
    confirmPin: "",
    dailySpendingLimit: "",
    userId: "",
    imageUrl: "",
    nickname: "",
    isStaff: false
  }
  editUser = {
    firstName: "",
    lastName: "",
    pin: "",
    confirmPin: "",
    userId: "",
    dailySpendingLimit: "",
    imageUrl: "",
    nickname: "",
    isStaff: false
  }

  public tutorialSubscription: Subscription;
  public showPopover7 = false;
  public showPopover7b = false;
  public showPopover7c = false;
  public unsubscribeTutorialSubscription: Subscription;
  public tokenSubscription: Subscription;
  public ipAddress: string = "";
  public organization = null;

  constructor(
    public userService: UserService,
    public router: Router,
    public lingoService: LingoService,
    public dialogsService: DialogsService,
    public squareService: SquareService,
    public customerSettingsService: CustomerSettingsService,
    public loadingService: LoadingService,
    public tutorialService: TutorialService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public contributorService: ContributorService
  ) {
    this.tutorialSubscription = this.tutorialService.tutorialUsersSubscription$.subscribe(
      popoverId => {
        if (popoverId == "popover7") {
          this.showPopover7 = true;
          setTimeout(() => {
            try {
              console.log("displaying it:");
              console.log("popover7:", document.getElementById('popover7'));
              document.getElementById("popover7").click();
            } catch (err) {
              console.log("tutorial error:", err);
            }
          }, 500)

        }
      }
    )

    this.unsubscribeTutorialSubscription = this.tutorialService.removeSubscriptions$
      .subscribe(res => {
        console.log("Unsubscribing!");
        this.tutorialSubscription.unsubscribe();
      })

    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token users");
        /// console.log(token);
        this.idToken = token;
      }
    )


    this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      console.log("got ip address:", ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ""){
        this.ipAddress = ipAddress;
      }
    })



  }




  ngOnInit() {
    //get current user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        if (res != null) {
          this.user = res;
          this.getMyAuthorizedUsers();
          this.loadOrganization();
          //now check if they're being directed to a specific user
          this.manageFundsUser = this.userService.getManageFundsUser();
          if (this.manageFundsUser != null) {
            //next time we route to this page it will need to be reset
            this.userService.setManageFundsUser(null);
            this.showManageFunds = true;
          }
          this.lingoService.loadLingo(this.idToken, "camp")
            .then(res => {
              this.loadingService.toggleLoadingScreen(false);
              var body = JSON.parse(res._body);
              body.message.Items.forEach(item => {
                if (item.item_name) {
                  this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
                }

              })
              this.parentTerms["accounts"] = this.orgLingo["authorized_user"] + "s";
              this.parentTerms["account"] = this.orgLingo["authorized_user"];
              console.log("orgLingo:", this.orgLingo);
              if(this.user.role == "organization-manager" || this.user.role == "organization-employee" || this.user.role == "store-manager"){
                this.terminology = this.orgTerms;
              }else{
                this.terminology = this.parentTerms;
              }
            }).catch(err => {
              this.loadingService.toggleLoadingScreen(false);
            })
        }
      })
      .catch(err => {
        this.loadingService.toggleLoadingScreen(false);
      })
  }


  loadOrganization() {
    this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
      .then(res => {
        console.log("Organization:", res);
        var body = JSON.parse(res._body);
        this.organization = body.message.Items[0];
        this.organization.camperPhotosRequired = (this.organization.camperPhotosRequired != undefined)? this.organization.camperPhotosRequired : true;
        console.log("org:", this.organization);
      }).catch(err => {
        console.log("get org err:", err);
      })
  }

  openAddUser() {

    if(this.authorizedUsers.length > 0){
      console.log("passing in campers:", this.authorizedUsers);
      this.dialogsService.confirmAddDialog("Your Campers", "", this.authorizedUsers)
      .toPromise()
      .then( res => {
        console.log("campers res:", res);
        if(res[0] == 'edit'){
          this.openEditUser(res[1]);
        }else if(res[0] == 'add'){
          this.showAddUser = !this.showAddUser;
          this.showEditUser = false;
        }
      })
    }else{
      this.showAddUser = !this.showAddUser;
      this.showEditUser = false;
    }


    
  }


  uploadDragImage(userId, file): Promise<any> {
    var photoKey = encodeURIComponent(userId);
    return this.userService.uploadUserImageS3(photoKey, file)
  }


  openUploadImage(camper){
    var title = "Upload image for " + camper.firstName;
    this.dialogsService.uploadImage(title, camper.imageUrl)
    .toPromise()
    .then( res => {
      console.log("currentProfile image:");
      console.log(res);
      if(res){
        //if(!res.includes('s3.amazonaws.com')){
          console.log("new image .. upload");
          this.uploadDragImage(camper.userId, res)
          .then( res => {
            console.log("uploaded image:", res);
            camper.imageUrl = res.Location;
            console.log(res.Location);

            this.userService.uploadUserImageDynamo(this.idToken, res.Location, camper.userId, camper.organizationId);
            this.getMyAuthorizedUsers();

          })
       // }
      }
      
    })
  }


  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
    })
  }


  /* add an authorized user to their account */
  addUser() {
    var isValid: boolean = this.validateUser(this.newUser);
    if (!isValid) {
      return;
    }
    //check if they've added any images
    var files = document.getElementById('photoupload')['files'];
    console.log("files is:", files);
    if (!files.length && this.organization && this.organization.camperPhotosRequired) {
      console.log("no files");
      //now make it fail if the user doesn't already have an image
      console.log("edit user:", this.editUser);
      this.dialogsService.alert("No image selected", "You must upload an image of your camper");
      return;
    }

    console.log(this.user.userId);
    this.userService.createAuthorizedUser(this.idToken, this.newUser, this.user.userId, this.user.organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        console.log("new user body:", body);
        if (body.status == "200") {
           this.showAddUser = false;
           var userId = body.message;
          if(this.user.availableBalance != undefined && this.user.availableBalance > 0.01){
            this.dialogsService.alert("Success", "Your user has been added to your account");
            var addedUser = this.newUser;
            addedUser.userId = body.message;
            this.newUser = {
              firstName: "",
              lastName: "",
              pin: "",
              confirmPin: "",
              dailySpendingLimit: "",
              userId: "",
              imageUrl: "",
              nickname: "",
              isStaff: false
            }
            this.manageFundsUser = addedUser;
            this.showAddFunds = true;
            this.showManageFunds = true;

            console.log("manage funds user:", this.manageFundsUser);
            console.log("showAddFunds:", this.showAddFunds);
          }else{
            this.dialogsService.confirm("Deposit funds now?", "Your available balance is currently $0, would you like to deposit funds now?", "Not Now", "OK")
            .toPromise()
            .then(res =>{
              if(res){
                this.router.navigate(['payment-settings']);
              }
            })
          }

          
          this.uploadImage(userId)
            .then(res => {
              if (res.hasOwnProperty('Location')) {
                var imageUrl = res.Location;
                this.userService.uploadUserImageDynamo(this.idToken, imageUrl, userId, this.user.organizationId)
                  .then(res => {
                    this.getMyAuthorizedUsers()
                      .then(res => {
                        console.log("Get my auth users .then");
                        console.log(res);
                        //now bring them to the funds page
                        if(this.user.availableBalance != undefined && this.user.availableBalance > 0.01){
                          this.showAddFunds = true;
                        }
                        //need to set the managefundsuser
                        this.authorizedUsers.forEach(user => {
                          if (user.userId == userId) {
                            this.manageFundsUser = user;
                          }
                        })
                        console.log("MGU:", this.manageFundsUser);
                      })
                  })
                  .catch(err => {
                    console.log("upload user db err:", err);
                  })
              } else {
                //there was no image to upload
                //now update the users
                this.getMyAuthorizedUsers();
              }
            }).catch(err => {
              console.log("upload image err:", err);
            })
        }
      })
      .catch(err => {
        console.log("create user err:", err);
      })

  }

  //upload image to s3 bucket

  uploadImage(userId): Promise<any> {
    var files = document.getElementById('photoupload')['files'];
    console.log("files is:", files);
    if (!files.length) {
      return Promise.resolve(true);
    }
    var file = files[0];
    var fileName = userId;
    var photoKey = encodeURIComponent(userId);
    return this.userService.uploadUserImageS3(photoKey, file)
  }


  /* get all authorized users on their account */
  getMyAuthorizedUsers(): Promise<any> {
    const userId = this.user.userId;
    //if we have any auth users already, reset their balances to 0
    if (this.authorizedUsers.length > 0) {
      this.authorizedUsers.forEach(user => {
        console.log(user);
        console.log(this.balanceByUser);
        this.balanceByUser[user.userId] = 0;
      })
    }
    return new Promise((resolve, reject) => {
      let getAuthUsers = this.userService.getAuthorizedUsers(this.idToken, userId);
      let getContributees = this.contributorService.getAccountsContributingTo(this.idToken, userId, this.user.organizationId);
      let promises = [getAuthUsers, getContributees];
      Promise.all(promises)
        .then( (res: any) => {
          console.log("res:", res);
          var body = JSON.parse(res[0]._body);
          this.loadComplete = true;
          if (body.status == "200") {
            
            let contributeesBody = JSON.parse(res[1]._body);
            let contributees = contributeesBody.contributees;
            contributees.forEach( camper => {
              camper.isContributingTo = true;
            });
            
            this.authorizedUsers = body.message.Items.concat(contributees);
            


            //initialize the prohibited Items
            if (this.authorizedUsers.length > 0) {
              var authUserIds = [];
              this.authorizedUsers.forEach(user => {
                authUserIds.push(user.userId);
              });
              //get the amount of funds for each auth user
              this.userService.getAuthorizedUserBalances(this.idToken, authUserIds)
                .then(res => {
                  var body = JSON.parse(res._body);
                  if (body.message.Items.length > 0) {
                    console.log("get auth users balances:", body.message.Items);
                    body.message.Items.forEach(item => {
                      if (item.contributorId == this.user.userId) {
                        this.myContribution[item.authUserId] = item.currentBalance;
                      }
                      if (this.balanceByUser[item.authUserId] != null) {
                        this.balanceByUser[item.authUserId] += item.currentBalance;
                      } else {
                        this.balanceByUser[item.authUserId] = item.currentBalance;
                      }
                    })

                    console.log("my contribution:", this.myContribution);

                    //now set the user's balance for displayinh
                    this.authorizedUsers.forEach(user => {
                      user.currentBalance = this.balanceByUser[user.userId];
                    });
                    this.authorizedUsers.forEach( user => {
                      //add their message here
                      user.alertMessage = "";
                      if(this.user.lowBalanceThreshold > user.currentBalance) {
                        user.alertMessage += "Low Balance ";
                      }
                      if(user.imageUrl == undefined){
                        user.alertMessage += "No Photo Set";
                      }
                      console.log("alert message is:", user.alertMessage);
                    })

                    resolve(true);
                  }
                })
                .catch(err => {
                  console.log("get auth user balance err:", err);
                  reject(err);
                })
              console.log("authorized users:", this.authorizedUsers);
            }

          }
        })
        .catch(err => {
          console.log("get auth users err:", err);
          reject(err);
        })


    })

  }



  openEditUser(user) {
    console.log("row:", user);
    this.editUser.firstName = user.firstName;
    this.editUser.lastName = user.lastName;
    this.editUser.userId = user.userId;
    this.editUser.dailySpendingLimit = user.dailySpendingLimit;
    this.editUser.imageUrl = user.imageUrl;
    this.editUser.pin = user.password;
    this.editUser.nickname = user.nickname;
    this.editUser.isStaff = (user.isStaff != undefined)? user.isStaff: false;
    this.showEditUser = !this.showEditUser;
    this.showAddUser = false;

  }

  deleteUser(user) {
    console.log("value:", user);
    if (user.currentBalance != undefined && user.currentBalance > 0) {
      this.dialogsService.alert("Funds Remaining", "You must remove all of the funds from this account before you can remove it");
      return;
    }
    this.dialogsService.confirm("Remove " + this.orgLingo['authorized_user'].slice(0, this.orgLingo['authorized_user'].length - 1), "Permanently remove " + user.firstName + "?")
      .toPromise()
      .then(remove => {
        if (remove) {
          console.log("remove");
          this.userService.deleteUser(this.idToken, user.userId, this.user.userId)
            .then(res => {
              console.log("delete user res:", res);
              var body = JSON.parse(res._body);
              if (body.status == "200") {
                this.getMyAuthorizedUsers();
              }

            })
            .catch(err => {
              console.log("could not delete:", err);
            })
        }

      });

  }

  submitEdit() {

    console.log("submit edit!");

    //check if they've added any images
    var files = document.getElementById('photoupload')['files'];
    console.log("files is:", files);
    if (!files.length) {
      console.log("no files");
      //now make it fail if the user doesn't already have an image
      console.log("edit user:", this.editUser);
      if (this.editUser.imageUrl == undefined && this.organization && this.organization.camperPhotosRequired) {
        this.dialogsService.alert("No image selected", "You must upload an image of your camper");
        return;
      }

    }

    

    var isValid: boolean = this.validateUser(this.editUser);
    if (!isValid) {
      console.log("!valid");
      return;
    }
    this.userService.updateUser(this.idToken, this.editUser, this.user.userId)
      .then(res => {
        console.log("update users res:", res);
        var body = JSON.parse(res._body);
        if (body.status == "200") {
          //upload the image
          this.showEditUser = false;
          this.uploadImage(this.editUser.userId)
            .then(res => {
              if (res.hasOwnProperty('Location')) {
                var imageUrl = res.Location;
                this.userService.uploadUserImageDynamo(this.idToken, imageUrl, this.editUser.userId, this.user.organizationId)
                  .then(res => {
                    this.showEditUser = false;
                    this.getMyAuthorizedUsers();
                  })
                  .catch(err => {
                    console.log("upload user db err:", err);
                  })
              } else {
                //there was no image to upload
                //now update the users
                console.log("no image to upload");
                console.log(res);
                this.getMyAuthorizedUsers();
              }
            }).catch(err => {
              console.log("upload image err:", err);
            })

        }
      })
      .catch(err => {
        console.log("create user err:", err);
      })
  }


  validateUser(user: any): boolean {
    console.log("validating user:", user);
    /*if (user.pin.length != 0 && user.pin.length < 4) {
      this.dialogsService.alert("Processing error", "Your PIN must be at least 4 characters long");
      return false;
    }*/
    /*if (user.pin != user.confirmPin) {
      this.dialogsService.alert("Processing error", "Your PINs do not match!");
      return false;
    }*/
    if (user.firstName == "" || user.lastName == "") {
      this.dialogsService.alert("Processing error", "Your user must have a first and last name");
      return false;
    }

    // var files = document.getElementById('photoupload')['files'];
    // if(!files.length && this.organization.camperPhotosRequired && !user.imageUrl){
    //   this.dialogsService.alert("Photo Required!", "You must add a photo to this account!");
    //   return false;  
    // }

    return true;
  }



  closeCard() {
    this.showAddUser = false;
    this.showEditUser = false;
  }



  toggleManageFunds(user) {
    this.showManageFunds = !this.showManageFunds;
    this.manageFundsUser = user;
    this.showAddFunds = false;
    this.showSubtractFunds = false;
    console.log("user is:", this.manageFundsUser);
  }



  openEditFunds(bool: boolean) {
    if (bool) {
      this.showAddFunds = true;
      this.showSubtractFunds = false;
    } else {
      this.showSubtractFunds = true;
      this.showAddFunds = false;
    }
  }


  submitAddFunds() {
    console.log("Add funds:", this.editFundsAmount);
    if (this.editFundsAmount <= this.user.availableBalance) {
      //now transfer the money
      var contributorName = "Main Account";
      var relationship = "main";
      if (this.manageFundsUser.isContributingTo && this.manageFundsUser.contribLink) {
        contributorName = this.manageFundsUser.contribLink.nickname;
        relationship = this.manageFundsUser.contribLink.relationship;
      }
      
      var timeZone = -4;
      if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
        timeZone = this.organization.timeZoneOffset;
      }
      this.loadingService.toggleLoadingScreen(true);
      this.squareService.fundsTransferToAuthUser(this.idToken, this.user.email, this.editFundsAmount, this.manageFundsUser.userId, this.user.userId,
        this.user.role, contributorName, relationship, this.manageFundsUser.firstName,this.manageFundsUser.lastName,this.user.userId /*this.manageFundsUser.accountHolder*/, this.manageFundsUser.organizationId,
        this.user.userId, this.user.email, this.ipAddress, timeZone
      )
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          var body = JSON.parse(res._body);
          if (body.status == "200" || body.message.statusCode == "200") {
            this.showAddFunds = false;
            this.editFundsAmount = 0;
            this.dialogsService.alert("Success!", "Your funds have been allocated!");
            this.toggleManageFunds(null);
            this.getUser()
              .then(res => {
                this.user = res;
                this.getMyAuthorizedUsers();
              });
          } else {
            this.dialogsService.alert("Processing Error", "There was an error transferring your funds");
          }
        })
        .catch(err => {
          this.loadingService.toggleLoadingScreen(false);
          console.log("FTAU err:", err);
          this.dialogsService.alert("Processing Error", "There was an error transferring your funds");
        })

    } else {
      //doesn't have enough funds
    }
  }


  submitWithdrawFunds() {
    if (this.editFundsAmount <= this.manageFundsUser.currentBalance) {
      this.squareService.fundsTransferFromAuthUser(this.idToken, this.user.email, this.editFundsAmount, this.manageFundsUser.userId, this.user.userId, this.manageFundsUser.firstName, this.manageFundsUser.lastName, this.user.userId/*this.manageFundsUser.accountHolder*/, this.manageFundsUser.organizationId
      ,this.user.userId, this.user.email, this.ipAddress)
        .then(res => {
          var body = JSON.parse(res._body);
          console.log("body:", body);
          if (body.status == "200" || body.message.statusCode == "200") {
            this.showSubtractFunds = false;
            this.editFundsAmount = 0;
            this.dialogsService.alert("Success!", "Your funds have been unallocated!");
            this.toggleManageFunds(null);
            this.getMyAuthorizedUsers();
            this.getUser()
              .then(res => {
                this.user = res;
              });
          } else {
            this.dialogsService.alert("Processing Error", "There was an error unallocating your funds");
          }
        }).catch(err => {
          console.log("FTAU err:", err);
          this.dialogsService.alert("Processing Error", "There was an error unallocating your funds");
        })
    }
  }


  closeFundsCard() {
    this.showAddFunds = false;
    this.showSubtractFunds = false;
  }


  //prohibited items functionality

  initializeProhibitedUserItems() {
    this.authorizedUsers.forEach(user => {
      this.prohibitedUserItems[user.userId] = [];
    })
  }

  populateProhibitedUserItems() {
    this.prohibitedItems.forEach(item => {
      //console.log("Item:", item);
      var subItem = {
        itemName: item.itemName,
        accountHolder: item.accountHolder,
        EntryId: item.EntryId,
        authorizedUserId: item.authorizedUserId
      }
      if (this.prohibitedUserItems[item.authorizedUserId] != null) {
        this.prohibitedUserItems[item.authorizedUserId].push(subItem);
      } else {
        this.populateProhibitedUserItems[item.authorizedUserId] = [subItem];
      }

    })
  }



  getProhibitedItems(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerSettingsService.getProhibitedItems(this.idToken, this.user.userId)
        .then(res => {
          var body = JSON.parse(res._body);
          if (body.status == "200") {
            this.prohibitedItems = body.message.Items;
            console.log("PI:", this.prohibitedItems);
            resolve(this.prohibitedItems);
          }
          reject(body);
        })
        .catch(err => {
          console.log("getProhibitedItems err:", err);
          reject(err);
        })
    })

  }

  openEditItems(user) {
    this.showManageItems = true;
    this.editItemsUser = user;
  }

  closeProhibitedItemsCard() {
    this.showManageItems = false;
    this.editItemsUser = {};
  }

  addNewItem() {
    this.newItem = {
      itemName: "",
      authorizedUserId: this.editItemsUser.userId,
      accountHolder: this.user.userId
    }
    this.prohibitedUserItems[this.editItemsUser.userId].push(this.newItem);
  }


  createItem(itemName) {
    console.log("create item:", itemName);
    if (itemName == "") {
      this.dialogsService.alert("Processing error", "You must specify a name for your prohibited item!");
      return;
    }
    this.newItem.itemName = itemName;
    this.customerSettingsService.addProhibitedItem(this.idToken, this.newItem)
      .then(res => {
        console.log("add addProhibitedItem res:", res);
        var body = JSON.parse(res._body);
        if (body.status == "200") {
          //reset the new item obj
          this.newItem = {
            itemName: "",
            authorizedUserId: "",
            accountHolder: ""
          }
          this.getProhibitedItems();
          this.dialogsService.alert("Added!", itemName + " has been added to your list of prohibited items!");
          //now reset and update the list
          this.initializeProhibitedUserItems();
          console.log("after re-initialize:", this.prohibitedUserItems);
          this.getProhibitedItems()
            .then(res => {
              this.populateProhibitedUserItems();
              console.log("After re-populate:", this.prohibitedUserItems);
            })
        }
      })
      .catch(err => {
        console.log("addProhibitedItem err:", err);
      })
  }


  deleteItem(item) {
    console.log("delete item:", item);
    this.customerSettingsService.deleteProhibitedItem(this.idToken, item.EntryId)
      .then(res => {
        //success
        //now reset and update the list
        this.initializeProhibitedUserItems();
        this.getProhibitedItems()
          .then(res => {
            this.populateProhibitedUserItems();
          })
      })
      .catch(err => {
        console.log("delete item err:", err);
      })
  }


  goToPaymentSettings() {
    console.log("payment settings");
    this.router.navigate(['payment-settings']);
  }


  nextStep(popoverId) {
    //this.tutorialService.completeTutorial(this.idToken, this.user.email);
    if (popoverId == 'popover7') {
      this.showPopover7 = false;
      this.showPopover7b = true;
      setTimeout(() => {
        try {
          console.log("displaying it:");
          console.log("popover7:", document.getElementById('popover7b'));
          document.getElementById("popover7b").click();
        } catch (err) {
          console.log("tutorial error:", err);
        }
      }, 500)
    } else if (popoverId == 'popover7b') {
      this.showPopover7b = false;
      this.showPopover7c = true;
      setTimeout(() => {
        try {
          console.log("displaying it:");
          console.log("popover7:", document.getElementById('popover7c'));
          document.getElementById("popover7c").click();
        } catch (err) {
          console.log("tutorial error:", err);
        }
      }, 500)
    } else if (popoverId == 'popover7c') {
      this.showPopover7c = false;
      this.tutorialService.triggerSideNavPopover('popover8');
    }



  }


  skipSteps(popoverId) {
    console.log("skip!");
    this.showPopover7 = false;
    this.tutorialService.skipSteps(this.idToken, this.user.email, this.user.organizationId);
  }

  //show / hide the sidenav menu
  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }


  endTutorial() {
    this.showPopover7 = false;
    this.showPopover7b = false;
    this.showPopover7c = false;
    this.tutorialService.completeTutorial(this.idToken, this.user.email, this.user.organizationId);
    setTimeout(() => {
      this.router.navigate(['home']);
    }, 1000);
  }


  getMessage(user){
    console.log("get message");
    console.log(user.firstName);
    return user.firstName;
  }

}



