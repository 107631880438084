import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import {Router} from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { DialogsService } from '../dialog/dialog.service';
import { ActivationService } from '../services/activation.service';
import { LogoWebUrl } from '../app.constants';



@Component({
  selector: 'app-activate',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userId: string = "";
  user: any = null;
  credentials = new AuthCredentials();
  cognitoUser: any;
  showEnterEmail: boolean = true;
  showRecoverPassword: boolean = false;
  showNewPassword: boolean = false;
  
  resetEmail: string = "";
  newPassword: string = "";
  

  showVerifyText: boolean = false;
  verifyText: string = "";
  licenseText: string = "";
  custNum = 0;
  num = 0;
  screenHeight = screen.height;
  logoUrl: string = "";

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public authService: AuthService,
    public dialogsService: DialogsService,
    public activationService: ActivationService
  ) {
      console.log("height:", this.screenHeight);
      this.logoUrl = LogoWebUrl;
   }


ngOnInit() {
  console.log("init reset password!");
}


submit(){
   
}


sendResetCode(){
  console.log("forgot password...");
  if(this.credentials.email == ""){
    this.dialogsService.alert("Email cannot be empty", "Please enter your email address");
    return;
  }
  this.authService.forgotPassword(this.credentials)
  .then(res => {
    console.log("fp res:", res);
    if(res){
      this.showVerifyText = true;
      this.showEnterEmail = false;
      this.dialogsService.alert("Recovery email has been sent","A verification code has been sent to your inbox to update your password");
    }else{
      this.dialogsService.alert("Could not send email","Please check your inbox or try again in a few minutes");
    }
  }).catch(err => {
    console.log("forgot password err:", err);
    console.log(err.code);
    if (err.code === "UserNotFoundException") {
      this.dialogsService.alert("Could not send email","User not found. Please verify that you entered the correct email AND activated your account via the activation link you should have received");
    } else {
      this.dialogsService.alert("Could not send email","Please check your inbox or try again in a few minutes");
    }
  })

}

/*Send a new email w/ verification code*/
resendVerificationCode(){
  this.authService.resendCode(this.credentials)
  .then(res => {
    console.log("resend code res:", res);
    if(res == "SUCCESS"){
      this.dialogsService.alert("Email sent","We have sent new credentials to your account");
    }
  }).catch(function(err){
    console.log("resendcode err:", err);
    this.dialogsService.alert("Processing Error","Could not re-send confirmaion code");
  })
}

login(){
  console.log("calling login");
  console.log("auth credentials", this.credentials);
  if(this.credentials.password != null && this.credentials.password != ""){
    this.authService.login(this.credentials)
    .then(res => {
      this.router.navigate(['home']);
    }).catch( err => {
      console.log("could not log in!");
      //this.router.navigate(['auth'])
    })
  }else{
    this.router.navigate(['auth']);
  }
}


changePassword(){
  this.authService.changePassword(this.credentials, this.verifyText, this.newPassword)
  .then(res => {
    if(res){
      //it worked
      this.dialogsService.alert("Success!","Your password has been updated! Logging you in now...");
      this.showRecoverPassword = false;
      //login now...
      this.credentials.password = this.newPassword;
      this.login();
    }else{
      this.dialogsService.alert("Could not change password.","Make sure you've entered a valid password");
    }
  }).catch(err => {
    this.dialogsService.alert("Could not change password.","Make sure you've entered a valid password");
  })
}

/* pass in verification credentials */
verifyAccount(){
  console.log("verify text:", this.verifyText);
  console.log("credentials:", this.credentials);
  this.authService.verifyAccount(this.credentials, this.verifyText)
  .then(res => {
    console.log("verify account res:", res);
    this.dialogsService.alert("Account confirmed!", "You can now log in normally");
    this.showVerifyText = false;
    this.showNewPassword = true;
    this.login();
  }).catch(err => {
    console.log("verify account err:", err);
    this.dialogsService.alert("Invalid code", "Make sure you enter the correct code");
  })

}

}
