import { NgModule } from '@angular/core';
import { PhonePipe } from './phone-pipe';
import { SortPipe } from './sort-pipe';

@NgModule({
    imports: [],
    declarations: [
        PhonePipe,
        SortPipe
    ],
    exports: [
        PhonePipe,
        SortPipe
    ]
})
export class PipeModule {
    static forRoot() {
        return {
            ngModule: PipeModule,
            providers: []
        }
    }
}