import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { EmployeesComponent } from './employees/employees.component';
import { ActivateComponent } from './activate/activate.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { RedirectComponent } from './redirect/redirect.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { CustomersComponent } from "./customers/customers.component";
import { OrganizationCampersComponent } from './organization-campers/organization-campers.component';
import { OrganizationSettingsComponent } from './organization-settings/organization-settings.component';
import { TransactionsComponent } from "./transactions/transactions.component";
import { ReportsComponent } from "./reports/reports.component";
import { RemainingFundsComponent } from "./remainingFunds/remainingFunds.component";
import { ResetPasswordComponent} from "./resetPassword/resetPassword.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { InventoryPurchaseDetailComponent } from './inventory/purchasedetail.component';
import { InventoryPurchaseOrderComponent } from './inventory/purchaseorder.component';
import { CarePackagesComponent } from "./care-packages/care-packages.component";
import { CarePackageOrdersComponent } from "./care-package-orders/care-package-orders.component";

const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: 'auth',  component: AuthComponent },
  { path: 'resetPassword',  component: ResetPasswordComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/:showTutorial', component: HomeComponent },
  { path: 'users', component: UsersComponent },
  { path: 'employees', component: EmployeesComponent },
  { path: 'activate/:userId', component: ActivateComponent },
  { path: 'activate/:userId/:custNum', component: ActivateComponent },
  { path: 'activate/:userId', component: ActivateComponent },
  { path: 'contributors', component: ContributorsComponent },
  { path: 'customer-settings', component: CustomerSettingsComponent },
  { path: 'payment-settings', component: PaymentSettingsComponent },
  { path: 'payment-settings/:userId', component: PaymentSettingsComponent },
  { path: 'redirect/:slug', component: RedirectComponent },
  { path: 'organizations' , component: OrganizationsComponent },
  { path: 'user-info', component: UserInfoComponent },
  { path: 'user-info/:changePass', component: UserInfoComponent },
  { path: 'organizations' , component: OrganizationsComponent },
  { path: 'customers' , component: CustomersComponent },
  { path: 'organization-campers', component: OrganizationCampersComponent },
  { path: 'organization-settings', component: OrganizationSettingsComponent },
  { path: 'transactions', component: TransactionsComponent },
  { path: 'transactions/:forceAccountHolder', component: TransactionsComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'remainingFunds/:userId/:session/:type', component: RemainingFundsComponent },
  { path: 'inventory', component: InventoryComponent },
  { path: 'inventory/purchasedetails/:purchaseId', component: InventoryPurchaseDetailComponent },
  { path: 'inventory/purchaseorders', component: InventoryPurchaseOrderComponent },
  { path: 'care-packages', component: CarePackagesComponent },
  { path: 'care-package-orders', component: CarePackageOrdersComponent }
];
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
