import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription }   from 'rxjs/Subscription';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { OrganizationService } from '../services/organization.service';
import { LoadingService } from '../services/loading.service';
import { DialogsService } from '../dialog/dialog.service';
import { TransactionsService } from '../services/transactions.service';
import { OrganizationSettingsService } from "../services/organization-settings.service";
import { InventoryService } from "../services/inventory.service";
import { InventoryCodeService } from '../services/inventory-code.service';
import { Item, Modifier, ModifierOption } from "../models/item";
import { InventoryImageBucketLong } from "../app.constants";

class GridItemConfig {
    col = 1;
    col2 = 1;
    row = 1;
    row2 = 1;
    sizex = 1;
    sizey = 1;
    dragHandle = null;
    resizeHandle = null;
    borderSize = 15;
    fixed= false;
    draggable= true;
    resizable= false;
    payload= null;
    maxCols= 0;
    minCols= 0;          
    maxRows= 0;           
    minRows= 0;           
    minWidth= 0;          
    minHeight= 0; 
}


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

    public user: any = null;
    public idToken: string = "";
    public showAddItem: boolean = false;
    public showEditItem: boolean = false;
    public showSection: "details" | "modifiers" | "barcodes" = "details";
    public showView: "location-tab" | "catalog" = 'location-tab';
    public showListView = false;
    public showStandardModifiers = false;
    public showEditStandardModifier = false;
    public standardModifiers: Modifier[] = [];
    public editStandardModifiers: boolean = false;
    public tabCategories: string[] = [];
    public searchText = '';

    public tokenSubscription: Subscription;
    public locations = [];
    public locationNames = new Map<string, string>();
    public inventoryByLocation = [];
    public inventory = [];
    public allInventory = [];
    public codes = [];
    public item: Item;
    public selectedLocation = null;
    public locationSelect = null;
    public modifier: Modifier;
    public isNewModifier: boolean = true;
    public possiblePositions: number[] = [];
    public organization: any;
    public transactionTypes = ["Transaction", "Deposit", "Withdrawal"];
    public statusMsg = '';
    
    //tabs
    public showTabDropdown = false;
    public tabOptions = [];
    public selectedTab = null;
    public numCols = 4;
    public maxCol = this.numCols;
    public maxRow = 100;


    public positionsByItem: any[] = [];
    public origPositionsByItem: any[] = [];
    public cascadePositionsByItem: any[] = [];

    public gridConfig = {
        'margins': [30,0,30,30],            //  The size of the margins of each item. Supports up to four values in the same way as CSS margins. Can be updated using setMargins()
        'draggable': true,          //  Whether the items can be dragged. Can be updated using enableDrag()/disableDrag()
        'resizable': false,          //  Whether the items can be resized. Can be updated using enableResize()/disableResize()
        'max_cols': 4,              //  The maximum number of columns allowed. Set to 0 for infinite. Cannot be used with max_rows
        'max_rows': 100,              //  The maximum number of rows allowed. Set to 0 for infinite. Cannot be used with max_cols
        'visible_cols': 4,          //  The number of columns shown on screen when auto_resize is set to true. Set to 0 to not auto_resize. Will be overriden by max_cols
        'visible_rows': 100,          //  The number of rows shown on screen when auto_resize is set to true. Set to 0 to not auto_resize. Will be overriden by max_rows
        'min_cols': 0,              //  The minimum number of columns allowed. Can be any number greater than or equal to 1.
        'min_rows': 0,              //  The minimum number of rows allowed. Can be any number greater than or equal to 1.
        'col_width': 180,           //  The width of each column
        'row_height': 150,          //  The height of each row
        'cascade': 'left',            //  The direction to cascade grid items ('up', 'right', 'down', 'left')
        'min_width': 100,           //  The minimum width of an item. If greater than col_width, this will update the value of min_cols
        'min_height': 100,          //  The minimum height of an item. If greater than row_height, this will update the value of min_rows
        'fix_to_grid': false,       //  Fix all item movements to the grid
        'auto_style': true,         //  Automatically add required element styles at run-time
        'auto_resize': false,       //  Automatically set col_width/row_height so that max_cols/max_rows fills the screen. Only has effect is max_cols or max_rows is set
        'maintain_ratio': false,    //  Attempts to maintain aspect ratio based on the colWidth/rowHeight values set in the config
        'prefer_new': false,        //  When adding new items, will use that items position ahead of existing items
        'limit_to_screen': true,   //  When resizing the screen, with this true and auto_resize false, the grid will re-arrange to fit the screen size. Please note, at present this only works with cascade direction up.
        'center_to_screen': true,  //  When resizing the screen, with this true and limit_to_screen true, the grid will center itself to the screen if max columns width is smaller than the grid width.
    }


    public inventoryBoxes = [];
    public cardStyle = {'height': '650px'};

    public _lastPositionUpdateAt: number = 0;

    constructor(
        public userService: UserService,
        public transactionsService: TransactionsService,
        public loadingService: LoadingService,
        public dialogsService: DialogsService,
        public organizationService: OrganizationService,
        public authService: AuthService,
        public organizationSettingsService: OrganizationSettingsService,
        public inventoryService: InventoryService,
        public inventoryCodeService: InventoryCodeService,
        public router: Router
    ){
        this.item = new Item();
        this.tokenSubscription = authService.tokenSubscription$.subscribe(
            token => {
                this.idToken = token;
            }
        )

        // setTimeout( () => {
        //     console.log("Invoke sqs!");
        //     this.inventoryService.invokeSQS();
        // }, 10000);
    }


    ngOnInit() {
        this.loadingService.toggleLoadingScreen(true);
        this.getUser()
            .then(res => {
                this.loadingService.toggleLoadingScreen(false);
                this.user = res;
                this.loadOrganization();
                console.log("got user:", this.user);
                //now load in the locations and the inventory
                this.getPOSLocations()
                  .then( locations => {
                    // console.log("got locations:", locations);
                    // if (this.locations.length > 0) {
                    //     let tabName = "All"
                    //     if ((this.locations[0].tabs || []).length > 0) tabName = this.locations[0].tabs[0];
                    //     this.selectLocation({ value: this.locations[0], tabName })
                    // }
                })
                this.listStandardModifiers();
            }); 
    }

    get barcodeShowPrintIcon() {
        if (this.item.quantityTracking === 'item') {
            let gtin: string | undefined;
            for (const code of this.codes || []) {
                gtin = code.gtin;
            }
            return gtin !== undefined;
        };
        if (this.item.quantityTracking === 'modifier') {
            let found = false;
            for (const code of this.codes || []) {
                if (!found && code.gtin !== undefined) found = true;
            }
            return found;
        };
        return false;
    }

    barcodeHandleItemClick(item) {
        if (item.quantityTracking === 'item') {
            let gtin = '';
            for (const code of this.codes || []) {
                gtin = code.gtin;
            }
            this.dialogsService.barcodeDialog(item.name, (item.price || 0).toFixed(2), gtin)
                .toPromise()
                .then( (res:any) => {
                })
            return;
        }
        if (item.quantityTracking === 'modifier') {
            this.showSection = 'barcodes';
            console.log('barcodeHandleItemClick', this.showAddItem, this.showEditItem, this.showSection);
            return;
        }
    }

    barcodeHandleModifierClick(item, code) {
        let price = Number(item.price || 0);
        if (item.modifiers !== undefined) {
            for (const modifier of item.modifiers || []) {
                for (const option of modifier.options || []) {

                    if (option.name === code.modifierKey) {
                        price = price + Number(option.cost || 0);
                    }
                }
            }
        }
        this.dialogsService
            .barcodeDialog(`${item.name}, ${code.modifierKey || ''}`, price.toFixed(2), code.gtin)
            .toPromise()
            .then( (res:any) => {})
    }

    closeInventoryCatalog() {
        this.showView = 'location-tab';
        this.inventory = [];
        this.inventoryBoxes = [];
        this.loadInventory(this.selectedLocation.locationId, this.selectedTab);
    }

    getCatalogRowHeight(row) {
        if (row.locationSettingNames === undefined || row.locationSettingNames.length === 0) return 50;
        return row.locationSettingNames.length * 150;
    }

    getUser(): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userService.getUser()
            .then(res => {
                this.idToken = res.idToken;
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    //load in all the pos locations
    getPOSLocations(): Promise<any>{
        return new Promise((resolve, reject) => {
            this.locationNames.clear();
            this.locations = [];
            this.organizationSettingsService.getPOSLocations(this.idToken, this.user.organizationId)
            .then(res =>{
                var body = JSON.parse(res._body);
                var message = body.items;
                message.forEach(location =>{
                    this.locations.push(location)
                    if (location.locationName !== undefined && location.locationId !== undefined) {
                        this.locationNames.set(location.locationId, location.locationName);
                    }
                })
                this.locations.sort((a, b) => {
                    if (a === undefined || a.locationName === undefined) return 1;
                    if (b === undefined || b.locationName === undefined) return -1;
                    return a.locationName > b.locationName ? 1 : -1;
                })
                resolve(this.locations);
            })
            .catch(err => {
                reject(err);
            })
        });
        
    }

    //dropdown triggered
    selectLocation($event){
        console.log($event.value);
        this.loadingService.toggleLoadingScreen(true);
        if(!$event.tabName) {
            this.selectedTab = null;
        }else{
            this.selectedTab = $event.tabName;
        }
        this.selectedLocation = $event.value;
        console.log("got location:", this.selectedLocation);
        this.possiblePositions = [];
        this.origPositionsByItem = [];
        this.positionsByItem = [];
        this.cascadePositionsByItem = [];
        if(this.selectedLocation.tabs && this.selectedLocation.tabs.length > 0){
            this.showTabDropdown = true;
            this.tabOptions = this.selectedLocation.tabs.slice();
            if(this.tabOptions.indexOf("All") == -1){
                this.tabOptions.push("All");
            }
            console.log("tab options:", this.tabOptions);
            if(!this.selectedTab) {
                this.selectTab({value:this.tabOptions[0]});
            }else{
                this.selectTab({value: this.selectedTab});
            }
            
        }else{
            this.showTabDropdown = false;
            this.tabOptions = [];
        }
        //now get the inventory
        console.log("now getting inventory:", this.selectedTab);
        this.loadInventory($event.value.locationId, this.selectedTab);


    }


    loadInventory(locationId?: string, selectedTab = null){
        this.positionsByItem = [];
        this.cascadePositionsByItem = [];
        this.origPositionsByItem = [];
        this.possiblePositions = [];
        this.inventoryService.fetchInventoryByLocation(this.idToken, locationId, this.user.organizationId)
        .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            console.log("got inventory:", res);
            let body = JSON.parse(res._body);
            var inv = body.items;

            var invKeys = []
            if (inv.length > 0) invKeys = Object.keys(inv[0]);
            var allInv = [];
            for (let i = 0; i < inv.length; i += 1) {
                const item = inv[i];
                const newItem = {};
                for (const invKey of invKeys) {
                    newItem[invKey] = item[invKey];
                }
                if (newItem['locationSettings'] !== undefined) {
                    const locationIds = Object.keys(newItem['locationSettings']);
                    item['locationSettingNames'] = [];
                    for (const locationId of locationIds) {
                        const name = this.locationNames.get(locationId);
                        if (name !== undefined) item['locationSettingNames'].push(name);
                    }
                    item['locationSettingNames'].sort((a, b) => {
                        return a > b ? 1 : -1;
                    })
                }
                inv[i] = item;
                allInv.push(newItem);
            }

            allInv.sort((a, b) => {
                if (a === undefined || a.name === undefined) return 1;
                if (b === undefined || b.name === undefined) return -1;
                return (a.name > b.name) ? 1 : -1;
            })    
            this.allInventory = allInv;

            console.log("init length:", inv.length);
            // let tempCategories = [];
            // const tabOptionsSet = new Set<string>(this.tabOptions || []);
            if(this.showTabDropdown && selectedTab){
                console.log("filtering!");
                console.log(selectedTab);
                inv = inv.filter(item => {
                    if (this.showView === 'catalog') {
                        return true;
                    } else if (selectedTab == "All" && item.locationSettings && item.locationSettings[locationId]) {
                        return true;
                    } else if (selectedTab && item.locationSettings && item.locationSettings[locationId] && item.locationSettings[locationId].tab === selectedTab) {
                        return true;
                    } else {
                        return false; 
                    }
                });
                // if(this.selectedLocation.categories){
                //     console.log("all categories are:", this.selectedLocation.categories);
                //     // only the categories at the given location & tab
                //     tempCategories = this.selectedLocation.categories.filter( category => {
                //         console.log("category:", category);
                //         if(!this.selectedLocation.tabsByCategory || !this.selectedLocation.tabsByCategory[category] || this.selectedLocation.tabsByCategory[category] == "any"){
                //             console.log("show this category (no array):", category);
                //             return true;
                //         }else{
                //             if(this.selectedLocation.tabsByCategory[category] == selectedTab){
                //                 console.log("show this category. It is on this tab...:", category);
                //                 return true;
                //             }else{
                //                 console.log("not on this tab..:", category);
                //                 return false;
                //             }
                //         }
                //     });
                //     this.tabCategories = tempCategories.slice();
                //     this.tabCategories.push("No Category");
                // }
                console.log("after filter:", inv.length);
            }

            // If:
            //  - All tab, then sort by name 
            //  - Not the All tab, then sort by position
            if (this.selectedTab == "All" || this.showView === 'catalog') {
                this.inventory = inv.sort( (a,b) => {
                    if (a === undefined || a.name === undefined) return 1;
                    if (b === undefined || b.name === undefined) return -1;
                    return a.name > b.name ? 1 : -1;
                });
            }
            else {
                this.inventory = inv.sort( (a,b) => {
                    if (a.locationSettings !== undefined && this.selectedLocation !== undefined && a.locationSettings[this.selectedLocation.locationId] === undefined) return 1;
                    if (b.locationSettings !== undefined && this.selectedLocation !== undefined && b.locationSettings[this.selectedLocation.locationId] === undefined) return -1;
                    const posA = a.locationSettings[this.selectedLocation.locationId].position;
                    const posB = b.locationSettings[this.selectedLocation.locationId].position;
                    return posA > posB ? 1 : -1;
                });
            }

            // this.allInventory = this.inventory.slice();
            // this.inventory = this.allInventory.filter(item => {
            //     if(!item.category){
            //         return true;
            //     }else{
            //         return false;
            //     }
            // });

            // console.log("all inventory:", this.allInventory);
            
            console.log("inventory is:", this.inventory);
            //check if it needs to be resized
           

            var index = 0;
            const withoutPosition = [];
            let tempBoxes = [];
            // let categoryLength = 0;
            // if(this.selectedLocation.categories){
            //     //insert the categories at the end
            //     //let index = 0;
            //     tempCategories = this.selectedLocation.categories.slice();
            //     categoryLength = 0;// tempCategories.length;
            //     //this.maxRow = Math.ceil((this.inventory.length + categoryLength) / this.numCols);
            //     tempCategories.forEach(category => {
            //         let newItem = {
            //             type: 'category',
            //             name: category,
            //             position: index
            //         }
            //         console.log("getting row, col for position:", newItem.position);
            //         let [row, col] = this.getCoordsByPosition(newItem.position);
            //         console.log(row,col);
            //         let config = Object.assign({}, this.gridItemConfig);
            //         config.row = row;
            //         config.col = col;
            //         config.draggable = false;
            //         config.fixed = true;
            //         let newBox = {
            //             item: newItem,
            //             config: config
            //         }
            //         console.log("new box:", newBox);
            //         if(!selectedTab){
            //             console.log("!selected tab!");
            //             tempBoxes.push(newBox);
            //             this.inventory.unshift(newItem);
            //             this.possiblePositions.push(index);
            //             categoryLength++;
            //             index++;
            //         }else{
            //             console.log("selectedTab");
            //             console.log("category:", category);
            //             console.log("selectedlocation:", this.selectedLocation);
            //             if(this.selectedLocation && this.selectedLocation.tabsByCategory && selectedTab == this.selectedLocation.tabsByCategory[category]){
            //                 console.log("selectedTab === tabsByCategory...");
            //                 tempBoxes.push(newBox);
            //                 this.inventory.unshift(newItem);
            //                 this.possiblePositions.push(index);
            //                 categoryLength++;
            //                 index++;
            //             } else {
            //                 console.log("not a match");
            //             }
            //         }
            //     });
            // }

            console.log("after category stuff");

            if((this.inventory.length) <= 6){
                this.cardStyle = {'height': '650px'};
            }else{
                var height = Math.ceil((this.inventory.length)/this.numCols)*208 + 210;
                console.log("height should be:", height);
                console.log("num cols is:", this.numCols);
                if(height < 650){
                    height = 650;
                }
                this.cardStyle = {
                    'height': JSON.stringify(height) + "px"
                }
            }
            // console.log("inventory length:", this.inventory.length);
            // console.log("category length:", categoryLength)

            //this.maxRow = Math.ceil((this.inventory.length + categoryLength) / this.numCols);
            // console.log("max row is:", this.maxRow);
            //index = 1;
            // console.log("entering inventory boxes forEach:", this.inventory);
            this.inventory.forEach( inventoryItem => {
                let item;
                if (this.showView === 'catalog') {
                    item = inventoryItem;
                    item.position = index;
                } else if (this.selectedLocation !== undefined && inventoryItem.locationSettings !== undefined && inventoryItem.locationSettings[this.selectedLocation.locationId] !== undefined) {
                    item = inventoryItem;
                    item.locationSettings[this.selectedLocation.locationId].position = index
                    item.position = item.locationSettings[this.selectedLocation.locationId].position;
                    item.tab = item.locationSettings[this.selectedLocation.locationId].tab;
                }
                if (item && item !== undefined) {
                        this.positionsByItem[item.itemId] = item.position;
                        this.origPositionsByItem[item.itemId] = item.position;
                        this.cascadePositionsByItem[item.itemId] = item.position;
                        if(this.possiblePositions.indexOf(index) == -1) this.possiblePositions.push(item.position);
                        let [row, col] = this.getCoordsByPosition(index);
                        // console.log(`${item.name} ${item.position || '0'} ${item.tab} x=${row} y=${col} indx=${index}`);

                        const config = new GridItemConfig();
                        config.row = row;
                        config.col = col;
                        let newBox = {
                            item: item,
                            config: config
                        }

                        // console.log(item.name, item.type, newBox);
                        if(item.type != 'category'){               
                            tempBoxes.push(newBox);
                            index++;
                        }
                }
            });

            console.log("inventory after all...", this.inventory);

            console.log("init location. Cascade positions:", this.cascadePositionsByItem);
            this.inventoryBoxes = [];
            this.inventoryBoxes = this.inventoryBoxes.concat(tempBoxes);
            console.log('inventoryBoxes', this.inventoryBoxes);
            console.log(this.positionsByItem);
            console.log(this.possiblePositions);
        })
        .catch( err => {
            this.loadingService.toggleLoadingScreen(false);
        })
    }

    loadItemCodes(itemId?: string, modifierKey?: string) {
        this.inventoryCodeService.listCodes(this.idToken, this.user.organizationId, itemId, modifierKey)
        .then(res => {
          var body = JSON.parse(res._body);
          //   console.log('Codes Body', body);
          this.codes = body.items;
          //   this.organization = body.message.Items[0];
          //   console.log("got organization:", this.organization);
          //   console.log("this.organization:", this.organization.organizationName);
        })    
    }


    resetFilters(){
        // this.item.category = undefined;
        this.loadInventory(this.selectedLocation.locationId, this.selectedTab);
    }

    // filterCategory(category: any){
    //     console.log("got category:", category);
    //     this.item.category = category.name;
    //     //now do filter
    //     this.inventory = this.allInventory.filter( item => {
    //         if(item.category == category.name){
    //             return true;
    //         }else{
    //             return false;
    //         }
    //     });
    //     this.inventory = this.inventory.sort( (a,b) => {
    //         return a.position - b.position;
    //     });
    //     console.log("inventory is:", this.inventory);
    //     let tempBoxes = [];
    //     let index = 1;
    //     //this.maxRow = Math.ceil(this.inventory.length / this.numCols);
    //     var height = Math.ceil((this.inventory.length)/this.numCols)*208 + 210;
    //     console.log("height should be:", height);
    //     if(height < 650){
    //         height = 650;
    //     }
    //     this.cardStyle = {
    //         'height': JSON.stringify(height) + "px"
    //     }
    //     this.positionsByItem = [];
    //     this.cascadePositionsByItem = [];
    //     this.origPositionsByItem = [];
    //     this.possiblePositions = [];
    //     this.inventory.forEach( item => {
    //         item.position = index;
    //         this.positionsByItem[item.itemId] = item.position;
    //         this.origPositionsByItem[item.itemId] = item.position;
    //         this.cascadePositionsByItem[item.itemId] = item.position;
    //         this.possiblePositions.push(index);
    //         let [row, col] = this.getCoordsByPosition(item.position || index);
    //         let config = Object.assign({}, this.gridItemConfig);
    //         config.row = row;
    //         config.col = col;
    //         //config.draggable = false;
    //         //config.fixed = true;

    //         let newBox = {
    //             item: item,
    //             config: config
    //         }
    //         newBox.config.row = row;
    //         newBox.config.col = col;              
    //         tempBoxes.push(newBox);
    //         index++;
    //     });

    //     this.inventoryBoxes = tempBoxes;
        
    // }

    selectPosition($event, itemId){
        console.log("selected positon...");
        console.log("item id is:", itemId);
        console.log("cascade positions here:", this.cascadePositionsByItem);
        let prevPos = this.cascadePositionsByItem[itemId];
        console.log("prevPos here:", prevPos);
        this.positionsByItem[itemId] = $event.value;
        console.log(this.positionsByItem);
        console.log(this.origPositionsByItem);
        this.cascadePositions($event.value, prevPos, itemId);
    }

    cascadePositions(newPos, prevPos, currId){
        console.log("New, old");
        console.log(newPos);
        console.log(prevPos);
        //need to adjust everything..
        //start with element at new position
        //if new position is > old position: move element down
        //continue until the position reaches the old position
        var itemsMoved = [currId];
        if(newPos > prevPos){
            console.log("moved higher, prop down");
           var currIndex = newPos;
           while(currIndex > prevPos){
               var itemId = this.getIdByPos(currIndex, itemsMoved);
               console.log("!curr index:", currIndex);
               console.log("got itemId:", itemId);
               console.log(this.positionsByItem[itemId]);
               this.positionsByItem[itemId] = (currIndex - 1);
               itemsMoved.push(itemId);
               console.log("set it to index:", currIndex - 1);
               currIndex--;
           }
           console.log("positions by index now:", this.positionsByItem); 
        }else if(newPos < prevPos){
            console.log("moved lower, prop up");
            var currIndex = newPos;
            while(currIndex < prevPos){
               var itemId = this.getIdByPos(currIndex, itemsMoved);
               console.log("!curr index:", currIndex);
               console.log("got itemId:", itemId);
               console.log(this.positionsByItem[itemId]);
               this.positionsByItem[itemId] = (currIndex + 1);
               itemsMoved.push(itemId);
               console.log("set it to index:", currIndex + 1);
               currIndex++;
           }
        }

        this.cascadePositionsByItem = Object.assign([],this.positionsByItem);
        this.inventoryBoxes.forEach( box => {
            let [row, col] = this.getCoordsByPosition(this.cascadePositionsByItem[box.item.itemId]);
            console.log("position for itemId:", box.item.itemId);
            console.log("is:", this.cascadePositionsByItem[box.item.itemId]);
            console.log("row,col:");
            console.log(row,col);
            box.config.row = row;
            box.config.col = col;
            box.item.position = this.cascadePositionsByItem[box.item.itemId];
            console.log("updated box:", box);
        })
        console.log("cascade positions:", this.cascadePositionsByItem);
        this.savePositions()
    }

    getIdByPos(index, itemsMoved: string[]): string{
        let itemId: string = "";
        for(var id in this.positionsByItem){
            //console.log("id:", id);
            if(this.positionsByItem[id] == index && itemsMoved.indexOf(id) == -1){
                //console.log("match!");
                itemId = id;
            }
        }
        return itemId;
    }


    havePositionsChanged(): boolean{
        // console.log("have positions changed?");
        var hasChanged = false;
        this.inventory.forEach( (item) => {
            if(this.positionsByItem[item.itemId] != this.origPositionsByItem[item.itemId]){
                hasChanged = true;
            }
        });
        console.log("have positions changed?", hasChanged, this._lastPositionUpdateAt < Date.now() - 1000);
        // if (hasChanged && this._lastPositionUpdateAt < Date.now() - 1000) {
        //     this._lastPositionUpdateAt = Date.now();
        //     this.savePositions();
        // }
        return hasChanged;
    }


    savePositions(){
        //check for duplicates
        var array = [];
        for( var key in this.positionsByItem){
            array.push(this.positionsByItem[key]);
        }
        console.log("savePositions() positions by item:", this.positionsByItem);
        console.log("savePositions() array:", array);
        var isUnique = this.checkIfArrayIsUnique(array);
        console.log("savePositions() is unique:", isUnique);
        if(!isUnique){
            this.dialogsService.alert("Invalid layout", "You cannot have multiple items with the same position");
            return;
        }
        this.loadingService.toggleLoadingScreen(true);
        console.log("savePositions() submitting positions by item:", this.positionsByItem);
        console.log("savePositions() positions by item:", this.positionsByItem);
        this.statusMsg = 'Saving Positions ...'
        console.log('savePositions() StatusMsg:', this.statusMsg);
        this.inventoryService.saveInventoryPositions(this.idToken, this.positionsByItem, this.user.organizationId, this.selectedLocation.locationId)
        .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            console.log("savePositions() res:");
            this.origPositionsByItem = Object.assign([],this.positionsByItem); 
            this.cascadePositionsByItem = Object.assign([],this.positionsByItem); 
            console.log(res);
            // this.dialogsService.alert("Success!", "Your positions have been updated!");
            this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
            this.statusMsg = 'Saved Positions'
            console.log('savePositions() StatusMsg:', this.statusMsg);
        })
        .catch( err => {
            this.loadingService.toggleLoadingScreen(false);
            console.log("savePositions() err:", err);
            this.statusMsg = `Error: ${err.message}`
            console.log('savePositions() StatusMsg:', this.statusMsg);
        });
    }


    removeTab(){
        // console.log(this.selectedTab);
        const that = this;
        this.dialogsService.confirm("Remove " + this.selectedTab + "?", "This will remove the tab and items will now appear under \"All\"")
        .toPromise()
        .then( res => {
            // console.log('removeTab res', res);
            if(res){
                this.inventoryService.removeCategoryOrTab(this.idToken, this.user.organizationId, this.selectedLocation.locationId, this.selectedTab, "tabs", this.tabOptions, this.selectedLocation.tabsByCategory)
                .then( res2 => {
                    // console.log('removeTab res', res2);
                    var body = JSON.parse(res2._body);
                    // console.log('removeTab body', body);
                    if(body.status == "200"){
                        // console.log('removeTab', this.selectedTab, "success");
                        // alert & refresh page
                        this.dialogsService.alert("Success", "Your tab has been removed!")
                        const index = that.selectedLocation.tabs.indexOf(this.selectedTab);
                        // console.log('removeTab tabOptions indexOf', index);
                        if (index >= 0) {
                            that.tabOptions.splice(index, 1);
                            const tabs = that.selectedLocation.tabs;
                            tabs.splice(index, 1);
                            that.selectedLocation.tabs = tabs;
                            // that.selectTab({value: 'Other'}); // TODO
                        }
                        // console.log('removeTab tabOptions', that.tabOptions);
                        this.selectLocation({value: this.selectedLocation});
                    }
                })
            }
        })
    }


    removeCategories(categoryName){
        console.log(categoryName);
        this.dialogsService.confirm("Remove " + categoryName + "?", "This will remove the category from any existing items in this category")
        .toPromise()
        .then( res => {
            if(res){
                this.inventoryService.removeCategoryOrTab(this.idToken, this.user.organizationId, this.selectedLocation.locationId, categoryName, "categories", this.selectedLocation.categories, this.selectedLocation.tabsByCategory)
                .then( res => {
                    var body = JSON.parse(res._body);
                    console.log(body);
                    if(body.status == "200"){
                        console.log("success");
                        // alert & refresh page
                        this.dialogsService.alert("Success", "Your category has been removed!")
                        this.selectLocation({value: this.selectedLocation});
                    }
                })
            }
        })  
    }




    editTabName(){
        console.log(this.selectedTab);
        if(this.selectedTab == "All"){
            this.dialogsService.alert("Invalid Tab", "You cannot change the name for this tab");
            return;
        }
        this.dialogsService.input("Edit Name", "Enter a new name for your tab", this.selectedTab, "Cancel", "Save", "text")
        .toPromise()
        .then( res => {
            if(res && res[1]){
                console.log(res[1]);
                let tabName = res[1];
                if (tabName.indexOf('"') !== -1 || tabName.indexOf(",") !== -1) {
                    return this.dialogsService.alert("Invalid category name", "Your category cannot contain any commas or double quotes");
                }
                if(tabName == this.selectedTab) {
                    this.dialogsService.alert("Same name", "To update a tab, you must change its name!");
                    return;
                }
                this.inventoryService.editTab(this.idToken, this.user.organizationId, this.selectedLocation.locationId, this.selectedTab, tabName, this.tabOptions, this.selectedLocation.tabsByCategory)
                .then( res => {
                    var body = JSON.parse(res._body);
                    console.log(body);
                    if(body.status == "200"){
                        console.log("success");
                        // alert & refresh page
                        this.dialogsService.alert("Success", "Your tab has been renamed!");
                        this.getPOSLocations()
                        .then( locations => {
                            console.log("got locations:", locations);
                            let selectedLocation = {};
                            locations.forEach( loc => {
                                if(loc.locationId == this.selectedLocation.locationId) {
                                    selectedLocation = loc;
                                }
                            })
                            this.selectLocation({value: selectedLocation});
                            this.selectedLocation = selectedLocation;
                            this.locationSelect = selectedLocation;
                            console.log("tabName:", tabName);
                            console.log("tab options:", this.tabOptions);
                            this.selectTab({value: tabName});
                            
                        });

                        
                    }
                })
                .catch( err => {
                    console.log("error:", err);
                })
            }
        })
    }


    //open input dialog to add new tab
    openAddTab(){
        console.log("open add tab..");
        this.dialogsService.input("New Tab", "Enter a name for your new POS tab", 0, "Cancel", "Add", "text")
        .toPromise()
        .then( res => {
            console.log("got value:", res);
            if(res && res[1]){
                var tabName = res[1];
                if (tabName.indexOf('"') !== -1 || tabName.indexOf(",") !== -1) {
                    return this.dialogsService.alert("Invalid category name", "Your category cannot contain any commas or double quotes");
                }
                //make sure this tab doesn't already exist on the location...
                if(this.selectedLocation.tabs && this.selectedLocation.tabs.indexOf(tabName) != -1){
                    this.dialogsService.alert("Invalid duplicate", "Your tabs must all have unique names!");
                    return;
                }else{
                    console.log("invoke endpt..");
                    this.inventoryService.addTab(this.idToken, this.user.organizationId, this.selectedLocation.locationId, tabName, this.selectedLocation.tabs)
                    .then(res => {
                        console.log("add tab res:", res);
                        //if successful, append this tab..
                        var body = JSON.parse(res._body);
                        if(body.status == "200"){
                            //success
                            this.dialogsService.alert("Success!", "Your tab has been added!");
                            //add tabs to arrays
                            if(this.selectedLocation.tabs){
                                this.selectedLocation.tabs.push(tabName);
                            }else{
                                this.selectedLocation.tabs = [tabName];
                            }
                            if(this.tabOptions){
                                this.tabOptions.push(tabName);
                            }else{
                                this.tabOptions = [tabName];
                            }
                            //add default "other" tab
                            // if(this.selectedLocation.tabs && this.selectedLocation.tabs.length === 1) {
                            //     this.selectedLocation.tabs.push("Other");
                            // }
                            // if(this.tabOptions && this.tabOptions.length === 1) {
                            //     this.tabOptions.push("Other");
                            // }
                            //show dropdown
                            this.showTabDropdown = true;
                            console.log("loc:");
                            console.log(this.selectedLocation);
                        }
                    })
                    .catch(err => {
                        this.dialogsService.alert("Processing Error", "There has been an error creating the new tab");
                    })
                }
            }
            
        })
    }

    //open dialog for category name
    openAddCategory(){
        console.log("open add category.. DISABLED");
        // this.dialogsService.input("New Category", "Enter a name for your new category", 0, "Cancel", "Add", "text")
        // .toPromise()
        // .then( res => {
        //     console.log("got value:", res);
        //     if(res && res[1]){
        //         let categoryName = res[1];
        //         // forbid commas, double quotes for now
        //         if (categoryName.indexOf('"') !== -1 || categoryName.indexOf(",") !== -1) {
        //             return this.dialogsService.alert("Invalid category name", "Your category cannot contain any commas or double quotes");
        //         }
        //         //make sure this tab doesn't already exist on the location...
        //         if(this.selectedLocation.categories && this.selectedLocation.categories.indexOf(categoryName) != -1){
        //             this.dialogsService.alert("Invalid duplicate", "Your categories must all have unique names!");
        //             return;
        //         }else{
        //             console.log("invoke endpt..");
        //             console.log(this.selectedLocation.tabsByCategory);
        //             let tabsByCategory = this.selectedLocation.tabsByCategory || {};
        //             console.log("tabs by category here:", tabsByCategory);
                    
        //             if(this.selectedTab){
        //                 tabsByCategory[categoryName] = this.selectedTab;
        //             }else{
        //                 tabsByCategory[categoryName] = "any";
        //             }
        //             console.log("tabs by category:", tabsByCategory);
        //             let tabsByCategoryString = JSON.stringify(tabsByCategory);
        //             console.log("tabs by cat string:", tabsByCategoryString);
                    
        //             this.inventoryService.addCategory(this.idToken, this.selectedLocation.locationId, categoryName, this.selectedLocation.categories, tabsByCategoryString)
        //             .then(res => {
        //                 console.log("add category res:", res);
        //                 //if successful, append this tab..
        //                 var body = JSON.parse(res._body);
        //                 if(body.status == "200"){
        //                     //success
        //                     this.dialogsService.alert("Success!", "Your category has been added!");
        //                     this.selectedLocation.tabsByCategory = tabsByCategory;
        //                     this.loadInventory(this.selectedLocation.locationId, this.selectedTab);
        //                     if(this.selectedLocation.categories){
        //                         this.selectedLocation.categories.push(categoryName);
        //                     }else{
        //                         this.selectedLocation.categories = [categoryName];
        //                     }
        //                     console.log("loc:");
        //                     console.log(this.selectedLocation);
        //                 }
        //             })
        //             .catch(err => {
        //                 this.dialogsService.alert("Processing Error", "There has been an error creating the new category");
        //             })
        //         }
        //     }
            
        // })
    }

    openInventoryCatalog() {
        this.showView = 'catalog';
        this.loadInventory();
    }

    openPurchaseOrders() {
        this.router.navigate(['inventory/purchaseorders']);
    }

    selectTab($event){
        console.log($event.value);
        this.selectedTab = $event.value;
        if (this.selectedTab === "All" && this.showListView === false) this.toggleListView();
        this.loadInventory(this.selectedLocation.locationId, this.selectedTab);
    }


    checkIfArrayIsUnique(myArray) {
        return myArray.length === new Set(myArray).size;
    }




    toggleMenu(){
        this.loadingService.toggleSideMenu();
    }


    openAddItem(){
        if(this.selectedLocation){
            let orgRate = this.organization.taxRate? this.organization.taxRate : 0;
            this.item.taxRate = orgRate;
            this.item.applyDailyLimits = this.selectedLocation.applyDailyLimits;
            this.showAddItem = true;
        }else{
            this.dialogsService.alert("No Location Selected", "You must select a location to add the item to!");
        }
    }

    openEditItem(item){
        console.log("Selected loc:", this.selectedLocation)
        console.log("Edit selected item:", item)

        this.item.applyDailyLimits = (item.applyDailyLimits !== null && item.applyDailyLimits !== undefined)? item.applyDailyLimits : this.selectedLocation.applyDailyLimits;

        this.item.deposit = (item.deposit)? item.deposit : false;
        if (item.deposit) this.item.transactionType = 'deposit';
        else this.item.transactionType = 'transaction';

        this.item.imageUrl = item.imageUrl;
        this.item.isEmployeeDiscountEligble = (item.isEmployeeDiscountEligble != undefined)? item.isEmployeeDiscountEligble : true;
        this.item.itemId = item.itemId;
        this.item.itemType = item.itemType ? item.itemType : "none";
        this.item.modifiers = item.modifiers;
        this.item.name = item.name;
        this.item.organizationId = item.organizationId;
        this.item.quantityTracking = item.quantityTracking || 'off';
        this.item.price = item.price;
        let orgRate = this.organization.taxRate ? this.organization.taxRate : 0;
        this.item.taxRate = (item.taxRate || item.taxRate == 0) ? item.taxRate : orgRate;
        this.item.type = (item.type)? item.type: 'fixed';

        this.loadItemCodes(item.itemId);

        // console.log("org rate:", orgRate);
        // console.log("set tax rate to:", this.item.taxRate);
        // if(this.item.itemType != "none"){
        //     this.item.isbn = item.isbn;
        //     this.item.quantity = item.quantity;
        //     this.item.dept = item.dept;
        //     this.item.dateLastOrdered = item.dateLastOrdered;
        //     this.item.dateLastSold = item.dateLastSold;
        //     this.item.title = item.title;
        // }
        this.showEditItem = true;
    }

    openAddModifier(modifier?: Modifier){
        this.showSection = "modifiers";
        if(!modifier){
            this.modifier = new Modifier();
            this.isNewModifier = true;
        }else{
            this.modifier = modifier;
            this.isNewModifier = false;
        }
    }

    closeModifier(){
        this.showSection = "details";
        this.showEditStandardModifier = false;
    }

    removeModifier(modifier: Modifier){
        this.item.modifiers.splice(this.item.modifiers.indexOf(modifier),1);
    }

    removeModifierOption(option: ModifierOption){
        this.modifier.options.splice(this.modifier.options.indexOf(option),1);
    }

    loadOrganization(){
        this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
            .then(res => {
            console.log("got organization:", res);
            var body = JSON.parse(res._body);
            if (body.status == "200") {
                this.organization = body.message.Items[0];
                console.log("org:", this.organization);
            }

            })
            .catch(err => {

            })
    }

    updateQuantityTracking(item: Item, quantityTracking: string) {
        console.log("update item:", item);
        console.log("quantityTracking is:", quantityTracking);
    }


    //bookstore, normal, apparel
    updateItemType(item: Item, itemType: string){
        console.log("update item:", item);
        console.log("type is:", itemType);
        // if(itemType == "bookstore" || itemType == "apparel"){
        //     this.dialogsService.setItemFields(item)
        //     .toPromise()
        //     .then( res => {
        //         console.log("set item fields:", res);
        //         if(res){
        //             // this.item.quantity = res.quantity;
        //             // this.item.dept = res.dept;
        //             // this.item.cogsPrice = res.cogsPrice;
        //             // this.item.dateLastOrdered = res.dateLastOrdered;
        //             // this.item.dateLastSold = res.dateLastSold;
        //             // this.item.isbn = res.isbn;
        //             // this.item.title = res.title;
        //         }else{
        //             //this.item.itemType = "none";
        //         }
        //     })
        // }else{
        //     //item.resetType();
        // }
    }

    openOptionsModal(index:number = null){
        console.log("open options modal....");
        let opt = null;
        if(index != null) {
            opt = Object.assign({}, this.modifier.options[index]);
        }
        console.log("passing in opt:", opt);
        this.dialogsService.addModifier(opt)
        .toPromise()
        .then( (res: ModifierOption) => {
            console.log("open options modal res:", res);
            if(res && res.name) {
                if(index !== null){
                    this.modifier.options.splice(index,1,res);
                }else{
                    this.modifier.options.push(res);
                }
            }
        })
        // this.dialogsService.input("Add an option", "Add a new modifier option to choose from", 0, "Cancel", "Add", "text")
        // .toPromise()
        // .then( res => {
        //     console.log("got value:", res);
        //     if(res && res[0]){
        //         var opt = res[1];
        //         this.modifier.options.push(opt);
        //         // this.dialogsService.confirm("Default to add this option?", "Do you want to automatically add this modifier to the item? It can be removed at checkout", "No", "Yes")
        //         // .toPromise()
        //         // .then( res => {
        //         //     if(res){
        //         //         this.modifier.default.push(opt);
        //         //     }
        //         // });
        //     }
            
        // })
    }

    saveModifier(){
        //push this modifier to end of items modifier array (or update.. as needed)
        console.log("save modifier:", this.modifier);
        console.log("to item:", this.item);
        if(this.modifier.name == ""){
            this.dialogsService.alert("Missing name", "You must assign a name to this modifier");
        }else if(this.modifier.options.length == 0){
            this.dialogsService.alert("No options", "You must add at least one option");
        }else if(this.showEditStandardModifier && !this.editStandardModifiers){
            console.log("save this modifier...");
            console.log(this.modifier);
            this.loadingService.toggleLoadingScreen(true);
            this.inventoryService.createStandardModifier(this.idToken, this.user.organizationId, this.modifier)
            .then( res => {
                console.log("create mod res:", res);
                this.loadingService.toggleLoadingScreen(false);
                var body = JSON.parse(res._body);
                if (body.status == "200") {
                    this.dialogsService.alert("Success!", "Your standard modifier has been added!");
                    this.showEditStandardModifier = false;
                    this.modifier = null;
                    this.listStandardModifiers();
                }else{
                    this.dialogsService.alert("Processing Error!", "Your standard modifier could not be added! Please try again later");
                }
            })
            .catch( err => {
                console.log("create mod err:", err);
                this.loadingService.toggleLoadingScreen(false);
                this.dialogsService.alert("Processing Error!", "Your standard modifier could not be added! Please try again later");
            });
        }else if(this.showStandardModifiers && this.editStandardModifiers){
            console.log("do an update!");
            console.log(this.modifier);
            this.loadingService.toggleLoadingScreen(true);
            this.inventoryService.editStandardModifier(this.idToken, this.user.organizationId, this.modifier)
            .then( res => {
                this.loadingService.toggleLoadingScreen(false);
                var body = JSON.parse(res._body);
                if (body.status == "200") {
                    this.dialogsService.alert("Success!", "Your standard modifier has been updated!");
                    this.showEditStandardModifier = false;
                    // this.standardModifiers.push(this.modifier);
                    this.modifier = null;
                    this.editStandardModifiers = false;
                    this.listStandardModifiers();
                    window.location.reload();
                }else{
                    this.dialogsService.alert("Processing Error!", "Your standard modifier could not be updated! Please try again later");
                }
            })
            .catch( err => {
                this.loadingService.toggleLoadingScreen(false);
                this.dialogsService.alert("Processing Error!", "Your standard modifier could not be updated! Please try again later");
            });
        }else{
            //valid
            if(this.isNewModifier){
                console.log("item is:", this.item);
                console.log("modifier:", this.modifier);
                if(!this.item.modifiers){
                    this.item.modifiers = [];
                }
                this.item.modifiers.push(this.modifier);
            }
            this.showSection = "details";
        }
    }

    removeItem(item){
        if (this.selectedLocation.locationId == null || this.selectedLocation.locationId === undefined) return;
        //confirm and delete here...
        this.dialogsService.confirm("Remove this item?", "This will remove the item from the tab")
        .toPromise()
        .then( res => {
            if(res){
                this.inventoryService.removeInventoryItem(this.idToken, item.itemId, this.user.organizationId, this.selectedLocation.locationId)
                .then( res => {
                    console.log("remove inventory res:", res);
                    let body = JSON.parse(res._body);
                    if(body.status == "200"){
                        //it worked
                        this.closeCard();
                        this.dialogsService.alert("Item Removed", "The item has been removed from your inventory");
                        this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
                    }else{
                        this.dialogsService.alert("Processing Error", "The item could not be removed");
                    }
                })
                .catch( err => {
                    this.dialogsService.alert("Processing Error", "The item could not be removed");
        })
            }
        })
    }

    closeCard(){
        this.showEditItem = false;
        this.showAddItem = false;
        // let prevCategory = "";
        // if(this.item.category){
        //     prevCategory = this.item.category;
        // }
        this.item = new Item();
        // if(prevCategory != ""){
        //     this.item.category = prevCategory;
        // }
        this.loadInventory(this.selectedLocation.locationId, this.selectedTab);
        
    }

    sortComparatorCreatedDate(propA, propB, rowA, rowB, sortDirection) {
        const a = rowA['createdAt'];
        const b = rowB['createdAt'];
        if (a < b) {
          return sortDirection === 'asc' ? -1 : 1;
        } else if (a > b) {
          return sortDirection === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
    }


    validateDistinct(): boolean{
        let isValid = true;
        if(!this.item.modifiers || this.item.modifiers.length > 2){ // || this.item.modifiers.length < 2){
            isValid = false;
        }
        console.log(this.item.modifiers)
        this.item.modifiers.forEach(mod => {
            console.log(mod);
            if(mod.multiAllowed){
                isValid = false;
            }
        })
        // if(this.item.modifiers[0].multiAllowed || this.item.modifiers[1].multiAllowed){
        //     isValid = false;
        // }
        return isValid;
    }


    addItem(){
        if(this.item.name == "" || this.item.price < 0){
            this.dialogsService.alert("Invalid submission", "You must have a name and a non-negative price for your item");
            return;
        }
        console.log("org id is:", this.user.organizationId);
        //get position:
        var position = 1;
        this.inventory.forEach( item =>{
            console.log("item:", item.position);
            if(!this.showTabDropdown){
                if(item.position >= position){
                    position = item.position + 1;
                }
            }else if(item.tab == this.selectedTab){
                if(item.position >= position){
                    position = item.position + 1;
                }
            }
        })
        // if(this.item.modifiersDistinct){
        //     let isValid = this.validateDistinct();
        //     if(!isValid){
        //         this.dialogsService.alert("Invalid Submission", "For modifier combinations, you cannot have more than two modifiers and none of them can allow. Edit your modifiers or switch off combination tracking to add this item");
        //         return;
        //     }
        // }
        // this.item.modifierIds = [];
        // if(this.item.modifiers) {
        //     this.item.modifiers.forEach( mod => {
        //         if(mod.modifierId && mod.modifierId.length > 10) {
        //             this.item.modifierIds.push(mod.modifierId);
        //         }
        //     })
        // }
        if (this.item.transactionType == 'deposit') {
            this.item.deposit = true;
        }
        console.log("Add item:", this.item);
        this.inventoryService.addInventoryItem(this.idToken, this.user.organizationId, this.selectedLocation.locationId, this.item, position, this.selectedTab)
        .then( res => {
            console.log("add inventory res:", res);
            let body = JSON.parse(res._body);
            if(body.status == "200"){
                //it worked
                const itemId = body.message;
                console.log("Item id:", itemId);
                if (this.item.imageFile) {
                    console.log("yes image file...");
                    this.item.itemId = itemId;
                    this.item.organizationId = this.user.organizationId;
                    this.item.imageUrl = InventoryImageBucketLong + "/" + this.item.itemId + ".png";
                    console.log("call update..");
                    this.updateItem(true);
                } else {
                    this.closeCard();
                    this.item = new Item();
                    this.dialogsService.alert("Item Added", "The item has been added to your inventory");
                    this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
                }
            }else{
                this.dialogsService.alert("Processing Error", "The item could not be added");
            }
        })
        .catch( err => {
            this.dialogsService.alert("Processing Error", "The item could not be added");
        })
    }

    updateItemTab(event, item) {
        console.log("update item tab");
        console.log(event, item);
        this.loadingService.toggleLoadingScreen(true);

        if (event.value === "All") {
            this.inventoryService.removeItemTab(this.idToken, item.itemId, this.user.organizationId, this.selectedLocation.locationId)
                .then( res => {
                    this.loadingService.toggleLoadingScreen(false);
                    console.log("Got res:", res);
                    this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
                })
                .catch( err => {
                    this.loadingService.toggleLoadingScreen(false);
                    this.dialogsService.alert("Processing Error", "The item's tab could not be updated");
                })
        } else {
            this.inventoryService.updateItemTab(this.idToken, item.itemId, this.user.organizationId, this.user.email, this.selectedLocation.locationId, event.value)
                .then( res => {
                    this.loadingService.toggleLoadingScreen(false);
                    console.log("Got res:", res);
                    this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
                })
                .catch( err => {
                    this.loadingService.toggleLoadingScreen(false);
                    this.dialogsService.alert("Processing Error", "The item's tab could not be updated");
                })
        }
    }

    updateItemCategory(event, item) {
        console.log("Update item category is DISABLED");
        // console.log(event, item);
        // this.loadingService.toggleLoadingScreen(true);
        // this.inventoryService.updateItemCategory(this.idToken, item.itemId, this.user.organizationId, this.user.email, this.selectedLocation.locationId, event.value, item.tab)
        //     .then( res => {
        //         this.loadingService.toggleLoadingScreen(false);
        //         console.log("Got res:", res);
        //         this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
        //     })
        //     .catch( err => {
        //         this.loadingService.toggleLoadingScreen(false);
        //         this.dialogsService.alert("Processing Error", "The item's category could not be updated");
        //     })
    }

    updateItem(isAdded = false){
        console.log("org id is:", this.user.organizationId);
        if(this.item.name == "" || this.item.price < 0){
            this.dialogsService.alert("Invalid submission", "You must have a name and a non-negative price for your item");
            return;
        }
        // if(this.item.modifiersDistinct){
        //     let isValid = this.validateDistinct();
        //     if(!isValid){
        //         this.dialogsService.alert("Invalid Submission", "For modifier combinations, you cannot have more than two modifiers and none of them can allow multi-select. Edit your modifiers or switch off combination tracking to edit this item");
        //         return;
        //     }
        // }
        // this.item.modifierIds = [];
        // if(this.item.modifiers) {
        //     this.item.modifiers.forEach( mod => {
        //         if(mod.modifierId && mod.modifierId.length > 10) {
        //             this.item.modifierIds.push(mod.modifierId);
        //         }
        //     })
        // }
        if (this.item.imageUrl && this.item.imageFile) {
            console.log("NOW updating the image!");
            let photoKey = encodeURIComponent(this.item.itemId) + ".png";
            this.inventoryService.uploadInventoryImageS3(photoKey, this.item.imageFile)
            .then( res => {
                console.log("UPLOAD SUCCESS");
            })
            .catch( err => {
                console.log("UPLOAD ERR!");
                console.log(err);
            });
        }
        if (this.item.transactionType == 'deposit') {
            this.item.deposit = true;
        }
        console.log("Update Item:", this.item);
        this.inventoryService.editInventoryItem(this.idToken, this.item )
        .then( res => {
            console.log("edit inventory res:", res);
            let body = JSON.parse(res._body);
            if(body.status == "200"){
                //it worked
                this.closeCard();
                if (!isAdded) {
                    this.dialogsService.alert("Item Updated", "The item has been updated!");
                } else {
                    this.dialogsService.alert("Item Added", "The item has been added to your inventory");
                }
                this.selectLocation({value: this.selectedLocation, tabName: this.selectedTab});
            }else{
                this.dialogsService.alert("Processing Error", "The item could not be updated");
            }
        })
        .catch( err => {
            this.dialogsService.alert("Processing Error", "The item could not be updated");
        })
    }



    //upload inv.
    uploadItems(){
        //get the file
        console.log("upload csv! DISABLED");
        // var files = document.getElementById('uploadItems')['files'];
        // console.log("files is:", files);
        // if (!files.length) {
        //     console.log("no files");
        //     //now make it fail if the user doesn't already have an image
        //    // console.log("edit user:", this.editUser);
        // }

        // var file = files[0];

        // console.log("file is:", file);

        // var reader = new FileReader();
        // reader.readAsText(file, "UTF-8");
        // reader.onload = (evt) => {
        //     console.log("evt:", evt);
        //     var csvText = evt.target['result'];
        //     //console.log("csv text is:", csvText);
        //     var json: any = this.csvToJSON(String(csvText));
        //     console.log("json:", json);
        //     //now need to pass this json & the userId to the backend
        //     // var organizationId = this.user.organizationId;
            
        //     // this.inventoryService.uploadInv(this.idToken, json, this.selectedLocation.locationId, this.user.organizationId)
        //     // .then(res => {
        //     //     console.log("upload inv rs:", res);
        //     // })
        //     // .catch(err => {
        //     //     console.log("upload inv err:", err);
        //     // })
        //     // console.log("upload inv mods");
        //     this.inventoryService.uploadInvMods(this.idToken, json, this.selectedLocation.locationId, this.user.organizationId)
        //     .then(res => {
        //         console.log("upload inv rs:", res);
        //     })
        //     .catch(err => {
        //         console.log("upload inv err:", err);
        //     })



        //     // this.inventoryService.uploadModifiers(this.idToken, json, this.selectedLocation.locationId, this.user.organizationId)
        //     // .then(res => {
        //     //     console.log("upload mod rs:", res);
        //     // })
        //     // .catch(err => {
        //     //     console.log("upload mod err:", err);
        //     // })

            
        // }
        // reader.onerror = (evt) => {
        //     console.log("error:", evt);
        // }

    }



csvToJSON(csv: string): any{
    var lines=csv.split("\n");
    var result = [];


    var headers=lines[0].split(",");
    console.log("lines:", lines[0]);
    console.log("headers are:", headers);
    headers[headers.length - 1] = headers[headers.length - 1].replace(/(\r\n|\n|\r)/gm,"");



    for(var i=1;i<lines.length;i++){

        var obj = {}
        var currentline=lines[i].split(",");

        for(var j=0;j<headers.length;j++){
            if(currentline[j] != undefined){
                currentline[j] = obj[headers[j]] = currentline[j].replace("�"," ");
                currentline[j] = obj[headers[j]] = currentline[j].replace("\r", "");
            }

        }

        result.push(obj);
    }

    return result;
  }

  //grid stuff

  dragStart(item, $event){
      console.log("item drag start:", item);
      console.log("event:", $event);
      let origPos = this.getPositionByCoords($event.row, $event.col);
      console.log("original position:", origPos);
      let coords = this.getCoordsByPosition(origPos);

  }


  dragEnd(item, $event){
      console.log("item drag end:", item);
      console.log("event:", $event);
      let endPos = this.getPositionByCoords($event.row, $event.col);
      console.log("end position:", endPos);
      let evt = {
          value: endPos
      }
      console.log("passing in evt:", evt);
      this.selectPosition(evt, item.itemId);
  }


  getPositionByCoords(row: number, col: number): number{
      if(row > this.maxRow){
        row = this.maxRow;
      }
      if(col > this.maxCol){
          col = this.maxCol;
      }
      let position = this.numCols*(row - 1) + col;
      return position;
  }


  getCoordsByPosition(position: number, startIndex: 0 | 1 = 0): number[]{
      if (startIndex === 0) position = position + 1;
      if (position === undefined || position === 0) return [1, 1];
      let row = Math.ceil(Number(position || 0)/this.numCols);
      let col = position % 4;
      // console.log("getCoordsByPosition", `row=${row} col=${col} pos=${position}`, "maxRow=", this.maxRow, 'maxCol=', this.maxCol, 'numCols=', this.numCols);
      if(row === 0) row = 1;
      if(col === 0) col = 4;
      if(row > this.maxRow) row = this.maxRow;
      if(col > this.maxCol) col = this.maxCol;

      // console.log("getCoordsByPosition x=", row, 'y=', col);
      return [row,col]
  }



  toggleListView(){
    this.showListView = !this.showListView;
    if (this.tabOptions.indexOf("All") == -1){
      this.tabOptions.push("All");
    }
    // if (!this.showView && this.tabOptions.indexOf("All") >= 0) {
    //   // remove tab
    // }
  }


  // standard modifier stuff

  openStandardModifiers() {
      this.showStandardModifiers = !this.showStandardModifiers;
  }

  openEditStandardModifier(standardModifier: Modifier = null) {
    this.showEditStandardModifier = true;
    if(!standardModifier) {
        this.modifier = new Modifier();
    }else{
        this.modifier = standardModifier;
        this.editStandardModifiers = true;
    }
  }


  listStandardModifiers(){
    this.inventoryService.listStandardModifiers(this.idToken, this.user.organizationId)
    .then( res => {
        console.log("list standard mods res:", res);
        var body = JSON.parse(res._body);
        if(body.status == "200"){
            this.standardModifiers = body.message.Items;
            console.log("standard modifiers:", this.standardModifiers);
        }
    });
  }


  removeStandardModifier(modifier: Modifier) {
      this.dialogsService.confirm("Remove " + modifier.name + "?", "This will remove this modifier from any items it is currently attached to")
        .toPromise()
        .then( res => {
            if(res){
                this.loadingService.toggleLoadingScreen(true);
                this.inventoryService.removeStandardModifier(this.idToken, modifier.modifierId, this.user.organizationId)
                .then( res => {
                    this.loadingService.toggleLoadingScreen(false);
                    let body = JSON.parse(res._body);
                    if(body.status == "200"){
                        this.dialogsService.alert("Success!", "Your standard modifier has been removed!");
                        this.listStandardModifiers();
                        //this.ngOnInit();
                        window.location.reload();
                    }else{
                        this.dialogsService.alert("Processing Error!", "Your standard modifier could not be removed! Please try again later");
                    }
                })
                .catch( err => {
                    this.loadingService.toggleLoadingScreen(false);
                    this.dialogsService.alert("Processing Error!", "Your standard modifier could not be removed! Please try again later");
                });
            }
        });
  }


  // image stuff

  newImage() {
      console.log("New image!");
      let title = "Upload your image";
        this.dialogsService.uploadImage(title, this.item.imageUrl, 100)
        .toPromise()
        .then( res => {
            console.log("Image res:");
            console.log(res);
            if (this.item.itemId) {
                this.item.imageUrl = InventoryImageBucketLong + "/" + this.item.itemId + ".png";
            }
            this.item.imageFile = res;
            let photoKey = encodeURIComponent(this.item.itemId) + ".png";
            this.inventoryService.uploadInventoryImageS3(photoKey, this.item.imageFile)
            .then( res => {
                console.log("UPLOAD SUCCESS");
            })
            .catch( err => {
                console.log("UPLOAD ERR!");
            });
        });
  }

}