import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../services/loading.service";
import { UserService } from '../services/user.service';
import { InventoryService } from '../services/inventory.service';
import { AuthService } from '../services/auth.service';
import { DialogsService } from '../dialog/dialog.service';
import { InventoryPurchaseService } from '../services/inventory-purchase.service';
import { Subscription }   from 'rxjs/Subscription';
import { OrganizationService } from '../services/organization.service';
import { LingoService } from '../services/lingo.service';
import { DateTime } from 'luxon';

export class InventoryPurchaseDetail {
  organizationId?: string;

  createdAt?: number;

  itemId?: string;

  itemName?: string;

  lineno?: number;

  modifierKey?: string;

  notes?: string;

  quantityDelivered?: number;

  quantityPurchased?: number;

  purchaseId?: string;

  unitCost?: number;

  vendorStockCode?: string;
}

@Component({
  selector: 'app-purchase-detail',
  templateUrl: './purchasedetail.component.html',
  styleUrls: ['./purchasedetail.component.scss']
})

export class InventoryPurchaseDetailComponent implements OnInit {

  public idToken: string;
  public organization: any;
  public organizationId: string;
  public lingoSubscription: Subscription;
  public orgLingo: { [id: string]: string } = {};
  public purchaseId: string;
  public inventory: any;
  public inventorySelect = [];
  public inventoryNames = new Map<string, string>();

  public showAddPurchaseDetails: boolean = false;
  public showEditPurchaseDetail: boolean = false;

  public details = [];
  public user: any;
  public purchasedetails = [];
  public sendingInvite: boolean = false;

  public newPurchaseDetail: InventoryPurchaseDetail = {}

  public statuses = [
    {value: "draft" , viewName:"Draft"},
    {value: "ordered", viewName: "Ordered"},
    {value: "partial-receipt", viewName: "Partial Receipt"},
    {value: "complete", viewName: "Complete"},
    {value: "cancelled", viewName: "Cancelled"},
  ]

  public tokenSubscription: Subscription;

  constructor(
    public userService: UserService,
    public dialogsService: DialogsService,
    public purchaseOrderService: InventoryPurchaseService,
    public authService: AuthService,
    public inventoryService: InventoryService,
    public lingoService: LingoService,
    public loadingService: LoadingService,
    public organizationService: OrganizationService,
    public route: ActivatedRoute,
    public router: Router,
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token purchasedetail!");
       /// console.log(token);
        this.idToken = token;
      }
    )
    this.lingoSubscription = lingoService.lingoSubscription$.subscribe(
      response => {
        this.orgLingo = response;
        console.log("org lingo:", response);
      }
    )
  }

  closeCard(){
    this.showAddPurchaseDetails = false;
    this.showEditPurchaseDetail = false;
  }

  closePage() {
    this.router.navigate(['inventory/purchaseorders']);
  }

  loadLingo(){
    this.lingoService.loadLingo(this.idToken, "camp")
    .then(res => {
      console.log("load lingo:", res);
      var body = JSON.parse(res._body);
      body.message.Items.forEach(item => {
        this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
      });
    });
  }

  loadInventory(){
    this.inventoryService.fetchInventory(this.idToken, this.organizationId)
    .then(res => {
      var body = JSON.parse(res._body);
      this.inventory = [];
      this.inventorySelect = [];
      this.inventoryNames = new Map<string, string>();
      for (const item of body.items) {
        this.inventory.push(item);
        //
        if (item.quantityTracking === 'modifier' && item.modifiers !== undefined) {
          for (const modifier of item.modifiers || []) {
            for (const option of modifier.options || []) {
              const key = `${item.itemId || ''}#${option.name || ''}`;
              this.inventorySelect.push({
                value: key,
                name: `${item.name}, ${option.name}`
              })
              this.inventoryNames.set(key, item.name);
            }
          }
        } else {
          const key = `${item.itemId || ''}#`;
          this.inventorySelect.push({
            value: key,
            name: item.name
          })
          this.inventoryNames.set(key, item.name);
        }
      }

      this.inventorySelect.sort((a, b) => {
        if (a === undefined || a.name === undefined) return 1;
        if (b === undefined || b.name === undefined) return -1;
        return a.name > b.name ? 1 : -1
      })

      // console.log("got inventory:", this.inventory);
      // console.log("got inventoryNames:", this.inventoryNames);
    })
  }

  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.organizationId)
    .then(res => {
      var body = JSON.parse(res._body);
      this.organization = body.message.Items[0];
      console.log("got organization:", this.organization);
      console.log("this.organization:", this.organization.organizationName);
    })
  }

  loadUser(): Promise<any> {
    return new Promise((resolve,reject) => {
        this.userService.getUser()
        .then(res => {
            this.idToken = res.idToken;
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    });
  }

  ngOnInit() {
    this.purchaseId = this.route.snapshot.params['purchaseId'];
    this.loadingService.toggleLoadingScreen(true);
    this.loadUser()
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        // console.log("Response", res);
        this.organizationId = res.organizationId;
        this.user = res;
        this.loadOrganization();
        this.loadInventory();
        this.getPurchaseDetails();
        this.loadLingo();
        // console.log(this.user);
        console.log('organizationId', this.organizationId);
      });
  }

  get allPurchaseDetails() {
    // let timeZone = 'America/New_York';
    // if (this.organization && this.organization.timeZone !== undefined) ({ timeZone } = this.organization)

    return this.details.map( purchasedetail => {
      purchasedetail.itemKey = `${purchasedetail.itemId}#${purchasedetail.modifierKey || ''}`
      return purchasedetail
    }).sort((a, b) => {
      if (a === undefined || a.lineno === undefined) return -1
      if (b === undefined || b.lineno === undefined) return 1;
      return (a.lineno > b.lineno) ? 1 : -1;
    });
  }

  getPurchaseDetail(): Promise<InventoryPurchaseDetail>{
    return new Promise( ( resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
    })
  }

  async getPurchaseDetails(): Promise<InventoryPurchaseDetail[]> {
    var organizationid  = this.organizationId;

    if(this.details.length > 0){
      this.details = [];
    }
    this.loadingService.toggleLoadingScreen(true);
    await this.purchaseOrderService.listPurchaseDetails(this.idToken, organizationid, this.purchaseId)
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        // console.log('purchasedetail.getPurchaseDetails', res);
        var body = JSON.parse(res._body)
        var em = body.items;
        console.log('PurchaseDetails: ', em)
        em.forEach(record => {
          if (record.purchaseId === this.purchaseId) {
            this.details.push(record)
          }
        })
      }).catch(err =>{
        console.log("Error getting PurchaseDetails", err);
        this.loadingService.toggleLoadingScreen(false);
      })
    // console.log(this.details);
    return Promise.resolve(this.details);
  }

  insertPurchaseDetail(){
    if ((this.newPurchaseDetail as never as any).itemKey !== undefined) {
      const pieces = (this.newPurchaseDetail as never as any).itemKey.split('#');
      if (pieces.length > 0) this.newPurchaseDetail.itemId = pieces[0] || '';
      if (pieces.length > 1) this.newPurchaseDetail.modifierKey = pieces[1] || '';
      const itemName = this.inventoryNames.get((this.newPurchaseDetail as never as any).itemKey);
      if (itemName !== undefined) this.newPurchaseDetail.itemName = itemName;
    }
    console.log('Insert', this.newPurchaseDetail);
    
    if(!this.validatePurchaseDetail(this.newPurchaseDetail)){
      this.dialogsService.alert("Invalid Entry", "You must enter a value for each field");
      return;
    }

    this.purchaseOrderService.insertPurchaseDetail(this.idToken, this.newPurchaseDetail)
    .then(res => {
      console.log("edit purchasedetail res;", res);
      this.closeCard();
      this.getPurchaseDetails();
    })
  }

  openAddPurchaseDetail(){
    this.showAddPurchaseDetails = !this.showAddPurchaseDetails;
    this.newPurchaseDetail = new InventoryPurchaseDetail();
    this.newPurchaseDetail.organizationId = this.organizationId;
    this.newPurchaseDetail.purchaseId = this.purchaseId;
    this.newPurchaseDetail.lineno = this.details.length;
  }

  openEditPurchaseDetail(purchasedetail: InventoryPurchaseDetail){
    this.showEditPurchaseDetail = !this.showEditPurchaseDetail;
    console.log('Open Edit', purchasedetail);

    this.newPurchaseDetail = new InventoryPurchaseDetail();
    this.newPurchaseDetail.createdAt = purchasedetail.createdAt;
    this.newPurchaseDetail.itemId = purchasedetail.itemId;
    this.newPurchaseDetail.itemName = purchasedetail.itemName;
    this.newPurchaseDetail.lineno = purchasedetail.lineno;
    this.newPurchaseDetail.modifierKey = purchasedetail.modifierKey;
    this.newPurchaseDetail.notes = purchasedetail.notes;
    this.newPurchaseDetail.organizationId = purchasedetail.organizationId;
    this.newPurchaseDetail.quantityDelivered = purchasedetail.quantityDelivered;
    this.newPurchaseDetail.quantityPurchased = purchasedetail.quantityPurchased;
    this.newPurchaseDetail.purchaseId = purchasedetail.purchaseId;
    this.newPurchaseDetail.unitCost = purchasedetail.unitCost;
    this.newPurchaseDetail.vendorStockCode = purchasedetail.vendorStockCode;

    (this.newPurchaseDetail as never as any).itemKey = `${purchasedetail.itemId}#${purchasedetail.modifierKey || ''}`;
  }

  updatePurchaseDetail(){
    this.newPurchaseDetail.organizationId = this.organizationId;
    if ((this.newPurchaseDetail as never as any).itemKey !== undefined) {
      const pieces = (this.newPurchaseDetail as never as any).itemKey.split('#');
      if (pieces.length > 0) this.newPurchaseDetail.itemId = pieces[0] || '';
      if (pieces.length > 1) this.newPurchaseDetail.modifierKey = pieces[1] || '';
      const itemName = this.inventoryNames.get((this.newPurchaseDetail as never as any).itemKey);
      if (itemName !== undefined) this.newPurchaseDetail.itemName = itemName;
    }
    console.log('Update', this.newPurchaseDetail);
    
    if(!this.validatePurchaseDetail(this.newPurchaseDetail)){
      this.dialogsService.alert("Invalid Entry", "You must enter a value for each field");
      return;
    }
    
    this.purchaseOrderService.updatePurchaseDetail(this.idToken, this.newPurchaseDetail)
    .then(res => {
      console.log("edit purchasedetail res;", res);
      this.closeCard();
      this.getPurchaseDetails();
    })
  }

  refreshPurchaseDetails(){
    console.log("Refreshing PurchaseDetails")
    this.loadOrganization();
    this.getPurchaseDetails();
  }

  //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }

  validatePurchaseDetail(purchaseOrder: InventoryPurchaseDetail){
    if (purchaseOrder.itemId === undefined || purchaseOrder.itemId === '') return false;
    console.log("Validate Purchase Order", purchaseOrder);
    return true;
  }

}
