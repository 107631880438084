import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { UserService } from "../services/user.service";
import { OrganizationCampersService } from "../services/organization-campers.service";
import { DialogsService } from '../dialog/dialog.service';
import { SquareService } from '../services/square.service';
import { Subscription }   from 'rxjs/Subscription';
import { AuthService } from '../services/auth.service';
// import { CsvService } from "angular2-json2csv"
import { TransactionsService } from '../services/transactions.service';
import json2csv from "json2csv/lib/json2csv";
import { OrganizationService } from '../services/organization.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';

declare var moment:any;

@Component({
  selector: 'app-organization-campers',
  templateUrl: './organization-campers.component.html',
  styleUrls: ['./organization-campers.component.scss']
})
export class OrganizationCampersComponent implements OnInit {

  public user: any = null;
  public idToken: string;
  public campers = [];
  public originalCampers = [];
  public selectedContribution = null;
  public showAddUser: boolean = false;
  public showEditUser: boolean = false;
  public showManageFunds: boolean = false;
  public showParentInfo: boolean = false;
  public showAddFunds: boolean = false;
  public showSubtractFunds: boolean = false;
  public showCamperTransaction: boolean = false;
  public showCamperFutureFunds: boolean = false;
  public showAllocateFunds: boolean = false;
  public showUnallocateFunds: boolean = false;
  public showTransferFunds: boolean = false;
  public showEditTab: boolean = false;
  public pointOfSaleEnabled: boolean = true;
  public spendingAccountsEnabled: boolean = false;
  public enableBalanceCorrection: boolean = false;
  public manageFundsUser: any = {};
  public parentInfo: any = {};
  public camperInfo: any = {};
  public myContribution = [];
  public userContributions = [];
  public balanceByUser = [];
  public editFundsAmount: number = 0;
  public tokenSubscription: Subscription;
  public showImportCampers: boolean = false;
  public organization= null;
  public camperSessions= [];
  public columns = [];
  public table = [];
  public newArr = [];
  public arrForEdit = [];
  public checked : any;
  public sessionSelect = [];
  public cInfo = [];
  public cTrans = [];
  public displayTransactions = [];
  public selectedUserContributions = [];
  public editFundsNote = "Cash";
  public selectedSessions = {};
  public sessions = [];
  public ipAddress: string = "";
  public editFundsExtraDetails = "";
  public paymentMethods = [
    "Cash",
    "Check"
  ];

  public startSeconds;
  public endSeconds;
  public ascendingSort: boolean = true;


  editUser = {
    firstName: "",
    lastName: "",
    nickname: "",
    pin: "",
    confirmPin: "",
    userId: "",
    street:"",
    city:"",
    state:"",
    zip:"",
    session:"",
    dailySpendingLimit: "",
    isStaff: false,
    imageUrl: null
  }

  status = [
    "All Status",
    "Complete",
    "Low Balance",
    "No Image",
    "No Session",
  ]
  public searchText = "";
  public otherCampers = [];
  public transferTo = null;

  public dateInputs: any = [
      {
          start: moment().subtract(12, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(9, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(4, 'month'),
          end: moment()
      },
      {
          start: moment(),
          end: moment().add(5, 'month'),
      }
  ];
  
  public singleDate: any;
  thisYear = (new Date()).getFullYear();
  start = new Date("1/1/" + this.thisYear);

  public mainInput = {
      start: moment().subtract(90, 'days'), //moment(this.start.valueOf()),
      end: moment((new Date()).valueOf())
  }

  constructor(
    public userService: UserService,
    public organizationCampersService: OrganizationCampersService,
    public dialogsService: DialogsService,
    public squareService: SquareService,
    public loadingService: LoadingService,
    public authService: AuthService,
   // public _csvService: CsvService,
    public transactionsService: TransactionsService,
    public organizationService: OrganizationService,
    public daterangepickerOptions: DaterangepickerConfig
) {

  this.daterangepickerOptions.settings = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
        ranges: {
            'Last Week': [moment().subtract(1, 'week'), moment()],
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 3 Months': [moment().subtract(4, 'month'), moment()],
            'Last 12 Months': [moment().subtract(12, 'month'), moment()],
            'Year to Date': [ moment(this.start.valueOf()), moment()]
        }
    };
    this.singleDate = Date.now();

  this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token org campers!");
       /// console.log(token);
        this.idToken = token;
      }
  )

  this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      console.log("got ip address:", ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ""){
        this.ipAddress = ipAddress;
      }
    })

 }

  ngOnInit() {
    //this.loadingService.toggleLoadingScreen(true);
    this.getUser()
    .then(res => {
      //this.loadingService.toggleLoadingScreen(false);
      this.user = res;
      this.table = this.status;
      if(res != null){
        this.getSessions();
        this.getOrganization();
        this.getCampers();
       
      }
    }).catch(err => {
      //this.loadingService.toggleLoadingScreen(false);

    })
  }

  getUser(): Promise<any>{
    return new Promise( (resolve, reject )=>{
      this.userService.getUser()
        .then(res => {
          console.log("Response: " , res)
          this.idToken = res.idToken;
          resolve(res)
        }).catch(err => {
          console.log("Error Getting users", err);
          reject(err);
      })
    })
  }


  public openManageTab() {
    console.log("Open manage tab");
    this.showEditTab = true;
    this.editFundsAmount = this.manageFundsUser.tabLimit || 0;
  }

  public async reverseDonation(donation) {
    console.log("Reverse donation:", donation);
    let confirmRes = await this.dialogsService.confirm("Reverse Donation?", "This will push the money back on the account and create a donation reversal.").toPromise();
    if (confirmRes) {
      try {
        this.loadingService.toggleLoadingScreen(true);
        const { authUserId, timeStamp, organizationId, transactionId } = donation;
        const res = await this.transactionsService.reverseDonation(authUserId, timeStamp, organizationId, transactionId);
        console.log("res:", res);
        this.loadingService.toggleLoadingScreen(false);
        this.dialogsService.alert("Success", "The donation has been reversed!");
        this.getCamperTransaction(this.cInfo);
      } catch (err) {
        console.log("Err:", err);
        this.loadingService.toggleLoadingScreen(false);
        this.dialogsService.alert("Processing Error", "An error occurred while attempting to reverse the deposit");
      }
    }
  }

  public async submitUpdateTab() {
    console.log("Submit update tab");
    // validate the amount is a number and it's greater than 0
    if (isNaN(this.editFundsAmount)) {
      return this.dialogsService.alert("Invalid amount", "You must enter a valid number");
    }
    if (this.editFundsAmount < 0) {
      return this.dialogsService.alert("Invalid amount", "You must enter a positive number");
    }

    const currentTab = this.manageFundsUser.tabLimit || 0;
    const diff = this.editFundsAmount - currentTab;
    if (diff === 0) {
      return this.dialogsService.alert("Balance not updated", `${this.manageFundsUser.firstName}'s tab is already ${this.editFundsAmount}`);
    }
    let message = "";
    if (diff > 0) {
      message = `Setting ${this.manageFundsUser.firstName}'s tab limit to ${this.editFundsAmount}
        will create a deposit into their account of ${diff}. Proceed?`; 
    } else {
      message = `Lowering ${this.manageFundsUser.firstName}'s tab limit to ${this.editFundsAmount}
        will result in withdrawing ${Math.abs(diff)} from their account. Proceed?`; 
    }

    const confirmRes = await this.dialogsService.confirm("Set Tab Limit?", message).toPromise();
    if (confirmRes) {
      this.loadingService.toggleLoadingScreen(true);
      try {
        const tabLimitRes = await this.organizationService.setTabLimit(this.manageFundsUser.userId, this.organization.organizationId, this.editFundsAmount,
          this.ipAddress, this.user.email, this.user.userId);
        this.editFundsAmount = 0;
        this.toggleManageFunds(null);
        this.getCampers();
        this.closeCard();
        this.loadingService.toggleLoadingScreen(false);
        this.dialogsService.alert("Success!", "The tab has been updated!");
      } catch (err) {
        this.loadingService.toggleLoadingScreen(false);
        console.log("An error occurred:", err);
        let errorMessage = "There was an error setting the tab";
        try {
          const body = JSON.parse(err._body);
          if (body.code && body.code.toString() !== "{}") errorMessage = body.code;
        } catch (err) { console.log("Error parsing error code:", err);}
        this.dialogsService.alert("Processing Error", errorMessage);
      }
    }
  }

  public async depositAllocationNow(allocation) {
    console.log("Deposit allocation:", allocation);
    try {
      this.loadingService.toggleLoadingScreen(true);
      const depositNowRes = await this.organizationCampersService.depositAllocationNow(this.user.organizationId, this.user.email, this.user.userId, allocation.allocationId)
      console.log("Deposit now res:", depositNowRes);
      this.dialogsService.alert("Success!", "The funds have been allocated");
      this.getCampers();
      this.fetchFutureFunds();
      this.closeCard();
      this.loadingService.toggleLoadingScreen(false);
    } catch (err) {
      console.log("Err:", err);
      this.loadingService.toggleLoadingScreen(false);
      let message = "";
      if (err._body) {
        try {
          const code = JSON.parse(err._body).code;
          message = `: ${code}`;
        } catch (err) {}
      }
      this.dialogsService.alert("Processing Error!", "There was an error Transferring funds" + message);
    }


  }

  public submitTransfer() {
    if (isNaN(this.editFundsAmount)) {
      return this.dialogsService.alert("Invalid amount", "You must enter a valid number");
    }
    if (this.editFundsAmount < 0) {
      return this.dialogsService.alert("Invalid amount", "You must enter a positive number");
    }
    this.loadingService.toggleLoadingScreen(true);
    this.squareService.transferFunds(this.idToken, this.user.organizationId, this.transferTo.userId, this.manageFundsUser.userId, this.selectedContribution.contributorId, this.editFundsAmount, this.user.userId)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      const body = JSON.parse(res._body)
      this.selectedContribution = null;
      this.showTransferFunds = false;
      this.editFundsAmount = 0;
      this.toggleManageFunds(null);
      this.getCampers();
      this.closeCard();
      this.dialogsService.alert("Success!", "The funds have been transferred!");
   })
   .catch(err => {
      console.log("Err:", err);
      this.loadingService.toggleLoadingScreen(false);
      let message = "";
      if (err._body) {
        try {
          const code = JSON.parse(err._body).code;
          message = `: ${code}`;
        } catch (err) {}
      }
      this.dialogsService.alert("Processing Error!", "There was an error Transferring funds" + message);
   });


  }

  public openTransferFunds() {
    this.showTransferFunds = true;
    this.selectedContribution = this.selectedUserContributions[0];
    this.transferTo = this.otherCampers[0];
  }

  async fetchFutureFunds() {
    try {
      let futureFunds = await this.squareService.fetchFutureFunds(this.idToken, this.user.userId, this.user.organizationId);
      console.log("Got future funds:", futureFunds.length);

      this.campers.forEach( camper => {
        let allAllocs = futureFunds.filter( fund => fund.authUserId === camper.userId);
        let camperAllocs = allAllocs.filter(fund => fund.status === 'pending');
        camper.allAllocs = allAllocs;
        camper.futureFundsTotal = camperAllocs.reduce( (total, alloc) => (total += alloc.amount), 0)
      })



    } catch (err) {
      console.log("Error getting future funds!", err);
    }
  }

  

  //need to retain filters here
  getCampers(){
    var organizationId = this.user.organizationId;
    var userId = this.user.userId;

    console.log("Camper Org Id: " , organizationId);

    if (this.user.role == 'account-contributor') {
      userId = this.user.accountHolder || this.user.accountHolderId;
    }

    if(this.campers.length > 0){
      this.campers.forEach(camp => {
        this.balanceByUser[camp.userId] = 0;
      })
    }
    this.loadingService.toggleLoadingScreen(true);
    this.organizationCampersService.getAllCampers(organizationId)
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        this.fetchFutureFunds();
        var body = JSON.parse(res._body)
        //sort alphabetically by last name
        console.log("before sort:", body.items);
        this.campers = body.items.sort( (a,b) => {
          if(a.lastName > b.lastName){
            return 1;
          }else if(b.lastName > a.lastName){
            return -1;
          }else{
            return 0;
          }
        });
        console.log("campers after:", this.campers);
        this.campers.forEach( row => {
          var status = [];
          if(!row.currentBalance || row.currentBalance < .01){
            status.push("Low Balance");
          }
          if(!row.session){
            status.push("No Session");
          }
          if(!row.imageUrl){
            status.push("No Image");
          }
          if(row.imageUrl && row.session && row.currentBalance > 0){
            status.push("Complete");
          }
          row.status = status;

          
        })
        this.originalCampers = this.campers;
        console.log("This is: " , this.campers)
         if(this.selectedSessions.hasOwnProperty('value')){
           this.filterSessions(this.selectedSessions);
         }
        
        if(this.searchText != ""){
          this.updateFilter(this.searchText);
        }
      }).catch(err => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Error getting all Organization Campers", err);
    })
  }


  openEditUser(user){
    // console.log("row", user);
    this.editUser.firstName = user.firstName;
    this.editUser.lastName = user.lastName;
    this.editUser.userId = user.userId;
    this.editUser.dailySpendingLimit = user.dailySpendingLimit;
    this.editUser.pin = user.password;
    this.editUser.nickname = user.nickname;
    this.editUser.street = user.street;
    this.editUser.city = user.city;
    this.editUser.state = user.state;
    this.editUser.zip = user.zip;
    this.editUser.session = user.session;
    this.editUser.isStaff = user.isStaff;
    this.editUser.imageUrl = user.imageUrl;
    this.showEditUser = !this.showEditUser;
    this.showAddUser = false;
  }

  closeCard(){
    this.showAddUser = false;
    this.showEditUser = false;
    this.showManageFunds = false;
    this.showParentInfo = false;
    this.showCamperTransaction = false;
    this.showCamperFutureFunds = false;
    this.selectedContribution = null;
    this.showTransferFunds = false;
    this.otherCampers = [];
    this.transferTo = null;
    this.editFundsAmount = 0;
    this.showEditTab = false;
    this.displayTransactions = [];
  }

  closeDepositWithdrawCard(){
    this.showAddFunds = false;
    this.showSubtractFunds = false;
    this.selectedContribution = null;
  }

  submitEdit(){
    console.log("submit edit");
    var isValid: boolean = this.validateUser(this.editUser);
    if(!isValid){
      console.log("it is not valid!!!");
      return;
    }
    this.userService.updateUser(this.idToken, this.editUser, this.user.userId)
      .then(res => {
        // console.log("Organization-Campers update response:" , res);
        var body = JSON.parse(res._body);
        if(body.status == "200"){
          this.showEditUser = false;
          this.uploadImage(this.editUser.userId)
            .then(res => {
              console.log("upload image res:");
              console.log(res);
              if(res.hasOwnProperty('Location')){
                var imageUrl = res.Location;
                console.log("Upload to dynamo!");
                this.userService.uploadUserImageDynamo(this.idToken, imageUrl, this.editUser.userId, this.user.organizationId)
                  .then(res => {
                    // console.log("Organization-Camper uploaded image to DynamoDb:" , res)
                    this.showEditUser = false;
                    console.log("upload to dynamores:");
                    console.log(res);
                    this.getCampers();
                  }).catch(err => {
                    console.log("Organization-Camper User to DynamoDB failed:" , err);
                })
              }
              else{
                //There were no image to upload
                //update the users
                this.getCampers();

              }
              this.editUser = {
                  firstName: "",
                  lastName: "",
                  nickname: "",
                  pin: "",
                  confirmPin: "",
                  userId: "",
                  street:"",
                  city:"",
                  state:"",
                  zip:"",
                  session:"",
                  dailySpendingLimit: "",
                  isStaff: false,
                  imageUrl: null
                }
            }).catch(err => {
              console.log("Organization-Camper Upload Image Error", err)
          })
        }
      }).catch(err => {
        console.log("Organization-Camper update user error", err);
    })
  }

  validateUser(user: any): boolean {
    console.log("Validating Organization-Camper user:", user);
    if(user.firstName == "" || user.lastName == ""){
      this.dialogsService.alert("Processing Error", "You must enter a First and Last name");
      return false;
    }
    // var files = document.getElementById('photoupload')['files'];
    // console.log("user is:", user);
    // console.log(this.organization.camperPhotosRequired);
    // if(!files.length && this.organization.camperPhotosRequired && !user.imageUrl){
    //   this.dialogsService.alert("Photo Required!", "You must add a photo to this camper!");
    //   return false;  
    // }

    return true;
  }

  uploadImage(userId): Promise<any>{
    var files = document.getElementById('photoupload')['files'];
    console.log("Organization-Camper Upload: ", files);
    if(!files.length){
      console.log("!FILES.LENGTH");
      return Promise.resolve(true);
    }
    var file = files[0];
    var photoKey = encodeURIComponent(userId);
    console.log("file is:", file);
    console.log("photo key is:", photoKey);
    return this.userService.uploadUserImageS3(photoKey,file)
  }

  async toggleManageFunds(user){
    this.showManageFunds = !this.showManageFunds;
    this.manageFundsUser = user;
    if(user != null && user.userId != undefined){
      this.getUserContributions(user);
      if (user.accountHolderId) {
        try {
          let res = (await this.userService.getAuthorizedUsers(this.idToken, user.accountHolderId))
          console.log("Other campers res:", res);
          this.otherCampers = JSON.parse(res._body).message.Items.filter( camper => camper.userId !== user.userId);
          console.log("Other campers:", this.otherCampers);
        } catch (err) {
          console.log("Caught error:", err);
        }
      }
    }
  }


  getUserContributions(user){
      this.organizationCampersService.getCampersBalances(this.idToken,[user.userId])
      .then(res => {
        const body = JSON.parse(res._body);
        this.selectedUserContributions = body.message.Items;
        console.log("user contributions:", this.selectedUserContributions);
      })
  }

  toggleParentInfo(user){
    this.showParentInfo = !this.showParentInfo;
    this.camperInfo = user;
    this.getParentInfo();
  }


  submitAllocateFunds(){
      var contributorName = "Main Account";
      var relationship = "main";
      var userRole = "account-holder";
      var timeZone = -4;
      if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
        timeZone = this.organization.timeZoneOffset;
      }
      this.squareService.fundsTransferToAuthUser(this.idToken, this.parentInfo.email, this.editFundsAmount, this.manageFundsUser.userId, this.parentInfo.userId,
        userRole, contributorName, relationship, this.manageFundsUser.firstName,this.manageFundsUser.lastName,this.manageFundsUser.accountHolder || this.manageFundsUser.accountHolderId,this.manageFundsUser.organizationId,
        this.user.userId, this.user.email, this.ipAddress, timeZone
      )
      .then(res => {
        var body = JSON.parse(res._body);
        if (body.code == "OK" || body.status == "200" || body.message.statusCode == "200") {
          this.showAllocateFunds = false;
          this.showUnallocateFunds = false;
          this.editFundsAmount = 0;
          this.toggleManageFunds(null);
          this.getCampers();
          this.dialogsService.alert("Success!", "The funds have been allocated!");
        } else {
          this.dialogsService.alert("Processing Error", "There was an error transferring your funds");
        }
      })
      .catch(err => {
        console.log("FTAU err:", err);
        this.dialogsService.alert("Processing Error", "There was an error transferring your funds");
      })


  }


  submitAddFunds(){
    console.log("Add Funds:" , this.editFundsAmount);
    var contributorName = "Main Account";
    var relationship = "main";
    var userRole = "account-holder";
    console.log(this.user)
    console.log(this.manageFundsUser)
    if(this.editFundsExtraDetails != "" && this.editFundsExtraDetails != null){
      this.editFundsNote = this.editFundsNote + " - " + this.editFundsExtraDetails;
    }
    this.loadingService.toggleLoadingScreen(true);
    this.squareService.fundsToTransfer(this.idToken,this.editFundsAmount,this.manageFundsUser.userId, this.manageFundsUser.accountHolder || this.manageFundsUser.accountHolderId, userRole,contributorName,relationship, this.manageFundsUser.firstName,
    this.manageFundsUser.lastName, this.manageFundsUser.accountHolder || this.manageFundsUser.accountHolderId,this.manageFundsUser.organizationId,
    this.user.userId, this.user.email, this.editFundsNote, this.ipAddress)
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        var body = JSON.parse(res._body)
        console.log("Submit Add FUnds:" , body)
        if(body.code === "COMPLETE" || body.code === "OK" || body.status === "200" || body.message.statusCode == "200"){
          this.showAddFunds = false;
          this.editFundsAmount = 0;
          this.editFundsExtraDetails = "";
          this.editFundsNote = "Cash";
          this.dialogsService.alert("Success!", "Your funds have been transferred");
          this.toggleManageFunds(null);
        }
        else {
          this.dialogsService.alert("Processing Error!", "There was an error transferring funds");
        }
        this.getCampers();
      }).catch(err => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Error :" ,err);
        let errorMessage = "There was an eror processing the deposit. Please reload the page";
        try {
          let body = JSON.parse(err._body);
          if (body.code) errorMessage = body.code;
        } catch(error) {}
        this.dialogsService.alert("Processing Error!", errorMessage);
    })
  }

  openEditFunds(bool: boolean) {
    if (bool) {
      this.showAddFunds = true;
      this.showSubtractFunds = false;
    } else {
      this.showSubtractFunds = true;
      this.showAddFunds = false;
    }
    this.selectedContribution = this.selectedUserContributions[0];
    console.log("selected contrib is:", this.selectedContribution);
  }


  openAllocateFunds(){
    this.showAllocateFunds = true;
    this.organizationCampersService.getCampersParentInfo(this.idToken, this.manageFundsUser.accountHolder || this.manageFundsUser.accountHolderId, this.manageFundsUser.organizationId)
    .then(res => {
      // console.log('parent.body', res);
      var body = JSON.parse(res._body);
      var message = body.item
      this.parentInfo = message;

      console.log("parent info is:");

      console.log(this.parentInfo);
    })
  }

  openUnallocateFunds(){
    this.showUnallocateFunds = true;
    this.selectedContribution = this.selectedUserContributions[0];
  }

  closeAllocateCard(){
    this.showAllocateFunds = false;
    this.showUnallocateFunds = false;
  }

  updateContributionSelected(obj){
    console.log("invoked update:");
    console.log(obj.target.value);
    this.selectedUserContributions.forEach( contrib => {
      if(contrib.contributorId == obj.target.value){
        this.selectedContribution = contrib;
      }
    })
    console.log("sel contrib:", this.selectedContribution);
    console.log("selected contribution:", obj);
  }

  submitUnallocateFunds(){
    console.log("contributions:");
    console.log(this.userContributions[this.manageFundsUser.userId])
    if (isNaN(this.editFundsAmount)) {
      return this.dialogsService.alert("Invalid amount", "You must enter a valid number");
    }
    if (this.editFundsAmount < 0) {
      return this.dialogsService.alert("Invalid amount", "You must enter a positive number");
    }
    console.log("selected contrib:");
    console.log(this.manageFundsUser);
    console.log(this.selectedContribution);
    let contributorName = "Main Account";
    let relationship = "main";
    let userRole = "account-holder";
    // let contributorId = this.manageFundsUser.accountHolder || this.manageFundsUser.accountHolderId;
    if (this.selectedContribution && this.selectedContribution.authUserId === this.manageFundsUser.userId && this.selectedContribution.authUserId) {
      console.log("there is a contrib!");
      relationship = this.selectedContribution.relationship;
      userRole = "account-contributor";
      // contributorId = this.selectedContribution.contributorId;
    }
    if(this.userContributions[this.manageFundsUser.userId] != null && this.userContributions[this.manageFundsUser.userId].length > 0){
      console.log("in here!");
      if(this.selectedContribution == null){
        this.dialogsService.alert("Invalid Request", "Please specify an account to unallocate from");
        return;
      }else{
        contributorName = this.selectedContribution.contributorName;
        relationship = this.selectedContribution.relationship;
        userRole = this.selectedContribution.contributorRole;
        // contributorId = this.selectedContribution.contributorId;
      }
    }

    if(this.editFundsAmount <= this.selectedContribution.currentBalance){
      this.loadingService.toggleLoadingScreen(true);
      this.squareService.fundsTransferFromAuthUser(this.idToken, null, this.editFundsAmount, this.manageFundsUser.userId,
         this.selectedContribution.contributorId, this.manageFundsUser.firstName, this.manageFundsUser.lastName, this.manageFundsUser.accountHolder, 
         this.user.organizationId, this.user.userId, this.user.email, this.ipAddress)
         .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            const body = JSON.parse(res._body)
            console.log("Withdrawing funds:" ,body)
            if(body.code === "OK"){
              this.selectedContribution = null;
              this.showUnallocateFunds = false;
              this.editFundsAmount = 0;
              this.toggleManageFunds(null);
              this.getCampers();
              this.dialogsService.alert("Success!", "The funds have been unallocated");
            } else{
              this.dialogsService.alert("Processing Error!", "There was an error Transferring funds: " + body.message);
            }
         })
         .catch(err => {
            this.loadingService.toggleLoadingScreen(false);
            this.dialogsService.alert("Processing Error!", "There was an error Transferring funds: " + err);
         });
    } else {
      this.dialogsService.alert("Invalid Amount", "You cannot unallocate more than is in this account");
    }

  }


  submitWithdrawFunds(){
    console.log("contributions:");
    console.log(this.userContributions[this.manageFundsUser.userId]);
    if (isNaN(this.editFundsAmount)) {
      return this.dialogsService.alert("Invalid amount", "You must enter a valid number");
    }
    if (this.editFundsAmount < 0) {
      return this.dialogsService.alert("Invalid amount", "You must enter a positive number");
    }
    console.log("selected contrib:");
    console.log(this.selectedContribution);
    var contributorName = "Main Account";
    var relationship = "main";
    var userRole = "account-holder";
    var contributorId = this.manageFundsUser.accountHolder;
    if (this.selectedContribution && this.selectedContribution.authUserId === this.manageFundsUser.userId && this.selectedContribution.authUserId) {
      console.log("there is a contrib!");
      relationship = this.selectedContribution.relationship;
      userRole = "account-contributor";
      contributorId = this.selectedContribution.contributorId;
    }
    if(this.userContributions[this.manageFundsUser.userId] != null && this.userContributions[this.manageFundsUser.userId].length > 0){
      console.log("in here!");
      if(this.selectedContribution == null){
        this.dialogsService.alert("Invalid Request", "Please specify an account to withdraw from");
        return;
      }else{
        contributorName = this.selectedContribution.contributorName;
        relationship = this.selectedContribution.relationship;
        userRole = this.selectedContribution.contributorRole;
        contributorId = this.selectedContribution.contributorId;

      }
    }

    if( this.editFundsAmount <= this.selectedContribution.currentBalance){
      var amt = (this.editFundsAmount * -1);
      this.loadingService.toggleLoadingScreen(true);
      this.squareService.fundsToTransfer(this.idToken,amt,this.manageFundsUser.userId,contributorId, userRole,contributorName,relationship,this.manageFundsUser.firstName, this.manageFundsUser.lastName,this.manageFundsUser.accountHolder, this.manageFundsUser.organizationId,
      this.user.userId, this.user.email, this.editFundsNote, this.ipAddress, this.enableBalanceCorrection)
      .then(res =>{
        this.loadingService.toggleLoadingScreen(false);
        this.selectedContribution = null;
        var body = JSON.parse(res._body)
        console.log("Withdrawing funds:" ,body)
        if(body.code === "OK" || body.status === "200" || body.message.statusCode == "200"){
          this.enableBalanceCorrection = false;
          this.showSubtractFunds = false;
          this.editFundsAmount =0;
          this.editFundsNote = "";
          this.dialogsService.alert("Success!", "Your funds have been transferred");
          this.userContributions = [];
          this.toggleManageFunds(null);
          this.getCampers();
        }
        else{
          this.dialogsService.alert("Processing Error!", "There was an error Transferring funds");
        }
      }).catch(err => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Error:" , err);
        if(err.status == "415"){
          //tried to withdraw more than possible
          this.dialogsService.alert("Processing Error!", "You cannot withdraw more than is available in an account!");
        }
      })
    }else{
      this.dialogsService.alert("Invalid Amount", "You cannot withdraw more than is in this account");
    }
  }


  updatedPaymentMethod(event){
    console.log("update payment method");
    console.log(event);
    this.editFundsNote = event;//event.target.value;
  }

  updateFilter(searchText){
    //console.log(searchText)
    const val = searchText; //event.target.value;
    //console.log(val);
    if(searchText == ""){
      this.campers = this.originalCampers;
      return;
    }
    const temp = this.originalCampers.filter(function (d) {
      if(!d.firstName || !d.lastName) {
        return false;
      }
      if( d.firstName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || d.lastName.toLowerCase().indexOf(val.toLowerCase()) !== -1){
        return true;
      }
      if(!val){
        return true;
      }
      if(d.session != undefined && d.session.includes(val.toLowerCase())){
        return true;
      }
      return false;
    })
    this.campers = temp;

  }

   //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }

  inputChange(event){
    console.log("input change:", event);
  }


  uploadCampersCSV(){
    //get the file
    console.log("upload csv!");
    var files = document.getElementById('campersimport')['files'];
    console.log("files is:", files);
    if (!files.length) {
      console.log("no files");
      //now make it fail if the user doesn't already have an image
      console.log("edit user:", this.editUser);
    }

    var file = files[0];

    console.log("file is:", file);

    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (evt) => {
        console.log("evt:", evt);
        var csvText: any = evt.target['result'];
        //console.log("csv text is:", csvText);
        var json: any = this.csvToJSON(csvText);
        console.log("json:", json);
        //now need to pass this json & the userId to the backend
        var organizationId = this.user.organizationId;
        this.dialogsService.alert("Importing data", "This may take a couple minutes");
        this.loadingService.toggleLoadingScreen(true);
        var startIndex = 0;
        var endIndex = 20;

       // do{
          this.organizationCampersService.importCampersWithBalances(this.idToken, json.slice(0,15), organizationId)
          .then(res => {
            console.log("res:", res);
          })
          .catch(err => {
            console.log("caught err:", err);
          });
          // this.organizationCampersService.importCampersCSV(this.idToken, json.slice(startIndex,endIndex), organizationId)
          // .then(res => {
          //   console.log("import campers res:", res);
          //   this.loadingService.toggleLoadingScreen(false);
          //   this.dialogsService.alert("Upload complete", "The upload has terminated");
          //   window.location.reload();
          // }).catch(err => {
          //   console.log("err:", err);
          //   this.loadingService.toggleLoadingScreen(false);
          //   this.dialogsService.alert("Upload complete", "The upload has terminated");
          //   window.location.reload();
          // })
          endIndex+=20;
          if(endIndex > json.length){
            endIndex = json.length;
          }
          startIndex+=20;
      //  }while(endIndex < json.length);


    }
    reader.onerror = (evt) => {
        console.log("error:", evt);
    }

  }


  csvToJSON(csv: string): any{
    var lines=csv.split("\n");

          var result = [];


          var headers=lines[0].split(",");
          console.log("lines:", lines[0]);
          console.log("headers are:", headers);
          headers[headers.length - 1] = headers[headers.length - 1].replace(/(\r\n|\n|\r)/gm,"");



          for(var i=1;i<lines.length;i++){

              var obj = {}
              var currentline=lines[i].split(",");

              for(var j=0;j<headers.length;j++){
                  if(currentline[j] != undefined){
                      currentline[j] = obj[headers[j]] = currentline[j].replace("�"," ");
                      currentline[j] = obj[headers[j]] = currentline[j].replace("\r", "");
                  }

              }

              result.push(obj);
          }

          return result;
  }


  openImportCampers(){
    this.showImportCampers = !this.showImportCampers;
  }
  getParentInfo(){
    console.log(this.camperInfo);
    this.organizationCampersService.getCampersParentInfo(this.idToken, this.camperInfo.accountHolderId, this.camperInfo.organizationId)
    .then(res => {
      // console.log('parent.body', res);
      var body = JSON.parse(res._body);
      var message = body.item
      this.parentInfo = message;
      if(this.parentInfo != undefined){
        this.parentInfo.none = 0;
      }

      console.log(this.parentInfo);
    })
  }

  getOrganization(){
    this.userService.getOrgInfo(this.user.organizationId)
    .then(res => {
      var response = JSON.stringify(res);
      var body = JSON.parse(response);
      var data = body._body;
      var dataItem = JSON.parse(data);
      var newData = dataItem.message.Items[0];
      this.organization = newData;
      if (this.organization && this.organization.features  && this.organization.features.pointOfSale)  {
        this.pointOfSaleEnabled = true;
      }
      if (this.organization && this.organization.features  && this.organization.features.spendingAccounts)  {
        this.spendingAccountsEnabled = true;
      }
      this.organization.camperPhotosRequired = (this.organization.camperPhotosRequired != undefined)? this.organization.camperPhotosRequired : true;
      this.paymentMethods = [
        "Cash",
        "Check"
      ];
      // if(this.organization.acceptedPaymentMethods != undefined){
      //   this.organization.acceptedPaymentMethods.forEach( paymentMethod => {
      //     this.paymentMethods.push("Credit - " + paymentMethod);
      //   });
      // }else{
      //   this.paymentMethods.push("Credit");
      // }
      this.paymentMethods.push("Other");
      console.log(this.organization)
      this.organizationService.getOrganizationSessions(this.idToken, this.user.organizationId)
      .then(res => {
        console.log("get org sessions res:", res);
        var body = JSON.parse(res._body);
        this.sessions = [];
        //this.sessions = body.message.Items;
        this.sessions[0] = "All Sessions";
        body.message.Items = body.message.Items.sort( function(x, y){
          if(x.sessionString > y.sessionString){
            return 1;
          }else{
            return -1;
          }
        })

        body.message.Items.forEach( (session) => {
          this.sessions.push(session.sessionString);
        });
        console.log("sessions:", this.sessions);
      })
    })
  }

  getSessions(){
    this.organizationCampersService.getAllCampers(this.user.organizationId)
      .then(res => {
        var body = JSON.parse(res._body)
        var camper = body.items;
        this.campers = camper;
        this.originalCampers = this.campers;
        console.log("This is: " , this.campers)

        this.campers.forEach(user =>{
          if(user.session != undefined){
            this.camperSessions.push(user.session);
          };

        });
        var newArr = [];
        var editArr = [];
        
        newArr[0] = "All Sessions";
        editArr[0] = " ";
        var count = 0;
        for(var i = 0; i< this.camperSessions.length; i++){
          for (var j = i+1; j<this.camperSessions.length; j++){
            if(this.camperSessions[i] == this.camperSessions[j]){
              count +=1;
            }
          }
          if(count <1){
            if(this.camperSessions[i] != " "){
              newArr.push(this.camperSessions[i]);
              editArr.push(this.camperSessions[i]);
            }
          }
          count = 0;
        }
        this.newArr = newArr;
        this.arrForEdit = editArr;
        this.columns = newArr;
        // console.log("new arr:");
        // console.log(this.newArr);
        // console.log('EditArr', editArr);
      })
    }

    filterSessions(e){
      console.log(e.value);
      this.selectedSessions = e;
      if(e.value.length > 1){
        this.toggle1(e.value);
      }
      else if(e.value == "All Sessions"){
          this.campers = this.originalCampers;
      }
      else if(e.value.length == 1){
        this.toggle(e.value[0]);

      }
      else if (e.value.length == 0){
        this.campers = this.originalCampers;
      }
    }

    toggle1(col){
      var temp = [];
      col.forEach(val =>{
        const isChecked = this.isChecked(val);
        if(isChecked){
            const t = this.originalCampers.filter(function (k){
              // console.log(k.session);
              if(k.session != undefined && k.session.includes(val)){
                // return k.session !== val.session;
                return true;
              }
            })
            temp.push(t);
        }
      })
      var i = [];
      temp.forEach(k => {
        console.log(k);
        if(k.length > 1){
          k.forEach(j => {
              console.log("MOre: ",j)
              i.push(j)
          });
        }
        else if(k.length == 1){
          i.push(k[0]);
        }
      })
      this.campers = i;

    }



    toggle(col){
      // console.log(col);
      const val = col;
      console.log("Val when they check the box",val);
      const isChecked = this.isChecked(col);
      // console.log("What was checked " ,isChecked);
      // console.log("Checking value in toggle",this.checked);

     // if(isChecked){
        console.log("In here:", val);
       try {
          const t = this.originalCampers.filter(function (k){
            // console.log(k.session);
            if(k.session != undefined && k.session.indexOf(val) !== -1){
              // return k.session !== val.session;
              return true;
            }
          })
          this.campers = t;
       } catch( err) {
         console.log("filter error:", err);
         this.campers = [];
       }
      // } else {
      //   console.log("not in here...");
      //   this.campers = [];
      // }
    }

    closeParentCard(){
      console.log("close parent card!");
      this.selectedContribution = null;
      this.showParentInfo = false;
    }


    isChecked(col){

      return this.columns.find(j =>{
        return j === col;
      })
    }
    isCheckedStatus(col){

      return this.table.find(j =>{
        return j === col;
      })
    }
    refreshCampers(){
      console.log("Refreshing campers");
      this.getSessions()
      this.getOrganization()
      this.getCampers()
    }

    removeCamper(camperInfo){
      console.log(camperInfo);
      this.dialogsService.confirm("Remove " + camperInfo.firstName + " ?" , "There will by no way to undo this.")
      .toPromise()
      .then(res =>{
        if(res){
          this.organizationCampersService.removeCamper(this.idToken,camperInfo.userId)
          .then(res => {
            console.log("Camper removed: ", res)
            this.getCampers();
          }).catch(err =>{
            console.log("Error removing camper: ", err);
          })
        }
      })
    }

  
  //sortAlphabetically
  sortAlphabetically(sortBy = 'last'){
    this.ascendingSort = !this.ascendingSort;
    console.log("sort alphabetically");
    if(this.ascendingSort){
      console.log("ascending sort!");
      this.campers = this.campers.sort( (a,b) => {
        if(sortBy === 'last'){
          if(a.lastName > b.lastName){
            return 1;
          }else if(b.lastName > a.lastName){
            return -1;
          }else{
            return 0;
          }
        }else{
          if(a.firstName > b.firstName){
            return 1;
          }else if(b.firstName > a.firstName){
            return -1;
          }else{
            return 0;
          }
        }
      });
    }else{
      console.log("descending sort!");
      this.campers = this.campers.sort( (a,b) => {
        if(sortBy === 'last'){
          if(a.lastName > b.lastName){
            return -1;
          }else if(b.lastName > a.lastName){
            return 1;
          }else{
            return 0;
          }
        }else{
          if(a.firstName > b.firstName){
            return -1;
          }else if(b.firstName > a.firstName){
            return 1;
          }else{
            return 0;
          }
        }
      });
    }
    console.log("this.campers...:", this.campers);
    this.campers = [...this.campers];
  }

  selectStatus(e){
    console.log("status filtering!");
    var status = e.target.value;
    if(status == 'All Status'){
      this.campers = this.originalCampers;
    }else{
      this.campers = this.originalCampers.filter(
        camper => {
          if(camper.status != undefined && camper.status.indexOf(status) != -1){
            return true;
          }else{
            return false;
          }
        }
      )
    }



  }
  

  
  downloadCSV(){
    console.log("downloading")
    // this._csvService.download(this.campers,"Campers");
  }

  openCamperTransaction(camper){
    console.log(camper);
    this.cInfo = camper;
    this.showCamperTransaction = !this.showCamperTransaction;
    this.getCamperTransaction(camper);
  }

  getCamperTransaction(camper){
    this.loadingService.toggleLoadingScreen(true);
    this.organizationCampersService.getCamperTransaction(this.idToken, camper)
    .then(res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log(body);
      var body = JSON.parse(res._body);
      var message = body.message.Items;
      console.log(message);
      this.cTrans = message.reverse();
      this.displayTransactions = this.cTrans;
      console.log(this.mainInput.start);
      console.log("Main input:", this.mainInput);
      let endSeconds = this.mainInput.end._d.getTime()/1000;
      let nowSeconds = Date.now() / 1000;
      // update the end time if it's w/in last few hours
      if (nowSeconds - endSeconds < 6*60*60) endSeconds = nowSeconds;
      this.filterTransactions(this.mainInput.start._d.getTime()/1000, endSeconds);
      this.displayTransactions.forEach(trans => {
        if (trans.timeStamp) {
          let date = new Date(trans.timeStamp*1000);
          let dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          trans.deviceDate = dateString;
        }
      })
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }

  voidTransaction(transaction){
    console.log("void transaction:", transaction);
    this.dialogsService.confirm("Void this transaction?", "This will transfer the funds back to the user's account")
    .toPromise()
    .then( res => {
      this.loadingService.toggleLoadingScreen(true);
      if(res){
        this.transactionsService.voidTransaction(this.idToken, transaction.transactionId, transaction.groupId)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          console.log("void transaction res:", res);
          //var body = JSON.parse(res._body);
          if(res.status == "200"){
            this.dialogsService.alert("Success", "The transaction has been voided!");
            this.getCamperTransaction(this.cInfo);
          }else{
            this.dialogsService.alert("Processing Error", "This transaction could not be voided. It's unique id is:" + transaction.transactionId);
          }
        })

      }
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert("Processing Error", "This transaction could not be voided. It's unique id is:" + transaction.transactionId);
    })

  }


  reconcileBalance(user){
    this.loadingService.toggleLoadingScreen(true);
    this.transactionsService.reconcileBalances(this.idToken, user.accountHolder || user.accountHolderId)
    .then( res => {
      console.log("updated..");
      setTimeout( () => {
        this.getCampers();
        this.loadingService.toggleLoadingScreen(false);
      },1000);
    })
    .catch( err => {
      console.log("updated..");
      setTimeout( () => {
        this.getCampers();
        this.loadingService.toggleLoadingScreen(false);
      },1000);
    })
  }


  exportCampers(){
    console.log("data to export is:", this.campers);
    this.loadingService.toggleLoadingScreen(true);
    var fileName = "Filtered Camper Info.csv";
    var a = document.createElement("a");
    document.body.appendChild(a);
    this.loadingService.toggleLoadingScreen(true);

    var fields = ["FirstName", "LastName", "Session", "Status", "Balance", "Cabin"];
    var data = [];

    let hasShirtSize = false
    
    this.campers.forEach( camper => {
      var subData = {};
      subData["FirstName"] = camper.firstName;
      subData["LastName"] = camper.lastName;
      subData["Session"] = camper.session;
      subData["Cabin"] = camper.cabinName || "-";
      let balance = camper.currentBalance;
      if (!balance) {
        balance = 0;
      }
      subData["Balance"] = balance;
      //will ultimately change how session is determined
      if(camper.currentBalance == undefined || camper.currentBalance == 0){
        subData["Status"] = "Low Balance";
      }else if(camper.session == null || camper.session == undefined){
        subData["Status"] = "No Session";
      }else if(camper.imageUrl == null || camper.imageUrl == undefined){
        subData["Status"] = "No Image";
      }else if(camper.imageUrl != null && camper.currentBalance > 0 && camper.session != undefined){
        subData["Status"] = "Complete";
      }

      if (camper.shirt !== undefined && camper.shirt.size !== undefined) {
        subData["ShirtSize"] = camper.shirt.size;
        hasShirtSize = true;
      }

      data.push(subData);
    })

    if (hasShirtSize) fields.push("ShirtSize");
    
    var csv = json2csv({data:data, fields: fields});
    var blob = new Blob([csv],{type:'text/csv'});
    var url = window.URL.createObjectURL(blob)
    a.href= url;
    a.download = fileName;
    this.loadingService.toggleLoadingScreen(false);
    a.click();
    window.URL.revokeObjectURL(url);
    this.loadingService.toggleLoadingScreen(false);


  }

  importAccounts(){
    window.location.href = 'https://console.funfangle.com/account/import/upload'
  }


  downloadTransactions(){
    console.log("data to export is:", this.cTrans);

    this.loadingService.toggleLoadingScreen(true);
    var timeZone = -4;
    if(this.organization['timeZoneOffset'] != undefined){
      timeZone = this.organization['timeZoneOffset'];
    }
    this.transactionsService.userTransactionReport(this.idToken, this.cInfo['userId'], timeZone )
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log("transaction report:", res);
      var body = JSON.parse(res._body);
      var csv = body.csv;
      var blob = new Blob([csv],{type:'text/csv'});
      var url = window.URL.createObjectURL(blob);
      var fileName = "Transactions for " + this.cInfo['firstName'] + " " + this.cInfo['lastName'] + ".csv";
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.href= url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
      console.log("caught err:", err);
    })


  }



  //date range picker stuff

  public filterTransactions(start, end){
    console.log("filter trans!");
    this.startSeconds = start;
    this.endSeconds = end;
    
    this.displayTransactions = this.cTrans.filter( (transaction) => {
      if(transaction.timeStamp >= start && transaction.timeStamp <= end){
        return true;
      }else{
        return false;
      }
      
    })
  }


  public selectedDate(value: any, dateInput: any) {
      dateInput.start = value.start;
      dateInput.end = value.end;

      console.log("in selected date...");
      console.log(value);
      console.log(dateInput);
      var startSeconds = value.start._d.getTime() / 1000;
      var endSeconds = value.end._d.getTime() / 1000;

      //now need to convert it to the organization's offset..

      var offset = this.organization.timeZoneOffset;

      startSeconds += 3600*offset;
      endSeconds += 3600*offset;
      console.log("start seconds:", startSeconds);
      console.log("end seconds:", startSeconds);


      this.filterTransactions(startSeconds,endSeconds);

      
  }
    
    public singleSelect(value: any) {
        this.singleDate = value.start;
    }

    public applyDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;

        console.log("in apply date...");
        console.log(value);
        console.log(dateInput);
    }

    public calendarEventsHandler(e:any) {
        console.log(e);
        //this.eventLog += '\nEvent Fired: ' + e.event.type;
    }


    public openFutureFunds(camper) {
      this.showCamperFutureFunds = true;
      this.cInfo = camper;
      console.log("Camper:", camper);
    }

}
