import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SquareService } from '../services/square.service';
import { UserService } from '../services/user.service';
import { DialogsService } from '../dialog/dialog.service';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { OrganizationService } from '../services/organization.service';
import { TutorialService } from '../services/tutorial.service';
import { SquareApplicationId } from '../app.constants';

import { AuthService } from '../services/auth.service';
import { Subscription }   from 'rxjs/Subscription';

import 'rxjs/add/operator/debounceTime';

declare var SqPaymentForm: any;

@Component({
  selector: 'app-payment-settings',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {


  public user: any = null;
  public idToken: string = "";
  public rechargeUserId: string = "";

  public rechargeUser: any = null;
  public showPopover4 = false;
  public showPopover5 = false;

  public squareUserInfo = {
    squareCardId: "",
    squareCardLast4: "",
    squareCardType: "",
    email: "",
    squareCustomerId: "",
    squareInfo: {}
  }
  public deposit_amount = 0;
  public defaultCard;

  public cardData = {};
  public paymentForm: any;
  public nameOnCard: string = "";
  public showAddCard: boolean = false;
  public showDepositFunds: boolean = false;
  public authorizedUsers = [];
  public organization = null;
  public agreeToTerms: boolean = false;
  public tutorialSubscription: Subscription;
  public unsubscribeTutorialSubscription: Subscription;
  public tokenSubscription: Subscription;
  public ipAddress: string = "";

  constructor(
    public userService: UserService,
    public squareService: SquareService,
    public dialogsService: DialogsService,
    public route: ActivatedRoute,
    public loadingService: LoadingService,
    public router: Router,
    public organizationService: OrganizationService,
    public tutorialService: TutorialService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    console.log('slug', this.route.snapshot.params['slug']);
    const slug = this.route.snapshot.params['slug'];
    if (slug === 'activities') {
      window.location.href = 'https://console.funfangle.com/activity/list'
      return;
    }
    if (slug === 'members') {
      window.location.href = 'https://console.funfangle.com/accounts'
      return;
    }
    window.location.href = 'https://console.funfangle.com'
  }
}
