import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { TutorialService } from '../services/tutorial.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { LogoWebUrl, DashboardUrl } from '../app.constants';
import { LingoService } from '../services/lingo.service';
import { OrganizationService } from '../services/organization.service';
import { CarePackagesService } from '../services/care-packages.service';

@Component({
  selector: 'app-ff-sidenav',
  templateUrl: './ff-sidenav.component.html',
  styleUrls: ['./ff-sidenav.component.scss']
})
export class FfSidenavComponent implements OnInit {

  public userRole: string = "";
  public userEmail: string = null;
  public subscription: Subscription;
  public userSubscription: Subscription;
  public lingoSubscription: Subscription;
  public tutorialSubscription: Subscription;
  public carePackageSubscription: Subscription;
  public pointOfSaleEnabled: boolean = true;
  public spendingAccountsEnabled: boolean = false;
  public user: any = null;
  public organization: any = null;
  public organizationType: string = null;
  public showToggle = false;
  public isAdmin = false;

  public idToken: string = "";
  public orgLingo: { [id: string]: string } = {};
  public userName: "";
  public userLastName: "";
  public showAccountHolder: boolean = false;
  public prevRole;

  public showPopover3 = false;
  public showPopover6 = false;
  public showPopover8 = false;

  public tokenSubscription: Subscription;
  public logoUrl: string = "";

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public router: Router,
    public lingoService: LingoService,
    public tutorialService: TutorialService,
    public organizationService: OrganizationService,
    public carePackageService: CarePackagesService
  ) {
    this.logoUrl = DashboardUrl || LogoWebUrl;
    this.isAdmin = window.localStorage.getItem("adminUser") !== null;
    this.userSubscription = userService.userSubscription$.subscribe(
      response => {
        this.userRole = response.role;
        this.userEmail = response.email;
        this.userName = response.firstName;
        this.userLastName = response.lastName;
        this.isAdmin = window.localStorage.getItem("adminUser") !== null;
        this.ngOnInit();
      }
    )
    this.lingoSubscription = lingoService.lingoSubscription$.subscribe(
      response => {
        this.orgLingo = response;
        this.orgLingo['authorized_user'] += 's';
      }
    )
    this.tutorialSubscription = this.tutorialService.tutorialSideNavSubscription$.subscribe(
      popoverId => {
        console.log("popoverId:", popoverId);

        if(popoverId == "close"){
          this.showPopover3 = false;
          this.showPopover6 = false;
          this.showPopover8 = false;
        }else if (popoverId == "popover3") {
          this.showPopover3 = true;
          setTimeout(() => {
            try {
              document.getElementById("popover3").click();
            } catch (err) {
              console.log("tutorial error:", err);
            }
          }, 500);
        }else if(popoverId == "popover6"){
          this.showPopover6 = true;
          setTimeout( () => {
           try {
              document.getElementById("popover6").click();
            } catch (err) {
              console.log("tutorial error:", err);
            }
          })
        }else if(popoverId == "popover8"){
          this.showPopover8 = true;
          setTimeout( () => {
           try {
              document.getElementById("popover8").click();
            } catch (err) {
              console.log("tutorial error:", err);
            }
          })
        }
      }
    )
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token ff-sidenav");
       /// console.log(token);
        this.idToken = token;
      }
    )

    this.carePackageSubscription = carePackageService.carePackageSubscription$.subscribe(
      enabled => {
        console.log("received it in sidenav:", enabled);
        this.organization.carePackageEnabled = enabled;
      }
    )
  }

  ngOnInit() {
    console.log("######################## init sidenav");
    this.userService.getUser()
      .then(res => {
        console.log("get user ff-sidenav:", res);
        this.idToken = res.idToken;
        this.user = res;
        this.userEmail = res.email;
        this.userRole = res.role;
        this.prevRole = this.userRole;
        this.userName = res.firstName;
        this.userLastName = res.lastName;

        if(this.userRole == 'organization-manager' || this.userRole == 'organization-employee' || this.userRole == 'store-manager'){
          this.showToggle = true;
        }

        this.lingoService.loadLingo(this.idToken, "camp")
          .then(res => {
            var body = JSON.parse(res._body);
            body.message.Items.forEach(item => {
              this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
            })
            this.orgLingo['authorized_user'] += "s";
          })
          setTimeout( () => {
            this.loadOrganization();
          }, 100);
          
      })
      .catch(err => {
        console.log("get user ff-sidenav err:", err);
      })
      
  }


  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
      .then(res => {
        console.log("got organization:", res);
        var body = JSON.parse(res._body);
        if (body.status == "200") {
          this.organization = body.message.Items[0];
          this.organizationType = this.organization.organizationType;
          console.log("org:", this.organization);
          if (this.organization && this.organization.features  && this.organization.features.pointOfSale)  {
            this.pointOfSaleEnabled = true;
          }
          if (this.organization && this.organization.features  && this.organization.features.spendingAccounts)  {
            this.spendingAccountsEnabled = true;
          }
        }

      })
      .catch(err => {
        console.log("get organization err:", err);
      })
  }



  logout() {
    var signoutRes = this.authService.logout();
    if (signoutRes) {
      this.router.navigate(['auth']);
      this.userRole = "";
      this.userEmail = null;
    }
  }



  goToUserInfo() {
    this.router.navigate(['user-info']);
  }

  goToPayments(){
    this.router.navigate(['payment-settings']);
  }



  skipSteps(popoverId) {
    this.showPopover3 = false;
    this.showPopover6 = false;
    this.showPopover8 = false;
    this.tutorialService.skipSteps(this.idToken, this.userEmail, this.user.organizationId);
  }

  nextStep(popoverId) {
    if(popoverId == 'popover3'){
      this.showPopover3 = false;
      this.tutorialService.triggerPaymentSettingsPopover("popover4");
    }else if(popoverId == 'popover6'){
      this.showPopover6 = false;
      this.tutorialService.triggerCampersPopover("popover7");
    }else if(popoverId == 'popover8'){
      this.showPopover8 = false;
      this.tutorialService.triggerContributorsPopover("popover9");
    }
  }

  launchTutorial(){
    this.router.navigate(['home/true'])
  }


  propagateToggle(){
    //this.showAccountHolder not updated immediately...
    setTimeout( () => {
      console.log("after wait!");
      console.log("this:", this.showAccountHolder);
      // if(this.showAccountHolder){
      //   this.userRole = 'account-holder';
      // }else{
      //   this.userRole = this.prevRole;
      // }
      this.organizationService.propagateToggle(this.showAccountHolder);
    },0);
    
  }

}

