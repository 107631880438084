export class Modifier{
    name: string;
    options: ModifierOption[];
    multiAllowed: boolean;
    modifierId?: string;

    constructor(){
        this.name = "";
        this.options = [];
        this.multiAllowed = false;
    }

}

export class ModifierOption{
    cost: number;
    isDefault: boolean;
    name: string;
    cogsPrice?: number;

    constructor(){
        this.cost = 0;
        this.name = "";
        this.isDefault = false;
    }
}

export class Item {
    applyDailyLimits: boolean;
    categoryId?: string;
    deposit: boolean;
    deptId?: string;
    isEmployeeDiscountEligble: boolean;
    itemId: string;
    itemType: string;
    modifiers: Modifier[];
    name: string;
    organizationId: string;
    price: number;
    quantityTracking: string;
    taxRate?: number;
    type: string;
    // variants: ;

    // withdrawal: boolean;
    transactionType: string;

    // modifiersDistinct: boolean;
    // modifierQuantities: {};
    // modifierIds: any;

    // quantity?: number;
    
    //item type fields
    // dept?: string;
    // cogsPrice?: number;
    // dateLastOrdered?: string;
    // dateLastSold?: string;
    
    //bookstore type
    // isbn?: string;
    // title?: string;

    //category...
    // category?: string;

    imageFile?: any;
    imageUrl?: string;


    constructor(){
        this.deposit = false;
        this.itemId = "";
        this.name = "";
        this.price = 0;
        this.type = "fixed";
        // this.withdrawal = false;
        this.transactionType = 'transaction';
        this.modifiers = [];
        this.organizationId = "";
        this.itemType = "none";
        this.isEmployeeDiscountEligble = true;
        this.quantityTracking = "off";
        // this.modifiersDistinct = false;
        // this.modifierQuantities = {};
        // this.modifierIds = [];
    }

    resetType() {
        // this.isbn = undefined;
        // this.title = undefined;
        // this.dept = undefined;
        // this.cogsPrice = undefined;
        // this.dateLastOrdered = undefined;
        // this.category = undefined;
    }
}