export class TransactionSearchTerms {
  last4: string | undefined;
  name: string | undefined;
  email: string | undefined;
  amount: number | undefined;

  dateInput: any;
  dateInputs: any[];

  constructor(){
  }

}
