import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Subject }    from 'rxjs/Subject';





/*Service for interfacing with Cognito methods*/
@Injectable()
export class LoadingService {

  constructor(
    public http: Http,
  ) { }


  public loadingSubscription = new Subject<any>();
  public sideNavSubscription = new Subject<any>();

  loadingSubscription$ = this.loadingSubscription.asObservable();
  sideNavSubscription$ = this.sideNavSubscription.asObservable();


  //push the lingo to all subscribers
  public toggleLoadingScreen(show: boolean){
    this.loadingSubscription.next(show);
  }


  public toggleSideMenu(){
    this.sideNavSubscription.next();
  }

}