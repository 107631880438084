import { Component, OnInit } from '@angular/core';
import {LingoService} from "../services/lingo.service";
import { Router } from "@angular/router";

import { OrganizationService } from "../services/organization.service";
import { AuthService } from "../services/auth.service";
import { DialogsService } from "../dialog/dialog.service";
import {AuthCredentials} from "../auth/auth-credentials.component";
import { ActivationService } from '../services/activation.service';
import { LoadingService } from '../services/loading.service';

import { LogoWebUrl } from '../app.constants';

import { OrganizationUser } from '../models/organization';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  public organization: any = null;

  newOrganization: OrganizationUser;

  public timeZones = [
    {
      name: "UTC-10",
      offset: -10,
      value: "Hawaiian Standard Time"
    },
    {
      name: "UTC-8",
      offset: -8,
      value: "Alaskan Standard Time"
    },
    {
      name: "UTC-7",
      offset: -7,
      value: "Pacific Standard Time"
    },
    {
      name: "UTC-6",
      offset: -6,
      value: "Mountain Standard Time"
    },
    {
      name: "UTC-5",
      offset: -5,
      value: "Central Standard Time"
    },
    {
      name: "UTC-4",
      offset: -4,
      value: "Eastern Standard Time"
    }]

  public logoUrl: string = "";

  public licenseText: string = "";
  public credentials = new AuthCredentials();

  constructor(
    public organizationService: OrganizationService,
    public router: Router,
    public lingoService: LingoService,
    public authService: AuthService,
    public dialogsService: DialogsService,
    public activationService: ActivationService,
    public loadingService: LoadingService

  ) {
    this.logoUrl = LogoWebUrl;
    this.newOrganization = new OrganizationUser();
   }

  ngOnInit() {
    // setTimeout( () => {
    //   this.activationService.getLicense('organization')
    //     .then(res => {
    //       this.licenseText = res;
    //     })
    // },100);
    this.authService.initializeAWSCognito();
    this.checkCookies();
  }
  

  addOrganization(){
    console.log(this.newOrganization);
    var isValid: boolean = this.validateOrganization(this.newOrganization);
    if(!isValid){
      return;
    }
    console.log("it's valid!");
    this.credentials.role = 'organization-manager';
    this.credentials.password = this.newOrganization.pin;
    this.credentials.email = this.newOrganization.email;
    this.loadingService.toggleLoadingScreen(true);
    this.authService.createUser(this.credentials, false)
      .then(res => {
          this.organizationService.createOrganization(this.newOrganization)
            .then(res => {
              this.loadingService.toggleLoadingScreen(false);
              var body = JSON.parse(res._body);
              console.log("Body", body)
              this.newOrganization = new OrganizationUser();
            }).catch(err => {
              this.loadingService.toggleLoadingScreen(false);
            })
        this.dialogsService.alert("Success", "Your camp has been registered. You can now log in normally");
        this.router.navigate(['auth']);
       })
      .catch(err => {
        console.log("create user err:", err);
        this.dialogsService.alert("Processing Error", err.code);
        this.loadingService.toggleLoadingScreen(false);
      })
  }



  validateOrganization(organization: any): boolean {
    console.log("validating organization", organization);
    if(organization.pin.length != 0 && organization.pin.length < 4){
      this.dialogsService.alert("Processing Error", "Your PIN must be at least 4 characters long ");
      return false;
    }
    if(organization.pin != organization.confirmPin){
      this.dialogsService.alert("Processing Error", "Your PINs do not match");
      return false;
    }
    if(organization.firstName == "" || organization.lastName == ""){
      this.dialogsService.alert("Processing Error", "You must enter a First and Last Name");
      return false;
    }
    if(organization.orgName == ""){
      this.dialogsService.alert("Processing Error", "You must enter a Organization Name");
      return false;
    }
    return true;
  }


  checkCookies(){
    var firstName = this.getCookie("firstName");
    var lastName = this.getCookie("lastName");
    var orgName = this.getCookie("orgName");
    console.log("checking cookies!");
    console.log(firstName);

    if(firstName && lastName && orgName){
      //pull everything in
      this.newOrganization.firstName = firstName;
      this.newOrganization.lastName = lastName;
      this.newOrganization.orgName = orgName;
      this.newOrganization.email = this.getCookie("email");
      this.newOrganization.pin = this.getCookie("pin");
      this.newOrganization.domainUrl = this.getCookie("domainUrl");
      this.newOrganization.confirmPin = this.getCookie("confirmPin");
      this.newOrganization.timeZoneOffset = parseInt(this.getCookie("timeZoneOffset"));
      this.newOrganization.nickName = this.getCookie("nickName");
      this.newOrganization.telephone = this.getCookie("telephone").toString();
      //now invoke the create function
      this.addOrganization();
    }
    this.unsetCookies();
  }


  unsetCookies(){
    this.setCookie("firstName", null, 1);
    this.setCookie("lastName", null, 1);
    this.setCookie("email", null, 1);
    this.setCookie("orgName", null, 1);
    this.setCookie("pin", null, 1);
    this.setCookie("confirmPin", null, 1);
    this.setCookie("domainUrl", null, 1);
    this.setCookie("timeZoneOffset", null, 1);
    this.setCookie("nickName", null, 1);
  }


  getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
      for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0)==' ') {
              c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
              return c.substring(name.length,c.length);
          }
      }
      return "";
  }

  setCookie(cname, cvalue, exseconds) {
    var d = new Date();
    d.setTime(d.getTime() + (exseconds*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
