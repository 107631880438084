import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'input-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">


                <div class="modal-error-message" *ngIf="errorMessage.length > 0">
                    {{errorMessage}}
                </div>

                <div *ngIf="itemType == 'bookstore'">
                    <mat-form-field class="inventory-input-container">
                        <input matInput [(ngModel)]="fields.title" placeholder="*Title">
                    </mat-form-field>
                    &nbsp;
                    <mat-form-field class="inventory-input-container">
                        <input matInput type="text" [(ngModel)]="fields.isbn" placeholder="*ISBN/barcode">
                    </mat-form-field>
                    
                </div>

                <!-- for all types -->
                <div>
                        <mat-form-field class="inventory-input-container">
                            <input matInput [(ngModel)]="fields.dept" placeholder="Department">
                        </mat-form-field>
                        <br />

                        <!--<mat-form-field class="inventory-input-container">
                            <input matInput type="number" [(ngModel)]="fields.cogsPrice" placeholder="COGS Price ($)">
                        </mat-form-field>-->
                        
                        &nbsp;

                        <mat-form-field class="inventory-input-container">
                            <input matInput type="number" [(ngModel)]="fields.quantity" placeholder="Quantity on hand">
                        </mat-form-field>

                        <br/>

                        <mat-form-field class="inventory-input-container">
                            <input matInput type="date" [(ngModel)]="fields.dateLastOrdered" placeholder="Last Ordered">
                        </mat-form-field>

                        &nbsp;

                        <mat-form-field class="inventory-input-container">
                            <input matInput type="date" [(ngModel)]="fields.dateLastSold" placeholder="Last Sold">
                        </mat-form-field>

                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="save()" class="submit-button">Save</button>
            </div>
            
        </div>
        `,
})
export class ItemFieldsDialog implements OnInit {

    public title: string = "Enter the fields";
    public message: string;
    public cancelText: string;
    public okText: string;
    public errorMessage: string = "";
    public item: any;
    public itemType: string = "";


    public fields = {
        isbn: "",
        title: "",
        sizes: "",
        dept: "",
        cogsPrice: undefined,
        quantity: undefined,
        dateLastSold: "",
        dateLastOrdered: ""
    };

    

    constructor(public dialogRef: MatDialogRef<ItemFieldsDialog>) {
       console.log("dialog ref:");
       console.log(dialogRef);
       console.log("item:", this.item);
    }

    ngOnInit(){
        console.log("on init...");
        console.log("item:", this.item);
        this.itemType = this.item.itemType;
        this.title = "Enter the " + this.itemType + " fields";
        this.fields.isbn = this.item.isbn;
        this.fields.title = this.item.title;
        this.fields.sizes = this.item.sizes;
        this.fields.dept = this.item.dept;
        this.fields.cogsPrice = this.item.cogsPrice;
        this.fields.quantity = this.item.quantity;
        this.fields.dateLastOrdered = this.item.dateLastOrdered;
        this.fields.dateLastSold = this.item.dateLastSold;
    }


    isValid(): boolean {
        let isValid = true;
        if(this.itemType == 'bookstore'){
            if(this.fields.isbn == "" || this.fields.title == ""){
                isValid = false;
                this.errorMessage = "The title and isbn fields are required";
            }else{
                console.log("isbn:", this.fields.isbn);
                this.fields.isbn = this.fields.isbn.replace(/[^\w\s]/gi,'');
            } 
        }
        return isValid;
    }

    save(){
        if(this.isValid()){
            this.errorMessage = "";
            this.dialogRef.close(this.fields);
        }
    }

    


    

}