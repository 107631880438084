import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { LoadingService } from "../services/loading.service";
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { DialogsService } from '../dialog/dialog.service';
import { InventoryPurchaseService } from '../services/inventory-purchase.service';
import { Subscription }   from 'rxjs/Subscription';
import { OrganizationService } from '../services/organization.service';
import { LingoService } from '../services/lingo.service';
import { VendorService } from '../services/vendor.service';
import { DateTime } from 'luxon';

export class InventoryPurchaseOrder {
  organizationId?: string;

  createdAt?: number;

  extReference?: string;

  notes?: string;

  promisedAt?: number;

  purchaseId?: string;

  receivedAt?: number;

  status?: 'draft' | 'ordered' | 'partial-receipt' | 'complete' | 'cancelled';

  totalShipping?: number;

  totalTax?: number;

  updatedAt?: number;

  vendorId?: string;
}

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchaseorder.component.html',
  styleUrls: ['./purchaseorder.component.scss']
})

export class InventoryPurchaseOrderComponent implements OnInit {

  public idToken: string;
  public organization: any;
  public organizationId: string;
  public lingoSubscription: Subscription;
  public orgLingo: { [id: string]: string } = {};

  public showAddPurchaseOrders: boolean = false;
  public showEditPurchaseOrder: boolean = false;

  public orders = [];
  public user: any;
  public purchaseorders = [];
  public sendingInvite: boolean = false;
  public vendorNames = new Map<string, string>();
  public vendorSelect = [];

  public newPurchaseOrder: InventoryPurchaseOrder = {}

  public statuses = [
    {value: "draft" , viewName:"Draft"},
    {value: "ordered", viewName: "Ordered"},
    {value: "partial-receipt", viewName: "Partial Receipt"},
    {value: "complete", viewName: "Complete"},
    {value: "cancelled", viewName: "Cancelled"},
  ]

  public tokenSubscription: Subscription;

  constructor(
    public userService: UserService,
    public dialogsService: DialogsService,
    public purchaseOrderService: InventoryPurchaseService,
    public loadingService: LoadingService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public lingoService: LingoService,
    public vendorService: VendorService,
    public router: Router
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token purchaseorder!");
       /// console.log(token);
        this.idToken = token;
      }
    )
    this.lingoSubscription = lingoService.lingoSubscription$.subscribe(
      response => {
        this.orgLingo = response;
        console.log("org lingo:", response);
      }
    )
  }

  closeCard(){
    this.showAddPurchaseOrders = false;
    this.showEditPurchaseOrder = false;
  }

  closePage() {
    this.router.navigate(['inventory']);
  }

  loadLingo(){
    this.lingoService.loadLingo(this.idToken, "camp")
    .then(res => {
      console.log("load lingo:", res);
      var body = JSON.parse(res._body);
      body.message.Items.forEach(item => {
        this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
      });
    });
  }

  loadOrganization(){
    this.organizationService.getOrganizationById(this.idToken, this.organizationId)
    .then(res => {
      var body = JSON.parse(res._body);
      this.organization = body.message.Items[0];
      console.log("got organization:", this.organization);
      console.log("this.organization:", this.organization.organizationName);
    })
  }

  loadUser(): Promise<any> {
    return new Promise((resolve,reject) => {
        this.userService.getUser()
        .then(res => {
            this.idToken = res.idToken;
            resolve(res);
        })
        .catch(err => {
            reject(err);
        });
    });
  }

  loadVendors(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.vendorService.listVendors(this.idToken, this.organizationId)
        .then(res => {
          this.vendorNames = new Map<string, string>();
          var body = JSON.parse(res._body)
          for (const item of body.items || []) {
            if (item.vendorId !== undefined && item.name !== undefined) {
              this.vendorNames.set(item.vendorId, item.name);
              this.vendorSelect.push({
                value: item.vendorId,
                name: item.name
              })    
            }
          }

          this.vendorSelect.sort((a, b) => {
            if (a === undefined || a.name === undefined) return 1;
            if (b === undefined || b.name === undefined) return -1;
            return a.name > b.name ? 1 : -1
          })
    
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  ngOnInit() {
    this.loadingService.toggleLoadingScreen(true);
    this.loadUser()
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        // console.log("Response", res);
        this.organizationId = res.organizationId;
        this.user = res;
        this.loadOrganization();
        this.getPurchaseOrders();
        this.loadVendors();
        this.loadLingo();
        // console.log(this.user);
        console.log('organizationId', this.organizationId);
      });
  }

  get allPurchaseOrders() {
    let timeZone = 'America/New_York';
    if (this.organization && this.organization.timeZone !== undefined) ({ timeZone } = this.organization)

    return this.orders.map( purchaseorder => {
      let createdDate = '';
      if (purchaseorder.createdAt !== undefined) {
        createdDate = DateTime.fromSeconds(purchaseorder.createdAt, { zone: timeZone }).toLocaleString(DateTime.DATETIME_SHORT);
      }

      let vendorName = '';
      if (purchaseorder.vendorId !== undefined) vendorName = this.vendorNames.get(purchaseorder.vendorId) || '';

      return {
        createdAt: purchaseorder.createdAt,
        createdDate,
        extReference: purchaseorder.extReference,
        notes: purchaseorder.notes,
        purchaseId: purchaseorder.purchaseId,
        status: purchaseorder.status,
        vendorId: purchaseorder.vendorId,
        vendorName
      }
    }).sort((a, b) => {
      if (a === undefined || a.createdAt === undefined) return -1
      if (b === undefined || b.createdAt === undefined) return 1;
      return (a.createdAt < b.createdAt) ? 1 : -1;
    });
  }

  getPurchaseOrder(): Promise<InventoryPurchaseOrder>{
    return new Promise( ( resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          this.idToken = res.idToken;
          resolve(res);
        });
    })
  }

  async getPurchaseOrders(): Promise<InventoryPurchaseOrder[]> {
    var organizationid  = this.organizationId;

    if(this.orders.length > 0){
      this.orders = [];
    }
    this.loadingService.toggleLoadingScreen(true);
    await this.purchaseOrderService.listPurchaseOrders(this.idToken, organizationid)
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        // console.log('purchaseorder.getPurchaseOrders', res);
        var body = JSON.parse(res._body)
        var em = body.items;
        // console.log('Items: ', em)
        em.forEach(record => {
          this.orders.push(record)
        })
      }).catch(err =>{
        console.log("Error getting PurchaseOrders", err);
        this.loadingService.toggleLoadingScreen(false);
      })
    // console.log(this.orders);
    return Promise.resolve(this.orders);
  }

  insertPurchaseOrder(){
    if(!this.validatePurchaseOrder(this.newPurchaseOrder)){
      return;
    }
    
    this.purchaseOrderService.insertPurchaseOrder(this.idToken, this.newPurchaseOrder)
    .then(res => {
      console.log("edit purchaseorder res;", res);
      this.closeCard();
      this.getPurchaseOrders();
      // this.openPurchaseDetails(res);
    })
  }

  openAddPurchaseOrder(){
    this.showAddPurchaseOrders = !this.showAddPurchaseOrders;
    this.newPurchaseOrder = new InventoryPurchaseOrder();
    this.newPurchaseOrder.organizationId = this.organizationId;
  }

  openEditPurchaseOrder(purchaseorder: InventoryPurchaseOrder){
    this.showEditPurchaseOrder = !this.showEditPurchaseOrder;
    console.log('Open Edit', purchaseorder);

    this.newPurchaseOrder = new InventoryPurchaseOrder();
    this.newPurchaseOrder.createdAt = purchaseorder.createdAt;
    this.newPurchaseOrder.extReference = purchaseorder.extReference;
    this.newPurchaseOrder.notes = purchaseorder.notes;
    this.newPurchaseOrder.organizationId = purchaseorder.organizationId;
    this.newPurchaseOrder.promisedAt = purchaseorder.promisedAt;
    this.newPurchaseOrder.purchaseId = purchaseorder.purchaseId;
    this.newPurchaseOrder.receivedAt = purchaseorder.receivedAt;
    this.newPurchaseOrder.status = purchaseorder.status;
    this.newPurchaseOrder.totalShipping = purchaseorder.totalShipping;
    this.newPurchaseOrder.totalTax = purchaseorder.totalTax;
    this.newPurchaseOrder.updatedAt = purchaseorder.updatedAt;
    this.newPurchaseOrder.vendorId = purchaseorder.vendorId;
  }

  openPurchaseDetails(purchaseorder: InventoryPurchaseOrder) {
    this.router.navigate(['inventory/purchasedetails/' + purchaseorder.purchaseId || '']);
  }

  updatePurchaseOrder(){
    this.newPurchaseOrder.organizationId = this.organizationId;
    if(!this.validatePurchaseOrder(this.newPurchaseOrder)){
      return;
    }
    
    this.purchaseOrderService.updatePurchaseOrder(this.idToken, this.newPurchaseOrder)
    .then(res => {
      console.log("edit purchaseorder res;", res);
      this.closeCard();
      this.getPurchaseOrders();
    })
  }

  refreshPurchaseOrders(){
    console.log("Refreshing PurchaseOrders")
    this.loadOrganization();
    this.getPurchaseOrders();
  }

  //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }

  validatePurchaseOrder(purchaseOrder: InventoryPurchaseOrder){
    console.log("Validate Purchase Order", purchaseOrder);
    if (purchaseOrder.status === undefined || (purchaseOrder.status as never as string) === ''){
      this.dialogsService.alert("Status", "Please select a status");
      return false;
    }
    if (purchaseOrder.vendorId === undefined || purchaseOrder.vendorId === ''){
      this.dialogsService.alert("Vendor", "Please select a vendor");
      return false;
    }
    return true;
  }

}
