export const config = {
    "camps": {
        "default":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://portal.funfangle.com",
            "env": "prod",
            "organizationId": "fee87eb5-1db1-42d9-a4bf-3a875cad410b"
        },
        "vueapp.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://admin-vueapp.funfangle.com",
            "env": "dev",
        },

        "int-portal.int.funfangle":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://int-portal.int.funfangle.com",
            "env": "dev",
            "organizationId": "b5722a2b-d723-42d9-9c67-889170955927"
        },


        "int-portal2.int.funfangle":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://int-portal.int.funfangle.com",
            "env": "dev",
            "organizationId": ""
        },

        "staging-portal.staging.funfangle":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://staging-portal.staging.funfangle.com",
            "env": "staging",
            "organizationId": "08eb5994-7905-4f1d-8a8f-a6747078690a"
        },


        "portal.funfangle.com":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/funfangle/funfangle-webapp-logo",
            "WebUrl": "https://portal.funfangle.com",
            "env": "prod",
            "organizationId": "90e62327-08a2-49d6-9d69-856d04a7932d"
        },


        "berea.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/berea/berea-webapp-logo.png",
            "WebUrl": "https://berea.funfangle.com",
            "env": "prod",
            "organizationId": "d16c2ab7-82fa-4e2f-9122-67cd98236fa3"
        },

        "goldenbell.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/goldenbell/goldenbell-webapp-logo.png",
            "WebUrl": "https://camperspendingaccount.goldenbellccc.org",
            "env": "prod",
            "organizationId": "1efed82b-a03f-45a0-876c-13d58f6a7842"
        },

        "campcrosley.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/crosley/crosley-webapp-logo.png",
            "WebUrl": "https://campcrosley.funfangle.camp",
            "env": "prod",
            "organizationId": "a55a32c4-27ba-4fbe-b37a-c85e2f74db3b"
        },

        "rawhideranch.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/rawhideranch/rawhideranch-webapp-logo.PNG",
            "WebUrl": "https://rawhideranch.funfangle.camp",
            "env": "prod",
            "organizationId": "07e2abdc-954b-449e-a6be-31fe93ee21a7"
        },



        "ccpa.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/ccpa/ccpa-webapp-logo.png",
            "WebUrl": "https://ccpa.funfangle.camp",
            "env": "prod",
            "organizationId": "29cb6b8d-439e-4ffc-90cb-89ba2fa47198"
        },



        "clydehurst.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/clydehurst/clydehurst-webapp-logo.png",
            "WebUrl": "https://clydehurst.funfangle.camp",
            "env": "prod",
            "organizationId": "3a96a756-b36b-456a-9153-44b474337044"
        },



        "newliferanch.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/newliferanch/newliferanch-webapp-logo.png",
            "WebUrl": "https://newliferanch.funfangle.camp",
            "dashboardUrl": "https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/newliferanch/newliferanch-dashboard-new.png",
            "ContactMessage": "If you registered a camper at New Life Ranch, you received an email from us about setting up an account. If you can't find that email, send an email to canteen@newliferanch.com and we'll resend the activation.",
            "env": "prod",
            "organizationId": "5d8f64f8-581f-4902-974e-4f9416808cb4"
            
        },


        "nlr.funfangle.camp":{
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/newliferanch/newliferanch-webapp-logo.png",
            "WebUrl": "https://canteen.newliferanch.com",
            "dashboardUrl": "https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/newliferanch/newliferanch-dashboard-new.png",
            "ContactMessage": "If you registered a camper at New Life Ranch, you received an email from us about setting up an account. If you can't find that email, send an email to canteen@newliferanch.com and we'll resend the activation.",
            "env": "prod",
            "organizationId": "5d8f64f8-581f-4902-974e-4f9416808cb4"
        },



        "lakeanncamp.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/lakeann/lakeann-webapp-logo.png",
            "WebUrl": "https://lakeanncamp.funfangle.camp",
            "env": "prod",
            "organizationId": "3a783e97-0007-48d6-8faa-a7e5a2dafff7"
        },



        "chanco.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/chanco/chanco-webapp-logo.png",
            "WebUrl": "https://chanco.funfangle.camp",
            "env": "prod",
            "organizationId": "024b4398-7992-4cc3-8806-79feca31227d"
        },




        "timberlee.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/timberlee/timberlee-webapp-logo.png",
            "WebUrl": "https://timberlee.funfangle.camp",
            "env": "prod",
            "organizationId": "a0ce3b97-f4e0-4713-9f16-8158e56e62ce"
        },



        "havenontherock.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/havenontherock/havenontherock-webapp-logo.png",
            "WebUrl": "https://havenontherock.funfangle.camp",
            "env": "prod",
            "organizationId": "c97b9d05-fe13-4687-8de6-8ec8b8eaf7ef"
        },                


        "hiddenacres.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/hiddenacres/hiddenacres-webapp-logo-newest.png",
            "dashboardUrl": "https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/hiddenacres/hiddenacres-dashboard.jpg",
            "WebUrl": "https://hiddenacres.funfangle.camp",
            "env": "prod",
            "organizationId": "b6032f93-58ef-4252-a492-8eaf64011b3e"
        },



        "adventurelinks.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/adventurelinks/adventurelinks-webapp-logo.png",
            "WebUrl": "https://adventurelinks.funfangle.camp",
            "env": "prod",
            "organizationId": "c033a7ae-f85b-4547-8d54-aeab65755c5c"
        },



        "campsiloam.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/siloam/siloam-webapp-logo.png",
            "WebUrl": "https://campsiloam.funfangle.camp",
            "env": "prod",
            "organizationId": "98087b43-782b-48d1-9cfa-da946bdd34b4"
        },



        "campfirefw.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/firefw/firefw-webapp-logo.png",
            "WebUrl": "https://campfirefw.funfangle.camp",
            "env": "prod",
            "organizationId": "e08a92ff-d641-400a-8c0a-ad1ae5966d59"
        },



        "echoranch.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/echoranch/echoranch-webapp-logo.png",
            "WebUrl": "https://echoranch.funfangle.camp",
            "env": "prod",
            "organizationId": "92fbca3c-b8d5-4860-85ce-6e75cf9b1ed8"
        },




        "highpoint.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/highpoint/highpoint-webapp-logo.png",
            "WebUrl": "https://highpoint.funfangle.camp",
            "env": "prod",
            "organizationId": "3c5c3489-7e1a-47b7-9497-516704dc7039"
        },


        "haluwasa.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/haluwasa/haluwasa-webapp-logo.png",
            "WebUrl": "https://haluwasa.funfangle.camp",
            "env": "prod",
            "organizationId": "b7800973-02d2-4b8d-a84c-faae07f132dc"
        },


        "harveycedars.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/harveycedars/harveycedars-webapp-logo.png",
            "WebUrl": "https://harveycedars.funfangle.camp",
            "env": "prod",
            "organizationId": "9af530a3-7585-47d6-bd15-387222898994"
        },

        "coldspringsretreat.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/coldspringsretreat/coldspringsretreat-webapp-logo.png",
            "WebUrl": "https://coldspringsretreat.funfangle.camp",
            "env": "prod",
            "organizationId": "eb58e282-cc80-44d0-946d-f587bd3041cb"
        },
        
        "sfrcamps.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/shepherdsfold/shepherdsfold-webapp-logo.png",
            "dashboardUrl": "https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/shepherdsfold/shepherdsfold-dashboard.png",
            "WebUrl": "https://sfrcamps.funfangle.camp",
            "env": "prod",
            "organizationId": "78de63be-3164-4311-be15-a27b18fb675f"
        },


        "tuscarora.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/tuscarora/tuscarora-webapp-logo.png",
            "WebUrl": "https://tuscarora.funfangle.camp",
            "env": "prod",
            "organizationId": "1d92b867-ff1d-4fd9-a9a8-d3eee1b5ef5e"
        },

        "parkdeli.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/parkdeli/parkdeli-webapp-logo.png",
            "WebUrl": "https://parkdeli.funfangle.camp",
            "env": "prod",
            "organizationId": "08eb5994-7905-4f1d-8a8f-a6747078690a"
        },

        "campcaudle.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campcaudle/campcaudle-webapp-logo.png",
            "WebUrl": "https://campcaudle.funfangle.camp",
            "env": "prod",
            "organizationId": "a6e5a4ef-3437-46be-8e9f-f6b158f51aab"
        },


        "whitehallcamp.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/whitehallcamp/whitehallcamp-webapp-logo.png",
            "WebUrl": "https://whitehallcamp.funfangle.camp",
            "env": "prod",
            "organizationId": "d9f83189-63a6-4ce0-b8df-5e4d4838850c"
        },

        "somersetbeach.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/somersetbeach/somersetbeach-webapp-logo.png",
            "WebUrl": "https://somersetbeach.funfangle.camp",
            "env": "prod",
            "organizationId": "bb5bbdd7-82ff-440d-a370-4f79b2f83ecd"
        },

        "riverside-wi.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/riverside-wi/riverside-wi-webapp-logo.png",
            "WebUrl": "https://riverside-wi.funfangle.camp",
            "env": "prod",
            "organizationId": "013351eb-38de-453c-804a-3e8312dd7fc9"
        },

        "campjburg.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campjburg/campjburg-webapp-logo.png",
            "WebUrl": "https://campjburg.funfangle.camp",
            "env": "prod",
            "organizationId": "6f4f4398-8bbc-44a5-926f-8f0dd6814060"
        },

        "campalbemarle.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campalbemarle/campalbemarle-webapp-logo.png",
            "WebUrl": "https://campalbemarle.funfangle.camp",
            "env": "prod",
            "organizationId": "d2457a01-b358-48bf-85a9-3ffc63d1a1d7"
        },

        "campcollins.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campcollins/campcollins-webapp-logo.png",
            "WebUrl": "https://campcollins.funfangle.camp",
            "env": "prod",
            "organizationId": "3c5f6524-29fa-4f73-afca-a99010e6c754"
        },

        "goldengoalpark.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/goldengoalpark/goldengoalpark-webapp-logo.png",
            "WebUrl": "https://goldengoalpark.funfangle.camp",
            "env": "prod",
            "organizationId": "08588a18-53a5-460e-a2b8-1e6357e34048"
        },

        "campfiresdi.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campfiresdi/campfiresdi-webapp-logo.png",
            "WebUrl": "https://campfiresdi.funfangle.camp",
            "env": "prod",
            "organizationId": "6f35b43d-bd7c-4410-a4c2-8fe4f03a4730"
        },

        "campallen1954.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campallen1954/campallen1954-webapp-logo.png",
            "WebUrl": "https://campallen1954.funfangle.camp",
            "env": "prod",
            "organizationId": "a0a619ca-fbf4-476c-9438-af4d9c632830"
        },


        "sambica.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/sambica/sambica-webapp-logo.png",
            "WebUrl": "https://sambica.funfangle.camp",
            "env": "prod",
            "organizationId": "6a232031-e5dd-4c28-93e5-715106fba117"
        },

        "jderevjanik.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/jderevjanik/jderevjanik-webapp-logo.png",
            "WebUrl": "https://jderevjanik.funfangle.camp",
            "env": "prod",
            "organizationId": "da33a65a-8c2c-4627-b2e0-0a767e289b20"
        },

        "campmyw.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campmyw/campmyw-webapp-logo.png",
            "WebUrl": "https://tuckshop.campmyw.com",
            "env": "prod",
            "organizationId": "accc16b5-ed55-4bda-a46c-8392a7198d7b"
        },

        "ockanickon.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/ockanickon/ockanickon-webapp-logo.png",
            "WebUrl": "https://ockanickon.funfangle.camp",
            "env": "prod",
            "organizationId": "55d845ba-136e-4880-aee4-e7a2c7e47034"
        },

        "monarchcamps.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/monarchcamps/monarchcamps-webapp-logo.png",
            "WebUrl": "https://monarchcamps.funfangle.camp",
            "env": "prod",
            "organizationId": "b0b7156e-d9d1-45c9-9144-c507ff9446fb"
        },
         "lurecrest.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/lurecrest/lurecrest-webapp-logo.png",
            "WebUrl": "https://lurecrest.funfangle.camp",
            "env": "prod",
            "organizationId": "ec36e8d1-b22c-4f73-b967-414fa490a032"
        },

        "eaglesky.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/eaglesky/eaglesky-webapp-logo.png",
            "WebUrl": "https://eaglesky.funfangle.camp",
            "env": "prod"
        },

        "balcones.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/balcones/balcones-webapp-logo.png",
            "WebUrl": "https://balcones.funfangle.camp",
            "env": "prod"
        },
        "texashermannsons.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/texashermannsons/texashermannsons-webapp-logo.png",
            "WebUrl": "https://texashermannsons.funfangle.camp",
            "env": "prod"
        },

        "campeagleva.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/funfangle-prod-organization-logos/campeagleva/campeagleva-webapp-logo.png",
            "WebUrl": "https://campeagleva.funfangle.camp",
            "env": "prod"
        },

        "stranch.funfangle.camp": {
            "logoUrl": "https://s3.us-east-2.amazonaws.com/stranch.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://stranch.funfangle.camp",
            "env": "prod"
        },
        "campkahquah.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/campkahquah.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://campkahquah.funfangle.camp",
            "env": "prod"
        },
         "alpine.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/alpine.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://alpine.funfangle.camp",
            "env": "prod"
        },
         "inupci.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/inupci.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://inupci.funfangle.camp",
            "env": "prod"
        },
         "windriverranch.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/windriverranch.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://windriverranch.funfangle.camp",
            "env": "prod"
        },
         "senecahills.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/senecahills.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://senecahills.funfangle.camp",
            "env": "prod"
        },
        "hcymca.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/hcymca.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://hcymca.funfangle.camp",
            "env": "prod"
        },

        "campspofford.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/campspofford.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://campspofford.funfangle.camp",
            "env": "prod"
        },


        "anokijig.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/anokijig.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://anokijig.funfangle.camp",
            "env": "prod"
        },

        "kidsloveelc.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/kidsloveelc.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://kidsloveelc.funfangle.camp",
            "env": "prod"
        },

        "captainrex.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/captainrex.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://captainrex.funfangle.camp",
            "env": "prod"
        },

        "srccha.funfangle": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/srccha.funfangle.com/dynamic/logos/main-logo.png",
            "WebUrl": "https://srccha.funfangle.com",
            "env": "prod"
        },

        "thewildsofnewengland.funfangle.camp": {
            "logoUrl":"https://s3.us-east-2.amazonaws.com/thewildsofnewengland.funfangle.camp/dynamic/logos/main-logo.png",
            "WebUrl": "https://thewildsofnewengland.funfangle.camp",
            "env": "prod"
        }
    },

    "environments": {
        "dev": {
            "regions":{
                "UserImageBucketRegion": "us-east-1",
                "CognitoRegion": "us-east-1",
                "EmailBucketRegion":"us-east-1",
                "LicenseBucketRegion": "us-east-1",
                "CarePackageImageBucketRegion": "us-east-1",
                "InventoryImageBucketRegion": "us-east-1"
            },
            "endpoints": {
                "ApiEndpoint": "https://api.dev.funfangle.com/general",
                "BaseEndpoint": "https://api.dev.funfangle.com",
                "SquareEndpoint": "https://api.dev.funfangle.com/square",
                "TutorialEndpoint":"https://api.dev.funfangle.com/tutorial",
                "OrganizationEndpoint": "https://api.dev.funfangle.com/organizations",
                "EmailEndpoint": "https://api.dev.funfangle.com/emails",
                "PosEndpoint": "https://api.dev.funfangle.com/pos",
                "MiscEndpoint": "https://api.dev.funfangle.com/misc",
                "ImportEndpoint":"https://api.dev.funfangle.com/import",
                "AuthEndpoint":"https://api.dev.funfangle.com/cognito",
                "DevicesEndpoint": "https://api.dev.funfangle.com/device"
            },
            "buckets": {
                "UserImageBucket": "accounts-dev.funfangle.com",
                "LicenseBucketName":"funfangle-licensing",
                "OrganizationLicenseKey":"organization-license.txt",
                "CustomerLicenseKey": "customer-license.txt",
                "EmailBucketName":"funfangle-organization-emails-dev",
                "CarePackageImageBucketName": "funfangle-carepackages-dev",
                "InventoryImageBucketName": "funfangle-inventory-images-dev",
                "InventoryImageBucketLong": "https://s3.amazonaws.com/funfangle-inventory-images-dev",
            },
            "cognito": {
                "CognitoPoolId": "us-east-1:e57cba12-e855-42dd-ad42-7b8723df34fe",
                "CognitoClientId":"1vc4cfj0vkku5e22lu7fact7c9",
                "AccessKey": "AKIAJLHSOT73E7MDVYEA",
                "SecAccessKey": "GFefc8zDceky/jqj2CzkUPM8BqNKJBtMpmt3c8GN",
                "UserPoolId": "us-east-1_lEBeveE0r"
            },
            "constants": {
                "SquareApplicationId": "c3EwaWRwLW5CR3lPNk1LQjNHVXFQeGVQN3RTR3c="
            }
        },

        "staging": {
            "regions":{
                "UserImageBucketRegion": "us-east-2",
                "CognitoRegion": "us-east-2",
                "EmailBucketRegion":"us-east-2",
                "LicenseBucketRegion": "us-east-2",
                "CarePackageImageBucketRegion": "us-east-2",
                "InventoryImageBucketRegion": "us-east-2"
            },
            "endpoints": {
                "ApiEndpoint": "https://api-staging.funfangle.com/general",
                "BaseEndpoint":"https://api-staging.funfangle.com",
                "SquareEndpoint":"https://api-staging.funfangle.com/square",
                "TutorialEndpoint":"https://api-staging.funfangle.com/tutorial",
                "OrganizationEndpoint": "https://api-staging.funfangle.com/organizations",
                "EmailEndpoint": "https://api-staging.funfangle.com/emails",
                "PosEndpoint": "https://api-staging.funfangle.com/pos",
                "MiscEndpoint": "https://api-staging.funfangle.com/misc",
                "ImportEndpoint": "https://api-staging.funfangle.com/import",
                "AuthEndpoint":"https://api-staging.funfangle.com/cognito",
                "DevicesEndpoint": "https://api-staging.funfangle.com/device"
            },
            "buckets": {
                "UserImageBucket": "accounts-prod.funfangle.com",
                "LicenseBucketName":"funfangle-licensing-prod",
                "OrganizationLicenseKey":"organization-license.txt",
                "CustomerLicenseKey": "customer-license.txt",
                "EmailBucketName":"funfangle-organization-emails-prod",
                "CarePackageImageBucketName": "funfangle-carepackages",
                "InventoryImageBucketName": "funfangle-inventory-images-prod",
                "InventoryImageBucketLong": "https://s3.us-east-2.amazonaws.com/funfangle-inventory-images-prod"
            },
            "cognito": {
                "CognitoPoolId": "us-east-2:b100bec9-0383-4d62-be3a-fee5bfb517ee",
                "CognitoClientId":"4dttlg9nrt5detig9387udutjm",
                "AccessKey": "AKIAIWBVAEZPHFMG3RAQ",
                "SecAccessKey": "tgUY43nT5Wc7ruwJLlZx1zuFcYo5wFC8pPAwiTjX",
                "UserPoolId": "us-east-2_gaOOfvuUU"
            },
            "constants": {
                "SquareApplicationId": "c3EwaWRwLXBxX3JZTnl6WXdaTEdIRE5LdVFWRXc="
            }
        },

        "prod": {
            "regions":{
                "UserImageBucketRegion": "us-east-2",
                "CognitoRegion": "us-east-2",
                "EmailBucketRegion":"us-east-2",
                "LicenseBucketRegion": "us-east-2",
                "CarePackageImageBucketRegion": "us-east-2",
                "InventoryImageBucketRegion": "us-east-2"
            },
            "endpoints": {
                "ApiEndpoint": "https://api.funfangle.com/general",
                "BaseEndpoint": "https://api.funfangle.com",
                "SquareEndpoint":"https://api.funfangle.com/square",
                "TutorialEndpoint":"https://api.funfangle.com/tutorial",
                "OrganizationEndpoint": "https://api.funfangle.com/organizations",
                "EmailEndpoint": "https://api.funfangle.com/emails",
                "PosEndpoint": "https://api.funfangle.com/pos",
                "MiscEndpoint": "https://api.funfangle.com/misc",
                "ImportEndpoint": "https://api.funfangle.com/import",
                "AuthEndpoint":"https://api.funfangle.com/cognito",
                "DevicesEndpoint": "https://api.funfangle.com/device"
            },
            "buckets": {
                "UserImageBucket": "accounts-prod.funfangle.com",
                "LicenseBucketName":"funfangle-licensing-prod",
                "OrganizationLicenseKey":"organization-license.txt",
                "CustomerLicenseKey": "customer-license.txt",
                "EmailBucketName":"funfangle-organization-emails-prod",
                "CarePackageImageBucketName": "funfangle-carepackages",
                "InventoryImageBucketName": "funfangle-inventory-images-prod",
                "InventoryImageBucketLong": "https://s3.us-east-2.amazonaws.com/funfangle-inventory-images-prod"
            },
            "cognito": {
                "CognitoPoolId": "us-east-2:b100bec9-0383-4d62-be3a-fee5bfb517ee",
                "CognitoClientId":"4dttlg9nrt5detig9387udutjm",
                "AccessKey": "AKIAIWBVAEZPHFMG3RAQ",
                "SecAccessKey": "tgUY43nT5Wc7ruwJLlZx1zuFcYo5wFC8pPAwiTjX",
                "UserPoolId": "us-east-2_gaOOfvuUU"
            },
            "constants": {
                "SquareApplicationId": "c3EwaWRwLXBxX3JZTnl6WXdaTEdIRE5LdVFWRXc="
            }
        }
    }


}