
export class OrganizationUser{
    firstName: string;
    lastName: string;
    email: string;
    orgName: string;
    orgAddress: string;
    pin: string;
    confirmPin: string;
    nickName: string;
    timeZoneOffset: number;
    domainUrl: string;
    telephone: string;


    constructor(){
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.orgName = "";
        this.orgAddress = "";
        this.pin = "";
        this.confirmPin = "";
        this.nickName = "";
        this.timeZoneOffset = 0;
        this.domainUrl = "";
        this.telephone = "";
    }


}