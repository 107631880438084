import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Jsonp } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { Subject } from 'rxjs/Subject';

import { AuthService } from './auth.service';
import {
  AuthEndpoint,
  TutorialEndpoint,
  ApiEndpoint,
  UserImageBucket,
  UserImageBucketRegion,
  CognitoPoolId,
  SquareEndpoint,
  OrganizationEndpoint,
  EmailBucketName,
  EmailBucketRegion,
  OrganizationId,
  AWSConfig
} from '../app.constants';
import { OfflineMessage } from '../../app/models/offline-message';
import { TokenService } from './token.service';


declare var AWSCognito: any;
declare var AWS: any;


/*Service for interfacing with Cognito methods*/
@Injectable()
export class UserService {


  public user: any = null;
  public idToken: string = "";
  public manageFundsUser: any = null;
  public warningMessage: OfflineMessage = null;

  //Define subscriptions
  //dynamo user subscription
  public userSubscription = new Subject<any>();
  userSubscription$ = this.userSubscription.asObservable();

  public warningMessageSubscription = new Subject<OfflineMessage>();
  warningMessageSubscription$ = this.warningMessageSubscription.asObservable();

  constructor(
    public http: Http,
    public authService: AuthService,
    public jsonp: Jsonp
  ) { }


  refreshUserDynamo(user: any){
    console.log("refreshing user dynamo!", user);
    this.userSubscription.next(user);
  }



  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      //AWSCognito is not defined w/o timeout here
      setTimeout(() => {
        console.log("Init aws cognito user-service???");
        this.authService.initializeAWSCognito();
        this.authService.getUserFromStorage()
          .then(res => {

            var isParent2 = false;
            console.log("cognito user res:", res[1].length);

            if(res[1] != undefined){
              for(var index = 0; index < res[1].length; index++){
                if(res[1][index].Name == 'custom:account-role'){
                  console.log("account role:", res[1][index].Value);
                  if(res[1][index].Value == "true"){
                    isParent2 = true;
                  }
                }
              }
            }


            this.idToken = res[0].signInUserSession.idToken.jwtToken;
            TokenService.setIdToken(this.idToken);
            const email = res[0].username;

            if(!isParent2){
              this.getUserDynamo(this.idToken, email)
              .then(res =>{
                var body = JSON.parse(res._body);
                var userData = body.Item;
                this.user = userData;
                this.user.idToken = this.idToken;
                resolve(this.user);
              })
              .catch(err => {
                console.log("get cognito user err:", err);
              })
            }else{
              console.log("it's parent 2!!");
              this.getParent2Dynamo(this.idToken, email)
              .then(res => {
                console.log("get parent 2 res:", res);
                var body = JSON.parse(res._body);
                let parents = body.message.Items;
                if (parents.length > 1) {
                  let orgId = OrganizationId;
                  let temp = parents.filter( parent => parent.organizationId === OrganizationId);
                  if (temp.length > 0) parents = temp;
                }
                var userData = body.message.Items[0];
                this.user = userData;
                this.user.firstName = this.user.parent2FirstName;
                this.user.lastName = this.user.parent2LastName;
                this.user.idToken = this.idToken;
                resolve(this.user);
              })
              .catch(err => {
                reject(false);
              })
            }



          })
          .catch(err => {
            reject(err);
          })
      }, 100)
    })
  }

  getParent2Dynamo(idToken: string, email: string): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      email: email
    }
    this.idToken = idToken;
    return this.http
      .post(`${OrganizationEndpoint}/user/getParent2/`, data, options)
      .toPromise()
  }

  setUser(user: any, idToken: string): void {
    this.user = user;
    this.user.idToken = idToken;
  }

  getCurrentUser(): Promise<any>{
    if(this.user != null){
      return Promise.resolve(this.user);
    }else{
      return new Promise((resolve,reject) => {

      })
    }
  }

  createUserDynamo(userId: string, email: string, role: string, idToken: string): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
      email: email,
      userId: userId,
      role: role
    }
    return this.http
      .post(`${ApiEndpoint}/user/create/`, data, options)
      .toPromise()
  }

  //helper function to add http headers
  getHeaders(idToken: string): RequestOptions {
    var headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', idToken);
    var options = new RequestOptions({ headers: headers });
    return options;
  }

  //get the user info from the database
  getUserDynamo(idToken: string, email: string): Promise<any> {
    var actingAsUserEmail = window.localStorage.getItem("actingAsUser");

    if(actingAsUserEmail != "null" && actingAsUserEmail != undefined && actingAsUserEmail != null){
      email = actingAsUserEmail;
    }
    const options = TokenService.getHeaders();
    const data = {
      email,
      organizationId: OrganizationId
    }
    this.idToken = idToken;
    return this.http
      .post(`${ApiEndpoint}/user/get/`, data, options)
      .toPromise()
  }

  //add a new authorized user to the account
  createAuthorizedUser(idToken: string, newUser: any, ownerId: string, organizationId: string, image: string = ""): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      pin: newUser.pin,
      ownerId: ownerId,
      organizationId: organizationId,
      dailySpendingLimit: newUser.dailySpendingLimit,
      nickname: newUser.nickname,
      session: newUser.session,
      isStaff: newUser.isStaff
    }
    console.log("data:", data);
    return this.http
      .post(`${ApiEndpoint}/authorizedUser/create/`, data, options)
      .toPromise()
  }

  //get all the authorized users associated with account-holder
  getAuthorizedUsers(idToken: string, userId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(`${ApiEndpoint}/authorizedUsers/get/?accountHolder=` + userId, options)
      .toPromise()
  }

  //update the existing user in the db
  updateUser(idToken: string, editUser: any, ownerId: string, image: string = ""): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
      firstName: editUser.firstName,
      lastName: editUser.lastName,
      pin: editUser.pin,
      accountHolder: ownerId,
      userId: editUser.userId,
      dailySpendingLimit: editUser.dailySpendingLimit,
      nickname: editUser.nickname,
      street: editUser.street,
      city: editUser.city,
      state: editUser.state,
      zip: editUser.zip,
      session: editUser.session,
      isStaff: editUser.isStaff
    }
    console.log("data:", data);
    return this.http
      .post(ApiEndpoint + "/authorizedUser/update/", data, options)
      .toPromise()
  }

  //remove a user from the db
  deleteUser(idToken: string, userId: string, ownerId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .delete(ApiEndpoint + "/authorizedUser/delete/?userId=" + userId + "&accountHolder=" + ownerId, options)
      .toPromise()
  }


  //get the user by their id
  getUserById(userId: string): Promise<any>{
    //no auth header for this one
    var headers = new Headers({ 'Content-Type': 'application/json' });
    var options = new RequestOptions({ headers: headers });
    return this.http
      .get(ApiEndpoint + "/user/getById?userId=" + userId, options)
      .toPromise();

  }


  //set the user's status to verified
  setUserVerified(email: string, custNum, userId, organizationId){
    //no auth header for this one
      const headers = new Headers({ 'Content-Type': 'application/json' });
      const options = new RequestOptions({ headers: headers });
      const data = {
        email: email,
        userId: userId,
        parentNum: custNum,
        organizationId
      }
      console.log("sending data:", data);
      return this.http
        .post(`${ApiEndpoint}/user/setUserVerified`, data, options)
        .toPromise();
  }


  uploadUserImageDynamo(idToken: string, fileUrl: string, userId: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      userId,
      imageURL: fileUrl,
      organizationId
    }
    console.log("data:", data);
    //return Promise.reject(data);
    return this.http
      .post(ApiEndpoint + "/authorizedUser/uploadUserImage", data, options)
      .toPromise();
  }

  getWarningMessage() {
    return this.warningMessage;
  }

  setWarningMessage(warningMessage: OfflineMessage) {
    this.warningMessage = warningMessage;
    this.warningMessageSubscription.next(warningMessage);
  }

  
  loadOfflineMessages(): Promise<OfflineMessage[]> {
    return this.http
      .get(AuthEndpoint + "/offline/getMessages")
      .map( (res: any) => JSON.parse(res._body).message.Items)
      .toPromise();
  }



  uploadUserImageS3(photoKey: string, file: any): Promise<any>{
    var albumBucketName = UserImageBucket;
    var bucketRegion = UserImageBucketRegion;
    AWS.config.update(AWSConfig(bucketRegion));

    let s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {Bucket: albumBucketName}
    });

    return new Promise( (resolve, reject) => {
      s3.upload({
        Key: photoKey,
        Body: file,
        ACL: 'public-read'
      }, function(err, data) {
        if (err) {
          console.log("upload failed:", err);
          reject(err);
        }else{
          console.log("UPLOADED:", data);
          resolve(data);
        }
      });
    });
  }



  // get an authorized user's balance
  getAuthorizedUserBalances(idToken:string, authUserIds: string[]): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      userIds: authUserIds,
    }
    return this.http
      .post(SquareEndpoint + "/balance/authUser", data, options)
      .toPromise();
  }


  getAllOrganizations(idToken: string): Promise<any> {
    console.log('DISABLED ENDPOINT!!');
    // var options = TokenService.getHeaders();

    // return this.http
    //     .get(OrganizationEndpoint + "/organizations/getAllOrganizations", options)
    //     .toPromise();
    return Promise.resolve();
  }


  getAllUsersByOrganization(idToken: string, organizationId: string): Promise<any> {
    console.log('DISABLED ENDPOINT!!');
    // var options = TokenService.getHeaders();
    // var data = {
    //   organizationId: organizationId
    // }
    // return this.http
    //     .post(TutorialEndpoint + "/organizations/getAllUsersByOrganization", data, options)
    //     .toPromise();
    return Promise.resolve();
  }

  // set the manage funds user
  setManageFundsUser(user: any){
    this.manageFundsUser = user;
  }

  // get the manage funds user
  getManageFundsUser(): any{
    return this.manageFundsUser;
  }


  updateUserInfo(idToken: string, email: string, firstName: string, lastName: string, phoneNumber: string, nickName: string, organizationId: string): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      email,
      firstName,
      lastName,
      phone: phoneNumber,
      nickName,
      organizationId
    }
    // return Promise.reject(data);
    return this.http
      .post(`${ApiEndpoint}/user/updateInfo`, data, options)
      .toPromise();
    }

getOrgInfo(organizationId: string){
  var headers = new Headers({ 'Content-Type': 'application/json' });
  var options = new RequestOptions({ headers: headers });
  return this.http
    .get(OrganizationEndpoint + "/organization/get/" + organizationId, options)
    .toPromise();
}

sendFirstEmailAfterVerification(user:any, orgData: any, parentNum, orgId: string, num:any){
  var emailKey = orgId + "/parentFirstEmailAfterActivation.html";
  var bucketName = EmailBucketName;
  var bucketRegion = EmailBucketRegion;
  var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
  var androidUrl = window.localStorage.getItem("androidUrl");
  
  AWS.config.update(AWSConfig(bucketRegion));

  var s3 = new AWS.S3({
    apiVersion: '2006-03-01',
  });
  var firstEmail: any;
  var orgInfo = orgData
  orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");

  var parentNum = user.parentNum;
  if(parentNum != null && parentNum != undefined){
      if(parentNum == 2){
          user.email = user.parent2Email;
          user.firstName = user.parent2FirstName;
      }

  }
  var params = {
    Bucket: EmailBucketName,
    Key: emailKey
  }
  return new Promise((resolve, reject) => {
    var email: any;
    var email2: any;
    s3.getObject(params, (err, data) => {

      if(err){
        reject(err);
      }else{
        email = data.Body.toString('ascii');
        email = email.replace(/{{firstName}}/g, user.firstName);
        email = email.replace(/{{lastName}}/g, user.lastName);
        email = email.replace(/{{userId}}/g, user.userId);
        email = email.replace(/{{webUrl}}/g, orgData.domainUrl);
        email = email.replace(/{{email}}/g, user.email);
        email = email.replace(/{{parentNum}}/g, parentNum);
        email = email.replace(/{{orgPhone}}/g, orgData.telephone);
        email = email.replace(/{{orgEmail}}/g,orgData.email);
        email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
        if (iPhoneUrl && androidUrl) {
          email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
          email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
        }
        email2 = data.Body.toString('ascii');
        email2 = email2.replace(/{{firstName}}/g, user.parent2FirstName);
        email2 = email2.replace(/{{lastName}}/g, user.parent2LastName);
        email2 = email2.replace(/{{userId}}/g, user.userId);
        email2 = email2.replace(/{{email}}/g, user.parent2Email);
        email2 = email2.replace(/{{webUrl}}/g, orgData.domainUrl);
        email2 = email2.replace(/{{parentNum}}/g, 2);
        email2 = email2.replace(/{{orgPhone}}/g, orgData.telephone);
        email2 = email2.replace(/{{orgEmail}}/g,orgData.email);
        email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
        if (iPhoneUrl && androidUrl) {
          email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
          email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
        }

        var headers = new Headers({ 'Content-Type': 'application/json' });
        var options = new RequestOptions({ headers: headers });
        var subject = "FunFangle Account Activated"
        if(num == 1){
          var d = {
            user: user,
            userEmail: user.email,
            subject: subject,
            orgData:orgData,
            webUrl: orgData.domainUrl,
            parentNum: parentNum,
            t: email,
          }
        }else if(num == 2){
          var d = {
            user: user,
            userEmail: user.parent2Email,
            subject: subject,
            orgData:orgData,
            webUrl: orgData.domainUrl,
            parentNum: parentNum,
            t: email2,
          }
        }




        firstEmail = this.http
          .post(ApiEndpoint + "/parents/firstAfterActivation", d,options)
          .toPromise();
                resolve(firstEmail);
            }
    })

  })
}
getOrganizationTotalSales(idToken: string, organizationId: string){
  var options = TokenService.getHeaders();
  var data = {
    organizationId: organizationId
  }
  return this.http
  .post(SquareEndpoint + "/transactions/getTransactionsByOrganizationId",data, options)
  .toPromise();
}

getTodaysTransactions(idToken:string, organizationId: string, timeZoneOffset: number, lastEvalKey = null){
  var options = TokenService.getHeaders();
  var data = {
    organizationId: organizationId,
    timeZoneOffset: timeZoneOffset
  }

  if(lastEvalKey != null){
    data['lastEvalKey'] = lastEvalKey;
  }

  return this.http 
    .post(TutorialEndpoint + "/transactions/getTodaysTransactions", data, options)
    .toPromise();
}

getUserTransaction(idToken: string, accountHolder: string): Promise<any>{
  var options = TokenService.getHeaders();
  var data = {
    accountHolder: accountHolder
  }
  return this.http
    .post(SquareEndpoint + "/transactions/getTransactionsByAccountHolderId", data, options)
    .toPromise();
}


getIPAddress(): Observable<any>{
  return this.jsonp.get('//api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK')
}

}
