import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { BaseEndpoint, OrganizationEndpoint, SquareEndpoint, TutorialEndpoint, PosEndpoint, MiscEndpoint, 
  ImportEndpoint, DevicesEndpoint, ApiEndpoint} from '../app.constants';
import { Till } from '../../app/models/till';
import { FunFangleReportData } from '../../app/models/reports';
import { TokenService } from './token.service';
import { DateTime } from 'luxon';

declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class ReportsService {

  public user: any = null;
  public idToken: string = "";

  constructor(
    public http: Http,
  ) { }

  getReportFunds(idToken, organizationId){
    console.log(organizationId);
  }

  camperAllocationsReport(idToken: string, organizationId: string, defaultTimeZone, session = null): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      organizationId: organizationId,
      defaultTimeZone: defaultTimeZone,
      session: session
    }
    return this.http
      .post(TutorialEndpoint + "/reports/camperAllocations", data, options)
      .toPromise();
  }

  loadDonations(idToken: string, organizationId: string, defaultTimeZone, startSeconds, endSeconds, session = null): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      organizationId: organizationId,
      defaultTimeZone: defaultTimeZone,
      startTime: startSeconds,
      endTime: endSeconds,
      session: session

    }
    return this.http
      .post(TutorialEndpoint + "/reports/donationSummaryReport", data, options)
      .toPromise();
  }

  loadUnallocatedFunds(idToken: string, organizationId: string, startKey: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      organizationId: organizationId,
      lastEvalKey: startKey
    }
    return this.http
      .post(`${TutorialEndpoint}/reports/unAllocatedFundsReport`, data, options)
      .toPromise();
  }

  sessionSummaryReport(idToken:string, organizationId: string, startSeconds, endSeconds): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      startTime: startSeconds,
      endTime: endSeconds
    }
    return this.http
      .post(`${MiscEndpoint}/reports/sessionSummaryReport`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  sessionTendersReport(organizationId: string, sessions: any[]): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      sessions
    }
    return this.http
      .post(`${MiscEndpoint}/reports/sessionTendersReport`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  locationSummaryReport(organizationId: string, lowerISO: string, upperISO: string): Promise<any> {
    const options = TokenService.getHeaders();
    let url = `${BaseEndpoint}/v2/bank/report/locationsummary`
      + `?organizationId=${organizationId}&lowerISO=${lowerISO}&upperISO=${upperISO}`;
    return this.http
      .get(url, options)
      .toPromise();
  }

  // sessionSummaryReport(idToken:string, organizationId: string, session: string, timeZoneOffset: number): Promise<any> {
  //   var options = TokenService.getHeaders();
  //   var data = {
  //     organizationId,
  //     session,
  //     timeZoneOffset
  //   }
  //   return this.http
  //     .post(`${TutorialEndpoint}/reports/sessionSummaryReport`, data, options)
  //     .toPromise();
  // }

  loadPendingChecks(idToken:string, organizationId: string): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
      organizationId
    }
    return this.http
      .post(`${TutorialEndpoint}/reports/pendingCheckReport`, data, options)
      .toPromise();
  }

  finishCheck(idToken: string, authUserId: string, timeStamp, status): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
      authUserId,
      timeStamp,
      status
    }
    return this.http
      .post(TutorialEndpoint + "/transactions/finishCheck", data, options)
      .toPromise();
  }

  loadWholeSaleValues(idToken:string, organizationId: string, locationId: string): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId: organizationId,
      locationId: locationId
    };

    return this.http
      .post(PosEndpoint + "/reports/getWholeSaleReport", data, options)
      .toPromise();
  }

  loadSessionActivitiesReport(organizationId: string, lowerDT: DateTime, upperDT: DateTime): Promise<any> {
    console.log('loadSessionActivitiesReport', organizationId, lowerDT.toISO(), upperDT.toISO());
    const options = TokenService.getHeaders();
    let url = `${BaseEndpoint}/v2/session/events/organization`
      + `?organizationId=${organizationId}&lower=${lowerDT.valueOf() / 1000}&upper=${upperDT.valueOf() / 1000}`;
    return this.http
      .get(url, options)
      .toPromise();
  }

  loadTendersReport(idToken: string, organizationId: string, defaultTimeZone, startSeconds, endSeconds): Promise<any> {
    console.log("load tenders report");
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      defaultTimeZone,
      startSeconds,
      endSeconds
    };

    return this.http
      .post(MiscEndpoint + "/reports/getTendersReport", data, options)
      .toPromise();
  }

  loadTotalInvReport(idToken: string, organizationId: string, locationIds: string[], locationNames: string[]): Promise<any>{

    let options = TokenService.getHeaders();
    let data = {
      organizationId: organizationId,
      locationIds: locationIds,
      locationNames: locationNames
    };

    return this.http
      .post(PosEndpoint + "/reports/getTotalInvReport", data, options)
      .toPromise();

  }

  loadNoWristbandsReport(idToken: string, organizationId: string, sessionString: string): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      sessionString
    };
    console.log("data:", data);
    return this.http
      .post(MiscEndpoint + "/reports/noWristbandsReport", data, options)
      .toPromise();
  }

  loadZeroBalancesReport(idToken: string, organizationId: string, sessionString: string): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      sessionString
    };
    console.log("data:", data);
    return this.http
      .post(MiscEndpoint + "/reports/zeroBalancesReport", data, options)
      .toPromise();
  }

  loadDepositsReport(idToken: string, organizationId: string, sessionString: string, selectedMethods: string[], startSeconds, endSeconds): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      sessionString,
      selectedMethods, 
      startSeconds,
      endSeconds
    }
    console.log(data);
    return this.http
      .post(ImportEndpoint + "/reports/depositsReport", data, options)
      .toPromise();
  }

  listOrganizationTills(idToken: string, organizationId: string): Promise<Till[]> {
    let options = TokenService.getHeaders();
    return this.http
      .get(BaseEndpoint + `/v2/bank/till/list?organizationId=${organizationId}`, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  // listTransactionsInSettlement(organizationId, email, settlementId): Promise<FunFangleReportData> {
  listSquareSettlements(organizationId, lowerISO, upperISO, locationId?: string): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let url = `${BaseEndpoint}/v2/bank/providers/square/settlement/list?organizationId=${organizationId || ''}&lowerISO=${lowerISO || ''}&upperISO=${upperISO || ''}`;
    if (locationId !== undefined) url = `${url}&locationId=${locationId}`;
    return this.http
      .get(url, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  // loadSquareSettlementsReport(organizationId, email, startSeconds, endSeconds): Promise<FunFangleReportData> {
  listSquareSettlementDetail(organizationId, locationId, transactionId): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let url = `${BaseEndpoint}/v2/bank/providers/square/settlement/detail?organizationId=${organizationId || ''}&locationId=${locationId || ''}&transactionId=${transactionId || ''}`;
    return this.http
      .get(url, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  loadSquareDepositsReport(idToken: string, organizationId, startSeconds, endSeconds): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      startSeconds,
      endSeconds
    };
    return this.http
      .post(`${OrganizationEndpoint}/reports/getSquareReconciliationReport`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  loadRemainingCashReport(idToken: string, organizationId, sessionString): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      sessionString
    };
    return this.http
      .post(`${SquareEndpoint}/refunds/getCashRefunds`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  loadSalesReport(idToken: string, organizationId: string, startSeconds: number, endSeconds: number): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      startSeconds,
      endSeconds
    };
    return this.http
      .post(`${ImportEndpoint}/reports/saleQuantityBySession`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }

  loadRefundsReport(idToken: string, organizationId: string, sessionString: string, startSeconds, endSeconds, timeZoneOffset, loadWithdrawals): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      sessionString,
      startSeconds,
      endSeconds,
      timeZoneOffset,
      loadWithdrawals
    }
    console.log(data);
    return this.http
      .post(ImportEndpoint + "/reports/refundsReport", data, options)
      .toPromise();
  }

  loadZReportData(idToken: string, organizationId: string, startSeconds: number, endSeconds: number, defaultTimeZone, startDateString, endDateString ): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      defaultTimeZone: defaultTimeZone,
      startTime: startSeconds,
      endTime: endSeconds,
      startDateString: startDateString,
      endDateString: endDateString
    };
    console.log("data:", data);
    return this.http
      .post(MiscEndpoint + "/reports/zReport", data, options)
      .toPromise();
  }

  loadSpecialSummaryReport(idToken: string, organizationId: string, startSeconds: number, endSeconds: number) {
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      startSeconds,
      endSeconds
    }
    return this.http
      .post(ImportEndpoint + "/reports/specialSummaryReport", data, options)
      .toPromise();
  }

  remainingBalancesInitializeRefund(organizationId: string, authIds: string[], sessionId: string, sessionString: string): Promise<FunFangleReportData> {
    let options = TokenService.getHeaders();
    let data = {
      authIds,
      organizationId,
      sessionId,
      sessionString
    };
    return this.http
      .post(`${BaseEndpoint}/v2/bank/refund/balance`, data, options)
      .map( (res: any) => { let body = JSON.parse(res._body); return body; })
      .toPromise();
  }
}
