import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BaseEndpoint, } from "../app.constants";
import { TokenService } from './token.service';

@Injectable()
export class InventoryCodeService {

  constructor(public http: Http) {
  }

  listCodes(idToken: string, organizationId: string, itemId?: string, modifierKey?: string): Promise<any> {
    let options = TokenService.getHeaders();

    let url = BaseEndpoint + "/v3/inventory/codes?orgid=" + organizationId;
    if (itemId !== undefined) url = url + "&item_id=" + itemId;
    if (modifierKey !== undefined) url = url + "&modifier_key=" + modifierKey;

    return this.http
      .get(url, options)
      .toPromise();
  }

}