import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BaseEndpoint, OrganizationEndpoint, PosEndpoint, EmailBucketName, EmailBucketRegion, CognitoPoolId, MiscEndpoint, AuthEndpoint, DevicesEndpoint, AWSConfig } from "../app.constants";
import { TokenService } from './token.service';

declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class OrganizationSettingsService {

  constructor(public http: Http) {
  }

  getSessionMessage(idToken: string, organizationId: string): Promise<any> {
    var options = TokenService.getHeaders();
      return this.http
        .get(OrganizationEndpoint + "/organization/" + organizationId, options)
        .toPromise();
    }

  updateSessionMessage(idToken: string, editSessionMessage: any, organizationId: string){
    var options = TokenService.getHeaders();
    var data = {
      endSessionMessage: editSessionMessage.endSessionMessage,
      showEndSessionMessage: editSessionMessage.showEndSessionMessage,
      organizationId: organizationId
    }
    console.log("Data",data)
    return this.http
      .post(OrganizationEndpoint + "/organization/update" , data,options)
      .toPromise();

  }
  updateTaxRate(idToken: string, rates: any, organizationId: string){
    var options = TokenService.getHeaders();
    var data = {
      taxRate:  rates.taxRate,
      employeeDiscount: rates.employeeDiscount,
      organizationId: organizationId
    }
    return this.http
      .post(OrganizationEndpoint + "/organization/updateTaxRate" , data,options)
      .toPromise();
  }
  addPOSLocation(idToken: string, POSLocation: any, organizationId: string, offset: number): Promise<any>{

    let timeZone = 'America/New_York';
    if (offset === -5) timeZone = 'America/Chicago';
    if (offset === -6) timeZone = 'America/Denver';
    if (offset === -7) timeZone = 'America/Los_Angeles';
    if (offset === -8) timeZone = 'America/Anchorage';

    var options = TokenService.getHeaders();
    var data = {
      locationName: POSLocation.locationName,
      organizationId: organizationId,
      // applyDailyLimits: POSLocation.applyDailyLimits,
      applyDailyLimits: true,
      timeZone,
      timeZoneOffset: String(offset)
    }
    return this.http
      .post(BaseEndpoint + "/v2/profile/location/upsert", data, options)
      .toPromise();
  }

  getPOSLocations(idToken: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(BaseEndpoint + "/v2/profile/location/list?organizationId=" + organizationId, options)
      .toPromise();
  }

  removeLocation(idToken: string , locationId: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      locationId : locationId,
      organizationId
    }
    return this.http
    .post(BaseEndpoint + "/v2/profile/location/delete" , data, options)
    .toPromise();
  }


  editLocation(idToken: string, locationId: string, locationName: string, applyDailyLimits: boolean, offset: number, organizationId: string): Promise<any>{
    let timeZone = 'America/New_York';
    if (offset === -5) timeZone = 'America/Chicago';
    if (offset === -6) timeZone = 'America/Denver';
    if (offset === -7) timeZone = 'America/Los_Angeles';
    if (offset === -8) timeZone = 'America/Anchorage';

    let options = TokenService.getHeaders();
    let data = {
      locationId,
      organizationId,
      locationName,
      // applyDailyLimits: applyDailyLimits,
      timeZone,
      timeZoneOffset: String(offset)
    }

    return this.http
      .post(BaseEndpoint + "/v2/profile/location/upsert", data, options)
      .toPromise();
  }


  updateItemsColumnSettings(idToken: string, organizationId: string, enabled: boolean): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      enabled: enabled,
      organizationId: organizationId
    }

    return this.http
      .post(PosEndpoint + "/settings/updateItemsColumnSettings", data, options)
      .toPromise();
  }

  updatePhotoRequiredSettings(idToken: string, organizationId: string, camperPhotosRequired: boolean): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      camperPhotosRequired,
      organizationId
    }

    return this.http
      .post(MiscEndpoint + "/settings/updatePhotoRequiredSettings", data, options)
      .toPromise();
  }

  getOrganizationEmails(organizationId: string, emailKeyNames: string[]): Promise<any>{
    let bucketName = EmailBucketName;
    let bucketRegion = EmailBucketRegion;

    AWS.config.update(AWSConfig(bucketRegion));

    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    let promises: Promise<any>[] = [];
    console.log("passed in keys:", emailKeyNames);
    emailKeyNames.forEach( emailKey => {
      let key = organizationId + "/" + emailKey;
      let params = {
        Bucket: EmailBucketName,
        Key: key
      }
      let promise = new Promise((resolve,reject) => {
        s3.getObject(params, (err,data) => {
          if(err){
            //don't want one failure to mess it up for the rest
            resolve(null);
          }else{
            resolve(data.Body.toString('ascii'));
          }
        })
      });
      promises.push(promise);
    })


    return Promise.all(promises);
  }

  getSquareUrl(idToken: string, organizationId: string, email: string): Promise<string> {
    let data = {
      organizationId,
      email
    };
    let options = TokenService.getHeaders();
    return this.http
      .post(AuthEndpoint + "/square/getAuthUrl", data, options)
      .map( (res: any) => JSON.parse(res._body).url)
      .toPromise();
  }

  loadXCampbrainSessions(organizationId: string) {
    const options = TokenService.getHeaders();
    return this.http
      .get(`${DevicesEndpoint}/organizations/${organizationId}/getSessions`, options)
      .map( (res: any) => JSON.parse(res._body).XCampbrainSessions )
      .toPromise();
  }

  whitelistXCampbrainSession(organizationId: string, activeUserEmail: string, XCampbrainSessionId: string, sessionEndKey: string, whitelist: boolean) {
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      activeUserEmail,
      XCampbrainSessionId,
      sessionEndKey,
      whitelist
    }
    return this.http
      .post(`${DevicesEndpoint}/session/whitelist`, data, options)
      .toPromise();
  }

  closeSessions(organizationId: string, activeUserEmail: string, XCampbrainSessions: string, override: boolean = false): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      XCampbrainSessions,
      activeUserEmail,
      override
    }
    return this.http
      .post(`${DevicesEndpoint}/session/closeSession`, data, options)
      .toPromise();
  }

  updateOrganizationEmail(emailKey:string, organizationId: string, file: any): Promise<any>{
    let bucketName = EmailBucketName;
    let bucketRegion = EmailBucketRegion;

    AWS.config.update(AWSConfig(bucketRegion));

    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    let key = organizationId + "/" + emailKey;

    return new Promise( (resolve, reject) => {
      s3.upload({
        Key: key,
        Body: file,
        Bucket: bucketName,
        ACL: 'public-read'
      }, function(err,data) {
        if (err) {
          console.log("upload failed:", err);
          reject(err);
        }else{
          console.log("UPLOADED:", data);
          resolve(data);
        }
      })
    })


  }

}
