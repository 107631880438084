import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Decimal } from 'decimal.js';
import { LoadingService } from '../services/loading.service';
import { UserService } from '../services/user.service';
import { ReportsService } from '../services/reports.service';
import { CustomerService } from '../services/customer.service';
import { ContributorService } from '../services/contributor.service';
import { TransactionsService } from '../services/transactions.service';
import { OrganizationCampersService } from '../services/organization-campers.service';
import { OrganizationSettingsService } from '../services/organization-settings.service';
import { OrganizationService } from '../services/organization.service';
import json2csv from 'json2csv/lib/json2csv';
import { DialogsService } from '../dialog/dialog.service';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { Till } from '../../app/models/till';
import {
  SquareReport,
  FunFangleReport,
  FunFangleReportData,
  SalesReport,
  RemainingBalancesWithCashReport,
  SessionReport,
  SessionTendersReport,
  SquareSettlementReport
} from '../../app/models/reports';
import { DateTime } from 'luxon';
import * as moment from 'moment-timezone';

// declare var moment: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public user: any = null;
  public idToken: string;
  public cust = [];
  public originalCustomers = [];
  public selectedReport: FunFangleReport;
  public selectedData: FunFangleReportData = { json: [], csv: '' };
  public settlementDetail: FunFangleReportData = { json: [], csv: '' };
  public withFunds = [];
  public allTransactions = [];
  public showAllocationCard = false;
  public showBelowThresholdCard = false;
  public showDepositsReport = false;
  public showDonationCard = false;
  public showInventoryTotals = false;
  public showLocationCard = false;
  public showNoWristbandReport = false;
  public showPendingCheck = false;
  public showRefundCard = false;
  public showRefundsReport = false;
  public showSessionActivitiesReport = false;
  public showSessionSummaryCard = false;
  public showSettlementDetail = false;
  public showSettlementsReport = false;
  public showSpecialSummaryReport = false;
  public showTendersReport = false;
  public showTillZReport = false;
  public showTransaction = false;
  public showUnallocatedFunds = false;
  public showWholeSaleVals = false;
  public showWithBalance = false;
  public showWithdrawalsReport = false;
  public showZeroBalancesReport = false;
  public showZReport = false;
  public transactionActivity = [];
  public campers = [];
  public balanceByUser = [];
  public originalCampers = [];
  public myContribution = [];
  public showCamperBalance = false;
  public showCamper = false;
  public parent1 = [];
  public parent2 = [];
  public tActivity = [];
  public test = [];
  public organization: any = null;
  public refundThreshold = 5;
  public sessions: any = null;
  public allSessions: any = null;
  public selectedSession: any = null;
  public selectedSessions: string | null = null;
  public selectedLocation: any = null;
  public authUserIds = [];
  public userFirstNames = [];
  public userLastNames = [];
  public showCheckBox = false;
  public selectedUsers = [];
  public tableData = null;

  public allLocations = [];

  public paymentMethods = ['All Methods', 'Cash', 'Credit', 'Check', 'Other', 'Registration'];
  public selectedMethods = ['All Methods'];

  public selectType = 'Session';
  public selectTypeOptions = [
    'Session', 'Date Range'
  ]

  public refundsTableData: any = [];
  public wholeSaleTableData: any = [];
  public csvData: any = null;

  public report: FunFangleReport = {
    name: '',
    fileName: '',
    displayName: ''
  }

  public singleDate: any;

  // public dateInputs: any = [
  //     {
  //         start: moment().subtract(12, 'month'),
  //         end: moment().subtract(6, 'month')
  //     },
  //     {
  //         start: moment().subtract(9, 'month'),
  //         end: moment().subtract(6, 'month')
  //     },
  //     {
  //         start: moment().subtract(4, 'month'),
  //         end: moment()
  //     },
  //     {
  //         start: moment(),
  //         end: moment().add(5, 'month'),
  //     }
  // ];

  thisYear = (new Date()).getFullYear();
  start = new Date('1/1/' + this.thisYear);

  public mainInput = {
      start: moment().subtract(7, 'days'),
      end: moment((new Date()).valueOf())
  }

  refundReports: FunFangleReport[] = [
    {
      name: 'Remaining Balances',
      description: 'All accounts with remaining balances',
      slug: 'REMAINING_BALANCES'
    },
    // {
    //   name: 'Refund Batches',
    //   description: 'Report of refunds, submitted in batch',
    //   slug: 'REFUND_BATCHES'
    // },
    {
      name: 'Refunds Report',
      description: 'Refunds for each camper, by date range',
      slug: 'ACCOUNT_REFUNDS'
    },
    {
      name: 'Below threshold',
      description: 'All balances below threshold with and without a credit card',
      slug: 'BELOW_THRESHOLD'
    },
    // {
    //   name: 'Remaining Balance with card',
    //   description: 'All balances with credit card',
    //   slug: 'BALANCES_WITH_CARD'
    // },
    // RemainingBalancesWithCashReport,
    {
      name: 'Pending Check Refunds',
      description: 'Refunds to be processed manually (i.e. via cash, check)',
      slug: 'PENDING_CHECKS'
    },

  ];
  salesReports: FunFangleReport[] = [
    {
      name: 'Transactions',
      description: 'Transaction details',
      slug: 'TRANSACTIONS'
    },
    {
      name: 'Location Summary Report',
      description: 'Sales details by location',
      slug: 'LOCATION_SUMMARY'
    },
    {
      name: 'Location Sub-Grouping Report',
      description: 'Sales breakdown by item, location, category, tab over date range',
      slug: 'GROUPED_SALES_REPORT'
    },
    {
      name: 'Tender Type Report',
      description: 'Transaction details for individual sales',
      slug: 'TENDERS'
    },
  ];
  miscReports: FunFangleReport[] = [
    {
      name: 'Deposits Report',
      description: 'Deposit methods for each account, by session or date range',
      slug: 'ACCOUNT_DEPOSITS'
    },
    {
      name: 'Allocations Report',
      description: 'Report of allocations to all account accounts',
      slug: 'ACCOUNT_ALLOCATIONS'
    },
    {
      name: 'Unallocated Funds Report',
      description: 'All unallocated funds',
      slug: 'ACCOUNT_UNALLOCATIONS'
    },
    {
      name: 'Zero Balances Report',
      description: 'Accounts with $0 balance by session',
      slug: 'ZERO_BALANCES'
    },
    {
      name: 'Withdrawals Report',
      description: 'Withdrawals for each camper, by date range',
      slug: 'ACCOUNT_WITHDRAWALS'
    },
    {
      name: 'Accounts Without Wristbands',
      description: 'Accounts without wristbands assigned',
      slug: 'ACCOUNT_NO_WRISTBAND'
    },
  ];
  inventoryReports: FunFangleReport[] = [
    SalesReport,
    {
      name: 'Inventory Item Wholesale Report',
      description: 'Wholesale values for all items by location',
      slug: 'INVENTORY_WHOLESALE'
    },
    {
      name: 'Inventory Location Wholesale Summary',
      description: 'Wholesale totals for all locations',
      slug: 'INVENTORY_TOTALS'
    }
  ];
  financialReports: FunFangleReport[] = [
    {
      name: 'Donation Report',
      description: 'Report of all donations',
      slug: 'DONATIONS'
    },
  ]
  sessionReports: FunFangleReport[] = [
    {
      name: 'Admissions Report',
      description: 'Report of all activity admissions',
      slug: 'SESSION_ACTIVITIES'
    }
  ]

  fields = ['firstName', 'lastName', 'parent2FirstName', 'parent2LastName', 'availableBalance', 'totalFunds'];
  transactionsActivityFields = ['userFirstName', 'userLastName', 'date', 'session', 'location', 'items', 'amount', 'status'];
  // donationReportFields = ['userFirstName', 'userLastName', 'date', '']

  camperBalanceFields = ['firstName', 'lastName', 'parent1', 'parent2', 'currentBalance'];

  public ascendingSort = true;

  constructor(
    public router: Router,
    public userService: UserService,
    public loadingService: LoadingService,
    public reportsService: ReportsService,
    public customerService: CustomerService,
    public contributorService: ContributorService,
    public transactionsService: TransactionsService,
    public organizationCampersService: OrganizationCampersService,
    public organizationService: OrganizationService,
    public dialogsService: DialogsService,
    public daterangepickerOptions: DaterangepickerConfig,
    public organizationSettingsService: OrganizationSettingsService
  ) {
    this.daterangepickerOptions.settings = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
        ranges: {
            'Last Week': [moment().subtract(1, 'week'), moment()],
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 3 Months': [moment().subtract(4, 'month'), moment()],
            'Last 12 Months': [moment().subtract(12, 'month'), moment()],
            'Year to Date': [ moment(this.start.valueOf()), moment()]
        }
    };
    // this.daterangepickerOptions2.settings = {
    //   locale: { format: 'YYYY-MM-DD' },
    //   alwaysShowCalendars: false,
    //   dateLimit: { days: 31 },
    //   ranges: {
    //       'Past Week': [moment().subtract(6, 'days'), moment()],
    //       'Two Weeks Ago': [moment().subtract(13, 'days'), moment().subtract(7, 'days')],
    //       'Three Weeks Ago': [moment().subtract(20, 'days'), moment().subtract(14, 'days')],
    //       'Four Weeks Ago': [moment().subtract(27, 'days'), moment().subtract(21, 'days')]
    //   }
    // };
    this.singleDate = Date.now();
  }

  ngOnInit() {
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.loadingService.toggleLoadingScreen(false);
        this.user = res;
        if (res != null) {
          // this.getReportFunds();
          // this.getTransactionActicity();
          this.getOrganization();
          this.loadPOSLocations();
        }
      }).catch(err => {
        this.loadingService.toggleLoadingScreen(false);
      })
  }

  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }

  getUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getUser()
        .then(res => {
          console.log('Response: ', res)
          // this.user = res;
          this.idToken = res.idToken;
          resolve(res)
        }).catch(err => {
          console.log('Error Getting users', err);
          reject(err);
        })
    })
  }

  getOrganization() {
    this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
      .then(res => {
        const body = JSON.parse(res._body);
        this.organization = body.message.Items[0];
        if (this.organization.refundThreshold != undefined) {
          this.refundThreshold = this.organization.refundThreshold;
        }
        if (this.organization.organizationType === 'camp') {
          this.salesReports.push(SessionReport);
          this.salesReports.push(SessionTendersReport);
        }
        const tillSettings = this.organization.tillSettings || {};
        // if (this.organization.showSpecialSummaryReport) {
        //   this.salesReports.push({
        //     name: 'Special Summary Report',
        //     description: 'Sales breakdown by item, location, category, tab over date range',
        //     slug: 'GROUPED_SALES_REPORT'
        //   });
        // }
        if (tillSettings.tillEnabled !== false) {
          this.salesReports.push({
            name: 'Till Z-Reports',
            description: 'Z-Report for each till',
            slug: 'TILL_ZREPORT'
          });
        }
        if (this.organization.squareReader && this.organization.squareReader.ready) {
          this.financialReports.push(SquareReport);
          this.financialReports.push(SquareSettlementReport); // TODO FIX THIS
        }

        this.organizationService.getOrganizationSessions(this.idToken, this.organization.organizationId)
          .then(res => {
            console.log('get org sessions res:', res);
            const body = JSON.parse(res._body);
            this.sessions = body.message.Items.sort( (a, b) => {
              if (a.sessionString > b.sessionString) {
                return 1;
              } else {
                return -1;
              }
            });
            this.allSessions = body.message.Items.slice();
            this.sessions.unshift({ sessionString: 'All Sessions'});
            console.log('sessions:', this.sessions);
          })
    })
  }

  getReportFunds(): Promise<any> {
    return new Promise((resolve, reject) => {
      const orgId = this.user.organizationId;
      this.customerService.getAllCustomer(orgId)
        .then(res => {
          const body = JSON.parse(res._body);
          const customer = body.items;
          // let newData: any;
          customer.forEach(user => {
            user.totalFunds = user.availableBalance;
            if (user.role == 'account-holder') {
              this.cust.push(user);
              this.contributorService.getMyContributors(this.idToken, user.userId)
                .then(res => {
                  const body = JSON.parse(res._body);
                  const contributors = body.message.Items;

                  const contributorIds = [];
                  contributors.forEach(contributor => {
                    contributorIds.push(contributor.userId);
                  })
                  contributorIds.push(user.userId);
                  this.contributorService.getContributions(this.idToken, contributorIds)
                    .then(res => {
                      const body = JSON.parse(res._body);
                      const contributions = body.message.Items;

                      this.cust.forEach(user => {
                        if (user.currentBalance == undefined) {
                          if (user.availableBalance != undefined) {
                            user.currentBalance = user.availableBalance;
                            user.totalFunds = user.availableBalance;
                          } else {
                            user.currentBalance = 0;
                            user.totalFunds = 0;
                          }

                        }

                        contributions.forEach(contribution => {
                          if (user.userId == contribution.contributorId) {
                            // console.log("Here");
                            user.currentBalance += contribution.currentBalance;
                            user.totalFunds += contribution.currentBalance;
                          }
                        })

                      })
                    })
                })
            }
          })

          this.originalCustomers = this.cust;
          console.log(this.originalCustomers);
          this.cust.forEach(parent => {
            // console.log(parent)
            if (parent.totalFunds > 0) {
              console.log(parent)
              this.withFunds.push(parent);
            }
          })
          // this.cust.forEach(p =>{
          //   // console.log(p);
          //   if(p.totalFunds > 0){
          //     this.tActivity.push(p);
          //
          //   }
          // })
          console.log(this.withFunds);
          console.log(this.cust);
          console.log(this.originalCustomers);
          console.log(this.tActivity);
          resolve(this.cust);
        }).catch(err => {
          reject(err)
        })
    })

  }

  initDatePickerConfig(row: FunFangleReport, resetTime = true) {
    if (row.slug === 'SQUARE_SETTLEMENTS') {
      if (resetTime) {
        this.mainInput = {
          start: moment().subtract(6, 'days'),
          end: moment((new Date()).valueOf())
         }
      }
      this.daterangepickerOptions.settings = {
        alwaysShowCalendars: true,
        dateLimit: { days: 31 },
        endDate: this.mainInput.end,
        startDate: this.mainInput.start,
        ranges: {
            'Past Week': [moment().subtract(6, 'days'), moment()],
            'Two Weeks Ago': [moment().subtract(13, 'days'), moment().subtract(7, 'days')],
            'Three Weeks Ago': [moment().subtract(20, 'days'), moment().subtract(14, 'days')],
            'Four Weeks Ago': [moment().subtract(27, 'days'), moment().subtract(21, 'days')]
        }
      };
    } else {
      if (resetTime) {
        this.mainInput = {
           start: moment().subtract(1, 'months').startOf('month'),
           end: moment().subtract(1, 'months').endOf('month')
         }
      }
      this.daterangepickerOptions.settings = {
        alwaysShowCalendars: true,
        endDate: this.mainInput.end,
        startDate: this.mainInput.start,
        locale: { format: 'YYYY-MM-DD' },
        ranges: {
            'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
            'Last 30 Days': [moment().subtract(30, 'days'), moment()],
            'Last 3 Months': [moment().subtract(4, 'month'), moment()],
            'Last 12 Months': [moment().subtract(12, 'month'), moment()],
            'Year to Date': [ moment().startOf('year'), moment()]
        }
      };
    }
  }

  onSelect( {selected }) {
    console.log('on select:', selected);
    this.selectedUsers = selected;
  }

  // sortAlphabetically
  sortAlphabetically(sortBy = 'camper', ascendingSort = !this.ascendingSort) {
    this.ascendingSort = ascendingSort;
    if (this.ascendingSort) {
      this.refundsTableData = this.refundsTableData.sort( (a, b) => {
        if (sortBy === 'camper') {
          let a_last_name = null;
          let b_last_name = null;

          if (a['lastName'] !== undefined || b['lastName'] !== undefined) {
            a_last_name = a['lastName'] || '';
            b_last_name = b['lastName'] || '';
          } else {
            const a_name = a['Camper name'];
            const b_name = b['Camper name'];
            if (a_name) {
              a_last_name = a_name.split(' ')[1];
            }
            if (b_name) {
              b_last_name = b_name.split(' ')[1];
            }
          }


          if (a_last_name > b_last_name) {
            return 1;
          } else if (a_last_name < b_last_name) {
            return -1;
          } else {
            return 0;
          }

        } else {
          const a_name = a['Parent name'];
          const b_name = b['Parent name'];
          let a_last_name = null;
          let b_last_name = null;
          if (a_name) {
            a_last_name = a_name.split(' ')[1];
          }
          if (b_name) {
            b_last_name = b_name.split(' ')[1];
          }

          if (a_last_name > b_last_name) {
            return 1;
          }  else if (a_last_name < b_last_name) {
            return -1;
          } else {
            return 0
          }
        }

      });
    } else {
      console.log('descending sort!');
      this.refundsTableData = this.refundsTableData.sort( (a, b) => {
        if (sortBy === 'camper') {
          let a_last_name = null;
          let b_last_name = null;
          if (a['lastName'] !== undefined || b['lastName'] !== undefined) {
            a_last_name = a['lastName'] || '';
            b_last_name = b['lastName'] || '';
          } else {
            const a_name = a['Camper name'];
            const b_name = b['Camper name'];
            if (a_name) {
              a_last_name = a_name.split(' ')[1];
            }
            if (b_name) {
              b_last_name = b_name.split(' ')[1];
            }
          }

          if (a_last_name > b_last_name) {
            return -1;
          } else if (a_last_name < b_last_name) {
            return 1;
          } else {
            return 0;
          }
        } else {
          const a_name = a['Parent name'];
          const b_name = b['Parent name'];
          let a_last_name = null;
          let b_last_name = null;
          if (a_name) {
            a_last_name = a_name.split(' ')[1];
          }
          if (b_name) {
            b_last_name = b_name.split(' ')[1];
          }

          if (a_last_name > b_last_name) {
            return -1;
          }  else if (a_last_name < b_last_name) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
    console.log('this.campers...:', this.refundsTableData);
    this.refundsTableData = [...this.refundsTableData];
  }


  updateSelectedSessions() {
    console.log('update selected sessions...');
    console.log(this.selectedSessions);
    if (this.report.name == 'aboveWithCard') {
      this.loadAboveWithCard();
    } else if (this.report.name == 'remainingBalances') {
      this.loadRemainingBalances();
    }
  }

  selectByPreference(preference: string) {
    this.selectedUsers = [];
    this.refundsTableData.forEach( data => {
      console.log(data);
      if (data['Balance Preference'] && data['Balance Preference'].indexOf(preference) !== -1)
        this.selectedUsers.push(data);
    });
  }

  selectAll() {
    this.selectedUsers = [];
    this.refundsTableData.forEach( data => {
      this.selectedUsers.push(data);
    })
  }

  deSelectAll() {
    this.selectedUsers = [];
  }


  updateSession(session) {
    console.log(this.selectedSession);

    if (this.selectedReport && this.selectedReport.method) {
      return this[this.selectedReport.method]();
    }

    if (this.report.name == 'belowWithCard') {
      this.loadingService.toggleLoadingScreen(true);
      this.organizationService.getReportBelowThresholdWithCard(this.idToken, this.selectedSession, this.refundThreshold, this.organization.organizationId)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          const body = JSON.parse(res._body);

          this.refundsTableData = body.json;

          console.log('json:', body.json);

          this.csvData = body.csv;
          this.authUserIds = body.authUserIds;
          this.userFirstNames = body.userFirstNames;
          this.userLastNames = body.userLastNames;
          this.showCheckBox = true;
        })
        .catch(err => {
          console.log('caught err:', err);
          this.loadingService.toggleLoadingScreen(false);
          if (err.status == '300') {
            this.dialogsService.alert('Empty Dataset', 'There are no campers that meet this criteria');
          }
          this.csvData = null;
          this.refundsTableData = [];
          this.authUserIds = [];
          this.userFirstNames = [];
          this.userLastNames = [];
          this.showCheckBox = false;
        })

    } else if (this.report.name == 'aboveWithCard') {
      this.loadAboveWithCard();
    } else if (this.report.name == 'remainingBalances') {
      this.loadRemainingBalances();
    }
  }

  loadAboveWithCard() {
    // if (this.selectedSessions.indexOf('All Sessions') !== -1) { this.selectedSession = "All Sessions"; }
    // else { this.selectedSession = null; }
    // this.loadingService.toggleLoadingScreen(true);
    // this.organizationService.getReportAboveThresholdWithCard(this.idToken, this.selectedSession, 0.01 /* this.refundThreshold */, this.organization.organizationId, this.selectedSessions)
    //   .then(res => {
    //     this.loadingService.toggleLoadingScreen(false);

    //     const body = JSON.parse(res._body);
    //     this.refundsTableData = body.json;
    //     console.log('grat...:', body.json);
    //     // figure out who needs to be checkboxed now..
    //     this.selectedUsers = [];
    //     body.json.forEach( camper => {
    //       console.log(camper);
    //       if (!camper['Returning']) {
    //         this.selectedUsers.push(camper);
    //       }
    //     })
    //     console.log('Selected users:', this.selectedUsers);
    //     this.csvData = body.csv;
    //     this.authUserIds = body.authUserIds;
    //     this.userFirstNames = body.userFirstNames;
    //     this.userLastNames = body.userLastNames;
    //     this.showCheckBox = true;
    //   })
    //   .catch(err => {
    //     console.log('caught err:', err);
    //     this.loadingService.toggleLoadingScreen(false);
    //     if (err.status == '300') {
    //       this.dialogsService.alert('Empty Dataset', 'There are no campers that meet this criteria');
    //     }
    //     this.csvData = null;
    //     this.refundsTableData = [];
    //     this.authUserIds = [];
    //     this.userFirstNames = [];
    //     this.userLastNames = [];
    //     this.showCheckBox = false;
    //   })
  }

  loadPOSLocations() {
    return new Promise((resolve, reject) => {
      if (this.allLocations.length > 0) {
        this.allLocations = [];
      }
      this.organizationSettingsService.getPOSLocations(this.idToken, this.user.organizationId)
        .then(res => {
              const body = JSON.parse(res._body);
              const message = body.items;
              this.allLocations = this.allLocations.concat(message);
              console.log('all locations are:', this.allLocations);
        })
        .catch(err => {
          reject(err);
        })
    });
  }

  loadRemainingBalances() {
    this.loadingService.toggleLoadingScreen(true);
    if (this.selectedSessions.indexOf('All Sessions') !== -1) { this.selectedSession = "All Sessions"; }
    else { this.selectedSession = null; }
    const csvFields = [{
      label: 'User Id',
      value: 'userId'
    },{
      label: 'First Name',
      value: 'firstName'
    },{
      label: 'Last Name',
      value: 'lastName'
    },{
      label: 'Remaining Balance',
      value: 'currentBalance'
    }];
    this.csvData = [];
    this.refundsTableData = [];
    this.authUserIds = [];
    this.userFirstNames = [];
    this.userLastNames = [];
    this.organizationService.getRemainingBalances(this.idToken, this.selectedSession, this.organization.organizationId, [ this.selectedSessions ])
      .then(res => {
        console.log('grat...:', res);
        const body = JSON.parse(res._body);
        this.showCheckBox = true;
        if (body.items !== undefined) this.refundsTableData = this.refundsTableData.concat(body.items);
        this.sortAlphabetically('camper', true);
        this.loadingService.toggleLoadingScreen(false);
        console.log('refundsTableData...:', this.refundsTableData);
        for (const item of body.items || []) {
          this.authUserIds.push(item.userId);
          this.userFirstNames.push(item.firstName);
          this.userLastNames.push(item.lastName);
        }
        this.csvData = json2csv({ data: this.refundsTableData, fields: csvFields });
      })
      .catch(err => {
        console.log('caught err:', err);
        this.loadingService.toggleLoadingScreen(false);
        if (err.status == '300') {
          this.dialogsService.alert('Empty Dataset', 'There are no campers that meet this criteria');
        }
        this.showCheckBox = false;
      })
  }


  exportZReport() {
    if (this.csvData == null || this.csvData.length == 0) {
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const fileName =  'Register Z-Report.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  exportRefundsReport(name) {
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('Select a Session', 'You cannot export a report without selecting a session');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = this.report.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  exportWholeSaleValueReport() {
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('Select a Location', 'You cannot export a report without selecting a location');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    //
    let selectedLocation;
    this.allLocations.forEach( location => {
      if (location.locationId == this.selectedLocation) {
        selectedLocation = location;
      }
    })
    const fileName = selectedLocation.locationName + ' Wholesale report.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }



   exportTotalInvReport() {
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    //
    let selectedLocation;
    this.allLocations.forEach( location => {
      if (location.locationId == this.selectedLocation) {
        selectedLocation = location;
      }
    })
    const fileName = 'Total Inventory Report.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  exportTendersReport() {
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const fileName = 'Tenders Report.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  refundRemainingBalances() {
    console.log('userIds array:', this.authUserIds);
    console.log('selected users:', this.selectedUsers);

    const length = this.selectedUsers.length;
    let index = 0;
    this.authUserIds = [];
    console.log(this.selectedUsers);
    while (index < length) {
      const userId = this.selectedUsers[index]['userId'] || this.selectedUsers[index]['User Id'];
      if (this.authUserIds.indexOf(userId) === -1) {  this.authUserIds.push(userId); }
      index++;
    }

    let session = null;
    for (const s of this.sessions) {
      if (s.sessionString === this.selectedSessions) {
        session = s;
      }
    }
    if (session == null || session === undefined) {
      this.dialogsService.alert('No Session', 'Make sure you have selected a session');
      return;
    }

    if ( (this.selectedSession == null && (!this.selectedSessions || this.selectedSessions.length === 0)) || this.csvData == null || this.csvData.length == 0 || this.authUserIds.length == 0) {
      console.log('session is null');
      console.log(this.csvData);
      console.log(this.authUserIds);
      console.log(this.selectedSession);
      this.dialogsService.alert('Empty Dataset', 'Make sure you have marked which users you want to act on');
      return;
    }
    let title = 'Process Remaining Balances';
    let message = 'This will submit remaining balances for refunds. Funds below threshold will be donated. Funds marked for refund will be issued to the card holder. Remaining funds will be marked as cash withdrawals.';
    // if (type == 'withdrawal') {
    //   title = 'Mark Funds Withdrawn?';
    //   message = 'This will zero these balances and mark them as withdrawn';
    //   if (this.organization.refundSettings && this.organization.refundSettings.roundDownAndDonateRemainder) {
    //     message = 'This will round down remaining balances and mark it as withdrawn. The change will be marked as a donation. All balances will be zeroed';
    //   }
    // } else if (type == 'refund') {
    //   title = 'Issue Refunds?';
    //   message = 'This will zero these balances and issue refunds';
    //   if (this.organization.refundSettings && this.organization.refundSettings.roundDownAndDonateRemainder) {
    //     message = 'This will round down remaining balances and refund them. The change will be marked as a donation. All balances will be zeroed';
    //   }
    // } else if (type == 'donation' && this.report.name == 'belowWithCard') {
    //   title = 'Donate Remaining Funds?';
    //   message = 'This will zero these balances and mark them as donations to the camp';
    // } else {
    //   title = 'Mark Funds Donated?';
    //   message = 'This will zero these balances and mark them as donations to the camp';
    // }

    this.dialogsService.confirm(title, message)
      .toPromise()
      .then(res => {
        if (res) {

          console.log('authUserIds:', this.authUserIds);
          console.log('session', this.selectedSessions);
          console.log('session', session);
          // console.log('first names:', this.userFirstNames);
          // console.log('last names:', this.userLastNames);

          let timeZone = -4;
          if (this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null) {
            timeZone = this.organization.timeZoneOffset;
          }

          this.loadingService.toggleLoadingScreen(true);
          this.reportsService.remainingBalancesInitializeRefund(this.user.organizationId, this.authUserIds, session.sessionId, session.sessionString)
              .then(res => {
                this.loadingService.toggleLoadingScreen(false);
                // console.log('set balances to zero res:', res);
                // const body = JSON.parse(res._body);
                // if (body.status === 200 || res.status === 200) {
                  this.closeCard();
                  // this.dialogsService.alert('Ok', 'The funds have been zeroed! Please check the Pending Checks report to see if there\'s any pending refunds');
                // }
              })
              .catch(err => {
                this.loadingService.toggleLoadingScreen(false);
                console.log('Err:', err);
                this.dialogsService.alert('Unexpected Response', 'Something unexpected occurred. Please contact FunFangle support for further assistance. Please do not try to run this report again until support has gotten back to you');
              })
        }
      })

  }

  donateRemainingFunds(type) {

    console.log('type:', type);
    console.log('userIds array:', this.authUserIds);

    console.log('selected users:', this.selectedUsers);

    const length = this.selectedUsers.length;
    let index = 0;
    this.authUserIds = [];
    this.userFirstNames = [];
    this.userLastNames = [];
    console.log(this.selectedUsers);
    while (index < length) {
      const userId = this.selectedUsers[index]['userId'] || this.selectedUsers[index]['User Id'];
      if (this.authUserIds.indexOf(userId) === -1) {  this.authUserIds.push(userId); }
      this.userFirstNames.push(this.selectedUsers[index]['firstName'] || this.selectedUsers[index]['User first name']);
      this.userLastNames.push(this.selectedUsers[index]['lastName'] || this.selectedUsers[index]['User last name']);

      index++;
    }




    if ( (this.selectedSession == null && (!this.selectedSessions || this.selectedSessions.length === 0)) || this.csvData == null || this.csvData.length == 0 || this.authUserIds.length == 0) {
      console.log('session is null');
      console.log(this.csvData);
      console.log(this.authUserIds);
      console.log(this.selectedSession);
      this.dialogsService.alert('Empty Dataset', 'Make sure you have selected a session and marked which users you want to act on');
      return;
    }
    let title = '';
    let message = '';
    if (type == 'withdrawal') {
      title = 'Mark Funds Withdrawn?';
      message = 'This will zero these balances and mark them as withdrawn';
      if (this.organization.refundSettings && this.organization.refundSettings.roundDownAndDonateRemainder) {
        message = 'This will round down remaining balances and mark it as withdrawn. The change will be marked as a donation. All balances will be zeroed';
      }
    } else if (type == 'refund') {
      title = 'Issue Refunds?';
      message = 'This will zero these balances and issue refunds';
      if (this.organization.refundSettings && this.organization.refundSettings.roundDownAndDonateRemainder) {
        message = 'This will round down remaining balances and refund them. The change will be marked as a donation. All balances will be zeroed';
      }
    } else if (type == 'donation' && this.report.name == 'belowWithCard') {
      title = 'Donate Remaining Funds?';
      message = 'This will zero these balances and mark them as donations to the camp';
    } else {
      title = 'Mark Funds Donated?';
      message = 'This will zero these balances and mark them as donations to the camp';
    }

    this.dialogsService.confirm(title, message)
      .toPromise()
      .then(res => {
        if (res) {
          console.log('authUserIds:', this.authUserIds);
          console.log('first names:', this.userFirstNames);
          console.log('last names:', this.userLastNames);


          let timeZone = -4;
          if (this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null) {
            timeZone = this.organization.timeZoneOffset;
          }


          if (this.report.name === 'aboveWithCard') {
            console.log('new way!');
            console.log(this.authUserIds);
            this.loadingService.toggleLoadingScreen(true);
            this.organizationService.manuallyManageRefunds(this.idToken, this.authUserIds, this.user.organizationId, this.user.userId, this.user.email)
               .then(res => {
                 this.loadingService.toggleLoadingScreen(false);
                console.log('set balances to zero res:', res);
                const body = JSON.parse(res._body);
                if (body.status === 200 || res.status === 200) {
                  this.closeCard();
                  this.dialogsService.alert('Success!', 'The funds have been zeroed! Please check the Pending Checks report to see if there\'s any pending refunds');
                }
              })
              .catch(err => {
                this.loadingService.toggleLoadingScreen(false);
                console.log('Err:', err);
                this.dialogsService.alert('Unexpected Response', 'Something unexpected occurred. Please contact FunFangle support for further assistance. Please do not try to run this report again until support has gotten back to you');
              })
          } else {
            console.log('Process the old way');
            this.loadingService.toggleLoadingScreen(true);
            this.organizationService.setBalancesZero(this.idToken, this.authUserIds, type, this.user.userId, this.user.email, this.organization.organizationId, this.userFirstNames, this.userLastNames, timeZone)
              .then(res => {
                this.loadingService.toggleLoadingScreen(false);
                console.log('set balances to zero res:', res);
                const body = JSON.parse(res._body);
                if (body.status === 200 || res.status === 200) {
                  this.closeCard();
                  this.dialogsService.alert('Success!', 'The funds have been zeroed!');
                }
              })
              .catch(err => {
                this.loadingService.toggleLoadingScreen(false);
              })
          }
        }
      })
  }

  downloadReportCSV() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const fileName = `${this.selectedReport.name}.csv`;
    const blob = new Blob([this.selectedData.csv], { type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  exportReport(value) {
    console.log(value)
    const a = document.createElement('a');
    document.body.appendChild(a);
    if (value == 'withBalance') {
      const fileName = 'With-Balance.csv';
      const csv = json2csv({ data: this.withFunds, fields: this.fields });
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    if (value === 'transactionsActivity') {
      const fileName = 'Transaction-Activity.csv';
      const csv = json2csv({ data: this.transactionActivity, fields: this.transactionsActivityFields });
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
    if (value === 'camperBalance') {
      const fileName = 'Camper-Balance.csv';
      const csv = json2csv({ data: this.campers, fields: this.camperBalanceFields });
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  openReports(row: FunFangleReport) {
    console.log('open reports');
    console.log(row);
    if (row.method && this[row.method] && row.custom !== true) {
      this.selectedReport = row;
      this.report.name = row.name;
      this.report.displayName = row.name;
      this.report.fileName = row.name + '.csv';
      return this[row.method]();
    }
    //
    this.initDatePickerConfig(row);
    //
    switch (row.slug) {
      case 'TRANSACTIONS':
        this.router.navigate(['transactions']);
        break;
      case 'BELOW_THRESHOLD':
        this.showBelowThresholdCard = !this.showBelowThresholdCard;
        this.showRefundCard = !this.showRefundCard;
        this.report.name = 'belowWithCard';
        this.report.fileName = 'Campers Below Threshold.csv'
        break;
      case 'BALANCES_WITH_CARD':
        console.log('above thereshold');
        this.showRefundCard = !this.showRefundCard;
        this.report.name = 'aboveWithCard';
        this.report.fileName = 'Remaining Balances with card.csv'
        break;
      case 'REMAINING_BALANCES':
        this.showRefundCard = !this.showRefundCard;
        this.report.name = 'remainingBalances';
        this.report.fileName = 'Remaining Camper Balances.csv'
        break;
      case 'ACCOUNT_ALLOCATIONS':
        this.showAllocationCard = !this.showAllocationCard;
        this.report.name = 'camperAllocations';
        this.report.fileName = 'Camper Allocations.csv';
        this.loadCamperAllocationsData();
        break;
      case 'DONATIONS':
        this.showDonationCard = !this.showDonationCard;
        this.report.name = 'donationReports';
        this.report.fileName = 'Donation Report.csv';
        this.loadDonations(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'LOCATION_SUMMARY':
        this.showLocationCard = !this.showLocationCard;
        this.report.name = 'locationSummaryReport';
        this.report.fileName = 'Location Summary.csv';
        this.loadLocationSummaryReport(this.mainInput.start._d, this.mainInput.end._d);
        break;
      case 'PENDING_CHECKS':
        this.showPendingCheck = !this.showPendingCheck;
        this.report.name = 'pendingCheckReport';
        this.loadPendingChecks();
        break;
      case 'INVENTORY_WHOLESALE':
        this.report.name = 'wholesaleValues';
        this.showWholeSaleVals = true;
        break;
      case 'INVENTORY_TOTALS':
        this.report.name = 'Inventory Totals.csv';
        this.showInventoryTotals = true;
        this.loadInventoryTotals();
        break;
      case 'TENDERS':
        this.report.name = 'Tenders.csv';
        this.showTendersReport = true;
        this.loadTendersReport(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'Register Z-Report':
        this.report.name = 'Register Z-Report.csv';
        this.showZReport = true;
        console.log('LOAD Z REPORT DATA!');
        this.loadZReportData(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'ACCOUNT_DEPOSITS':
        this.report.name = 'FunFangle_Account_Deposits_Report.csv';
        this.report.displayName = 'Account Deposits Report';
        this.showDepositsReport = true;
        break;
      case 'ACCOUNT_NO_WRISTBAND':
        this.report.name = 'FunFangle_No_Wristbands.csv';
        this.showNoWristbandReport = true;
        break;
      case 'ACCOUNT_REFUNDS':
        this.report.name = 'Refunds Report.csv';
        this.report.displayName = 'Refunds Report';
        this.selectType = 'Date Range';
        this.showRefundsReport = true;
        this.loadRefundsReport(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
      case 'ACCOUNT_UNALLOCATIONS':
        this.report.name = 'unallocatedFunds';
        this.report.fileName = 'Unallocated Funds Report.csv';
        this.showUnallocatedFunds = true;
        this.loadUnallocatedFunds();
        break;
      case 'ACCOUNT_WITHDRAWALS':
        this.report.name = 'Withdrawals Report.csv';
        this.report.displayName = 'Withdrawals Report';
        this.selectType = 'Date Range';
        this.showWithdrawalsReport = true;
        this.loadRefundsReport(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'GROUPED_SALES_REPORT':
        this.report.name = 'Special Summary Report.csv';
        this.showSpecialSummaryReport = true;
        this.loadSpecialSummaryReport(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'REFUND_BATCHES':
        this.report.name = 'Refunds Report.csv';
        this.report.displayName = 'Refund Batch';
        this.selectType = 'Date Range';
        this.showRefundsReport = true;
        //this.loadRefundsReport(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000);
        break;
      case 'SESSION_ACTIVITIES':
        this.report.name = 'FunFangle_Activities_Report.csv';
        this.report.displayName = 'Activities Report';
        this.showSessionActivitiesReport = true;
        this.loadSessionActivitiesReport(this.mainInput.start._d, this.mainInput.end._d);
        break;
      case 'SQUARE_SETTLEMENTS':
        this.report.name = 'SquareSettlements.csv';
        // this.report.description = 
        this.report.fields = SquareSettlementReport.fields;
        this.report.showDateRange = true;
        this.report.showSessionSelect = false;
        this.showSettlementsReport = true;
        this.loadSquareSettlementsReport()
        break;
      case 'TILL_ZREPORT':
        this.report.name = 'Till Z-Reports.csv';
        this.showTillZReport = true;
        this.loadTillZReports();
      case 'ZERO_BALANCES':
        this.report.name = 'Zero Balances Report.csv';
        this.showZeroBalancesReport = true;
        break;
    }
  }

  async loadSalesReport() {
    console.log('Sales report!');
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    let startSeconds = this.mainInput.start._d.getTime() / 1000;
    let endSeconds = this.mainInput.end._d.getTime() / 1000;
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.loadSalesReport(this.user.idToken, this.user.organizationId, startSeconds, endSeconds);
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  async loadSessionsSummaryReport(resetTime = true) {
    console.log('Session summary report...');
    if (resetTime) {
     this.mainInput = {
        start: moment().subtract(3, 'months'),
        end: moment((new Date()).valueOf())
      }
    }
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    let startSeconds = this.mainInput.start._d.getTime() / 1000;
    let endSeconds = this.mainInput.end._d.getTime() / 1000;
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.sessionSummaryReport(this.idToken, this.organization.organizationId, startSeconds, endSeconds)
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  async reportFunction(row, method) {
    console.log('Row:', row);
    console.log('Method:', method);
    this[method](row['Settlement ID']);
  }

  async listSquareSettlementDetailCSV(locationId: string, transactionId: string) {
    try {
      const fileName = `Settlement Details ${locationId} ${transactionId}.csv`;
      this.loadingService.toggleLoadingScreen(true);
      const data = await this.reportsService.listSquareSettlementDetail(this.user.organizationId, locationId, transactionId);
      console.log('Data: ', data);
      const a = document.createElement('a');
      const blob = new Blob([data.csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      // now need to download csv file and name it...
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  async loadSquareSettlementDetail(row) {
    this.showSettlementDetail = true;
    this.showSettlementsReport = false;
    console.log('loadSquareSettlementDetail', row);
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.settlementDetail = await this.reportsService.listSquareSettlementDetail(this.user.organizationId, row.locationId, row.transactionId);
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      console.log(err.status);
      if (err.status === 410) {
        console.log('Status 410...');
        const body = JSON.parse(err._body);
        const url = body.url;
        console.log('URL:', url);
        const confirmRes = await this.dialogsService.confirm('Additional Permissions Required', 'To access Square Settlement data you\'ll need to authorize additional permissions. Continue?').toPromise();
        if (confirmRes) {
          window.location.replace(url);
        }
        return;
      }
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  async loadSquareSettlementsReport(resetTime = true) {
    console.log('loadSquareSettlementReport');
    if (resetTime) {
      this.mainInput = {
         start: moment().subtract(6, 'days'),
         end: moment((new Date()).valueOf())
       }
     }

    const lowerDate = this.mainInput.start._d;
    const upperDate = this.mainInput.end._d;
    
    let lowerMon = (lowerDate.getMonth() + 1).toString();
    if (lowerMon.length === 1) lowerMon = `0${lowerMon}`;
    let lowerDay = lowerDate.getDate().toString();
    if (lowerDay.length === 1) lowerDay = `0${lowerDay}`;
    const lowerISO = `${lowerDate.getFullYear()}-${lowerMon}-${lowerDay}T00:00:00.000`;
 
    let upperMon = (upperDate.getMonth() + 1).toString();
    if (upperMon.length === 1) upperMon = `0${upperMon}`;
    let upperDay = upperDate.getDate().toString();
    if (upperDay.length === 1) upperDay = `0${upperDay}`;
    const upperISO = `${upperDate.getFullYear()}-${upperMon}-${upperDay}T23:59:59.999`;    

    // let offset = -4;
    // if (this.organization.timeZoneOffset != undefined) {
    //   offset = this.organization.timeZoneOffset;
    // }
    // let startSeconds = this.mainInput.start._d.getTime() / 1000;
    // let endSeconds = this.mainInput.end._d.getTime() / 1000;
    // const startDate = new Date(startSeconds * 1000);
    // const endDate = new Date(endSeconds * 1000);
    // const startISO = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDay()}`
    // const endISO = `${endDate.getFullYear()}-${endDate.getMonth()}-${endDate.getDay()}`
    // startDate.setHours(0, 0, 0, 0);
    // endDate.setHours(23, 59, 59, 999);
    // startSeconds = startDate.getTime() / 1000;
    // endSeconds = endDate.getTime() / 1000;
    // const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    // const diff = offset - localOffset;
    // startSeconds -= 3600 * diff;
    // endSeconds -= 3600 * diff;
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.listSquareSettlements(this.user.organizationId, lowerISO, upperISO);
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      console.log(err.status);
      if (err.status === 410) {
        console.log('Status 410...');
        const body = JSON.parse(err._body);
        const url = body.url;
        console.log('URL:', url);
        const confirmRes = await this.dialogsService.confirm('Additional Permissions Required', 'To access Square Settlement data you\'ll need to authorize additional permissions. Continue?').toPromise();
        if (confirmRes) {
          window.location.replace(url);
        }
        return;
      }
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  async loadSquareDepositsReport() {
    console.log('SquareDeposits report!');
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    let startSeconds = this.mainInput.start._d.getTime() / 1000;
    let endSeconds = this.mainInput.end._d.getTime() / 1000;
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.loadSquareDepositsReport(this.user.idToken, this.user.organizationId, startSeconds, endSeconds);
      this.loadingService.toggleLoadingScreen(false);
      console.log('SquareDeposits data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }

  }

  loadTillZReports() {
    this.reportsService.listOrganizationTills(this.user.idToken, this.user.organizationId)
      .then( (tills: Till[]) => {
        console.log('List organization tills');
        console.log('Res:', tills);
        this.tableData = tills;
      })
      .catch( err => {
        console.log('Caught an error:', err);
      });
  }

  exportSessionActivitiesReport() {
    // create the csv
    const fileName = 'SessionActivitiesReport.csv';
    const fields = [
      'sessionId',
      'eventId',
      'label',
      'capacityCount',
      'capacityRemaining',
      'cancelledCount',
      'checkInCount',
      'registeredCount',
      'registeredSales',
      'startISO',
      'startWeekNumber',
      'endISO'];

    const a = document.createElement('a');
    const csv = json2csv({ data: this.refundsTableData, fields: fields });
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  loadSessionActivitiesReport(lowerDate: Date, upperDate: Date) {
    let timeZone = 'America/New_York';
    if (this.organization.timeZone != undefined) {
      timeZone = this.organization.timeZone;
    }
  
    let lowerMon = (lowerDate.getMonth() + 1).toString();
    if (lowerMon.length === 1) lowerMon = `0${lowerMon}`;
    let lowerDay = lowerDate.getDate().toString();
    if (lowerDay.length === 1) lowerDay = `0${lowerDay}`;
    const lowerISO = `${lowerDate.getFullYear()}-${lowerMon}-${lowerDay}T00:00:00.000`;
    const lowerDT = DateTime.fromISO(lowerISO, { zone: timeZone });

    let upperMon = (upperDate.getMonth() + 1).toString();
    if (upperMon.length === 1) upperMon = `0${upperMon}`;
    let upperDay = upperDate.getDate().toString();
    if (upperDay.length === 1) upperDay = `0${upperDay}`;
    const upperISO = `${upperDate.getFullYear()}-${upperMon}-${upperDay}T23:59:59.999`;
    const upperDT = DateTime.fromISO(upperISO, { zone: timeZone });

    this.loadingService.toggleLoadingScreen(true);
    // console.info('location-summary-report')
    this.reportsService.loadSessionActivitiesReport(this.organization.organizationId, lowerDT, upperDT)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('loadSessionActivitiesReport:', res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.items;
      console.log('showSessionActivitiesReport', this.showSessionActivitiesReport);
      console.log('showSessionActivitiesReport json:', body.items);
      this.csvData = body.csv;
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }

  loadSpecialSummaryReport(startSeconds = 0, endSeconds = 100000000000000000000) {
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadSpecialSummaryReport(this.user.idToken, this.user.organizationId, startSeconds, endSeconds)
    .then( (res: any) => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load special summary report');
      console.log(res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json;
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err', err);
    })
  }

  exportTillZReport(till: Till) {
    console.log('Till:', till);
    // create the csv
    const fileName = 'Till Z Report ' + till.registerId + '.csv';
    const fields = ['Till ID', 'Register ID', 'Register Name', 'Location', 'Opened', 'Opened By', 'Closed', 'Closed By', 'Status', '   '];
    const data = [];

    data.push({
      'Till ID': till.tillId,
      'Register ID': till.registerId,
      'Register Name': till.registerName || '',
      'Location': till.openLocationName,
      'Opened': (new Date(till.openedAt * 1000)).toLocaleString(),
      'Opened By': till.openedByEmail,
      'Closed': (new Date(till.closedAt * 1000)).toLocaleString(),
      'Closed By': till.closedByEmail,
      'Status': till.status
    });

    data.push({});
    data.push({
      'Till ID': 'Sales Summary'
    });
    data.push({
      'Till ID': 'Cash Total',
      'Register ID': 'Check Total',
      'Register Name': 'Account Total',
      'Location': 'Credit Total',
      'Opened': 'Other Total',
      'Opened By': 'Net Sales',
      'Closed': 'Discounts',
      'Closed By': 'Gross Sales',
      'Status': 'Tax',
      '   ': 'Voids'
    })
    data.push({
      'Till ID': till.summary.cashTotal.toFixed(2),
      'Register ID': till.summary.checkTotal.toFixed(2),
      'Register Name': till.summary.ffAccountTotal.toFixed(2),
      'Location': till.summary.creditTotal.toFixed(2),
      'Opened': till.summary.otherTotal.toFixed(2),
      'Opened By': till.summary.netSales.toFixed(2),
      'Closed': till.summary.discountTotal.toFixed(2),
      'Closed By': till.summary.grossSales.toFixed(2),
      'Status': till.summary.taxTotal.toFixed(2),
      '   ': till.summary.voidTotal ? (till.summary.voidTotal.toFixed(2)) : 'Untracked'
    })
    data.push({});
    data.push({
      'Till ID': 'Opening',
      'Register ID': 'Paid In',
      'Register Name': 'Paid Out',
      'Location': 'Net',
      'Opened': "Expected",
      'Opened By': 'Closing Balance',
      'Closed': 'Difference',
    });
    data.push({
      'Till ID': till.openedBalance.toFixed(2),
      'Register ID': till.totalCashIn.toFixed(2),
      'Register Name': till.totalCashOut.toFixed(2),
      'Location': till.netCash.toFixed(2),
      'Opened': (till.openedBalance + till.netCash).toFixed(2),
      'Opened By': till.closedBalance.toFixed(2),
      'Closed': till.tillDifference.toFixed(2),
    });
    if (till.currencies) {
      data.push({});
      data.push({
        'Till ID': '1\'s',
        'Register ID': '5\'s',
        'Register Name': '10\'s',
        'Location': '20\'s',
        'Opened': '50\'s',
        'Opened By': '100\'s',
        'Closed': '2\'s'
      });
      data.push({
        'Till ID': till.currencies.dollars[0].amount,
        'Register ID': till.currencies.dollars[1].amount,
        'Register Name': till.currencies.dollars[2].amount,
        'Location': till.currencies.dollars[3].amount,
        'Opened': till.currencies.dollars[4].amount,
        'Opened By': till.currencies.dollars[5].amount,
        'Closed': till.currencies.dollars[6].amount
      });

      data.push({});
      data.push({
        'Till ID': '1 cent',
        'Register ID': '5 cent',
        'Register Name': '10 cent',
        'Location': '25 cent',
        'Opened': '50 cent',
        'Opened By': '$1',
        'Closed': '$2'
      });
      data.push({
        'Till ID': till.currencies.cents[0].amount,
        'Register ID': till.currencies.cents[1].amount,
        'Register Name': till.currencies.cents[2].amount,
        'Location': till.currencies.cents[3].amount,
        'Opened': till.currencies.cents[4].amount,
        'Opened By': till.currencies.cents[5].amount,
        'Closed': till.currencies.cents[6].amount
      });
      if (till.currencies.rolls && till.currencies.rolls.length > 0) {
        data.push({});
        data.push({
          'Till ID': '1 cent roll (x50)',
          'Register ID': '5 cent roll (x40)',
          'Register Name': '10 cent roll (x50)',
          'Location': '25 cent roll (x40)',
          'Opened': '50 cent roll (x20)',
          'Opened By': '$1 roll (x25)',
          'Closed': '$2 roll (x25)'
        });
        data.push({
          'Till ID': till.currencies.rolls[0].amount,
          'Register ID': till.currencies.rolls[1].amount,
          'Register Name': till.currencies.rolls[2].amount,
          'Location': till.currencies.rolls[3].amount,
          'Opened': till.currencies.rolls[4].amount,
          'Opened By': till.currencies.rolls[5].amount,
          'Closed': till.currencies.rolls[6].amount
        });
      }
    }
     const a = document.createElement('a');
     const csv = json2csv({ data: data, fields: fields });
     const blob = new Blob([csv], { type: 'text/csv' });
     const url = window.URL.createObjectURL(blob)
     a.href = url;
     a.download = fileName;
     a.click();
     window.URL.revokeObjectURL(url);
  }

  loadTendersReport(startSeconds = 0, endSeconds = 100000000000000000000) {
    console.log('load tenders report...');
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadTendersReport(this.user.idToken, this.user.organizationId, offset, startSeconds, endSeconds)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load tenders report');
      console.log(res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json.filter( transaction => {
        if (transaction['Operation Type'] != 'allocation' && transaction['Operation Type'] != 'unallocation') {
          return true;
        }
      });
      console.log('json:', body.json);
      this.csvData = body.csv;
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }


  loadInventoryTotals() {
    const locationIds = [];
    const locationNames = [];
    this.allLocations.forEach( location => {
      locationIds.push(location.locationId);
      locationNames.push(location.locationName);
    });
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadTotalInvReport(this.user.idToken, this.user.organizationId, locationIds, locationNames)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      if (res) {
        const body = JSON.parse(res._body);
        console.log('load inventory totals:', body);
        this.wholeSaleTableData = body.json;
        this.csvData = body.csv;
      }
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
    });

  }


  loadWholeSaleValues(locationId: string) {

    console.log('load wholesale values:', locationId);
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadWholeSaleValues(this.user.idToken, this.organization.organizationId, locationId)
    .then( res => {
      console.log('got res:', res);
      this.loadingService.toggleLoadingScreen(false);
      if (res) {
        const body = JSON.parse(res._body);
        this.wholeSaleTableData = body.json;
        this.csvData = body.csv;
      }
    })
    .catch( err => {
      console.log('load error:', err);
      this.loadingService.toggleLoadingScreen(false);
    })

  }

  loadUnallocatedFunds(startKey = null) {
    this.loadingService.toggleLoadingScreen(true);

    this.reportsService.loadUnallocatedFunds(this.idToken, this.organization.organizationId, startKey)
    .then( res => {
      console.log('res:', res);
      this.loadingService.toggleLoadingScreen(false);
      const body = JSON.parse(res._body);
      // this.refundsTableData = body.json;
      // this.csvData = body.csv;
      console.log('body.json:', this.refundsTableData);
      const startKey = body.exclusiveStartKey;
      console.log('start key:', startKey);
      if (startKey != undefined && startKey != null) {
        if (this.refundsTableData.length == 0) {
          this.refundsTableData = body.json;
        } else {
          this.refundsTableData.push.apply(this.refundsTableData, body.json);
        }
        this.loadUnallocatedFunds(startKey);
      } else {
        if (this.refundsTableData.length == 0) {
          this.refundsTableData = body.json;
        } else {
          this.refundsTableData.push.apply(this.refundsTableData, body.json);
        }
      }
    })
    .catch( err => {
      console.log(err);
      this.loadingService.toggleLoadingScreen(false);

    })

  }


  public async loadSessionTendersReport() {
    console.log('Session tenders report...');
    if (!this.selectedSession || this.selectedSession.length === 0) {
      console.log('No sessions selected');
      return;
    }
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.sessionTendersReport(this.organization.organizationId, this.selectedSession)
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }


  }



  loadPendingChecks() {
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadPendingChecks(this.idToken, this.organization.organizationId)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('Res:', res);
      const body = JSON.parse(res._body);
      console.log('Body:', body);
      this.refundsTableData = body.json;
      console.log('pending checks:', body.json);
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }

  loadLocationSummaryReport(lowerDate: Date, upperDate: Date) {
    let lowerMon = (lowerDate.getMonth() + 1).toString();
    if (lowerMon.length === 1) lowerMon = `0${lowerMon}`;
    let lowerDay = lowerDate.getDate().toString();
    if (lowerDay.length === 1) lowerDay = `0${lowerDay}`;
    const lowerISO = `${lowerDate.getFullYear()}-${lowerMon}-${lowerDay}T00:00:00.000`;

    let upperMon = (upperDate.getMonth() + 1).toString();
    if (upperMon.length === 1) upperMon = `0${upperMon}`;
    let upperDay = upperDate.getDate().toString();
    if (upperDay.length === 1) upperDay = `0${upperDay}`;
    const upperISO = `${upperDate.getFullYear()}-${upperMon}-${upperDay}T23:59:59.999`;

    this.loadingService.toggleLoadingScreen(true);
    // console.info('location-summary-report')
    this.reportsService.locationSummaryReport(this.organization.organizationId, lowerISO, upperISO)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      // console.log('location summar report:', res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.items;
      // console.log('json:', body.items);
      this.csvData = body.csv;
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
    })

  }


  loadDonations(startSeconds = 0, endSeconds = 100000000000000000000, session = null) {
    console.log('load donations');
    let timeZone = -4;
    if (this.organization.timeZoneOffset != undefined) {
      timeZone = this.organization.timeZoneOffset;
    }
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadDonations(this.idToken, this.organization.organizationId, timeZone, startSeconds, endSeconds, session)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load donation res:', res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json;
      console.log('json:', body.json);
      this.csvData = body.csv;
    }).catch( err => {
      console.log('load donations err:', err);
      if (err.status == 300) {
        this.refundsTableData = [];
        this.csvData = null;
      }
      this.loadingService.toggleLoadingScreen(false);
    })
  }


  updateSessionAllocations() {
    const session = this.selectedSession;
    console.log(session);
    this.loadCamperAllocationsData(session);
  }


  updateSessionDonations() {
    this.loadDonations(this.mainInput.start._d.getTime() / 1000, this.mainInput.end._d.getTime() / 1000, this.selectedSession);
  }


  loadCamperAllocationsData(session = null) {
    let timeZone = -4;
    if (this.organization.timeZoneOffset != undefined) {
      timeZone = this.organization.timeZoneOffset;
    }
    console.log('loading allocations data!');
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.camperAllocationsReport(this.idToken, this.organization.organizationId, timeZone, session)
    .then( res => {
      console.log('res:', res);
      this.loadingService.toggleLoadingScreen(false);
      console.log('grbt...:', res);
      const body = JSON.parse(res._body);

      this.refundsTableData = body.json.reverse();

      console.log('json:', body.json);

      this.csvData = body.csv;
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
      this.csvData = '';
      this.refundsTableData = [];
    })
  }


  closeCard() {
    this.showWithBalance = false;
    this.showTransaction = false;
    this.showLocationCard = false;
    this.showPendingCheck = false;
    this.showCamper = false;
    this.showRefundCard = false;
    this.refundsTableData = [];
    this.csvData = null;
    this.selectedSession = null;
    this.showCheckBox = false;
    this.showAllocationCard = false;
    this.showDepositsReport = false;
    this.showDonationCard = false;
    this.showInventoryTotals = false;
    this.showNoWristbandReport = false;
    this.showRefundsReport = false;
    this.showSessionActivitiesReport = false;
    this.showSessionSummaryCard = false;
    this.showSettlementDetail = false;
    this.showSettlementsReport = false;
    this.showSpecialSummaryReport = false;
    this.showTendersReport = false;
    this.showTillZReport = false;
    this.showUnallocatedFunds = false;
    this.showWithdrawalsReport = false;
    this.showWholeSaleVals = false;
    this.showZeroBalancesReport = false;
    this.showZReport = false;
    this.selectedSessions = null;
    this.selectedUsers = [];
    this.selectedReport = null;
    this.selectedData = { json: [], csv: ''}
  }



  public selectedDate(value: any, dateInput: any, method = null) {
      dateInput.start = value.start;
      dateInput.end = value.end;

      // console.log('in selected date...');
      // console.log('value:', value);
      // console.log(dateInput);

      // console.log('start:', value.start);
      // console.log('_d:', value.start._d);

      let startSeconds = value.start._d.getTime() / 1000;
      let endSeconds = value.end._d.getTime() / 1000;

      // now need to convert it to the organization's offset..

      const offset = (this.organization.timeZoneOffset ? this.organization.timeZoneOffset : 0);

      startSeconds += 3600 * offset;
      endSeconds += 3600 * offset;
      // console.log('start seconds:', startSeconds);
      // console.log('end seconds:', endSeconds);

      if (method && this[method]) {
        this[method](false);
      }

      if (this.showDepositsReport) {
        this.loadDepositsReport(startSeconds, endSeconds);
      } else if (this.showDonationCard) {
        this.loadDonations(startSeconds, endSeconds);
      } else if (this.showLocationCard) {
        this.loadLocationSummaryReport(value.start._d, value.end._d);
      } else if (this.showRefundsReport) {
        this.loadRefundsReport(startSeconds, endSeconds);
      } else if (this.showSessionActivitiesReport) {
        this.loadSessionActivitiesReport(value.start._d, value.end._d);
      } else if (this.showSpecialSummaryReport) {
        this.loadSpecialSummaryReport(startSeconds, endSeconds);
      } else if (this.showSettlementsReport) {
        this.loadSquareSettlementsReport(false);
      } else if (this.showTendersReport) {
        this.loadTendersReport(startSeconds, endSeconds);
      } else if (this.showZReport) {
        this.loadZReportData(startSeconds, endSeconds, true);
      } else if (this.showWithdrawalsReport) {
         this.loadRefundsReport(startSeconds, endSeconds);
      }



  }

    public singleSelect(value: any) {
        this.singleDate = value.start;
    }

    public applyDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;

        console.log('in apply date...');
        console.log(value);
        console.log(dateInput);
    }

    public calendarEventsHandler(e: any) {
        console.log(e);
        // this.eventLog += '\nEvent Fired: ' + e.event.type;
    }



    public finishCheck(transaction, status) {
      console.log(transaction, status);
      let title = 'Check Sent'
      let message = 'Are you sure you want to mark this check as sent?';
      if (status == 'cancelled') {
        title = 'Check Cancelled',
        message = 'Are you sure you want to mark this as cancelled? Note: you will need to manually re-deposit the funds. This only marks the refund as invalid';
      }
      this.dialogsService.confirm(title, message)
      .toPromise()
      .then( res => {
        if (res) {
          this.loadingService.toggleLoadingScreen(true);
          this.reportsService.finishCheck(this.idToken, transaction.authUserId, transaction.timeStamp, status)
          .then(res => {
            this.loadingService.toggleLoadingScreen(false);
            console.log(res);
            const body = JSON.parse(res._body);
            console.log(body);
            if (body.status === 200) {
              this.dialogsService.alert('Success','This transaction has been updated');
            }
            this.loadPendingChecks();
          }).catch( err => {
            this.loadingService.toggleLoadingScreen(false);
            console.log(err);
          })
        }
      })

    }

    _denominations(amount: number) {
        let remaining = new Decimal(amount || 0).abs().mul(100).round().div(100);
        const cash20 = remaining.divToInt(20).toNumber();
        remaining = remaining.minus(20 * cash20);
        const cash10 = remaining.divToInt(10).toNumber();
        remaining = remaining.minus(10 * cash10);
        const cash5 = remaining.divToInt(5).toNumber();
        remaining = remaining.minus(5 * cash5);
        const cash1 = remaining.divToInt(1).toNumber();
        remaining = remaining.minus(1 * cash1);
        const cents25 = remaining.divToInt(0.25).toNumber();
        remaining = remaining.minus(0.25 * cents25);
        const cents10 = remaining.divToInt(0.1).toNumber();
        remaining = remaining.minus(0.1 * cents10);
        const cents5 = remaining.divToInt(0.05).toNumber();
        remaining = remaining.minus(0.05 * cents5);
        const cents1 = remaining.divToInt(0.01).toNumber();
        remaining = remaining.minus(0.01 * cents1);
        return { cash20, cash10, cash5, cash1, cents25, cents10, cents5, cents1 };
    }

    exportPendingChecks(jsonData) {
      console.log('data:', jsonData);

      const fileName = 'Pending Checks.csv';

      const fields = ['Account Holder Name', 'Participant Name', 'Session', 'Lodging', 'Amount', '$20', '$10', '$5', '$1', '$0.25', '$0.10', '$0.05', '$0.01'];

      const data = [];

      jsonData.forEach( item => {
        const denominations = this._denominations(item.amount || 0);
        const subData = {
          'Account Holder Name': item.parentName,
          'Participant Name': item.camperName,
          'Session': item.session,
          'Lodging': item.lodgingLabel,
          'Amount': Math.abs(item.amount),
          '$20': denominations.cash20,
          '$10': denominations.cash10,
          '$5': denominations.cash5,
          '$1': denominations.cash1,
          '$0.25': denominations.cents25,
          '$0.10': denominations.cents10,
          '$0.05': denominations.cents5,
          '$0.01': denominations.cents1
        }
        if (item.transactionHistory) {
          subData['Transaction History'] = item.transactionHistory;
        }
        data.push(subData);
      })

      const a = document.createElement('a');
      const csv = json2csv({ data: data, fields: fields });
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);

    }



    exportUnallocatedFundsReport() {

      const fileName = 'Unallocated Funds.csv';

      const fields = ['Name', 'Email', 'Unallocated', 'Total'];

      const data = [];


      const a = document.createElement('a');
      const csv = json2csv({ data: this.refundsTableData, fields: fields });
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob)
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);

    }


    isValNaN(val) {
      return isNaN(val);
    }

  loadNoWristbandReport(session) {
    console.log('load NWR!');
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadNoWristbandsReport(this.user.idToken, this.user.organizationId, session)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load tenders report');
      console.log(res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json;
      console.log('json:', body.json);
      this.csvData = body.csv;
    });
  }

  exportNoWristbandsReport(reportName) {
    console.log('Export NWR!');
    console.log('selected session:', this.selectedSession);
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const fileName =  'No Wristbands -' + this.selectedSession + '.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);



  }


  public loadZReportData(startSeconds = 0, endSeconds = 100000000000000000000, applyOffset = false) {
    console.log('load z-report data!');
    this.loadingService.toggleLoadingScreen(true);
    let timeZone = -4;
    if (this.organization.timeZoneOffset != undefined) {
      timeZone = this.organization.timeZoneOffset;
    }
    // convert the start seconds to ytd...
    console.log('timeZone:', timeZone);
    let startDate = new Date(startSeconds * 1000 - 3600 * 1000 * timeZone);
    if (!applyOffset) {
      startDate = new Date(startSeconds * 1000);
    }
    console.log('????');
    console.log('Start date w/ offset:', startDate);
    console.log('start w/o offset:', (new Date(startSeconds * 1000)));

    let dateString = (startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear();
    const timeOfDay = startDate.getHours() + ':' + startDate.getMinutes();

    let startHrs = startDate.getHours();
    const startMinutesNum = startDate.getMinutes();
    let startAm = 'PM';

    if (startHrs < 12) {
      startAm = 'AM';
    } else if (startHrs > 12) {
      startHrs -= 12;
    }
    let startMinutesString = startMinutesNum.toString();
    if (startMinutesNum < 10) {
      startMinutesString = '0' + startMinutesString;
    }

    const startTimeString = startHrs + ':' + startMinutesString + ' ' + startAm;

    let endDate = new Date(endSeconds * 1000 - 3600 * 1000 * timeZone);
    if (!applyOffset) {
      endDate = new Date(endSeconds * 1000);
    }
    let endDateString = (endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear();
    const endTimeOfDay = endDate.getHours() + ':' + endDate.getMinutes();

    let endHrs = endDate.getHours();
    const endMinutesNum = endDate.getMinutes();
    let endAm = 'PM';

    if (endHrs < 12) {
      endAm = 'AM';
    } else if (endHrs > 12) {
      endHrs -= 12;
    }
    let endMinutesString = endMinutesNum.toString();
    if (endMinutesNum < 10) {
      endMinutesString = '0' + endMinutesString;
    }

    const endTimeString = endHrs + ':' + endMinutesString + ' ' + endAm;

    dateString += ' ' + startTimeString;
    endDateString += ' ' + endTimeString;
    console.log('Start:', dateString);
    console.log('end:', endDateString);

    this.reportsService.loadZReportData(this.user.idToken, this.user.organizationId, startSeconds, endSeconds, timeZone, dateString, endDateString)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load z-report');
      console.log(res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json.filter( transaction => {
        if (transaction['Operation Type'] != 'allocation' && transaction['Operation Type'] != 'unallocation') {
          return true;
        }
      });
      console.log('json:', body.json);
      this.csvData = body.csv;
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }


public filterDepositRefunds(event) {
  if (this.showDepositsReport) {
    this.filterDeposits(event);
  } else {
    this.filterRefunds(event);
  }
}

public loadDepositRefundsReport(startSeconds = null, endSeconds = null) {
  if (this.showDepositsReport) {
    this.loadDepositsReport(startSeconds, endSeconds);
  } else {
    this.loadRefundsReport(startSeconds, endSeconds);
  }
}

public filterDeposits(event) {
  console.log('filter deposits:', event);
  console.log(this.selectedMethods);
  console.log('Session:', this.selectedSession);
  if (this.selectedSession) {
    this.loadDepositsReport();
  }
}

public filterRefunds(event) {
  console.log('filter deposits:', event);
  console.log(this.selectedMethods);
  console.log('Session:', this.selectedSession);
  if (this.selectedSession) {
    this.loadRefundsReport();
  }
}

public loadRefundsReport(startSeconds = null, endSeconds = null) {
  console.log('REFUNDS REPORT');
  console.log('select type:', this.selectType);

  const offset = (this.organization.timeZoneOffset ? this.organization.timeZoneOffset : 0);
  let session = this.selectedSession;
  if (this.selectType === 'Date Range') {
    session = null;
    if (!startSeconds && !endSeconds) {
      startSeconds = this.mainInput.start._d.getTime() / 1000;
      endSeconds = this.mainInput.end._d.getTime() / 1000;
    }
    let offset = -4;
    if (this.organization.timeZoneOffset != undefined) {
      offset = this.organization.timeZoneOffset;
    }
    const startDate = new Date(startSeconds * 1000);
    const endDate = new Date(endSeconds * 1000);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    startSeconds = startDate.getTime() / 1000;
    endSeconds = endDate.getTime() / 1000;
    const localOffset = -1 * new Date().getTimezoneOffset() / 60;
    const diff = offset - localOffset;
    startSeconds -= 3600 * diff;
    endSeconds -= 3600 * diff;
  }
  console.log('start, end:');
  console.log(startSeconds, endSeconds);
  const loadWithdrawals = this.showWithdrawalsReport;

  this.loadingService.toggleLoadingScreen(true);
  this.reportsService.loadRefundsReport(this.user.idToken, this.user.organizationId, session, startSeconds, endSeconds, offset, loadWithdrawals)
  .then( res => {
    console.log('load refunds report res:');
    console.log(res);
    this.loadingService.toggleLoadingScreen(false);
    const body = JSON.parse(res._body);
    this.refundsTableData = body.json;
    console.log('json:', body.json);
    this.csvData = body.csv;
  })
  .catch( err => {
    console.log('caught err:', err);
    this.loadingService.toggleLoadingScreen(false);
    this.refundsTableData = [];

  })
}

public loadDepositsReport(startSeconds = null, endSeconds = null) {
  console.log('select type:', this.selectType);


  let session = this.selectedSession;
  if (this.selectType === 'Date Range') {
    session = null;
    if (!startSeconds && !endSeconds) {
      startSeconds = this.mainInput.start._d.getTime() / 1000;
      endSeconds = this.mainInput.end._d.getTime() / 1000;
      const offset = (this.organization.timeZoneOffset ? this.organization.timeZoneOffset : 0);
      startSeconds += 3600 * offset;
      endSeconds += 3600 * offset;
    }
  }

  let offset = -4;
  if (this.organization.timeZoneOffset != undefined) {
    offset = this.organization.timeZoneOffset;
  }
  const startDate = new Date(startSeconds * 1000);
  const endDate = new Date(endSeconds * 1000);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  startSeconds = startDate.getTime() / 1000;
  endSeconds = endDate.getTime() / 1000;
  const localOffset = -1 * new Date().getTimezoneOffset() / 60;
  const diff = offset - localOffset;
  startSeconds -= 3600 * diff;
  endSeconds -= 3600 * diff;

  console.log('start, end:');
  console.log(startSeconds, endSeconds);

  this.loadingService.toggleLoadingScreen(true);
  this.reportsService.loadDepositsReport(this.user.idToken, this.user.organizationId, session, this.selectedMethods, startSeconds, endSeconds)
  .then( res => {
    console.log('load deposits report res:');
    console.log(res);
    this.loadingService.toggleLoadingScreen(false);
    const body = JSON.parse(res._body);
    this.refundsTableData = body.json;
    console.log('json:', body.json);
    this.csvData = body.csv;
  })
  .catch( err => {
    console.log('caught err:', err);
    this.loadingService.toggleLoadingScreen(false);
    this.refundsTableData = [];

  })
}

public loadZeroBalancesReport(session) {
  console.log('load zero balances!');
    this.loadingService.toggleLoadingScreen(true);
    this.reportsService.loadZeroBalancesReport(this.user.idToken, this.user.organizationId, session)
    .then( res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log('load zero balances report');
      console.log(res);
      const body = JSON.parse(res._body);
      this.refundsTableData = body.json;
      console.log('json:', body.json);
      this.csvData = body.csv;
    })
    .catch( err => {
      console.log('Caught err:');
      console.log(err);
      this.loadingService.toggleLoadingScreen(false);
      this.refundsTableData = [];
    })
}

public exportDepositsReport() {
  if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const fileName =  this.report.name;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
}


  public exportZeroBalancesReport() {
    if (this.csvData == null || this.csvData.length == 0) {
      console.log('session is null');
      this.dialogsService.alert('No Data', 'There is no data to export');
      return;
    }

    const blob = new Blob([this.csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    const fileName =  'Zero Balances -' + this.selectedSession + '.csv';
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }


  public async loadRemainingCashReport() {
    console.log('Load remaining cash report.');
    console.log('Selected session:', this.selectedSession);
    if (!this.selectedSession) { return; }
    try {
      this.loadingService.toggleLoadingScreen(true);
      this.selectedData = await this.reportsService.loadRemainingCashReport(this.user.idToken, this.user.organizationId, this.selectedSession);
      this.loadingService.toggleLoadingScreen(false);
      console.log('Selected data:', this.selectedData);
    } catch (err) {
      this.loadingService.toggleLoadingScreen(false);
      console.log('err:', err);
      this.dialogsService.alert('Loading Error', 'There was an error loading your report. Please reload the page');
    }
  }

  printBalance(row: FunFangleReport): string {
    let balance = 0;
    if (row['Balance remaining'] !== undefined) balance = row['Balance remaining'];
    if (row['currentBalance'] !== undefined) balance = row['currentBalance'];
    return balance.toFixed(2);
  }

  printCamperName(row: FunFangleReport): string {
    if (row['Camper name'] !== undefined) return row['Camper name'];
    return `${row['firstName'] || ''} ${row['lastName'] || ''}`;
  }

  printParentName1(row: FunFangleReport): string {
    let name = '';
    if (row['Parent name'] !== undefined) name = row['Parent name'];
    if (row['accountHolder'] !== undefined) {
      const first = row['accountHolder']['firstName'] || '';
      const last = row['accountHolder']['lastName'] || '';
      name = `${first} ${last}`;
    }
    return name;
  }

  printParentName2(row: FunFangleReport): string {
    let name = '';
    if (row['Parent 2 name'] !== undefined) name = row['Parent 2 name'];
    if (row['accountHolder'] !== undefined) {
      const first = row['accountHolder']['parent2FirstName'] || '';
      const last = row['accountHolder']['parent2LastName'] || '';
      name = `${first} ${last}`;
    }
    return name;
  }

}
