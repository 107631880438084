import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CarePackage, CarePackageItem, CarePackageOrder } from '../models/care-package';
import { DialogsService } from '../dialog/dialog.service';
import { Subscription } from 'rxjs/Subscription';
import { CarePackagesService } from "../services/care-packages.service";
import { LoadingService } from '../services/loading.service';
import json2csv from "json2csv/lib/json2csv";
import { OrganizationService } from '../services/organization.service';

declare var moment: any;

@Component({
  selector: 'app-care-packages',
  templateUrl: './care-packages.component.html',
  styleUrls: ['./care-packages.component.scss']
})
export class CarePackagesComponent implements OnInit {

  public carePackages: CarePackage[] = [];
  public orders: CarePackageOrder[] = [];
  public allOrders: CarePackageOrder[] = [];
  public idToken: string;
  public tokenSubscription: Subscription;
  public user: any = null;
  public statuses: string[] = ["Unfulfilled", "Fulfilled", "Cancelled"];

  public showAddCarePackage: boolean = false;
  public showEditCarePackage: boolean = false;
  public emptyPackagesMessage = {
    emptyMessage: "No packages found. Click the plus to add a Care Package."
  }
  public emptyOrdersMessage = {
    emptyMessage: "No Orders Found"
  }

  public package: CarePackage;
  public organization;
  public sortOrder = 'newest';
  thisYear = (new Date()).getFullYear();
  start = new Date("1/1/" + this.thisYear);
  public ordersFilterApplied: boolean = false;

  public dateInputs: any = [
      {
          start: moment().subtract(12, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(9, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(3, 'month'),
          end: moment()
      },
      {
          start: moment(),
          end: moment().add(5, 'month'),
      }
  ];

  public datePickerOptions = {
    singleDatePicker: true
  }

  public mainInput = {
      start:  moment(this.start.valueOf()),//moment(this.start.valueOf()),
      end: moment().add(1, 'day')
  }

  public singleDate: any;
  public startSeconds;
  public endSeconds;

  constructor(
    public userService: UserService,
    public dialogsService: DialogsService,
    public authService: AuthService,
    public carePackageService: CarePackagesService,
    public loadingService: LoadingService,
    public organizationService: OrganizationService
  ) { 
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log("Update token customer-componenet!");
        /// console.log(token);
        this.idToken = token;
      }
    )

  }

  //show / hide the sidenav menu
  toggleMenu() {
    this.loadingService.toggleSideMenu();
  }

  ngOnInit() {
    //get user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
        .then(res => {
            this.loadingService.toggleLoadingScreen(false);
            this.user = res;
            this.loadOrganization();
            this.carePackageService.loadPackagesByOrganization(this.idToken, this.user.organizationId)
            .then( res => {
              console.log("care package:", res);
              var body = JSON.parse(res._body);
              if(body.status == "200"){
                this.carePackages = body.message.Items;
              }
            })
            .catch( err => {
              console.log("err:", err);
            })
            this.loadOrders();
            //this.loadOrganization();
        }); 
  }

    loadOrganization(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
          .then(res => {
            var body = JSON.parse(res._body);
            if (body.status == "200") {
              this.organization = body.message.Items[0];
              if (this.organization.carePackageEmailUpdatesEnabled === undefined || this.organization.carePackageEmailUpdatesEnabled === null) {
                this.organization.carePackageEmailUpdatesEnabled = true;
              }
            }
            resolve(this.organization);

          })
          .catch(err => {
            console.log("get organization err:", err);
            reject(err);
        })
      });
    }

  getUser(): Promise<any> {
      return new Promise((resolve,reject) => {
          this.userService.getUser()
          .then(res => {
              this.idToken = res.idToken;
              resolve(res);
          })
          .catch(err => {
              reject(err);
          });
      });
  }



  loadOrders(){
    this.carePackageService.loadOrders(this.idToken, this.user.organizationId, "organizationId")
    .then(res => {
      console.log("res:", res);
      var body = JSON.parse(res._body);
      if(body.status == "200"){
        this.orders = body.message.Items;
        console.log("got orders:", this.orders.length);
        this.orders.sort( (a, b) => {
          if (!a.placedAt) {
            return 1;
          } else if (!b.placedAt) {
            return -1;
          }
          return b.placedAt - a.placedAt;
        });
        this.orders.forEach( order => {
          let modifierString = "";
          if (order.placedAt) {
            let date = new Date(order.placedAt*1000);
            order.placedAtDate = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
            let hours = date.getHours();
            let am = "AM";
            let minutes = date.getMinutes().toString();
            if (parseInt(minutes) < 10) {
              minutes = "0" + minutes;
            }
            if (hours > 12) {
              hours -= 12;
              am = "PM";
            } else if (hours == 12){
              am = "PM";
            }
            let timeString = hours + ":" + minutes + " " + am;
            order.placedAtDate += " " + timeString;
          }
          if(order.modifiersByItem){
            for( let key in order.modifiersByItem){
              for( let subKey in order.modifiersByItem[key]){
                modifierString += order.modifiersByItem[key][subKey] + ", ";
              }
            }
          }
          modifierString = modifierString.slice(0, modifierString.length - 2);
          if(modifierString && modifierString != ""){
            order.modifierString = modifierString;
          }
        });
        this.allOrders = this.orders.slice();
      }
    })
  }

  



  openAddCarePackage(){
    this.showAddCarePackage = !this.showAddCarePackage;
    this.package = new CarePackage();
    this.package.items = [];
  }

  openEditCarePackage(carePackage: CarePackage){
    this.showEditCarePackage = !this.showEditCarePackage;
    this.package = carePackage;
  }

  removeCarePackage(carePackage: CarePackage){
    this.dialogsService.confirm("Remove this Care Package?", "You won't be able to undo this")
    .toPromise()
    .then( res => {
      if(res){
        //do the remove..
        this.loadingService.toggleLoadingScreen(true);
        this.carePackageService.deletePackage(this.idToken, carePackage)
        .then( res => {
          this.loadingService.toggleLoadingScreen(false);
          let body = JSON.parse(res._body);
          if(body.status == "200"){
            this.dialogsService.alert("Success!", "The Care Package has been removed!");
            this.ngOnInit();
          }else{
            this.dialogsService.alert("Processing Error!", "There was an error removing this package. Please try again later");
          }
         
        })
        .catch( err => {
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing Error!", "There was an error removing this package. Please try again later");
        })
      }
    })
  }

  closeCards(){
    this.showAddCarePackage = false;
    this.showEditCarePackage = false;
  }

  openAddItem(){
    this.dialogsService.carePackageItemDialog(new CarePackageItem())
    .toPromise()
    .then( (res:any) => {
      console.log("care package res:", res);
      if(res && res instanceof CarePackageItem){
        console.log("pushing it...");
        this.package.items.push(res);
      }
    })
  }

  async refundPackage(order: CarePackageOrder) {
    console.log("Order:", order);
    let confirm = await this.dialogsService.confirm("Refund Carepackage?", "There will be no way to undo this").toPromise();
    if (!confirm) return;
    this.loadingService.toggleLoadingScreen(true);
    this.carePackageService.refundPackage(this.idToken, order)
      .then( res => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Refund res:", res);
        this.dialogsService.alert("Success", "The order has been refunded!");
        this.loadOrders();
      })
      .catch(err => {
        this.loadingService.toggleLoadingScreen(false);
        console.log("Refund err:", err);
        let errorCode = JSON.parse(err._body).code;
        this.dialogsService.alert("Processing error", errorCode);
      })
    // alert box
  }

  openEditItem(item: CarePackageItem){
    this.dialogsService.carePackageItemDialog(item)
    .toPromise()
    .then( (res:any) => {
      console.log("care package res:", res);
      if(res && res instanceof CarePackageItem){
        console.log("pushing it...");
        item.title = res.title;
        item.description = res.description;
        item.modifiers = res.modifiers;
      }
    })
  }

  removeItem(item: CarePackageItem) {
    if (this.package && this.package.items) {
      let index = this.package.items.indexOf(item);
      this.dialogsService.confirm("Remove " + item.title + "?", "Once you hit Update, this cannot be undone")
      .toPromise()
      .then( res => {
        if (res) {
          this.package.items.splice(index, 1);
        }
      });
      
    }
  }


  addPackage(){
    console.log("add package:", this.package);
    //check for images
    let files = document.getElementById('photoupload')['files'];
    let packageId = this.carePackageService.generateUUID();
    console.log("files is:", files);
    if (!files.length) {
      console.log("no files...");
      this.submitAdd(packageId);
    }else{
      this.uploadImages(files, packageId, "add");
    }
  }

  uploadImages(files: any, packageId: string, type: string){
    console.log("image being uploaded...");
    let file = files[0];
    let imageKey = encodeURIComponent(packageId);
    this.loadingService.toggleLoadingScreen(true);
    this.carePackageService.uploadPackageImage(imageKey, this.user.organizationId, file)
    .then(res => {
      this.loadingService.toggleLoadingScreen(false);
      console.log("process upload res..");
      if (res.hasOwnProperty('Location')) {
        let imageUrl = res.Location;
        if(type == "add"){
          this.submitAdd(packageId, imageUrl);
        }else if(type == "edit"){
          this.submitUpdate(imageUrl);
        }
      }else{
        if(type == "add"){
          this.submitAdd(packageId);
        }else if(type == "edit"){
          this.submitUpdate();
        }
      }
    })
    .catch(err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }


  submitAdd(packageId: string, photoUrl: string = null){
    if(this.isValid(this.package)){
      this.loadingService.toggleLoadingScreen(true);
      let itemString = "";
      this.package.items.forEach( item => {
        itemString += item.title + ", ";
      })
      itemString = itemString.slice(0, itemString.length - 2);
      this.package.itemsString = itemString;
      this.carePackageService.createPackage(this.idToken, this.package, this.user.organizationId, packageId, photoUrl)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          let body = JSON.parse(res._body);
          console.log("care package create res:", res);
          console.log(body);
          if (body.status == "200") {
            this.dialogsService.alert("Success!", "Your Care Package has been created!");
            this.closeCards();
            this.ngOnInit();
          }else{
            this.dialogsService.alert("Processing Error", "There was an error creating this package. Please try again later");
          }
        })
        .catch(err => {
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing Error", "There was an error creating this package. Please try again later");
        })
    }else{
      this.dialogsService.alert("Invalid Package", "You must enter a title, a non-zero price, and add at least one item!");
    }
  }

  exportOrders() {
    this.loadingService.toggleLoadingScreen(true);
    let csvOrders = [];
    let totalPrice = 0;
    let totalTax = 0;
    this.orders.forEach( order => {
      let tax = order.tax || 0;
      let price = order.price || 0;
      totalTax += tax;
      totalPrice += price;
      let newOrder = {
        "Account Holder": order.accountHolderFirstName + " " + order.accountHolderLastName,
        "Authorized User": order.authUserFirstName + " " + order.authUserLastName,
        "Package": order.packageTitle,
        "Session": order.session,
        "Total Price": order.price,
        "Tax": tax.toFixed(2),
        "Cabin": (order.cabinName) ? order.cabinName : "-",
        "Status": order.status,
        "Deliver By": order.deliverBy,
        "Notes": order.orderNotes,
        "Modifiers": order.modifiersByItem,
        "Order Date": order.placedAtDate
      }
      csvOrders.push(newOrder);
    });
    csvOrders.push({
      "Account Holder": "Totals",
      "Total Price": totalPrice,
      "Tax": totalTax
    })
    let fields = ['Authorized User', 'Account Holder', 'Order Date', 'Package', 'Session', 'Modifiers', 'Cabin', 'Total Price', 'Tax', 'Status', 'Deliver By', 'Notes'];
    var a = document.createElement("a");
    document.body.appendChild(a);
    var csv = json2csv({ data: csvOrders, fields: fields });
    var blob = new Blob([csv], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob)
    a.href = url;
    a.download = "Care Package Orders.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    this.loadingService.toggleLoadingScreen(false);
  }

  updatePackage() {

    console.log("add package:", this.package);
    //check for images
    let files = document.getElementById('photoupload')['files'];
    console.log("files is:", files);
    if (!files.length) {
      console.log("no files...");
      this.submitUpdate();
    }else{
      console.log("is files...");
      this.uploadImages(files, this.package.packageId, "edit");
    }
  }

  submitUpdate(photoUrl: string = null){
    if(this.isValid(this.package)){
      this.loadingService.toggleLoadingScreen(true);
      let itemString = "";
      this.package.items.forEach( item => {
        itemString += item.title + ", ";
      })
      itemString = itemString.slice(0, itemString.length - 2);
      this.package.itemsString = itemString;
      this.carePackageService.updatePackage(this.idToken, this.package, this.user.organizationId, photoUrl)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          let body = JSON.parse(res._body);
          console.log("care package update res:", res);
          console.log(body);
          if (body.status == "200") {
            this.dialogsService.alert("Success!", "Your Care Package has been updated!");
            this.closeCards();
            this.ngOnInit();
          }else{
            this.dialogsService.alert("Processing Error", "There was an error updating this package. Please try again later");
          }
        })
        .catch(err => {
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing Error", "There was an error updating this package. Please try again later");
        })
    }else{
      this.dialogsService.alert("Invalid Package", "You must enter a title, a non-zero price, and add at least one item!");
    }
  }


  isValid(carePackage: CarePackage): Boolean{
    let valid = true;
    console.log("validate");
    console.log(carePackage);
    if(!carePackage.price || carePackage.price <= 0){
      valid = false;
    }
    if(!carePackage.title || carePackage.title == ""){
      valid = false;
    }
    if(!carePackage.items || carePackage.items.length == 0){
      valid = false;
    }
    return valid;
  }

  sortDate() {
    console.log("Sort date!");
    if (this.sortOrder === 'newest') {
      this.sortOrder = 'oldest';
      console.log("oldest on top");
      this.orders.sort( (a, b) => {
        if (!a.placedAt) {
            return 1;
          } else if (!b.placedAt) {
            return -1;
          }
          return a.placedAt - b.placedAt;
      })
      console.log(this.orders);
    } else {
      console.log("newest on top");
      this.sortOrder = 'newest';
      this.orders.sort( (a, b) => {
          if (!a.placedAt) {
            return 1;
          } else if (!b.placedAt) {
            return -1;
          }
          return b.placedAt - a.placedAt;
        });
        console.log(this.orders);
    }
    this.orders = [...this.orders];
  }


  openEditStatus(order: CarePackageOrder){
    console.log("open edit status..");
    console.log(order);
    this.dialogsService.select("Select a status", this.statuses)
    .toPromise()
    .then( (status: any) => {
      console.log("open edit status:", status);
      if(status){
        //update the status
        this.carePackageService.updateOrderStatus(this.idToken, order.orderId, order.packageId, status)
        .then( res => {
          console.log("update order status res:", res);
          let body = JSON.parse(res._body);
          if(body.status == "200"){
            this.dialogsService.alert("Status Updated!", "The status has been updated!");
            if (status == "Fulfilled" && this.organization.carePackageEmailUpdatesEnabled) {
              console.log("fulfilled");
              this.carePackageService.sendCarePackageEmail(this.idToken, this.user, order.packageTitle, this.organization, "fulfilled")
                .then( res => {
                  console.log("Care pacakge fulfilled res:", res);
                })
                .catch( err => {
                  console.log("err:", err);
                });
            }
            this.ngOnInit();
          }else{
            this.dialogsService.alert("Processing Error", "Could not update the status");
          }
        })
        .catch( err => {
          console.log("err:", err);
          this.dialogsService.alert("Processing Error", "Could not update the status");
        })
      }
    })

  }


    public selectedDate(value: any, dateInput: any) {
      // dateInput.start = value.start;
      // dateInput.end = value.end;

      // console.log("in selected date...");
      // console.log(value);
      // console.log(dateInput);
      // var startSeconds = value.start._d.getTime() / 1000;
      // var endSeconds = value.end._d.getTime() / 1000;

      // //now need to convert it to the organization's offset..

      // var offset = this.organization.timeZoneOffset;

      // startSeconds += 3600*offset;
      // endSeconds += 3600*offset + 60*60*24;
      // console.log("start seconds:", startSeconds);
      // console.log("end seconds:", endSeconds);



      // this.filterOrders(startSeconds, endSeconds);

      
  }

  public filterOrders(startSeconds, endSeconds) {
    this.orders = this.allOrders.filter( order => {
      if (order.placedAt && order.placedAt >= startSeconds && order.placedAt <= endSeconds) {
        return true;
      } else {
        return false;
      }
    })
  }
    
  public singleSelect(value: any) {
    console.log("single select...");
    this.singleDate = value.start;
  }

  public applyDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;

        console.log("in apply date...");
        console.log(value);
        console.log(dateInput);
  }

  public calendarEventsHandler(e:any) {
        console.log(e);
        if (e.event.type === "apply") {

          console.log("in selected date...");
          var startSeconds = e.picker.startDate._d.getTime() / 1000;
          // var endSeconds = e.picker.endDate._d.getTime() / 1000;

          //now need to convert it to the organization's offset..

          var offset = this.organization.timeZoneOffset;
          this.mainInput.start = e.picker.startDate;
          this.mainInput.end = e.picker.endDate;
          // startSeconds += 3600*offset;
          var endSeconds = 60*60*24 + startSeconds;
          console.log("start seconds:", startSeconds);
          console.log("end seconds:", endSeconds);

          this.ordersFilterApplied = true;

          this.filterOrders(startSeconds, endSeconds);
        }
        //this.eventLog += '\nEvent Fired: ' + e.event.type;
  }

  public clearOrdersFilter() {
    this.ordersFilterApplied = false;
    this.orders = this.allOrders.slice();
    this.mainInput = {
      start:  moment(this.start.valueOf()),
      end: moment().add(1, 'day')
    }
  }

}
