export class AuthCredentials{
  public password: string;
  public password2: string;
  public email: string;
  public role: string;

  constructor(){
    this.password = "";
    this.email = "";
    this.role = "account-holder";
  }

  toString(): string{
    var returnString = "password:" + this.password;
    returnString += " email: " + this.email;
    returnString += " role: " + this.role;
    return returnString;
  }

  validateCredentials(): boolean{
    return (this.validateEmail() && this.validatePassword());
  }

  /*validate email is correct format email@email.com*/
  validateEmail(): boolean{
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.email);
  }

  /*make sure password conforms to cognito standards - 8 chars w/ special character, uppercase, lowercase*/
  validatePassword(): boolean{
    if(this.password != this.password2){
      return false;
    }
    if(this.password.length < 8){
      return false;
    }
    //if(!/[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g.test(this.password)){
    //  return false;
    //}
    //if(!/[a-z]/.test(this.password) || !/[A-Z]/.test(this.password)){
    //  return false;
    //}
    //check for uppercase and lowercase letters
    return true;
  }


}
