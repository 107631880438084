import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router, RouterModule, NavigationEnd, NavigationStart } from '@angular/router';
import { Event } from '@angular/router';
import { UserService } from './services/user.service';
import { MatIconRegistry } from '@angular/material';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';

import { Ng2OverlayManager } from 'ng2-overlay';
import { Subscription } from 'rxjs/Subscription';

import { SquareService } from './services/square.service';
import { TutorialService } from './services/tutorial.service';
import { DialogsService } from './dialog/dialog.service';
import { OfflineMessage } from '../app/models/offline-message';
import { CustomerService } from '../app/services/customer.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit {
  title = 'FunFangle';
  showMenu: boolean;

  public user: any;
  public loadingSubscription: Subscription;
  public overlaySubscription: Subscription;
  public sideNavSubscription: Subscription;
  public tutorialStatusSubscription: Subscription;
  public showTutorial = false;
  public tutorialActive = false;
  public browserBlock = false;


  ngOnInit() {
    this.detectBrowserType();
    // every 30 seconds
    this.watchForOfflineMessages(30000);
    this.loadAppUrls();
    if(this.browserBlock){
      console.log('block everything');
      //this.router.navigate(['auth']);
      return;
    }
    this.router.events.subscribe((val: Event) => {
      if (val instanceof NavigationEnd) {
        if (this.showMenu) {
          var width = document.body.offsetWidth;
          console.log('navigated tutorial active:', this.tutorialActive);
          console.log('width is:', width);
          if (width < 1200 && !this.tutorialActive) {
            console.log('should be hiding menu now!!!');
            this.showMenu = false;
          }
        }

        if ((val.url === '/auth') || (val.url === '/') || (val.url.includes('activate')) || (val.url === '/organizations') || (val.url.includes('remainingFunds')) || (val.url.includes('resetPassword')) ) {
          this.showMenu = false;
        }
        else {
          //check the screen size
          console.log('Screen width: ' + document.body.offsetWidth);
          if (document.body.offsetWidth >= 1200) {
            this.showMenu = true;
          }

        }

        if (this.showTutorial || this.tutorialActive) {
          console.log('url:', val.url);
          var popoverId = this.tutorialService.getPopoverId();
          console.log('popoverId:', popoverId);
          if (popoverId == '') {
            this.showTutorial = false;
            this.tutorialActive = false;
          } else if (popoverId == 'popover3' || popoverId == 'popover4') {
            if (!val.url.includes('payment-settings')) {
              this.showTutorial = false;
              this.tutorialActive = false;
              this.tutorialService.closeSideNavTutorials();
            }
          } else if (popoverId == 'popover6') {
            if (!val.url.includes('users')) {
              this.showTutorial = false;
              this.tutorialActive = false;
              this.tutorialService.closeSideNavTutorials();
            }
          } else if (popoverId == 'popover8') {
            if (!val.url.includes('contributors')) {
              this.showTutorial = false;
              this.tutorialActive = false;
              this.tutorialService.closeSideNavTutorials();
            }
          }
        }

      }
    });

    //handle un-authenticated user routing
    if (!window.location.href.includes('/activate') && (!window.location.href.includes('/organizations') && (!window.location.href.includes('resetPassword') && !window.location.href.includes('remainingFunds')))) {
      setTimeout(() => {
        console.log('Initializing aws cognito app.component!');
        this.authService.initializeAWSCognito();
        this.authService.getUserFromStorage()
          .then(res => {
            //is logged in, refresh every so often
            setInterval(() => {
              console.log('triggering refresh!');
              this.authService.refreshToken();
            }, 5000*60);

          })
          .catch(err => {
            if (window.location.href.includes('/payment-settings/')) {
              var url = window.location.href;
              var split = url.split('/');
              var userId = split[split.length - 1];
              this.squareService.setRechargeUserId(userId);
            }
            this.router.navigate(['auth']);
          })
      }, 100)
    }


  }

  constructor(
    public router: Router,
    public userService: UserService,
    public authService: AuthService,
    public overlayManager: Ng2OverlayManager,
    public squareService: SquareService,
    public loadingService: LoadingService,
    public tutorialService: TutorialService,
    public dialogService: DialogsService,
    public customerService: CustomerService
  ) {



    this.loadingSubscription = loadingService.loadingSubscription$.subscribe(
      response => {
        // console.log('~~~~~~~~~~~~~~~~~~~~~response is:', response);
        if (response) {
          this.overlayManager.open('window-loading', null);
        } else {
          this.overlayManager.close('window-loading');
        }
      }
    );
    this.overlaySubscription = tutorialService.tutorialOverlaySubscription$.subscribe(
      response => {
        this.showTutorial = response;
      }
    )
    this.sideNavSubscription = loadingService.sideNavSubscription$.subscribe(
      response => {
        if (!this.showMenu) {
          this.showMenu = true;
        } else {
          this.showMenu = false;
        }
      }
    )

    this.tutorialStatusSubscription = tutorialService.tutorialStatusSubscription$.subscribe(
      response => {
        if (response) {
          this.showMenu = true;
        }
        this.tutorialActive = response;

      }
    )

  }



  onResize(event) {
    console.log('Resize!!!', event.target.innerWidth);
    var width = event.target.innerWidth;
    if (width >= 1200 && !window.location.href.includes('/activate') && !window.location.href.includes('/organizations') && !this.showMenu && !window.location.href.includes('auth') && !window.location.href.includes('/remainingFunds') && !window.location.href.includes('resetPassword')) {
      this.showMenu = true;
    } else if (width < 1200 && this.showMenu) {
      this.showMenu = false;
    }
  }

  watchForOfflineMessages(timeout: number) {
    this.loadOfflineMessages();
    setInterval( () => {
      this.loadOfflineMessages();
    }, timeout);
  }

  loadOfflineMessages() {
    console.log('Load offline messages!');
    this.userService.loadOfflineMessages()
      .then( (messages: OfflineMessage[]) => {
        let offlineMessage = messages.find(m => m.type === 'down');
        let warningMessage = messages.find(m => m.type === 'warning');

        if (offlineMessage) {
          this.setCookie('offlineMessage', JSON.stringify(offlineMessage), 600);
          window.location.href = '/maintenance/maintenance.html'
        }

        if (warningMessage) {
          console.log('We\'ve got a warning message');
          this.userService.setWarningMessage(warningMessage);
        } else {
          this.userService.setWarningMessage(null);
        }

      })
      .catch( err => {
        console.log(err);
        this.userService.setWarningMessage(null);
      })
  }

  loadAppUrls() {
    this.customerService.loadAppUrls('com.funfangle.parent');
  }


  setCookie(cname, cvalue, exseconds) {
    var d = new Date();
    d.setTime(d.getTime() + (exseconds*1000));
    var expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  detectBrowserType() {
    var objappVersion = navigator.appVersion;
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = '' + parseFloat(navigator.appVersion);
    var objBrMajorVersion = parseInt(navigator.appVersion, 10);
    var objOffsetName, objOffsetVersion, ix;

    console.log('agent:', objAgent);
    //Opera
    if (objAgent.indexOf('OPR') != -1) {
      objbrowserName = 'Opera';
      var startIndex = objAgent.indexOf('OPR/') + 4;
      objBrMajorVersion = parseFloat(objAgent.slice(startIndex, startIndex + 2));
      console.log('objBrMajor version:', objBrMajorVersion);
    }
    //Edge
    else if (objAgent.indexOf('Edge') != -1) {
      console.log('Edge detected!!!');
      objbrowserName = 'Edge';
      var startIndex = objAgent.indexOf('Edge/') + 5;
      objBrMajorVersion = parseFloat(objAgent.slice(startIndex, startIndex + 2));
      console.log('objBrMajor version:', objBrMajorVersion);
    }
    // In Chrome 
    else if ((objOffsetVersion = objAgent.indexOf('Chrome')) != -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    }
    // In Microsoft internet explorer 
    else if (((objOffsetVersion = objAgent.indexOf('MSIE')) != -1) || (objAgent.indexOf('Trident') != -1) )  {
      objbrowserName = 'IE';
      if(objAgent.indexOf('Trident')){
        objBrMajorVersion = 11;
      }else{
        objBrMajorVersion = 10;
      }
    }
    // In Firefox 
    else if ((objOffsetVersion = objAgent.indexOf('Firefox')) != -1) {
      objbrowserName = 'Firefox';
      var startIndex = objAgent.indexOf('rv:') + 3;
      objBrMajorVersion = parseFloat(objAgent.slice(startIndex, startIndex + 4));
      console.log('objBrMajor version:', objBrMajorVersion);
    }
    // In Safari 
    else if ((objOffsetVersion = objAgent.indexOf('Safari')) != -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) != -1) objfullVersion = objAgent.substring(objOffsetVersion + 8);
      console.log('obj full version here:', objfullVersion);
      let indexOfDot = objfullVersion.indexOf('.');
      console.log('indexOf Dot:', indexOfDot);
      let majorVNum = objfullVersion.slice(0, indexOfDot);
      console.log('Major vnum:', majorVNum);
      objBrMajorVersion = parseFloat(majorVNum);
      console.log('objbr version:', objBrMajorVersion);
    }
    // For other browser "name/version" is at the end of userAgent 
    else if ((objOffsetName = objAgent.lastIndexOf(' ') + 1) < (objOffsetVersion = objAgent.lastIndexOf('/'))) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() == objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present 
    if(objbrowserName != 'Firefox' && objbrowserName != 'Edge' && objbrowserName != 'IE' && objbrowserName != 'Opera' && objbrowserName != 'Safari'){
      if ((ix = objfullVersion.indexOf(';')) != -1)
        objfullVersion = objfullVersion.substring(0, ix);
      if ((ix = objfullVersion.indexOf(' ')) != -1)
        objfullVersion = objfullVersion.substring(0, ix);
        objBrMajorVersion = parseInt('' + objfullVersion, 10);
      if (isNaN(objBrMajorVersion)) {
        objfullVersion = '' + parseFloat(navigator.appVersion);
        objBrMajorVersion = parseInt(navigator.appVersion, 10);
      }
    }
    



    console.log('' +'Browser name = '+objbrowserName+'<br>' +'Full version = '+objfullVersion+'<br>' +'Major version = '+objBrMajorVersion+'<br>' +'navigator.appName = '+navigator.appName+'<br>' +'navigator.userAgent = '+navigator.userAgent+'<br>' )
    console.log('Browser:', objbrowserName);
    console.log('version:', objBrMajorVersion);
    //alert("Browser: " + objbrowserName + " " + objBrMajorVersion);
    this.browserBlock = false;

    //now check if to block
    switch(objbrowserName){
      case 'Chrome':
        if(objBrMajorVersion < 50){
          this.browserBlock = true;
          this.dialogService.confirm('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser. Click OK to be re-directed')
          .toPromise()
          .then( res=> {
            
            if(res){
              window.location.href = 'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en';
            }
            
          })

        }
        break;
      case 'Firefox':
        if(objBrMajorVersion < 50){
          this.browserBlock = true;
          this.dialogService.confirm('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser. Click OK to be re-directed')
          .toPromise()
          .then( res=> {
            
            if(res){
              window.location.href = 'https://support.mozilla.org/en-US/kb/update-firefox-latest-version';
            }
            
          })
        }
        break;
      case 'Edge':
        if(objBrMajorVersion < 12){
          this.browserBlock = true;
          this.dialogService.alert('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser');
        }
        break;
      case 'IE':
        console.log('case IE!')
        console.log(objBrMajorVersion);
        console.log(objBrMajorVersion < 11);
        console.log(objBrMajorVersion - 1);
        if(objBrMajorVersion < 11){
          console.log('failure is coming here');
          this.browserBlock = true;
          this.dialogService.alert('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser');
        }
        break;
      case 'Opera':
        if(objBrMajorVersion < 30){
          this.browserBlock = true;
          this.dialogService.alert('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser');
        }
        break;
      case 'Safari':
        if(objBrMajorVersion < 10){
          this.browserBlock = true;
          this.dialogService.confirm('Unsupported Browser', 'The browser you are using is not supported. Please update your browser to latest version or use another browser. Click OK to be re-directed')
          .toPromise()
          .then( res=> {
            
            if(res){
              window.location.href = 'http://www.wikihow.com/Update-Safari-on-Mac';
            }
            
          })
        }
        break;

    }
    

  }


}

