import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { Item, Modifier, ModifierOption } from "../models/item";


@Component({
    selector: 'modifier-detail',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">


                <div class="modal-error-message" *ngIf="errorMessage.length > 0">
                    {{errorMessage}}
                </div>


                <div>
                    <mat-form-field class="inventory-input-container">
                        <input type="text" matInput [(ngModel)]="option.name" placeholder="Name (i.e. Vanilla)">
                    </mat-form-field>
                    &nbsp;&nbsp;
                    <mat-form-field class="inventory-input-container">
                        <input type="number" matInput [(ngModel)]="option.cost" placeholder="Upcharge">
                    </mat-form-field>
                    <br/>
                    <div>
                        Default selected?
                        <span matTooltipPosition="right">
                            <mat-checkbox [(ngModel)]="option.isDefault">
                            </mat-checkbox>
                        </span>
                    </div>
                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="save()" class="submit-button">Save</button>
            </div>
            
        </div>
        `,
})
export class ModifierDetailDialog implements OnInit {

    public title: string = "New Modifier Option";

    public option: ModifierOption;
    public errorMessage: string = "";
    

    constructor(public dialogRef: MatDialogRef<ModifierDetailDialog>) {
       console.log("dialog ref:");
       console.log(dialogRef);
    }

    ngOnInit(){
        console.log("on init...");
        console.log("modifier...");
        if(!this.option) {
            this.option = new ModifierOption();
        }
    }


    isValid(): boolean {
        let isValid = true;
        if(!this.option.name || this.option.name == ""){
            isValid = false;
            this.errorMessage = "You must enter a name for this modifier option";
        }
        return isValid;
    }

    save(){
        if(this.isValid()){
            this.errorMessage = "";
            this.dialogRef.close(this.option);
        }
    }

    


    

}