import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { ApiEndpoint } from '../app.constants';
import { TokenService } from './token.service';




/*Service for interfacing with customer settings*/
@Injectable()
export class CustomerSettingsService {

  constructor(
    public http: Http,
  ) { }


  public idToken: string = "";

  //update a user's settings
  updateCustomerSettings(idToken:string, settings: any, email: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        lowBalanceThreshold: settings.lowBalanceAlert,
        email,
        organizationId
    }
    return this.http
        .post(`${ApiEndpoint}/customer-settings/updateCustomerSettings`, data, options)
        .toPromise();
  }


  //get all of the prohibited items
  getProhibitedItems(idToken: string, userId: string): Promise<any>{
      var options = TokenService.getHeaders();
      return this.http
        .get(ApiEndpoint + "/prohibited/getItems/" + userId, options)
        .toPromise();
  }

  //add a prohibited item to an authorized user
  addProhibitedItem(idToken: string, item: any): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        authorizedUserId: item.authorizedUserId,
        accountHolder: item.accountHolder,
        itemName: item.itemName
    }
    return this.http
    .post(ApiEndpoint + "/prohibited/add", data, options)
    .toPromise();
  }


  //delete an item from an authorized user's items
  deleteProhibitedItem(idToken: string, entryId: string): Promise<any>{
      var options = TokenService.getHeaders();
      return this.http
        .delete(ApiEndpoint + "/prohibited/delete/" + entryId, options)
        .toPromise();
  }







}