import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';


import { BaseEndpoint, OrganizationEndpoint, PosEndpoint, DevicesEndpoint } from '../app.constants';
import { SquareEndpoint } from '../app.constants';
import { TokenService } from './token.service';


declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class OrganizationCampersService{

  public user: any = null;
  public idToken: string = "";

  constructor(
    public http: Http,
  ){}

  getAllCampers(organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(BaseEndpoint + "/v2/profile/participants?organizationId=" + organizationId + `&fields=${encodeURIComponent('cabinName,currentBalance,imageUrl,session,shirt')}`, options)
      .toPromise()
  }

  getCampersBalances(idToken: string, campersIds: string[]): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      userIds: campersIds
    }
    return this.http
      .post(SquareEndpoint + "/balance/authUser", data, options)
      .toPromise();
  }

  importCampersCSV(idToken:string, json: any, organizationId: string ): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      json: json,
      organizationId: organizationId
    }
    return this.http
      .post(OrganizationEndpoint + "/users/importUsers", data, options)
      .toPromise();
  }

  importCampersWithBalances(idToken:string, json: any, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      json: json,
      organizationId: organizationId
    }
    console.log("sending data:", data);
    return this.http
      .post(PosEndpoint + "/users/importUsersWithBalances", data, options)
      .toPromise();
  }

  getHeaders(idToken: string): RequestOptions {
      var headers = new Headers({ 'Content-Type' : 'application/json'});
      headers.append('Authorization', idToken);
      var options = new RequestOptions({headers: headers});
      return options;
  }

  getCampersParentInfo(idToken: string, userId: string, organizationId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(BaseEndpoint + `/v2/profile/user?userId=${userId}&organizationId=${organizationId}&limit=20000`, options)
      .toPromise();
  }
  removeCamper(idToken: string, userId:string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      userId: userId
    }
    return this.http
    .post(OrganizationEndpoint + "/organizations/removeCamper", data, options)
    .toPromise();
  }
  getCamperTransaction(idToken: string, camper: any): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      authUserId: camper.userId
    }
    return this.http
      .post(SquareEndpoint + "/transactions/getTransactionsByAuthUserId", data,options)
      .toPromise();
  }

  depositAllocationNow(organizationId, activeUserEmail, activeUserId, allocationId): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      organizationId,
      activeUserEmail,
      activeUserId,
      allocationId
    }
    return this.http
      .post(`${DevicesEndpoint}/allocations/depositAllocationNow`, data,options)
      .toPromise();
  }

}
