import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { CarePackageItem, CarePackageModifier } from "../models/care-package";


@Component({
    selector: 'modifier-detail',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-package-item-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="package-item-wrap">

                <div class="package-item-row1">

                    <div class="package-item-header">
                        Item Details
                    </div>

                    <div class="modal-error-message" *ngIf="errorMessage.length > 0">
                        {{errorMessage}}
                    </div>


                    <div>
                        <mat-form-field class="inventory-input-container">
                            <input type="text" matInput [(ngModel)]="item.title" placeholder="Item Name">
                        </mat-form-field>
                        <br/>
                        <mat-form-field class="users-input-container">
                            <textarea style="width:200% !important; height:60px !important; border:1px solid grey !important;padding:2px !important" matInput [(ngModel)]="item.description" placeholder="Item Description"></textarea>
                        </mat-form-field>
                        <br/>
                    </div>
                </div>

                <div class="package-item-row2">
                    <div class="package-item-header">
                        Modifier Details (optional)
                    </div>
                    <div class="modifier-wrap" *ngFor="let modifier of item.modifiers; let j = index">
                        <div class="inventory-modifier-wrap row">
                            <mat-form-field class="inventory-input-container mod-left">
                                <input type="text" matInput [(ngModel)]="modifier.name" placeholder="Modifier Name">
                            </mat-form-field>
                            <div class="mod-right">
                                <button (click)="removeMod(modifier, j)" class="ff-card-header-icon-button" mat-icon-button><mat-icon>close</mat-icon></button>
                            </div>
                        </div>
                        <br/>
                        <div *ngFor="let opt of modifier.options; let i = index">
                            <div class="inventory-modifier-wrap row">
                                <mat-form-field class="inventory-input-container mod-left">
                                    <input [(ngModel)]="opt.name" type="text" matInput  placeholder="Option name">
                                </mat-form-field>
                                <div class="mod-right">
                                   <button (click)="removeModOption(modifier, i)" class="ff-card-header-icon-button" mat-icon-button><mat-icon>close</mat-icon></button>
                                </div>
                            </div>
                            <br/>
                        </div>
                        <div class="add-option-text" (click)="addOption(modifier)">
                            Add modifier option
                        </div>
                        <br/>
                    </div>
                    <div class="add-modifier-text" (click)="addModifier()">
                        Add Modifier
                    </div>
                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="save()" class="submit-button">Save</button>
            </div>
            
        </div>
        `,
})
export class CarePackageItemDialog implements OnInit {

    public title: string = "New Package Item";
    public item: CarePackageItem;
    public errorMessage: string = "";
    public itemRef: CarePackageItem;

    //public modifiers: CarePackageModifier[] = [];




    constructor(public dialogRef: MatDialogRef<CarePackageItemDialog>) {
       console.log("dialog ref:");
       console.log(dialogRef);
       setTimeout(() => {
           this.item = this.itemRef;
       },100);
    }

    ngOnInit(){
        console.log("on init...");
        this.item = new CarePackageItem();
    }


    isValid(): boolean {
        let isValid = true;
        if(!this.item.title || this.item.title == ""){
            isValid = false;
            this.errorMessage = "You must enter a name for this item";
        }
        //now validate modifiers
        if(this.item.modifiers && this.item.modifiers.length > 0){
            this.item.modifiers.forEach( mod => {
                if(mod.name == "" || !mod.name){
                    this.errorMessage = "You must enter a name for all your modifiers";
                    isValid = false;
                }
                console.log("options:", mod.options);
                mod.options.forEach( opt => {
                    if(opt.name == "" || !opt.name){
                        this.errorMessage = "All your options must have names";
                        isValid = false;
                    }
                })
                

            })
        }
        return isValid;
    }

    addOption(modifier: CarePackageModifier){
        modifier.options.push({name: ""});
        console.log("modifier options after:", modifier.options);
    }

    addModifier(){
        console.log("modifiers:", this.item.modifiers);
        if(this.item.modifiers){
            this.item.modifiers.push(new CarePackageModifier());
        }else{
            this.item.modifiers = [(new CarePackageModifier())];
        }
        console.log("modifiers now:", this.item.modifiers);
    }

    removeModOption(modifier: CarePackageModifier, index: number){
        modifier.options.splice(index, 1);
    }

    removeMod(modifier: CarePackageModifier, index: number){
        this.item.modifiers.splice(index, 1);
    }

    save(){
        console.log("modifiers:", this.item.modifiers);
        let isValid = this.isValid();
        console.log("is valid:", isValid);
        //this.item.modifiers = this.modifiers;
        if(this.isValid()){
            this.errorMessage = "";
            this.dialogRef.close(this.item);
        }
    }

    


    

}