import { MatDialogRef } from '@angular/material';
import { Component } from '@angular/core';

@Component({
    selector: 'input-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
        <div class="add-camper-dialog-container">
            <div class="ff-dialog-header">{{ title }}</div>
            <div class="outer-campers-wrap">
                <div  fxLayout="row" class="campers-wrap" *ngFor="let camper of authorizedUsers">
                    <div class="image-column">
                        <div *ngIf="camper.imageUrl == undefined || camper.imageUrl == null">
                            <mat-icon>person_outline</mat-icon>
                        </div>
                        <div class="user-image-wrap" *ngIf="camper.imageUrl != undefined && camper.imageUrl != null">
                            <img [src]="camper.imageUrl"/>
                        </div>
                    </div>
                    <div class="name-column">
                        {{camper.firstName}} {{camper.lastName}}
                    </div>

                    <div class="action-column">
                        <button title="Edit {{camper.firstName}}'s information" (click)="openEditUser(camper)"
                            mat-icon-button class="users-icon">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    
                </div>
            </div>

            <div class="add-camper-button-wrap">
                <button mat-raised-button (click)="addUser()" class="submit-button">New Camper</button>
            </div>
            
        </div>
        `,
})
export class ConfirmAddDialog {

    public title: string;
    public message: string;
    public cancelText: string;
    public okText: string;
    public authorizedUsers = [];
    

    constructor(public dialogRef: MatDialogRef<ConfirmAddDialog>) {
        console.log(dialogRef);
        console.log("auth users are:", this.authorizedUsers);
        console.log(dialogRef['componentInstance']);
        if(dialogRef.hasOwnProperty('componentInstance')){
            this.authorizedUsers = dialogRef.componentInstance.authorizedUsers;
            console.log("! undefined");
            console.log(this.authorizedUsers);
        }
    }


    openEditUser(user){
        var actions = ['edit', user];
        this.dialogRef.close(actions);
    }


    addUser(){
        var actions = ['add', null];
        this.dialogRef.close(actions);
    }

    


    

}