import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import {Router} from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { DialogsService } from '../dialog/dialog.service';
import { ActivationService } from '../services/activation.service';
import { TransactionsService } from '../services/transactions.service';
import { LogoWebUrl } from '../app.constants';


@Component({
  selector: 'app-activate',
  templateUrl: './remainingFunds.component.html',
  styleUrls: ['./remainingFunds.component.scss']
})
export class RemainingFundsComponent implements OnInit {

  userId: string = "";
  activeUser:any = null;
  user: any = null;
  dataLoaded: boolean = false;
  credentials = new AuthCredentials();
  cognitoUser: any;
  showVerifyText: boolean = false;
  screenHeight = screen.height;
  type: string = "";
  session: string = "";
  processComplete: boolean = false;
  success: boolean = false;
  displayMessage: string = "";
  activeUserEmail = null;
  logoUrl: string = "";

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public authService: AuthService,
    public dialogsService: DialogsService,
    public activationService: ActivationService,
    public transactionService: TransactionsService
  ) {
      console.log("height:", this.screenHeight);
      this.logoUrl = LogoWebUrl;
   }


  ngOnInit() {
    //load the user to be activated
    this.userId = this.route.snapshot.params['userId'];
    this.type = this.route.snapshot.params['type'];
    this.session = this.route.snapshot.params['session'];
    console.log(this.type, this.session);
    this.getUser()
    .then( res => {
      if(res != undefined && res.email != undefined){
        this.activeUserEmail = res.email;
      }else{
        this.activeUserEmail = "deep link";
      }

    })
    .catch(err => {
      this.activeUserEmail = "deep link";
    })
    this.userService.getUserById(this.userId)
    .then(res => {
      var items = JSON.parse(res._body).message.Items;
      if(items.length > 0){
        this.user = JSON.parse(res._body).message.Items[0];
    
        console.log("user is:", this.user);
      }


      if(this.type == 'donation'){
        this.transactionService.processIndividualDonation(this.user.userId, this.user.email, this.session, this.type, this.user.organizationId, this.activeUserEmail)
        .then(res => {
          console.log("donation res:", res);
          var body = JSON.parse(res['_body']);
          console.log("body is:", body);
          var amount = body.totalProcessed;
          this.processComplete = true;
          if(amount > 0){
            this.success = true;
            this.displayMessage = "Thank you for your donation! Your remaining balance has been donated to our campership fund"; 
          }else{
            this.success = true;
            this.displayMessage = "Thank you for your donation! Your remaining balance has been donated to our campership fund"; 
          }

        })
        .catch(err => {
          console.log("caught err:", err);
          this.processComplete = true;
          this.success = false;
          if(this.user.remainingFundsProcessed){
            this.displayMessage = "Thank you for your donation! Your remaining balance has been donated to our campership fund"; 
          }else{
            this.displayMessage = "There was an error processing the donation. Please try again later";
          }
          
        })
      }else if(this.type == 'refund'){
        var hasCard = true;


        if(this.user.squareCustomerId == undefined){
          var hasCard = false;
          //this.processComplete = true;
          //this.displayMessage = "We've marked your funds for refunding. Expect a check ";
          //return;
        }


        this.transactionService.processIndividualDonation(this.user.userId, this.user.email, this.session, this.type, this.user.organizationId, hasCard)
        .then(res => {
          console.log("donation res:", res);
          var body = JSON.parse(res['_body']);
          console.log("body is:", body);
          var amount = body.totalProcessed;
          this.processComplete = true;
          // if(amount > 0){
          //   this.success = true;
          //   this.displayMessage = "Thank you " + this.user.firstName + ", your remaining $" + amount + " has been refunded. It may take a few days to appear back on your card"; 
          // }else{
          //   this.success = true;
          //   this.displayMessage = "Thank you " + this.user.firstName + ", your remaining funds have been refunded! They may take a few days to appear on your card";
          // }
          this.success = true;
          if(hasCard){
            this.displayMessage = "We have marked your remaining funds to be refunded. The funds may take a few business days to appear back on your card";
          }else{
            this.displayMessage = "We have marked your remaining funds to be refunded. Expect a check in the coming weeks. For any questions, please contact us at the camp office";
          }
        })
        .catch(err => {
          console.log("caught err:", err);
          this.processComplete = true;
          this.success = false;
          if(this.user.remainingFundsProcessed){
            if(hasCard){
             this.displayMessage = "We have already marked your remaining funds to be refunded. The funds may take a few business days to appear back on your card";
            }else{
              this.displayMessage = "We have already marked your remaining funds to be refunded. Expect a check in the coming weeks. For any questions, please contact us at the camp office";
            }
          }else{
            this.displayMessage = "There was an error processing the refund. Please try again later";
          }
          
        })
      }

      this.dataLoaded = true;
    })
    .catch(err => {
      console.log("err:", err);
    })
  }



  getUser(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.userService.getUser()
        .then(res => {
          console.log("Get-USer:", res);
          this.activeUser = res;
          resolve(res);
        })
        .catch(err => {
          console.log("get user err:", err);
          reject(err);
        });
    })
  }





}
