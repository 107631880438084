import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

import { ApiEndpoint, BaseEndpoint, SquareEndpoint, TutorialEndpoint, AuthEndpoint } from '../app.constants';
import { TokenService } from './token.service';



/*Service for interacting with Square API */
@Injectable()
export class SquareService {

 //public applicationId = "sandbox-sq0idp-fN76CG4XwmlQBDu_j3ajBw";
 //public accessToken = "sandbox-sq0atb-5cDr2Uz3BrOPfppFue7RqA";
 public rechargeUserId = null;

 constructor(
   public http: Http,
 ) { }

 //create a square customer
 createCustomer(idToken: string, user: any): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
        .post(SquareEndpoint + "/square/customer/create/", user, options)
        .toPromise();
 }

 //add the user info to the user in the db
addUserInfo(idToken: string, squareUserInfo: any, organizationId): Promise<any>{
    var options = TokenService.getHeaders();
    squareUserInfo.organizationId = organizationId;
    return this.http
        .post(SquareEndpoint + "/square/addUserInfo", squareUserInfo, options)
        .toPromise();
}

setDefaultCard(idToken: string, email: string, cardId: string, organizationId): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
        email,
        defaultCardId: cardId,
        organizationId
    };
    return this.http
        .post(`${SquareEndpoint}/square/addCustomerId`, data, options)
        .toPromise();
}


//add customer id to the user in the db
addCustomerId(idToken: string, customerId: string, email: string, organizationId): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        email: email,
        squareCustomerId: customerId,
        organizationId
    }
    return this.http
        .post(`${SquareEndpoint}/square/addCustomerId`, data, options)
        .toPromise();
}


//create a card
createCustomerCard(idToken: string, requestBody: any): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
        .post(SquareEndpoint + "/square/createCustomerCard", requestBody, options)
        .toPromise();
}


//remove a card
removeCustomerCard(idToken: string, email: string, customerId: string, cardId: string, organizationId: string, squareInfo): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        email,
        customerId,
        cardId,
        organizationId,
        squareInfo
    }
    return this.http
        .post(SquareEndpoint + "/square/card/delete", data, options)
        .toPromise();
}

fetchFutureFunds(idToken: string, userId: string, organizationId: string): Promise<any> {
    const options = TokenService.getHeaders();
    const data = {
        userId,
        organizationId
    }
    return this.http.post(`${SquareEndpoint}/balance/getScheduledAllocationsForOrganization`, data, options)
        .map( (res: any) => { let body = JSON.parse(res._body); return body.allocations; })
        .toPromise()
}

//charge the customer and add the funds to their account
chargeCustomer(idToken: string, email:string, deposit_amount: number, customerId: string, cardId: string, organizationId: string, userId: string, firstName: string, lastName: string,
ipAddress: string, timeZone, incrementBalance: boolean = true): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
        organizationId: organizationId,
        deposit_amount: deposit_amount,
        email: email,
        customerId: customerId,
        cardId: cardId,
        firstName: firstName,
        lastName: lastName,
        userId: userId,
        ipAddress: ipAddress,
        timeZone: timeZone,
        incrementBalance: incrementBalance
    }
    console.log("passing in:", data);
    return this.http
        .post(SquareEndpoint + "/square/card/charge", data, options)
        .toPromise();
}


refundTransaction(idToken: string, transactionId: string, squareTransactionId: string, tenderId: string, locationId: string, amount: number, timeZone, organizationId: string): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      transactionId,
      organizationId,
      squareTransactionId, 
      tenderId,
      locationId,
      amount,
      timeZone 
    }
    return this.http
      .post(`${TutorialEndpoint}/transactions/processRefund`, data, options)
      .toPromise();

}

refundDepositV2(transactionId: string, amount: number, organizationId: string, paymentType = 'credit'): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      amount,
      amountType: 'DECIMAL',
      organizationId,
      paymentType,
      responseAmountType: 'DECIMAL',
      transactionId
    }
    return this.http
      .post(`${BaseEndpoint}/v2/bank/refund/deposit`, data, options)
      .toPromise();
}

refundSaleV2(transactionId: string, amount: number, organizationId: string, paymentType = 'credit'): Promise<any>{
    const options = TokenService.getHeaders();
    const data = {
      amount,
      amountType: 'DECIMAL',
      organizationId,
      paymentType,
      responseAmountType: 'DECIMAL',
      transactionId
    }
    return this.http
      .post(`${BaseEndpoint}/v2/bank/refund/sale`, data, options)
      .toPromise();
}

// Perform an Unallocation
fundsTransferToAuthUser(idToken: string, email:string, amount: number, authUserId: string, userId: string, role: string, contributorName: string, relationship: string, firstName: string, lastName: string, accountHolder: string, organizationId: string, 
activeUserId: string, activeUserEmail: string, ipAddress: string, timeZone): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
        amount: amount,
        contributeeId: authUserId,
        contributorId: userId,
        organizationId: organizationId,
        amountType: 'DECIMAL',
        responseAmountType: 'DECIMAL'
    }
    console.log('/bank/allocate ', data);
    return this.http
        .post(`${BaseEndpoint}/v2/bank/allocate`, data, options)
        .toPromise();
  
//     var options = TokenService.getHeaders();
//     var data = {
//         email:email,
//         amount: amount,
//         userId: userId,
//         authUserId: authUserId,
//         role: role,
//         name: contributorName,
//         relationship: relationship,
//         accountHolder: accountHolder,
//         firstName: firstName,
//         lastName: lastName,
//         organizationId: organizationId,
//         activeUserId: activeUserId,
//         activeUserEmail: activeUserEmail,
//         ipAddress: ipAddress,
//         timeZone: timeZone
//     }
//     return this.http
//         .post(SquareEndpoint + "/transfer/toAuthUser", data, options)
//         .toPromise();
}

// deposit / withdraw funds
fundsToTransfer(idToken: string, amount:number, authUserId: string, userId:string, role: string, contributorName:string, relationship: string, firstName: string, lastName: string, accountHolder: string, organizationId: string,
  activeUserId: string, activeUserEmail: string, editFundsNote: string, ipAddress: string, enableBalanceCorrection: boolean = false): Promise<any>{
  if (amount >= 0) {
    var options = TokenService.getHeaders();
    var data1 = {
        amount: amount,
        authUserId: authUserId,
        contributorId: userId,
        organizationId: organizationId,
        paymentType: editFundsNote !== undefined ? editFundsNote.toLowerCase() : 'unspecified',
        amountType: 'DECIMAL',
        responseAmountType: 'DECIMAL'
    }
    return this.http
        .post(`${BaseEndpoint}/v2/bank/deposit/allocate`, data1, options)
        .toPromise();
  } else {
    var options = TokenService.getHeaders();
    var data2 = {
        amount: amount * -1,
        authUserId: authUserId,
        contributorId: userId,
        organizationId: organizationId,
        paymentType: 'cash',
        amountType: 'DECIMAL',
        responseAmountType: 'DECIMAL'
    }
    return this.http
        .post(`${BaseEndpoint}/v2/bank/withdraw`, data2, options)
        .toPromise();
  }
  
//    var options = TokenService.getHeaders();
//    console.log("org id is:", organizationId);
//    var data = {
//      amount,
//      authUserId,
//      userId,
//      role,
//      name: contributorName,
//      relationship,
//      accountHolder,
//      firstName,
//      lastName,
//      organizationId,
//      activeUserId,
//      activeUserEmail,
//      paymentMethod: editFundsNote,
//      ipAddress,
//      enableBalanceCorrection
//    }
//    return this.http
//      .post(`${SquareEndpoint}/transfer/fundsTransferEmployeeToAuthUser`, data ,options)
//      .toPromise();
}

// Perform Unallocation
//transfer funds to an auth user from their account-holder's account
fundsTransferFromAuthUser(idToken: string, email:string, amount: number, authUserId: string, userId: string, firstName: string, lastName: string, accountHolder: string, organizationId: string,
activeUserId: string, activeUserEmail: string, ipAddress: string): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
        amount: amount,
        contributeeId: authUserId,
        contributorId: userId,
        organizationId: organizationId,
        amountType: 'DECIMAL',
        responseAmountType: 'DECIMAL'
    }
    return this.http
        .post(`${BaseEndpoint}/v2/bank/unallocate`, data, options)
        .toPromise();
  
    // var options = TokenService.getHeaders();
    // var data = {
    //     email:email,
    //     amount: amount,
    //     authUserId: authUserId,
    //     userId: userId,
    //     accountHolder: accountHolder,
    //     firstName: firstName,
    //     lastName: lastName,
    //     organizationId: organizationId,
    //     activeUserId: activeUserId,
    //     activeUserEmail: activeUserEmail,
    //     ipAddress: ipAddress
    // }
    // console.log("ftfau:", data);
    // return this.http
    //     .post(SquareEndpoint + "/transfer/fromAuthUser", data, options)
    //     .toPromise();
}



//split funds evenly amoung all campers associated with an account

// fundsToWithdraw(idToken: string, amount: number, authUserId: string, userId: string): Promise<any> {
//    var options = TokenService.getHeaders();
//    var data = {
//      amount: amount,
//      authUserId: authUserId,
//      userId: userId
//    }
//    return this.http
//      .post(SquareEndpoint + "/transfer/fundsWithdrawEmployeeToAuthUser", data, options)
//      .toPromise();
//
//   }

// splitFundsEvenly(idToken: string, email: string, authUserIds: string[], userId: string, amount: number, role: string, contributorName: string, relationship: string, ipAddress: string, timeZone, organizationId): Promise<any> {
//     var options = TokenService.getHeaders();
//     var data = {
//         email:email,
//         authUserIds: authUserIds,
//         userId: userId,
//         amount: amount,
//         role: role,
//         name: contributorName,
//         relationship: relationship,
//         ipAddress: ipAddress,
//         timeZone: timeZone,
//         organizationId
//     }
//     console.log("split funds data:", data);
//     console.log("token:", idToken);
//     return this.http
//         .post(SquareEndpoint + "/transfer/splitFundsEvenly", data, options)
//         .toPromise();
// }

//store the rechargeUserId
setRechargeUserId(userId: string){
    this.rechargeUserId = userId;
}

getRechargeUserId(): string{
    return this.rechargeUserId;
}



parentInitiatedRefund(idToken: string, email: string, refundAmount: number, timeZone, ipAddress: string, organizationId: string): Promise<any> {
    var options = TokenService.getHeaders();
    var data = {
        email:email,
        refundAmount: refundAmount,
        ipAddress: ipAddress,
        timeZone: timeZone,
        organizationId
    }
    return this.http
        .post(TutorialEndpoint + "/transactions/parentInitiatedRefund", data, options)
        .toPromise();
}


transferFunds(idToken: string, organizationId: string, toAccount: string, fromAccount: string, contributorId: string, amount: number, activeUserId: string): Promise<any> {
    // var options = TokenService.getHeaders();
    // var data = {
    //     amount: amount,
    //     authUserId: authUserId,
    //     contributorId: userId,
    //     organizationId: organizationId,
    //     amountType: 'DECIMAL',
    //     responseAmountType: 'DECIMAL'
    // }
    // return this.http
    //     .post(`${BaseEndpoint}/v2/bank/allocate`, data, options)
    //     .toPromise();
  
    const options = TokenService.getHeaders();
    const data = {
      toAuthUserId: toAccount,
      authUserId: fromAccount,
      fromContributorEmail: null,
      contributorId: contributorId,
      organizationId: organizationId,
      amount: amount,
      amountType: "DECIMAL",
      userId: activeUserId
    }
    return this.http
        .post(`${BaseEndpoint}/v2/bank/transfer`, data, options)
        .toPromise();
  }










}
