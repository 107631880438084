import { Component, OnInit } from '@angular/core';
import { DialogsService } from '../dialog/dialog.service';
import { TransactionSearchTerms } from '../models/transaction-search';
import { LoadingService } from '../services/loading.service';
import { OrganizationService } from '../services/organization.service';
import { SquareService } from '../services/square.service';
import { TransactionsService } from '../services/transactions.service';
import { UserService } from '../services/user.service';
import json2csv from "json2csv/lib/json2csv";
import { Daterangepicker } from 'ng2-daterangepicker';
import { DaterangepickerConfig } from 'ng2-daterangepicker';
import { ActivatedRoute } from '@angular/router';
import { Subscription }   from 'rxjs/Subscription';

declare var moment:any;

@Component({
  selector: 'app-customers',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})

export class TransactionsComponent implements OnInit {
  public allTransactions = [];
  public displayTransactions = [];
  public user: any = null;
  public orgId: string = null;
  public idToken: string = "";
  public searchText = "";
  public trans = [];
  public allUserTrans = [];
  public userRole = "";
  public organization = null;
  public singleDate: any;
  public startSeconds;
  public endSeconds;
  public ipAddress: string = "";
  public forceAccountHolder: boolean = false;
  public bypassRouteCheck: boolean = false;
  public searchTerms = new TransactionSearchTerms();

  thisYear = (new Date()).getFullYear();
  start = new Date("1/1/" + this.thisYear);

  public dateInputs: any = [
      {
          start: moment().subtract(12, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(9, 'month'),
          end: moment().subtract(6, 'month')
      },
      {
          start: moment().subtract(3, 'month'),
          end: moment()
      },
      {
          start: moment(),
          end: moment().add(5, 'month'),
      }
  ];

  public dateInput = {
      start: moment().subtract(7, 'days'), //moment(this.start.valueOf()),
      end: moment((new Date()).valueOf() + 100000000) //+ 1000000000
  }

  public toggleSubscription: Subscription;

  constructor(
    public userService: UserService,
    public transactionsService: TransactionsService,
    public loadingService: LoadingService,
    public dialogsService: DialogsService,
    public organizationService: OrganizationService,
    public daterangepickerOptions: DaterangepickerConfig,
    public squareService: SquareService,
    public route: ActivatedRoute
  ){

    this.toggleSubscription = organizationService.toggleSubscription$.subscribe(
      show => {
        // console.log("receiving value:", show);
        this.forceAccountHolder = show;
        this.bypassRouteCheck = true;
        this.allTransactions = [];
        this.ngOnInit();
      }
    )

    this.daterangepickerOptions.settings = {
        locale: { format: 'YYYY-MM-DD' },
        alwaysShowCalendars: false,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
            'Last Month': [moment().subtract(1, 'month'), moment()],
            'Last 3 Months': [moment().subtract(3, 'month'), moment()],
            'Last 12 Months': [moment().subtract(12, 'month'), moment()],
            'Year to Date': [ moment(this.start.valueOf()), moment()]
        }
    };
    this.singleDate = Date.now();


    this.userService.getIPAddress()
    .subscribe( res => {
      var ipAddress = res._body.ip;
      // console.log("got ip address:", ipAddress);
      if(ipAddress != null && ipAddress != undefined && ipAddress != ""){
        this.ipAddress = ipAddress;
      }
    });

  }
  
  ngOnInit(){
    if(!this.bypassRouteCheck){
      // console.log("checking route!");
      let forceAccountHolder = this.route.snapshot.params['forceAccountHolder'];
      if(forceAccountHolder){
        this.forceAccountHolder = forceAccountHolder;
      }
    }

    // console.log("force account holder:", this.forceAccountHolder);

    this.getUser()
    .then(res => {
      this.user = res;
      this.getAllUserTransaction();
      this.getOrganization()
      .then( res => {
        if (this.user.role !== 'account-hodler') {
          this.getAllTransactions(null, true);
        }
      });
    })
  }


  getOrganization(): Promise<any> {
    return new Promise ( (resolve, reject) => {
      this.organizationService.getOrganizationById(this.idToken, this.orgId)
      .then(res => {
        var body = JSON.parse(res._body);
        this.organization = body.message.Items[0];
        // default to not show it now
        this.organization.itemsColumnEnabled = (this.organization.itemsColumnEnabled != undefined)? this.organization.itemsColumnEnabled : false;
        resolve(this.organization);
      })
      .catch( err => {
        reject(err);
      })
    })
    
  }

  getUser(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.userService.getUser()
        .then(res => {
          // console.log("Get-USer:", res);
          this.idToken = res.idToken;
          this.orgId = res.organizationId;
          this.userRole = res.role;
          resolve(res);
        })
        .catch(err => {
          console.log("get user err:", err);
          reject(err);
        });
    })
  }


  getAllTransactions(lastEvalKey = null, reset = false, transactionAmount?: number, cardHolderEmail?: string, cardHolderName?: string, cardLast4?: string)
    {
      if(reset){
        this.allTransactions = [];
        this.allTransactions.push({});
        this.displayTransactions = [];
        this.displayTransactions.push({});
      }
      // console.log(this.orgId);
      this.loadingService.toggleLoadingScreen(true);

      const lowerDate: Date = this.dateInput.start._d;
      const upperDate: Date = this.dateInput.end._d;

      const lowerISO = `${lowerDate.getFullYear()}-${(lowerDate.getMonth() + 1).toString().padStart(2, '0')}-${(lowerDate.getDate()).toString().padStart(2, '0')}T00:00:00.000`;
      const upperISO = `${upperDate.getFullYear()}-${(upperDate.getMonth() + 1).toString().padStart(2, '0')}-${(upperDate.getDate()).toString().padStart(2, '0')}T23:59:59.999`;
      // console.log('getAllTransactions', this.orgId, lastEvalKey, lowerISO, upperISO);

      // let offset = -4;
      // if (this.organization.timeZoneOffset !== undefined) {
      //   offset = this.organization.timeZoneOffset;
      // }
      // let startSeconds = this.dateInput.start._d.getTime() / 1000;
      // let endSeconds = this.dateInput.end._d.getTime() / 1000;
      // const startDate = new Date(startSeconds * 1000);
      // const endDate = new Date(endSeconds * 1000);
      // startDate.setHours(0, 0, 0, 0);
      // endDate.setHours(23, 59, 59, 999);
      // startSeconds = startDate.getTime() / 1000;
      // endSeconds = endDate.getTime() / 1000;
      // const localOffset = -1 * new Date().getTimezoneOffset() / 60;
      // const diff = offset - localOffset;
      // startSeconds -= 3600 * diff;
      // endSeconds -= 3600 * diff;
      // console.log(startSeconds, endSeconds);

      this.transactionsService.getAllTransactions(this.idToken, this.orgId, lastEvalKey, lowerISO, upperISO, transactionAmount, cardHolderEmail, cardHolderName, cardLast4)
      .then(res =>{
        this.loadingService.toggleLoadingScreen(false);
        // console.log("all trans res", res);
        var body = JSON.parse(res._body);
        // console.log('res.body', body);
        var items = body.items;
        if (body.items) {
          for (let trans of items) {
            if (trans.timeStamp) {
              let date = new Date(trans.timeStamp*1000);
              let dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
              trans.deviceDate = dateString;
            }
          }
        }
        if(body.hasOwnProperty('lastKey')){
          // console.log("LAST EVAL KEY:", body.message.LastEvaluatedKey);
          // console.log("go get more...");
          // console.log(body.message.LastEvaluatedKey);
          //console.log(body.message.Items);
          if(this.allTransactions.length == 0){
            this.allTransactions = body.items;
          }else{
            this.allTransactions.push.apply(this.allTransactions,body.items);
          }
          this.getAllTransactions(body.lastKey, undefined, transactionAmount, cardHolderEmail, cardHolderName, cardLast4);
          this.displayTransactions = this.allTransactions;
        }else{
          // console.log("NO LAST EVAL KEY:", body.message.LastEvaluatedKey);
          // console.log("no need to get any more...");
          if(this.allTransactions.length == 0){
            // console.log("set it");
            this.allTransactions = body.items;
          }else{
            // console.log("push.apply");
            this.allTransactions.push.apply(this.allTransactions, body.items);
          }
          // console.log("pre sort");
          this.allTransactions.sort( (x,y) => {
            return y.timeStamp - x.timeStamp
          })
          // console.log("post sort");
          this.displayTransactions = this.allTransactions;
          try {
            // let startSeconds = this.dateInput.start._d.getTime() / 1000;
            // let endSeconds = this.dateInput.end._d.getTime() / 1000;
            // console.log("start seconds:" + startSeconds);
            // console.log("end seconds:" + endSeconds);
            // console.log("ALL TRANSACTIONS NOW!");
            // // this.filterTransactions(startSeconds, endSeconds);
            // console.log(this.allTransactions);
          } catch (err) {
            console.log("caught error");
            console.log(err);
          }
        }
        
      })
      .catch(err => {
        this.loadingService.toggleLoadingScreen(false);
      })
  }
  
  refreshTransaction(){
    this.getAllTransactions(null, true);
  }
  refreshUserTransaction(){
    this.getAllUserTransaction();
  }


  getAllUserTransaction(){
    // console.log(this.user);
    // console.log(this.user.userId);
    this.loadingService.toggleLoadingScreen(true);
    this.userService.getUserTransaction(this.idToken, this.user.userId)
    .then(res=> {
      this.loadingService.toggleLoadingScreen(false);
      // console.log("res:", res);
      var body = JSON.parse(res._body);
      var message = body.message.Items;
      this.allUserTrans = message;
      this.allUserTrans.sort(function(x,y){
        return y.timeStamp - x.timeStamp
      })
      // console.log(this.allUserTrans);
    })
    .catch( err => {
      this.loadingService.toggleLoadingScreen(false);
    })
  }

  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }


  get getDisplayTransactions() {
    // let index = 0;
    // this.displayTransactions.forEach( trans => {
    //   trans.index = index;
    //   index++;
    // });
    return this.displayTransactions;
  }

  downloadTransactions(){
    // console.log("data to export is:", this.allTransactions);
    
    // this.loadingService.toggleLoadingScreen(true);
    var timeZone = -4;
    if(this.organization.timeZoneOffset != undefined){
      timeZone = this.organization.timeZoneOffset;
    }
    // var fields = ["Time", "Name", "Session",  "Register Id", "Register Alias", "Cashier", "Operation Type", "Method", "Status", "Line Item", "Location", "Category", "Tab", "Item Type", "Amount", "Net Sales", "Transaction Id", "Payment Id"];
    var fields = ["Time", "Name", "Session",  "Register Id", "Register Name", "Cashier", "Operation Type", "Method", "Payment Notes", "Status", "Notes", "Items", "Mods", "Location", "Category", "Tab", "Item Type", "Amount", "Net Sales", "Transaction Id", "Payment Id", "Payout Id", "Payout Date", "Refund Id",
    "Line Item", "Price", "Quantity", "Modifiers", "Subtotal", "Discounts", "Net Total", "Tax", "Total Due"];    
    // this.displayTransactions = this.displayTransactions.filter( trans => trans.items && trans.items.indexOf("Reimburse") !== -1);
    let data = [];
    // console.log("Transactions:", this.displayTransactions);
    this.displayTransactions.forEach( transaction => {
      let subData = {};
      
      //Generic fields first
      subData["Name"] = transaction.userFirstName + " " + transaction.userLastName;
      subData["Session"] = transaction.session;

      if(!transaction.paymentType && !transaction.paymentMethod && transaction.type === 'transaction'){
          subData["Method"] = "account";
      }else if (transaction.paymentType) {
          subData["Method"] = transaction.paymentType;
      } else if (transaction.paymentMethod) {
          subData["Method"] = transaction.paymentMethod;
      }
      
      subData["Amount"] = transaction.amount;
      subData["Payment Notes"] = transaction.paymentNotes || " ";
      subData["Net Sales"] = transaction.netSales;
      subData["Payment Id"] = transaction.squareTransactionId || transaction.processorPaymentId;
      subData["Refund Id"] = transaction.processorRefundId;
      subData["Register Name"] = transaction.registerName || "-";
      subData["Notes"] = transaction.notes;
      subData["Items"] = transaction.items;
      let timeStamp = transaction.timeStamp;
      if (transaction.timeStamp) timeStamp = transaction.timeStamp;
      //date/time stuff...
      if(timeStamp != undefined){
          var offset = 3600*timeZone;
          var localSeconds = timeStamp - offset;
          //now need to convert this to a date/time
          var date = new Date(localSeconds*1000);
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          var dateString = month + "/" + day + "/" + year;
          var hour = date.getHours();
          var am = "PM";
          let hourString = "";
          let minuteString = "";
          if(hour > 12){
              hour -= 12;
          }else if(hour < 12){
              am = "AM";
          }
          hourString = hour.toString();
          if(hour < 10){
              hourString = "0" + hour;
          }
          let minutes = date.getMinutes();
          minuteString = minutes.toString();
          if(minutes < 10){
              minuteString = "0" + minutes;
          }
          var seconds = date.getSeconds();
          let secondString = seconds.toString();
          if(seconds < 10){
              secondString = "0" + seconds;
          }
          var timeString = hourString + ":" + minuteString + ":" + secondString + " " + am;
          subData["Time"] = dateString + " " + timeString + " UTC " + timeZone;
      }
      
      
      //subData["Date"] = transaction.date;
      
      subData["Operation Type"] = transaction.type;
      // subData["Items"] = transaction.items;
      subData["Location"] = transaction.locationName;
      subData["Status"] = transaction.status;
      subData["Register Id"] = transaction.registerId;
      subData["Transaction Id"] = transaction.transactionId;
      subData["Cashier"] = transaction.activeUserEmail;

      subData["Payout Id"] = transaction.processorPayoutId;
      subData["Payout Date"] = transaction.processorPayoutDate;

      // nned: "Line Item", "Price", "Quantity", "Modifiers", "Subtotal", "Discounts", "Net Total", "Tax", "Total Due",
      if(!transaction.cart || transaction.cart.length === 1) {
          subData["Items"] = transaction.items;
          subData["Line Item"] = (transaction.cart && transaction.cart.length === 1)? transaction.cart[0].itemName : transaction.items;
          subData["Category"] = (transaction.cart && transaction.cart.length === 1) ? transaction.cart[0].category : undefined;
          subData["Discounts"] = transaction.discount ? -1*transaction.discount : 0; // transaction.discount ? -1*Math.abs(transaction.discount) : 0;
          subData["Modifiers"] = 0;
          subData["Net Total"] = transaction.netSales ? Math.abs(transaction.netSales) : 0;
          subData["Quantity"] = transaction.numItems ? transaction.numItems : 0;
          let subTotal =  Math.abs(transaction.netSales) + transaction.discount; // Math.abs(transaction.netSales) - Math.abs(transaction.discount);
          subData["Price"] = subTotal ? Math.abs(subTotal) : 0;
          subData["Subtotal"] = subTotal ? Math.abs(subTotal) : 0;
          // console.log("Trans type:", transaction.type);
          subData["Tab"] = (transaction.cart && transaction.cart.length === 1) ? transaction.cart[0].tab : undefined;
          subData["Tax"] = transaction.tax ? transaction.tax : 0;
          subData["Total Due"] = transaction.amount ? Math.abs(transaction.amount) : 0;
          if (transaction.type === "transaction-reversal") {
            subData["Total Due"] *= -1;
          }
          data.push(subData);
      } else {
          try {
          transaction.cart.forEach( item => {
              let clone = Object.assign({}, subData);
              clone["Line Item"] =  item.itemName;
              clone["Price"] = item.baseCost ? item.baseCost : 0;
              clone["Category"] = item.category;
              if(item.discount){
                clone["Discounts"] = -1 * item.discount; // -1*Math.abs(item.discount);
              }else{
                clone["Discounts"] = 0;
              }
              clone["Item Type"] = item.itemType;
              let modString = "";
              if (item.selectedModifiers) {
                modString = item.selectedModifiers.map(mod => mod.name).reduce( (a, b) => { return `${a} ${b}` }, "");
              }
              clone["Mods"] = modString;
              if(item.modifierCost){
                  clone["Modifiers"] = item.modifierCost;
              }else{
                  clone["Modifiers"] = 0;
              }
              clone["Quantity"] = item.quantity ? item.quantity : 0;
              clone["Subtotal"] = item.subtotal ? item.subtotal : 0;
              clone["Net Total"] = clone["Subtotal"] + clone["Discounts"];// item.netTotal ? item.netTotal : 0;
              clone["Tab"] = item.tab;
              clone["Tax"] = item.taxes ? item.taxes : 0;
              clone["Total Due"] = item.totalCost ? item.totalCost : 0;
              if (item.taxes > 0 && clone["Modifiers"] > 0 && transaction.timeStamp < 1567372759) {
                let taxPct = item.taxes/(clone["Price"]*clone["Quantity"]);
                // console.log("TAX PCT:", taxPct);
                if (!isNaN(taxPct)) {
                  clone["Tax"] = taxPct*clone["Subtotal"];
                }
              }
              clone["Total Due"] = clone["Net Total"] - clone["Discounts"] + clone["Tax"];
              // console.log("Trans type:", transaction.type);
              if (transaction.type === "transaction-reversal") {
                clone["Total Due"] *= -1;
              }
              data.push(clone);
          });
          } catch ( err) {
              console.log(".foreach error", err);
          }
      }
      
      
      
    });
    // console.log("DATA HERE:", data);
    var csv = json2csv({data, fields});
    var blob = new Blob([csv],{type:'text/csv'});
    var url = window.URL.createObjectURL(blob);
    var fileName = "All Transactions.csv"
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.href= url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);


    // this.transactionsService.transactionReport(this.idToken, this.orgId, timeZone, this.startSeconds, this.endSeconds )
    // .then( res => {
    //   this.loadingService.toggleLoadingScreen(false);
    //   // console.log("transaction report:", res);
    //   var body = JSON.parse(res._body);
    //   var csv = body.csv;
    //   var blob = new Blob([csv],{type:'text/csv'});
    //   var url = window.URL.createObjectURL(blob);
    //   var fileName = "All Transactions.csv"
    //   var a = document.createElement("a");
    //   document.body.appendChild(a);
    //   a.href= url;
    //   a.download = fileName;
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // })
    // .catch( err => {
    //   this.loadingService.toggleLoadingScreen(false);
    //   // console.log("caught err:", err);
    // })

   

  }

  public refundDepositV2(transaction) {
      // console.log("refund depositV2:", transaction);
      var amount = transaction.amount;
      if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
        amount = transaction.remainingBalance;
      }
      this.dialogsService.input("Refund this deposit?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
      .toPromise()
      .then( res => {
        // console.log("res:", res);
  
        if(res[0]){
          var refundAmount = res[1];
          this.loadingService.toggleLoadingScreen(true);
          this.squareService.refundDepositV2(transaction.transactionId, refundAmount, this.user.organizationId)
          .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            // console.log("refund res:", res);
            // var body = JSON.parse(res._body);
  
            this.dialogsService.alert("Success", "The refund has been initiated!");
            this.getAllTransactions(null, true);
          })
          .catch( () => {
            // console.log("refund err:", err);
            // var body = JSON.parse(err._body);
            // console.log("body:", body);
            // var errorDetail = body.errors[0].detail;
            this.loadingService.toggleLoadingScreen(false);
            // this.dialogsService.alert("Processing error", errorDetail);
            this.dialogsService.alert("Processing error", 'An error was encountered when initiating the refund. Please contact support');
          })
        }
      })
    }
  
    public refundSaleV2(transaction) {
      // console.log("refund refundSaleV2:", transaction);
      var amount = Math.abs(transaction.amount);
      if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
        amount = Math.abs(transaction.remainingBalance);
      }
      // this.dialogsService.input("Refund this sale?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
      this.dialogsService.confirm("Sale Refund", "Confirm refund of $" + amount)
      .toPromise()
      .then( res => {
        // console.log("res:", res);
  
        // if(res[0]){
        if (res === true && res !== undefined) {
          // var refundAmount = res[1];
          const refundAmount = amount;
          this.loadingService.toggleLoadingScreen(true);
          this.squareService.refundSaleV2(transaction.transactionId, refundAmount, this.user.organizationId)
          .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            // console.log("refund res:", res);
            // var body = JSON.parse(res._body);
  
            this.dialogsService.alert("Success", "The refund has been initiated!");
            this.getAllTransactions(null, true);
          })
          .catch( err => {
            console.log("refund err:", err);
            // var body = JSON.parse(err._body);
            // console.log("body:", body);
            // var errorDetail = body.errors[0].detail;
            this.loadingService.toggleLoadingScreen(false);
            // this.dialogsService.alert("Processing error", errorDetail);
            this.dialogsService.alert("Processing error", 'An error was encountered when initiating the refund. Please contact support');
          })
        }
      })
    }
  
    public refundTransactionV1(transaction) {
      // console.log("refund transaction:", transaction);
      var amount = transaction.amount;
      if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
        amount = transaction.remainingBalance;
      }
      this.dialogsService.input("Refund this transaction?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
      .toPromise()
      .then( res => {
        // console.log("res:", res);
  
        if(res[0]){
          var refundAmount = res[1];
          this.loadingService.toggleLoadingScreen(true);
          var timeZone = -4;
          if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
            timeZone = this.organization.timeZoneOffset;
          }
          this.squareService.refundTransaction(this.idToken, transaction.transactionId, transaction.squareTransactionId, transaction.squareTenderId, transaction.squareLocationId, refundAmount, timeZone, this.user.organizationId)
          .then( res => {
            this.loadingService.toggleLoadingScreen(false);
            // console.log("refund res:", res);
            var body = JSON.parse(res._body);
  
            this.dialogsService.alert("Success", "The refund has been processed!");
            this.getAllTransactions(null, true);
          })
          .catch( err => {
            console.log("refund err:", err);
            var body = JSON.parse(err._body);
            // console.log("body:", body);
            var errorDetail = body.errors[0].detail;
            this.loadingService.toggleLoadingScreen(false);
            this.dialogsService.alert("Processing error", errorDetail);
          })
      }
    })
  }

  async searchTransactionTerms() {
    const res = await this.dialogsService.transactionSearchTermDialog(this.searchTerms, this.dateInput, this.dateInputs)
      .toPromise()
      .catch();
    if (res) {
      // console.log('search-terms', res);
      this.searchTerms = res;
      if (this.searchTerms.dateInput !== undefined) this.dateInput = this.searchTerms.dateInput;
      this.getAllTransactions(null, true, res.amount, res.email, res.name, res.last4);
    }
  }


  voidTransaction(transaction){
    // console.log("void transaction:", transaction);
    this.dialogsService.confirm("Void this transaction?", "This will transfer the funds back to the user's account")
    .toPromise()
    .then( res => {
      if(res){
        this.loadingService.toggleLoadingScreen(true);
        this.transactionsService.voidTransaction(this.idToken, transaction.transactionId, transaction.groupId)
        .then(res => {
          this.loadingService.toggleLoadingScreen(false);
          // console.log("void transaction res:", res);
          //var body = JSON.parse(res._body);
          if(res.status == "200"){
            this.dialogsService.alert("Success", "The transaction has been voided!");
            //this.getCamperTransaction();
            this.getAllTransactions(null, true);
          }else{
            this.dialogsService.alert("Processing Error", "This transaction could not be voided. It's unique id is:" + transaction.transactionId);
          }
        })
        .catch(err => {
          this.loadingService.toggleLoadingScreen(false);
          this.dialogsService.alert("Processing Error", "This transaction could not be voided. It's unique id is:" + transaction.transactionId);
        });

      }
    })

  }

public async reverseDonation(donation) {
  // console.log("Reverse donation:", donation);
  let confirmRes = await this.dialogsService.confirm("Reverse Donation?", "This will push the money back on the account and create a donation reversal.").toPromise();
  if (confirmRes) {
    try {
      this.loadingService.toggleLoadingScreen(true);
      const { authUserId, timeStamp, organizationId, transactionId } = donation;
      const res = await this.transactionsService.reverseDonation(authUserId, timeStamp, organizationId, transactionId);
      // console.log("res:", res);
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert("Success", "The donation has been reversed!");
      this.getAllTransactions(null, true);
    } catch (err) {
      console.log("Err:", err);
      this.loadingService.toggleLoadingScreen(false);
      this.dialogsService.alert("Processing Error", "An error occurred while attempting to reverse the deposit");
    }
  }
}

// public refundDepositV2(transaction) {
//     // console.log("refund depositV2:", transaction);
//     var amount = transaction.amount;
//     if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
//       amount = transaction.remainingBalance;
//     }
//     this.dialogsService.input("Refund this deposit?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
//     .toPromise()
//     .then( res => {
//       // console.log("res:", res);

//       if(res[0]){
//         var refundAmount = res[1];
//         this.loadingService.toggleLoadingScreen(true);
//         this.squareService.refundDepositV2(transaction.transactionId, refundAmount, this.user.organizationId)
//         .then( res => {
//           this.loadingService.toggleLoadingScreen(false);
//           // console.log("refund res:", res);
//           // var body = JSON.parse(res._body);

//           this.dialogsService.alert("Success", "The refund has been initiated!");
//           this.getAllTransactions(null, true);
//         })
//         .catch( () => {
//           // console.log("refund err:", err);
//           // var body = JSON.parse(err._body);
//           // console.log("body:", body);
//           // var errorDetail = body.errors[0].detail;
//           this.loadingService.toggleLoadingScreen(false);
//           // this.dialogsService.alert("Processing error", errorDetail);
//           this.dialogsService.alert("Processing error", 'An error was encountered when initiating the refund. Please contact support');
//         })
//       }
//     })
//   }

//   public refundSaleV2(transaction) {
//     // console.log("refund refundSaleV2:", transaction);
//     var amount = Math.abs(transaction.amount);
//     if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
//       amount = Math.abs(transaction.remainingBalance);
//     }
//     // this.dialogsService.input("Refund this sale?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
//     this.dialogsService.confirm("Sale Refund", "Confirm refund of $" + amount)
//     .toPromise()
//     .then( res => {
//       // console.log("res:", res);

//       // if(res[0]){
//       if (res === true && res !== undefined) {
//         // var refundAmount = res[1];
//         const refundAmount = amount;
//         this.loadingService.toggleLoadingScreen(true);
//         this.squareService.refundSaleV2(transaction.transactionId, refundAmount, this.user.organizationId)
//         .then( res => {
//           this.loadingService.toggleLoadingScreen(false);
//           // console.log("refund res:", res);
//           // var body = JSON.parse(res._body);

//           this.dialogsService.alert("Success", "The refund has been initiated!");
//           this.getAllTransactions(null, true);
//         })
//         .catch( err => {
//           console.log("refund err:", err);
//           // var body = JSON.parse(err._body);
//           // console.log("body:", body);
//           // var errorDetail = body.errors[0].detail;
//           this.loadingService.toggleLoadingScreen(false);
//           // this.dialogsService.alert("Processing error", errorDetail);
//           this.dialogsService.alert("Processing error", 'An error was encountered when initiating the refund. Please contact support');
//         })
//       }
//     })
//   }

//   public refundTransactionV1(transaction) {
//     // console.log("refund transaction:", transaction);
//     var amount = transaction.amount;
//     if(transaction.remainingBalance != undefined && transaction.remainingBalance != null){
//       amount = transaction.remainingBalance;
//     }
//     this.dialogsService.input("Refund this transaction?", "Enter an amount to refund back onto their card. Up to $" + amount, amount)
//     .toPromise()
//     .then( res => {
//       // console.log("res:", res);

//       if(res[0]){
//         var refundAmount = res[1];
//         this.loadingService.toggleLoadingScreen(true);
//         var timeZone = -4;
//         if(this.organization.timeZoneOffset != undefined && this.organization.timeZoneOffset != null){
//           timeZone = this.organization.timeZoneOffset;
//         }
//         this.squareService.refundTransaction(this.idToken, transaction.transactionId, transaction.squareTransactionId, transaction.squareTenderId, transaction.squareLocationId, refundAmount, timeZone, this.user.organizationId)
//         .then( res => {
//           this.loadingService.toggleLoadingScreen(false);
//           // console.log("refund res:", res);
//           var body = JSON.parse(res._body);

//           this.dialogsService.alert("Success", "The refund has been processed!");
//           this.getAllTransactions(null, true);
//         })
//         .catch( err => {
//           console.log("refund err:", err);
//           var body = JSON.parse(err._body);
//           // console.log("body:", body);
//           var errorDetail = body.errors[0].detail;
//           this.loadingService.toggleLoadingScreen(false);
//           this.dialogsService.alert("Processing error", errorDetail);
//         })

//       }
    
//     })

//   }

  // time stuff 

  public filterTransactions(start,end){
    // console.log("filter transctions!");
    this.startSeconds = start;
    this.endSeconds = end;
    this.displayTransactions = this.allTransactions.filter( (transaction) => {
      if(transaction.timeStamp >= start && transaction.timeStamp <= end){
        return true;
      }else{
        return false;
      }
      
    })
    // console.log("display transactions:", this.displayTransactions);
  }


  public selectedDate(value: any, dateInput: any) {
      dateInput.start = value.start;
      dateInput.end = value.end;
      

      // console.log("in selected date...");
      // console.log(value);
      // console.log(dateInput);
      var startSeconds = value.start._d.getTime() / 1000;
      var endSeconds = value.end._d.getTime() / 1000;

      //now need to convert it to the organization's offset..

      var offset = this.organization.timeZoneOffset;

      startSeconds += 3600*offset;
      endSeconds += 3600*offset;
      // console.log("start seconds:", startSeconds);
      // console.log("end seconds:", startSeconds);

      // console.log("main input start:");
      // console.log(this.dateInput.start._d.getTime()/1000);

      this.getAllTransactions(null, true);

      
  }
    
    public singleSelect(value: any) {
        this.singleDate = value.start;
    }

    public applyDate(value: any, dateInput: any) {
        dateInput.start = value.start;
        dateInput.end = value.end;

        // console.log("in apply date...");
        // console.log(value);
        // console.log(dateInput);
    }

    public calendarEventsHandler(e:any) {
        // console.log(e);
        //this.eventLog += '\nEvent Fired: ' + e.event.type;
    }

}
