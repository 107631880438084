import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import {
  MiscEndpoint,
  CarePackageImageBucketName,
  CarePackageImageBucketRegion,
  CognitoPoolId,
  EmailBucketName,
  EmailBucketRegion,
  EmailEndpoint,
  SquareEndpoint,
  AWSConfig
} from "../app.constants";
import { Http, Headers, RequestOptions } from '@angular/http';
import { CarePackage, CarePackageOrder } from "../models/care-package";
import { TokenService } from './token.service';

declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class CarePackagesService {

  public carePackageSubscription = new Subject<any>();
  carePackageSubscription$ = this.carePackageSubscription.asObservable();

  constructor(public http: Http) { 

  }

  updateCarePackageSettings(idToken: string, organizationId: string, enabled: boolean, emailsEnabled: boolean): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId,
      enabled,
      emailsEnabled
    }
    console.log("Data:", data);
    return this.http 
      .post(MiscEndpoint + "/care-packages/enableCarePackages", data, options)
      .toPromise();
  }


  toggleCarePackageSettings(enabled: boolean){
    this.carePackageSubscription.next(enabled);
  }


  uploadPackageImage(imageKey, organizationId, file: any): Promise<any>{
    let bucketName = CarePackageImageBucketName;
    let bucketRegion = CarePackageImageBucketRegion;

    let key = organizationId + "/" + imageKey;

    AWS.config.update(AWSConfig(bucketRegion));

    var s3 = new AWS.S3({
      apiVersion: '2006-03-01'
    });


    return new Promise( (resolve, reject) => {
      s3.upload({
        Key: key,
        Body: file,
        ACL: 'public-read',
        Bucket: bucketName
      }, function(err,data){
        if(err){
          reject(err);
        }else{
          console.log("Uploaded data:", data);
          resolve(data);
        }
      })
    })


  }

  createPackage(idToken: string, carePackage: CarePackage, organizationId: string, packageId: string = null, imageUrl: string = null): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId: organizationId,
      price: carePackage.price,
      title: carePackage.title,
      items: carePackage.items,
      itemsString: carePackage.itemsString,
      packageId: packageId,
      imageUrl: imageUrl
    }
    console.log("data is:", data);
    return this.http 
      .post(MiscEndpoint + "/care-packages/createPackage", data, options)
      .toPromise();
  }


  loadPackagesByOrganization(idToken: string, organizationId: string): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId: organizationId,
    }
    return this.http 
      .post(MiscEndpoint + "/care-packages/loadPackagesByOrganization", data, options)
      .toPromise();
  }


  updatePackage(idToken: string, carePackage: CarePackage, organizationId: string, imageUrl: string): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId: organizationId,
      packageId: carePackage.packageId,
      price: carePackage.price,
      title: carePackage.title,
      items: carePackage.items,
      itemsString: carePackage.itemsString,
      imageUrl: imageUrl
    }
    console.log(data);
    return this.http 
      .post(MiscEndpoint + "/care-packages/updatePackage", data, options)
      .toPromise();
  }


  deletePackage(idToken: string, carePackage: CarePackage): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      organizationId: carePackage.organizationId,
      packageId: carePackage.packageId
    }
    console.log(data);
    return this.http 
      .post(MiscEndpoint + "/care-packages/deletePackage", data, options)
      .toPromise();
  }

  subtractFromBalance(idToken: string, amount: number, userId: string, authUserId: string): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      amount: amount,
      userId: userId,
      authUserId: authUserId
    }
    return this.http
      .post(MiscEndpoint + "/care-packages/subtractFromBalance", data, options)
      .toPromise();
  }

  refundPackage(idToken: string, order: CarePackageOrder): Promise<any> {
    let options = TokenService.getHeaders();
    let data = {
      orderId: order.orderId,
      packageId: order.packageId,
      organizationId: order.organizationId,
      userId: order.userId
    };

    return this.http
      .post(`${SquareEndpoint}/square/refundCarePackage`, data, options)
      .toPromise();

  }


  placeOrder(idToken: string, userId: string, authUserId: string, packageId: string, organizationId: string, price: number, 
    accountHolderFirstName: string,  accountHolderLastName: string, authUserFirstName: string, authUserLastName: string,
    packageTitle: string, modifiersByItem: any, orderNotes: string, deliverBy: Date, cabinName: string = null): Promise<any>{
      let options = TokenService.getHeaders();
      let data = {
        userId,
        authUserId,
        packageId,
        organizationId,
        price,
        accountHolderFirstName,
        accountHolderLastName,
        authUserFirstName,
        authUserLastName,
        packageTitle,
        modifiersByItem,
        orderNotes,
        deliverBy,
        cabinName
      }

      console.log("passing in data:", data);
      return this.http
        .post(MiscEndpoint + "/care-packages/placeOrder", data, options)
        .toPromise();
  }


  loadOrders(idToken: string, id: string, getBy: string): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      id: id,
      getBy: getBy
    }

    return this.http
      .post(MiscEndpoint + "/care-packages/getOrders", data, options)
      .toPromise();
  }

  updateOrderStatus(idToken: string, orderId: string, packageId: string, status): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
      orderId: orderId,
      packageId: packageId,
      status: status
    }
    return this.http
      .post(MiscEndpoint + "/care-packages/updateOrderStatus", data, options)
      .toPromise();
  }


  sendCarePackageEmail(idToken: string, user: any, carePackageTitle, organization: any, updateType: string): Promise<any> {
    let emailKey;
    let subject;
    if (updateType === "ordered") {
       emailKey = organization.organizationId + "/carePackageOrdered.html";
       subject =  "Care Package Ordered";
    } else if (updateType === "fulfilled") {
      emailKey = organization.organizationId + "/carePackageFulfilled.html";
      subject = "Care Package Order Fulfilled";
    }
    let bucketName = EmailBucketName;
    let bucketRegion = EmailBucketRegion;
    try {
      AWS.config.update(AWSConfig(bucketRegion));
    } catch (err) {
      console.log("caught error");
      console.log(err);
    }
    console.log("here");
    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });
    var orgInfo = organization;
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");
    const params = {
      Bucket: EmailBucketName,
      Key: emailKey
    }
    return new Promise((resolve, reject) => {
      let email: any;
      s3.getObject(params, (err, data) => {
          console.log("Getting object");
          console.log(data);
          if(err){
            reject(err);
          }else{
            email = data.Body.toString('ascii')
            email = email.replace(/{{firstName}}/g, user.firstName);
            email = email.replace(/{{carePackageTitle}}/g, carePackageTitle);
            email = email.replace(/{{webUrl}}/g, organization.domainUrl);
            email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
            let options = TokenService.getHeaders();
            const params = {
              email: user.email,
              content: email,
              replyToEmail: organization.replyToEmail,
              subject: subject,
              organization: organization,
              from: organization.fromEmail
            }
           console.log("Params:", params);
           resolve(this.http
            .post(EmailEndpoint + "/generic/sendEmail", params, options)
            .toPromise());
          }
      });
    });





  }



  generateUUID(): string{
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

}
