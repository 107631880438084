import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BaseEndpoint, OrganizationEndpoint, PosEndpoint, ImportEndpoint, MiscEndpoint, AuthEndpoint, InventoryImageBucketName, InventoryImageBucketRegion, CognitoPoolId, AWSConfig } from "../app.constants";
import { Item, Modifier } from "../models/item";
import { TokenService } from './token.service';

declare var AWSCognito: any;
declare var AWS: any;

@Injectable()
export class InventoryService {

  constructor(public http: Http) {
  }

  isAPIv1(organizationId) {
    if (organizationId === 'fee87eb5-1db1-42d9-a4bf-3a875cad410b') return false; // Demo
    if (organizationId === '1cca003a-c27c-4341-8f66-6e9afd7cfcaa') return false; // Test
    if (organizationId === '84b07890-35b8-4c90-b35d-e1ddf0464270') return false; // CC
    return true;
  }

  fetchInventory(idToken: string, organizationId: string): Promise<any>{
    let options = TokenService.getHeaders();

    return this.http
      .get(BaseEndpoint + "/v3/inventory/items?orgid=" + organizationId, options)
      .toPromise();
  }

  fetchInventoryByLocation(idToken: string, locationId?: string, organizationId?: string): Promise<any>{
    if (organizationId === undefined) return Promise.reject(new Error('Undefined organizationId'));
    let options = TokenService.getHeaders();
    let url = BaseEndpoint + "/v3/inventory/items?orgid=" + organizationId;
    if (locationId !== undefined) url = url + "&location_id=" + locationId;

    return this.http
      .get(url, options)
      .toPromise();
  }


  addInventoryItem(idToken: string, organizationId: string, locationId: string, item: Item, position: number, tab: string):  Promise<any>{
    let options = TokenService.getHeaders();
    
    let data = {
        applyDailyLimits: item.applyDailyLimits,
        deposit: item.deposit,
        isEmployeeDiscountEligble: item.isEmployeeDiscountEligble,
        itemType: item.itemType,
        locationId: locationId,
        name: item.name,
        organizationId: organizationId,
        price: item.price,
        type: item.type,
        taxRate: item.taxRate,
        // supplimentary
        tab: tab,
        position: position,
        // withdrawal: item.withdrawal,
        // modifiers: item.modifiers,
        // cogsPrice: item.cogsPrice,
        // modifiersDistinct: item.modifiersDistinct,
        // modifierIds: item.modifierIds,
    };

    console.log("data:", data);


    // if(item.itemType != "none"){
    //     data['isbn'] = item.isbn;
    //     data['title'] = item.title;
    //     data['dept'] = item.dept;
    //     // data['cogsPrice'] = item.cogsPrice;
    //     data['quantity'] = item.quantity;
    //     data['dateLastSold'] = item.dateLastSold;
    //     data['dateLastOrdered'] = item.dateLastOrdered;
    // }

    // if(item.category){
    //     data['category'] = item.category;
    // }

    console.log("sent data:", data);

    // V3 TODO
    return this.http
        .post(BaseEndpoint + "/v3/inventory/item/insert", data, options)
        .toPromise();
  }


//   invokeSQS() {
//       let data = {
//           event_type: "task_assigned_to_user",
//           body: { data: "data" }
//       }
//       var headers = new Headers({'Content-Type': 'application/json'});
//       var options = new RequestOptions({headers: headers});

//       return this.http
//         .post("https://igdbpit5c5.execute-api.us-east-1.amazonaws.com/dev/events/insert", data, options)
//         .toPromise();

//   }



  editInventoryItem(idToken: string, item: Item): Promise<any> {
      let options = TokenService.getHeaders();

      let data = {
          applyDailyLimits: item.applyDailyLimits,
          categoryId: item.categoryId,
          deposit: item.deposit,
          deptId: item.deptId,
          imageUrl: item.imageUrl,
          isEmployeeDiscountEligble: item.isEmployeeDiscountEligble,
          itemId: item.itemId,
          itemType: item.itemType,
          modifiers: item.modifiers,
          name: item.name,
          organizationId: item.organizationId,
          price: item.price,
          quantityTracking: item.quantityTracking,
          taxRate: item.taxRate,
          type: item.type,
          //   variants: item.variants
          //   modifiersDistinct: item.modifiersDistinct,
          //   modifierIds: item.modifierIds,
      };

    //   if(item.itemType != "none"){
    //       data['isbn'] = item.isbn;
    //       data['title'] = item.title;
    //       data['dept'] = item.dept;
    //       data['cogsPrice'] = item.cogsPrice;
    //       data['quantity'] = item.quantity;
    //       data['dateLastSold'] = item.dateLastSold;
    //       data['dateLastOrdered'] = item.dateLastOrdered;
    //   }

    // V3 TODO
    return this.http
        .post(BaseEndpoint + "/v3/inventory/item/update", data, options)
        .toPromise();

  }


  updateItemTab(idToken: string, itemId: string, organizationId: string, email: string, locationId: string, newTab: string): Promise<any> {
    let options = TokenService.getHeaders();

    let data = {
        itemId,
        organizationId,
        email,
        locationId,
        tab: newTab
        // type: "tab"
    };
    console.log(data);
    // V3 TODO
    return this.http
        .post(BaseEndpoint + "/v3/inventory/item/tab/update", data, options)
        .toPromise();
  }


// updateItemCategory(idToken: string, itemId: string, organizationId: string, email: string, locationId: string, newCategory: string, currentTab: string): Promise<any> {
//     let options = TokenService.getHeaders();

//     let data = {
//         itemId,
//         organizationId,
//         email,
//         locationId,
//         category: newCategory,
//         tab: currentTab
//         // currentTab,
//         // type: "category"
//     };
//     console.log(data);
//     return this.http
//         .post(BaseEndpoint + "/v2/inventory/item/upsert", data, options)
//         .toPromise();
//   }


  removeInventoryItem(idToken: string, itemId: string, organizationId: string, locationId: string): Promise<any>{
      let options = TokenService.getHeaders();

      let data = {
          itemId: itemId,
          organizationId: organizationId,
          locationId: locationId
      }

    // V3 TODO
    return this.http
        .post(BaseEndpoint + "/v3/inventory/item/tab/delete", data, options)
        .toPromise();
  }

  removeItemTab(idToken: string, itemId: string, organizationId: string, locationId: string): Promise<any>{
    let options = TokenService.getHeaders();

    let data = {
        itemId: itemId,
        organizationId: organizationId,
        locationId: locationId
    }
    return this.http
      .post(BaseEndpoint + "/v3/inventory/item/tab/delete", data, options)
      .toPromise();
  }



  saveInventoryPositions(idToken: string, itemPositions: any, organizationId: string, locationId: string): Promise<any>{
      let options = TokenService.getHeaders();
      var items = [];
      for(var key in itemPositions){
          items.push({
              itemId: key,
              position: itemPositions[key]
          });
      }
      console.log("items:", items);
      let data = {
        itemPositions: items,
        organizationId: organizationId,
        locationId: locationId
      };
      console.log("data is:", data);
      // V3 TODO
      return this.http
        .post(BaseEndpoint + "/v3/inventory/positions", data, options)
        .toPromise();
  }


  addTab(idToken: string, organizationId: string, locationId: string, tabName: string, tabs: any): Promise<any>{
    let options = TokenService.getHeaders();
    let data = {
        organizationId,
        locationId: locationId,
        tabName: tabName,
        tabs: tabs
    };
    return this.http
        .post(BaseEndpoint + "/v2/profile/location/tab/create", data, options)
        .toPromise();
  }


  editTab(idToken: string, organizationId: string, locationId: string, oldName: string, tabName: string, tabs, tabsByCategory): Promise<any>{
      let options = TokenService.getHeaders();
      if (tabs && tabs !== undefined && tabs.indexOf("Other") >= 0) {
        tabs.splice(tabs.indexOf("Other"));
      }
      let data = {
        organizationId,
          locationId,
          oldName,
          tabName,
          tabs,
          tabsByCategory
      }
      console.log(data);
      return this.http 
            .post(BaseEndpoint + "/v2/profile/location/tab/update", data, options)
            .toPromise();
  }

  removeCategoryOrTab(idToken: string, organizationId: string, locationId:string, oldName: string, type: string, objects, tabsByCategory): Promise<any>{
    let options = TokenService.getHeaders();
    objects.splice(objects.indexOf("Other"));
    if (type === 'tabs') {
      let data ={
            organizationId,
            locationId,
            tabName: oldName,
            type,
            tabs: objects,
            tabsByCategory
      }
      console.log(data);
      return this.http
        .post(BaseEndpoint + "/v2/profile/location/tab/delete", data, options)
        .toPromise();
    } else {
        // let data ={
        //     organizationId,
        //     locationId,
        //     oldName,
        //     type,
        //     objects,
        //     tabsByCategory
        // }
        // console.log(data);
        // return this.http
        //         .post(PosEndpoint + "/inventory/removeCategoryOrTab", data, options)
        //         .toPromise();
    }
  }

//   addCategory(idToken: string, locationId: string, categoryName: string, categories: any, tabsByCategory: string): Promise<any>{
//     let options = TokenService.getHeaders();
//     console.log("tabs by category is:", tabsByCategory);
//     let data = {
//         locationId: locationId,
//         categoryName: categoryName,
//         categories: categories,
//         tabsByCategory: tabsByCategory
//     };
//     console.log("passing in data:", data);
//     return this.http
//         .post(PosEndpoint + "/inventory/addCategory", data, options)
//         .toPromise();
//   }



//   uploadInv(idToken: string, items: any, locationId: string, organizationId: string): Promise<any>{
//     let options = TokenService.getHeaders();
//     let data = {
//         locationId: locationId,
//         items: items,
//         organizationId: organizationId
//     };
//     return this.http
//         .post(ImportEndpoint + "/inventory/loadFullInv", data, options)
//         .toPromise();
//   }

//   uploadInvMods(idToken: string, items: any, locationId: string, organizationId: string): Promise<any>{
//     let options = TokenService.getHeaders();
//     let data = {
//         locationId: locationId,
//         items: items,
//         organizationId: organizationId
//     };
//     console.log("Upload invmods...");
//     console.log(data);
//     return this.http
//         .post(ImportEndpoint + "/inventory/loadInvMods", data, options)
//         .toPromise();
//   }



//    uploadModifiers(idToken: string, items: any, locationId: string, organizationId: string): Promise<any>{
//         let options = TokenService.getHeaders();
//         let data = {
//             locationId: locationId,
//             modifiers: items,
//             organizationId: organizationId
//         };
//         return this.http
//             .post(PosEndpoint + "/inventory/uploadModifiers", data, options)
//             .toPromise();
//   }

  createStandardModifier(idToken: string, organizationId: string, modifier: Modifier): Promise<any> {
    let opt = TokenService.getHeaders();
    let data = {
        organizationId,
        name: modifier.name,
        multiAllowed: modifier.multiAllowed,
        options: modifier.options
    };
    return this.http
        .post(MiscEndpoint + "/standard-modifiers/createStandardModifier", data, opt)
        .toPromise();
  }

  uploadInventoryImageS3(photoKey: string, file: any): Promise<any> {
    const bucketName = InventoryImageBucketName;
    const bucketRegion = InventoryImageBucketRegion;

    AWS.config.update(AWSConfig(bucketRegion));

    const s3 = new AWS.S3({
        apiVersion: '2006-03-01',
    });

    return new Promise( (resolve, reject) => {
        s3.upload({
            Key: photoKey,
            Body: file,
            ACL: 'public-read',
            Bucket: bucketName
        }, (err, data) => {
            if (err) {
                console.log("failed:", err);
                reject(err);
            } else {
                console.log("success:", data);
                resolve(data);
            }
        });
    });
  }

  editStandardModifier(idToken: string, organizationId: string, modifier: Modifier): Promise<any> {
      let opt = TokenService.getHeaders();
      let data = {
          organizationId,
          name: modifier.name,
          modifierId: modifier.modifierId,
          options: modifier.options,
          multiAllowed: modifier.multiAllowed
      };
      return this.http
        .post(MiscEndpoint + "/standard-modifiers/editStandardModifier", data, opt)
        .toPromise();
  }

  listStandardModifiers(idToken: string, organizationId: string): Promise<any> {
      let opt = TokenService.getHeaders();
      let data = {
          organizationId
      };
      return this.http
        .post(MiscEndpoint + "/standard-modifiers/listStandardModifiers", data, opt)
        .toPromise();
  }


  removeStandardModifier(idToken: string, modifierId: string, organizationId: string): Promise<any> {
      let opt = TokenService.getHeaders();
      let data = {
          modifierId,
          organizationId
      };
      return this.http
        .post(MiscEndpoint + "/standard-modifiers/removeStandardModifier", data, opt)
        .toPromise();
  }



}