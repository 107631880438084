export class FunFanglePanel {
    name: string;
    fields: FunFanglePanelField[];
    actionButtons?: FunFangleButtons;
    loadFunction?: string;
    selectEnabled?: boolean;
    subButtons?: FunFangleButton[];
}


class  FunFangleButtons {
    width: number;
    buttons: FunFangleButton[];
}

class FunFangleButton {
    method: string;
    name: string;
    class?: string;
}

class FunFanglePanelField {
    variableName: string;
    displayName: string;
    width?: number;
}

let sessionPanel = new FunFanglePanel();
let sessionFields: FunFanglePanelField[] = [
    {
        variableName: "SessionName",
        displayName: "Session Name",
        width: 180
    },
    {
        variableName: "CampName",
        displayName: "Camp Name",
        width: 180
    },
    {
        variableName: "Whitelisted",
        displayName: "Whitelisted?",
        width: 85
    },
    {
        variableName: "SessionStart",
        displayName: "Start Date",
        width: 100
    },
    {
        variableName: "SessionEnd",
        displayName: "End Date",
        width: 100
    },
    {   
        variableName: "SessionStatus",
        displayName: "Status",
        width: 150
    }
];
sessionPanel.fields = sessionFields;
sessionPanel.name = "Session Management";
sessionPanel.loadFunction = "loadXCampbrainSessions";
let buttons: FunFangleButton[] = [
    {
        name: "Whitelist",
        method: "whitelistSession",
        class: "add-button"
    },
    {
        name: "De-Whitelist",
        method: "whitelistSession",
        class: "remove-button"
    }
];
sessionPanel.actionButtons = {
    width: 200,
    buttons
}
let subButtons: FunFangleButton[] = [
    {
        name: "Close Session(s)",
        method: "closeSessions",
        class: "remove-button"
    }
]
sessionPanel.subButtons = subButtons;
sessionPanel.selectEnabled = true;
export const SessionPanel = sessionPanel;