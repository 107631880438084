import { config } from "./tenant-config";
declare var AWS: any;

    var domain = window.location.hostname;
    domain = domain.replace(".lvh.me","").replace(".com","").replace(".com","");
    domain = domain.replace(".org","");
    domain = domain.replace(".club","");
    domain = domain.replace(".s3-website.us-east-2.amazonaws","");
    console.log("Domain:", domain);
    if (domain.indexOf("admin-") !== -1) domain = domain.replace('admin-','');
    console.log("domain after:", domain);
    console.log(config.camps);
    console.log(config.camps[domain])
    var camp = (config.camps[domain]) ? config.camps[domain] : config.camps.default;
    var env = camp.env || "prod";
    var environment = config.environments[env]; 
    console.log("CAMP:", camp);

    //set endpoints
    export const ApiEndpoint = environment.endpoints.ApiEndpoint;
    export const BaseEndpoint = environment.endpoints.BaseEndpoint;
    export const SquareEndpoint = environment.endpoints.SquareEndpoint;
    export const TutorialEndpoint = environment.endpoints.TutorialEndpoint;
    export const OrganizationEndpoint = environment.endpoints.OrganizationEndpoint;
    export const EmailEndpoint = environment.endpoints.EmailEndpoint;
    export const PosEndpoint = environment.endpoints.PosEndpoint;
    export const MiscEndpoint = environment.endpoints.MiscEndpoint;
    export const ImportEndpoint = environment.endpoints.ImportEndpoint;
    export const AuthEndpoint = environment.endpoints.AuthEndpoint;
    export const DevicesEndpoint = environment.endpoints.DevicesEndpoint;
    export const SquareApplicationId = atob(environment.constants.SquareApplicationId);

    //set regions
    export const UserImageBucketRegion = environment.regions.UserImageBucketRegion;
    export const CognitoRegion = environment.regions.CognitoRegion;
    export const EmailBucketRegion = environment.regions.EmailBucketRegion;
    export const LicenseBucketRegion = environment.regions.LicenseBucketRegion;
    export const CarePackageImageBucketRegion = environment.regions.CarePackageImageBucketRegion;
    export const InventoryImageBucketRegion = environment.regions.InventoryImageBucketRegion;

    // set buckets
    export const UserImageBucket = environment.buckets.UserImageBucket;
    export const LicenseBucketName = environment.buckets.LicenseBucketName;
    export const OrganizationLicenseKey = environment.buckets.OrganizationLicenseKey;
    export const CustomerLicenseKey = environment.buckets.CustomerLicenseKey;
    export const EmailBucketName = environment.buckets.EmailBucketName;
    export const CarePackageImageBucketName = environment.buckets.CarePackageImageBucketName;
    export const InventoryImageBucketName = environment.buckets.InventoryImageBucketName;
    export const InventoryImageBucketLong = environment.buckets.InventoryImageBucketLong;

    // set cognito
    export const CognitoPoolId = environment.cognito.CognitoPoolId;
    export const CognitoClientId = environment.cognito.CognitoClientId;
    export const AccessKey = environment.cognito.AccessKey;
    export const SecAccessKey = environment.cognito.SecAccessKey;
    export const UserPoolId = environment.cognito.UserPoolId;
    export const AWSConfig =  (region: string) => {
        return {
            region,
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: environment.cognito.CognitoPoolId
            })
        }
    }

    // tennant-specific stuff

    export const WebUrl = camp.WebUrl;
    export const OrganizationId = camp.organizationId;
    export const LogoWebUrl = camp.logoUrl;
    export const DashboardUrl = camp.dashboardUrl || camp.logoUrl;
    export const ContactMessage = camp.ContactMessage || "Contact your organization managers to set one up";


console.log("WEB URL", WebUrl);