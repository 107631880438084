import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
// import { Subject } from 'rxjs/Subject';

import { AuthService } from './auth.service';
import { BaseEndpoint, OrganizationEndpoint, AuthEndpoint, ApiEndpoint, EmailEndpoint, EmailBucketName, EmailBucketRegion, AWSConfig} from '../app.constants';
import { TokenService } from './token.service';


// declare var AWSCognito: any;
declare var AWS: any;

/*Service for interfacing with Cognito methods*/
@Injectable()
export class CustomerService{

  public organizationId: any = null;
  public idToken: string = "";
  public email:  any;
  public resendEmail: any;
  constructor(
    public http: Http,
    public authService: AuthService

  ){}
  
  loadAppUrls(appId: string) {
    const data = {
      appId
    };
    console.log("Loading app urls!");
    this.http
      .post(AuthEndpoint + "/attendance/getAppUrl", data )
      .map( (res: any) => JSON.parse(res._body).Item )
      .toPromise()
      .then( res => {
        console.log("Got app:", res);
        if (res) {
          let androidUrl = res.androidUrl;
          let iPhoneUrl = res.iPhoneUrl; 
          console.log(androidUrl, iPhoneUrl); 
          if (androidUrl && iPhoneUrl) {
            window.localStorage.setItem("iPhoneUrl", iPhoneUrl);
            window.localStorage.setItem("androidUrl", androidUrl);
          }

        }
      });
  }


  getAllCustomer(organizationId: string, hideInactive?: boolean, limit = 50000): Promise<any>{
    if (organizationId === undefined) return;
    let url = BaseEndpoint + "/v2/profile/users?organizationId="+ organizationId + `&fields=${encodeURIComponent('cabinName,currentBalance,imageUrl,session,verified')}`;
    if (hideInactive !== undefined) url = url + "&hideInactive=" + hideInactive;
    if (limit !== undefined) url = url + "&limit=" + limit;
    var options = TokenService.getHeaders();
    return this.http
      .get(url, options)
      .toPromise()
  }

  addCustomer(idToken: string, customer: any): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      email: customer.email,
      role: customer.role,
      firstName: customer.firstName,
      lastName: customer.lastName,
      organizationId: customer.organizationId,
      relationship: customer.relationship,
      phone: customer.phone,
      verified: customer.verified,
      parent2Email: customer.parent2Email,
      parent2FirstName: customer.parent2FirstName,
      parent2LastName: customer.parent2LastName
    }
    return this.http
      .post(ApiEndpoint + "/user/create/" , data, options)
      .toPromise()
  }

  addParent2(idToken: string, customer: any, parent2FirstName, parent2LastName, parent2Email, organizationId): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      customerEmail: customer.email,
      parent2Email: parent2Email,
      parent2LastName: parent2LastName,
      parent2FirstName: parent2FirstName,
      organizationId
    }
    return this.http.post(OrganizationEndpoint + "/parents/addParent2", data, options)
    .toPromise();

  }

  sendInviteSpecificParent(idToken: string, customerId, email, firstName, lastName, organization, parentNum): Promise<any>{
    var options = TokenService.getHeaders();
    var subject = "FunFangle Account Setup" ;

    var data = {
      cId: customerId,
      fname: firstName,
      lname: lastName,
      email: email,
      subject: subject,
      parentNum: parentNum,
      organization,
      webUrl: organization.domainUrl,
    }
    return this.http
      .post(ApiEndpoint + "/parents/sendInvite", data,options)
      .toPromise();



  }

  sendInvite(idToken:string, customer:any, user:any, customerId:string, organization: any, orgId: string, disableP1Invite = false): Promise<any>{
    console.log(customer)
    var emailKey = orgId + "/parentSendInvitationEmail.html";
     if (organization && organization.attendanceSettings && organization.attendanceSettings.sendAttendanceLink) emailKey = `${orgId}/attendanceInvite.html`;
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;

    console.log("email key:", emailKey);
    console.log(bucketName, bucketRegion);

    AWS.config.update(AWSConfig(bucketRegion));
    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var orgInfo = organization
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");

    var parentNum = user.parentNum;
    if(parentNum == null || parentNum == undefined){
      parentNum = 1;
    }
    var params = {
      Bucket: EmailBucketName,
      Key: emailKey
    }
    return new Promise((resolve, reject) => {
      var email: any;
      var email2: any;
      var emailResponse: any;
      var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
      var androidUrl = window.localStorage.getItem("androidUrl");
      s3.getObject(params, (err, data) => {
        console.log("Getting object");
        console.log(data);

        if(err){
          reject(err);
        }else{
          email = data.Body.toString('ascii')
          console.log("orig:", email);
          email = email.replace(/{{firstName}}/g, customer.firstName);
          email = email.replace(/{{inviterName}}/g, `The ${organization.organizationName} Team`);
          email = email.replace(/{{organizationName}}/g, organization.organizationName);
          email = email.replace(/{{customLoginText}}/g, '');
          email = email.replace(/{{lastName}}/g, customer.lastName);
          email = email.replace(/{{userId}}/g, customerId);
          email = email.replace(/{{webUrl}}/g, organization.domainUrl);
          email = email.replace(/{{parentNum}}/g, parentNum);
          email = email.replace(/{{orgPhone}}/g, organization.telephone);
          email = email.replace(/{{orgEmail}}/g, organization.email);
          email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
            email = email.replace(/{{androidUrl}}/g, androidUrl);
            email = email.replace(/{{iOSUrl}}/g, iPhoneUrl);
          }
          console.log("after:", email);
          // console.log(email);
          var options = TokenService.getHeaders();
          console.log("organization is:", organization);
          var d = {
            cId: customerId,
            fname: customer.firstName,
            lname: customer.lastName,
            email: customer.email,
            subject: organization.inviteSubject,
            parentNum: 1,
            organization: organization,
            webUrl: organization.domainUrl,
            t:email,
        }
        console.log("data:", data);
        if(customer.parent2Email != undefined && customer.parent2Email != null && customer.parent2Email != ""){
          //send two emails
          console.log("sending two emails");
          if (!disableP1Invite) {
            this.http
            .post(ApiEndpoint + "/parents/sendInvite", d,options)
            .toPromise().then(res => {
              console.log("invite 1 res:", res);
            })
          }
          email2 = data.Body.toString('ascii')
          email2 = email2.replace(/{{firstName}}/g, customer.parent2FirstName);
          email2 = email2.replace(/{{inviterName}}/g, `The ${organization.organizationName} Team`);
          email2 = email2.replace(/{{organizationName}}/g, organization.organizationName);
          email2 = email2.replace(/{{customLoginText}}/g, '');
          email2 = email2.replace(/{{lastName}}/g, customer.parent2LastName);
          email2 = email2.replace(/{{userId}}/g, customerId);
          email2 = email2.replace(/{{webUrl}}/g, organization.domainUrl);
          email2 = email2.replace(/{{parentNum}}/g, 2);
          email2 = email2.replace(/{{orgPhone}}/g, organization.telephone);
          email2 = email2.replace(/{{orgEmail}}/g, organization.email);
          email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
            email2 = email2.replace(/{{androidUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iOSUrl}}/g, iPhoneUrl);
          }
          var data2 = {
              cId: customerId,
              fname: customer.parent2FirstName,
              lname: customer.parent2LastName,
              email: customer.parent2Email,
              parentNum: 2,
              subject: organization.inviteSubject,
              organization: organization,
              webUrl: organization.domainUrl,
              t: email2,
            }
            console.log("resolving now for two parents...");
          resolve(emailResponse= this.http
              .post(ApiEndpoint + "/parents/sendInvite", data2, options)
              .toPromise());
        }else{
          resolve(this.http
          .post(ApiEndpoint + "/parents/sendInvite", d,options)
          .toPromise());

        }

        }
      })

    })
  }


  removeAccount(organizationId: string, email?:string, userId?: string): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      email,
      organizationId,
      userId
    }
    return this.http
      .post(BaseEndpoint + "/v2/profile/account/delete", data, options)
      .toPromise();
  }


  removeInvitation(idToken: string, email:string, organizationId): Promise<any>{
    var options = TokenService.getHeaders();
    var data = {
      email: email,
      organizationId
    }
    return this.http
      .post(ApiEndpoint + "/contributors/remove", data, options)
      .toPromise();
  }


  sendReminderFundEmail(idToken: string, user: any, orgData: any, orgId:any): Promise<any>{
    var emailKey = orgId + "/noFundsReminderEmail.html";
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;
    var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
    var androidUrl = window.localStorage.getItem("androidUrl");

    AWS.config.update(AWSConfig(bucketRegion));

    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var fundReminder: any;
    var orgInfo = orgData
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");
    var parentNum = 0;
    console.log(parentNum);
    console.log(user);
    if(user.parent2Verified && user.verified){
      parentNum = 3;
    }
    else if(user.parent2Verified && !user.verified){
      parentNum = 2;
    }
    else if(!user.parent2Verified && user.verified){
      parentNum = 2;
    }
    var params = {
      Bucket: EmailBucketName,
      Key: emailKey
    }
    return new Promise((resolve, reject) => {
      var email: any;
      var email2: any;
      s3.getObject(params, (err, data) => {
        if(err){
          reject(err);
        }else{
          email = data.Body.toString('ascii');
          email = email.replace(/{{firstName}}/g, user.firstName);
          email = email.replace(/{{lastName}}/g, user.lastName);
          email = email.replace(/{{userId}}/g, user.userId);
          email = email.replace(/{{webUrl}}\/payment-settings/g,  `${orgData.domainUrl}/bank`);
          email = email.replace(/{{webUrl}}/g, orgData.domainUrl);
          email = email.replace(/{{email}}/g, user.email);
          email = email.replace(/{{parentNum}}/g, parentNum);
          email = email.replace(/{{orgPhone}}/g, orgData.telephone);
          email = email.replace(/{{orgEmail}}/g,orgData.email);
          email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
          }

          console.log("Email is:", email);

          email2 = data.Body.toString('ascii');
          email2 = email2.replace(/{{firstName}}/g, user.parent2FirstName);
          email2 = email2.replace(/{{lastName}}/g, user.parent2LastName);
          email2 = email2.replace(/{{userId}}/g, user.userId);
          email2 = email2.replace(/{{email}}/g, user.parent2Email);
          email2 = email2.replace(/{{webUrl}}\/payment-settings/g, `${orgInfo.domainUrl}/bank`);
          email2 = email2.replace(/{{webUrl}}/g, orgData.domainUrl);
          email2 = email2.replace(/{{parentNum}}/g, 2);
          email2 = email2.replace(/{{orgPhone}}/g, orgData.telephone);
          email2 = email2.replace(/{{orgEmail}}/g,orgData.email);
          email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
          }
          console.log("Parent Num:",parentNum);
          // resolve(true);

          var options = TokenService.getHeaders();
          var subject = "Friendly Reminder About Camper Spending Money";
          var d = {
            user: user,
            userEmail: user.email,
            orgData: orgData,
            subject: subject,
            webUrl: orgData.domainUrl,
            parentNum: 1,
            t:email,
          }


          if(user.parent2Email != undefined && user.parent2Email != null){
            //send two emails
            console.log("sending two emails");
             this.http
            .post(ApiEndpoint + "/parents/sendReminderFundEmail", d,options)
            .toPromise();

            var data2 = {
                user: user,
                userEmail: user.parent2Email,
                orgData: orgData,
                subject: subject,
                webUrl: orgData.domainUrl,
                parentNum: 2,
                t:email2,
              }
             resolve(this.http
            .post(ApiEndpoint + "/parents/sendReminderFundEmail", data2,options)
            .toPromise());
          }else{
             resolve(this.http
            .post(ApiEndpoint + "/parents/sendReminderFundEmail", d,options)
            .toPromise());
          }


        }
      })
    })


  }



  getAuthorizedUsersAssoc(idToken: string, userId: string): Promise<any>{
    var options = TokenService.getHeaders();
    return this.http
      .get(ApiEndpoint + "/authorizedUsers/get/?accountHolder=" + userId, options)
      .toPromise()
  }
  sendReminderPhotoEmail(idToken: string, user: any, orgData: any): Promise<any>{
    var orgId = orgData.organizationId;
    var emailKey = orgId + "/imageReminderEmail.html";
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;
    var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
    var androidUrl = window.localStorage.getItem("androidUrl");

    AWS.config.update(AWSConfig(bucketRegion));
    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var imageEmail: any;
    var orgInfo = orgData
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");
    var parentNum = user.parentNum;
   if(parentNum != null && parentNum != undefined){
       if(parentNum == 2){
           user.email = user.parent2Email;
           user.firstName = user.parent2FirstName;
       }
   }

   var params = {
     Bucket: EmailBucketName,
     Key: emailKey
   }

   return new Promise((resolve, reject) => {
     var email: any;
     var email2: any;

     s3.getObject(params, (err, data) => {
       if(err){
         reject(err);
       }else{
         email = data.Body.toString('ascii');
         email = email.replace(/{{firstName}}/g, user.firstName);
         email = email.replace(/{{lastName}}/g, user.lastName);
         email = email.replace(/{{userId}}/g, user.userId);
         email = email.replace(/{{webUrl}}/g,  orgData.domainUrl);
         email = email.replace(/{{email}}/g, user.email);
         email = email.replace(/{{parentNum}}/g, parentNum);
         email = email.replace(/{{orgPhone}}/g, orgData.telephone);
         email = email.replace(/{{orgEmail}}/g,orgData.email);
         email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
         if (iPhoneUrl && androidUrl) {
            email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
          }
         email2 = data.Body.toString('ascii');
         email2 = email2.replace(/{{firstName}}/g, user.parent2FirstName);
         email2 = email2.replace(/{{lastName}}/g, user.parent2LastName);
         email2 = email2.replace(/{{userId}}/g, user.userId);
         email2 = email2.replace(/{{email}}/g, user.parent2Email);
         email2 = email2.replace(/{{webUrl}}/g,  orgData.domainUrl);
         email2 = email2.replace(/{{parentNum}}/g, 2);
         email2 = email2.replace(/{{orgPhone}}/g, orgData.telephone);
         email2 = email2.replace(/{{orgEmail}}/g,orgData.email);
         email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
         if (iPhoneUrl && androidUrl) {
            email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
        }
         var options = TokenService.getHeaders();
         var subject= "Action Requested: Set Camper Photo";
         var d = {
           user: user,
           orgData: orgData,
           subject: subject,
           webUrl:  orgData.domainUrl,
           parentNum: 1,
           t:email,
         }

         if(user.parent2Email != undefined && user.parent2Email != null){
           //send two emails
           console.log("sending two emails");
            this.http
           .post(EmailEndpoint + "/parents/sendReminderPhotoEmail", d, options)
           .toPromise();

           var data2 = {
               user: user,
               orgData: orgData,
               subject: subject,
               webUrl:  orgData.domainUrl,
               parentNum: 2,
               t:email2,
             }
            resolve(this.http
           .post(EmailEndpoint + "/parents/sendReminderPhotoEmail", data2, options)
           .toPromise());
         }else{
            resolve(this.http
           .post(EmailEndpoint + "/parents/sendReminderPhotoEmail", d,options)
           .toPromise());
         }
       }
     })
     // resolve(true);
   })




  }


  sendBatchInvitation(idToken: string, customers: string[],organization: string): Promise<any>{
    var options = TokenService.getHeaders();
    console.log(customers);
    console.log(organization);
    var subject = "FunFangle Account Setup" ;
    var data = {
      customers: customers,
      organization: organization,
      subject: subject
    }
    return this.http
    .post(EmailEndpoint + "/parents/sendBatchInviteEmail", data, options)
    .toPromise();
  }

  sendReminderInvite(idToken: string, customer:any, user:any, customerId:string, organization: any, orgId: string): Promise<any>{
    var emailKey = orgId + "/parentReminderInvitationEmail.html";
    if (organization && organization.attendanceSettings && organization.attendanceSettings.sendAttendanceLink) emailKey = `${orgId}/attendanceInvite.html`;
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;
    var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
    var androidUrl = window.localStorage.getItem("androidUrl");

    AWS.config.update(AWSConfig(bucketRegion));
    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var orgInfo = organization
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");

    var parentNum = user.parentNum;
    if(parentNum == null || parentNum == undefined){
      parentNum = 1;
    }
    var params = {
      Bucket: EmailBucketName,
      Key: emailKey
    }
    return new Promise((resolve, reject) => {
      var email: any;
      var email2: any;
      s3.getObject(params, (err, data) => {

        if(err){
          reject(err);
        }else{
          console.log(user)
          email = data.Body.toString('ascii')
          email = email.replace(/{{firstName}}/g, customer.firstName);
          email = email.replace(/{{inviterName}}/g, `The ${organization.organizationName} Team`);
          email = email.replace(/{{organizationName}}/g, organization.organizationName);
          email = email.replace(/{{customLoginText}}/g, 'You should have received your temporary credentials in a previous email');
          email = email.replace(/{{lastName}}/g, customer.lastName);
          email = email.replace(/{{userId}}/g, customer.userId);
          email = email.replace(/{{webUrl}}/g,  organization.domainUrl);
          email = email.replace(/{{parentNum}}/g, parentNum);
          email = email.replace(/{{orgPhone}}/g, organization.telephone);
          email = email.replace(/{{orgEmail}}/g, organization.email);
          email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
            email = email.replace(/{{androidUrl}}/g, androidUrl);
            email = email.replace(/{{iOSUrl}}/g, iPhoneUrl);
          }

          var options = TokenService.getHeaders();
          var subject = "FunFangle Account Setup" ;
          console.log("organization is:", organization);
          console.log(customer);
          var d = {
            cId: customerId,
            fname: customer.firstName,
            lname: customer.lastName,
            email: customer.email,
            subject: subject,
            parentNum: 1,
            organization: organization,
            webUrl: organization.domainUrl,
            t:email,
        }

        console.log(d)
        if(customer.parent2Email != undefined && customer.parent2Email != null){
          //send two emails
          console.log("sending two emails");
          this.http
          .post(EmailEndpoint + "/parents/sendReminderInvite", d,options)
          .toPromise().then(res => {
            console.log("invite 1 res:", res);
          })
          email2 = data.Body.toString('ascii')
          email2 = email2.replace(/{{firstName}}/g, customer.parent2FirstName);
          email2 = email2.replace(/{{inviterName}}/g, `The ${organization.organizationName} Team`);
          email2 = email2.replace(/{{organizationName}}/g, organization.organizationName);
          email2 = email2.replace(/{{customLoginText}}/g, 'You should have received your temporary credentials in a previous email');
          email2 = email2.replace(/{{lastName}}/g, customer.parent2LastName);
          email2 = email2.replace(/{{userId}}/g, customerId);
          email2 = email2.replace(/{{webUrl}}/g, organization.domainUrl);
          email2 = email2.replace(/{{parentNum}}/g, 2);
          email2 = email2.replace(/{{orgPhone}}/g, organization.telephone);
          email2 = email2.replace(/{{orgEmail}}/g, organization.email);
          email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
          if (iPhoneUrl && androidUrl) {
            email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
            email2 = email2.replace(/{{androidUrl}}/g, androidUrl);
            email2 = email2.replace(/{{iOSUrl}}/g, iPhoneUrl);
          }
          var data2 = {
              cId: customerId,
              fname: customer.parent2FirstName,
              lname: customer.parent2LastName,
              email: customer.parent2Email,
              parentNum: 2,
              subject: subject,
              organization: organization,
              webUrl: organization.domainUrl,
              t: email2,
            }
            console.log(data2);
          resolve(this.http
              .post(EmailEndpoint + "/parents/sendReminderInvite", data2, options)
              .toPromise());

        }else{
          resolve(this.http
          .post(EmailEndpoint + "/parents/sendReminderInvite", d,options)
          .toPromise());
        }

        }
      })

    })
  }

  sendReminderUnallocEmail(idToken: string, customer:any, user:any, customerId:string, organization: any): Promise<any>{
    console.log("User:", user);
    console.log("Customer:", customer);
    var orgId = organization.organizationId;
    var emailKey = orgId + "/unallocateFundReminderEmail.html";
    var bucketName = EmailBucketName;
    var bucketRegion = EmailBucketRegion;
    var iPhoneUrl = window.localStorage.getItem("iPhoneUrl");
    var androidUrl = window.localStorage.getItem("androidUrl");

    AWS.config.update(AWSConfig(bucketRegion));
    var s3 = new AWS.S3({
      apiVersion: '2006-03-01',
    });

    var unallocateEmail: any;
    var orgInfo = organization
    orgInfo.organizationName = orgInfo.organizationName.replace("Camp ", "");
    var parentNum = customer.parentNum;
     if(parentNum == null || parentNum == undefined){
       parentNum = 1;
     }
     var params = {
       Bucket: EmailBucketName,
       Key: emailKey
     }
       return new Promise((resolve, reject) => {
         var email: any;
         var email2: any;

         s3.getObject(params, (err, data) => {
           if(err){
             reject(err);
           }else{
             email = data.Body.toString('ascii');
             email = email.replace(/{{firstName}}/g, customer.firstName);
             email = email.replace(/{{lastName}}/g, customer.lastName);
             email = email.replace(/{{userId}}/g, customer.userId);
             email = email.replace(/{{webUrl}}/g, organization.domainUrl);
             email = email.replace(/{{email}}/g, customer.email);
             email = email.replace(/{{parentNum}}/g, parentNum);
             email = email.replace(/{{orgPhone}}/g, organization.telephone);
             email = email.replace(/{{orgEmail}}/g,organization.email);
             email = email.replace(/{{orgName}}/g, orgInfo.organizationName);
             if (iPhoneUrl && androidUrl) {
               email = email.replace(/{{androidAttendanceUrl}}/g, androidUrl);
               email = email.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
             }
             email2 = data.Body.toString('ascii');
             email2 = email2.replace(/{{firstName}}/g, customer.parent2FirstName);
             email2 = email2.replace(/{{lastName}}/g, customer.parent2LastName);
             email2 = email2.replace(/{{userId}}/g, customer.userId);
             email2 = email2.replace(/{{email}}/g, customer.parent2Email);
             email2 = email2.replace(/{{webUrl}}/g, organization.domainUrl);
             email2 = email2.replace(/{{parentNum}}/g, 2);
             email2 = email2.replace(/{{orgPhone}}/g, organization.telephone);
             email2 = email2.replace(/{{orgEmail}}/g,organization.email);
             email2 = email2.replace(/{{orgName}}/g, orgInfo.organizationName);
             if (iPhoneUrl && androidUrl) {
              email2 = email2.replace(/{{androidAttendanceUrl}}/g, androidUrl);
              email2 = email2.replace(/{{iPhoneAttendanceUrl}}/g, iPhoneUrl);
            }

              var options = TokenService.getHeaders();
              var subject = "Action Requested: Allocate Funds to Your Camper" ;
              console.log("organization is:", organization);
              var d = {
                cId: customerId,
                fname: customer.firstName,
                lname: customer.lastName,
                email: customer.email,
                parentNum:1,
                subject: subject,
                organization: organization,
                webUrl: organization.domainUrl,
                t:email,
            }


            if(customer.parent2Email != undefined && customer.parent2Email != null){
              //send two emails
              console.log("sending two emails");
              this.http
              .post(EmailEndpoint + "/parents/sendReminderUnallocateFundsEmail", d,options)
              .toPromise();

              var data2 = {
                  cId: customerId,
                  fname: customer.parent2FirstName,
                  lname: customer.parent2LastName,
                  email: customer.parent2Email,
                  parentNum: 2,
                  subject: subject,
                  organization: organization,
                  webUrl: organization.domainUrl,
                  t:email2
                }
              resolve(this.http
              .post(EmailEndpoint + "/parents/sendReminderUnallocateFundsEmail", data2,options)
              .toPromise());
            }else{
              resolve(this.http
              .post(EmailEndpoint + "/parents/sendReminderUnallocateFundsEmail", d,options)
              .toPromise());
            }
           }
         })
         // resolve(true);
       })
  }

updateStatus(idToken:string, user: any, status: string):Promise<any>{
  var options = TokenService.getHeaders();
  var data = {
    user: user.userId,
    status: status
  }
  return this.http
  .post(OrganizationEndpoint + "/customer-settings/updateCustomerStatus", data,options)
  .toPromise();
}



}
