export class FunFangleReportFields {
    name: string;
    width?: number;
}

export class FunFangleReportButtons {
    text: string;
    function: string;
    cssClass?: string;
}

export class FunFangleReportRowButton {
    method: string;
    name: string;
    width: number;
}



export class FunFangleReport {
    name: string;
    description?: string;
    displayName?: string;
    fields?: FunFangleReportFields[];
    fileName?: string;
    method?: string;
    showDateRange?: boolean = false;
    slug?: string;
    exportDescription?: string = 'Export as a .csv file';
    showSessionSelect?: boolean = false;
    reportButtons?: FunFangleReportButtons[] = [];
    custom?: boolean;
    checkboxEnabled?: boolean = false;
    reportNoteText?: string = null;
    multiSessionSelect?: boolean = false;
    selectSessionString?: boolean = true;
    rowButton?: FunFangleReportRowButton = null;

    constructor(name, description, fields = [], method = null, showDateRange = false, 
        showSessionSelect = false, checkboxEnabled: boolean = false, reportButtons: FunFangleReportButtons[] = [],
        reportNoteText = null, exportDescription = 'Export as a .csv file') {
        this.name = name;
        this.description = description;
        this.fields = fields;
        this.method = method;
        this.showDateRange = showDateRange;
        if (exportDescription) this.exportDescription = exportDescription;
        this.showSessionSelect = showSessionSelect;
        this.reportButtons = reportButtons;
        this.checkboxEnabled = checkboxEnabled;
        this.reportNoteText = reportNoteText;
    }
}

export class FunFangleReportData {
    json: any[];
    csv: any;
}

let squareFields: FunFangleReportFields[] = [
    {
        name: "Account Holder",
        width: 200
    },
    {
        name: "Authorized User",
        width: 200    
    },
    {
        name:   "Date",
        width: 200
    },
    {
        name: "Amount",
        width: 100
    }, 
    {
        name: "Square ID",
        width: 200
    },
    {
        name: "Paid Via",
        width: 200
    }
];

const squareReport = new FunFangleReport("Square Payments Report", "List of payments through Square by date range", squareFields, "loadSquareDepositsReport", true, false, false, [], null);
squareReport.slug = 'SQUARE_DEPOSITS';
export const SquareReport = squareReport;

let salesReportFields = [
    {
        name: "Item",
        width: 300
    },
    {
        name: "Location",
        width: 150
    },
    {
        name: "Quantity",
        width: 100
    },
    {
        name: "Pre-Tax Revenue",
        width: 150
    },
    {
        name: "Tax",
        width: 150
    },
    {
        name: "Total Revenue",
        width: 200
    }
];

const salesReport = new FunFangleReport("Inventory Sales Report", "Sales by item grouped by session", salesReportFields, "loadSalesReport", true, false, false, [], null);
salesReport.slug = 'INVENTORY_SALES';
export const SalesReport = salesReport;

let remainingBalancesWithCashFields = [
    {
        name: "Camper",
        width: 200
    },
    {
        name: "Parent #1",
        width: 200
    },
    {
        name: "Parent #2",
        width: 200
    },
    {
        name: "Session",
        width: 200
    }, 
    {
        name: "Cash Amount",
        width: 200
    }
];

let remainingBalanceButtons: FunFangleReportButtons[] = [
    {
        text: "Mark Funds Withdrawn",
        function: "withdrawRemainingCash"
    }
];

const remainingBalancesWithCashReport = new FunFangleReport("Remaining Balances With Cash Report", "See how much money cannot be pushed back on card or via registration software", remainingBalancesWithCashFields,
    "loadRemainingCashReport", false, true, false, [], "*Amounts that cannot be pushed back on card. Excludes campers returning for future sessions", null);
remainingBalancesWithCashReport.slug = 'REMAINING_BALANCES_WITH_CASH'
export const RemainingBalancesWithCashReport = remainingBalancesWithCashReport;


let sessionFields: FunFangleReportFields[] = [
    {
        name: "Session",
        width: 200
    },
    {
        name: "Start Date",
        width: 100    
    },
    {
        name: "End Date",
        width: 100
    },
    // {
    //     name: "Gross Sales",
    //     width: 100
    // }, 
    {
        name: "Net Sales",
        width: 100
    },
    {
        name: "Allocations",
        width: 100
    },
    {
        name: "Unallocations",
        width: 100
    },
    {
        name: "Refunds",
        width: 100
    },
    {
        name: "Donations",
        width: 100
    },
    {
        name: "Net Carryover",
        width: 100
    },
];

const sessionReport = new FunFangleReport("Session Summary (Beta)", "Sales details by session", sessionFields, "loadSessionsSummaryReport", true, false, false, [], null);
sessionReport.slug = 'SESSION_SUMMARY';
export const SessionReport = sessionReport;

let sessionTenderFields: FunFangleReportFields[] = [
    {
        name: "Time",
        width: 150
    },
    {
        name: "Session",
        width: 200    
    },
    {
        name: "Operation Type",
        width: 150
    },
    {
        name: "Customer",
        width: 150
    }, 
    {
        name: "Total Due",
        width: 125
    },
    {
        name: "Tendered Amount",
        width: 125
    },
    {
        name: "Tender Type",
        width: 125
    },
];

let sessionTendersReport = new FunFangleReport("Session Tenders (Beta)", "Transaction details for individual sales by session", sessionTenderFields, "loadSessionTendersReport", false, true, false, [], null);
sessionTendersReport.multiSessionSelect = true;
sessionTendersReport.selectSessionString = false;
sessionTendersReport.slug = 'SESSION_TENDERS';
export const SessionTendersReport = sessionTendersReport;


let squareSettlementFields: FunFangleReportFields[] = [
    {
        name: "Date",
        width: 200
    },
    {
        name: "Amount",
        width: 125    
    },
    {
        name: "Settlement ID",
        width: 240
    },
    {
        name: "Bank Account ID",
        width: 200
    }, 
    {
        name: "Status",
        width: 100
    }
];

let squareSettlementsReport = new FunFangleReport("Square Settlements", "Bank Deposits for Square Payment Settlements", squareSettlementFields, "loadSquareSettlementsReport", true, false, false, [], null);
// squareSettlementsReport.rowButton = {
//     method: "listSquareSettlements",
//     width: 200,
//     name: "Download"
// }
squareSettlementsReport.slug = 'SQUARE_SETTLEMENTS';
squareSettlementsReport.custom = true;
export const SquareSettlementReport = squareSettlementsReport;