import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { Subject } from 'rxjs/Subject';

import { CognitoPoolId, LicenseBucketName, LicenseBucketRegion, CustomerLicenseKey, OrganizationLicenseKey, AWSConfig } from '../app.constants';


declare var AWS: any;

/*Service for interfacing with Cognito methods*/
@Injectable()
export class ActivationService {


    constructor() { }



    getLicense(licenseType: string): Promise<any> {
        var licenseKey = "";
        if (licenseType == 'customer') {
            licenseKey = CustomerLicenseKey;
        } else {
            licenseKey = OrganizationLicenseKey;
        }
        var bucketName = LicenseBucketName;
        var bucketRegion = LicenseBucketRegion;
        console.log("cognito pool id")

        AWS.config.update(AWSConfig(bucketRegion));

        var s3 = new AWS.S3({
            apiVersion: '2006-03-01',
        });
        var params = {
            Bucket: LicenseBucketName,
            Key: licenseKey
        }
        return new Promise((resolve, reject) => {
            s3.getObject(params, (err, data) => {
                console.log("get object");
                console.log(err, data);
                if(err){
                    reject(err);
                }else{
                    resolve(data.Body.toString('ascii'))
                }
            })
        })


    }









}
