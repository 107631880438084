import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { AuthCredentials } from '../auth/auth-credentials.component';
import { DialogsService } from '../dialog/dialog.service';
import { ActivationService } from '../services/activation.service';
import { LogoWebUrl } from '../app.constants';



@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  userId: string = "";
  user: any = null;
  dataLoaded: boolean = false;
  userVerified: boolean = true;
  credentials = new AuthCredentials();
  cognitoUser: any;
  showVerifyText: boolean = false;
  verifyText: string = "";
  licenseText: string = "";
  custNum = 0;
  num = 0;
  screenHeight = screen.height;
  public logoUrl: string = "";

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public authService: AuthService,
    public dialogsService: DialogsService,
    public activationService: ActivationService
  ) {
      console.log("height:", this.screenHeight);
      this.logoUrl = LogoWebUrl;
   }


  ngOnInit() {
    //load the user to be activated
    this.userId = this.route.snapshot.params['userId'];
    this.custNum = this.route.snapshot.params['custNum'];
    if(this.custNum == undefined || this.custNum == null){
      this.custNum = 1;
    }
    this.userService.getUserById(this.userId)
    .then(res => {
      var items = JSON.parse(res._body).message.Items;
      if(items.length > 0){
        this.user = JSON.parse(res._body).message.Items[0];

        if(this.custNum == 1){
          this.userVerified = this.user.verified;
          this.credentials.email = this.user.email;
        }else{
          this.userVerified = this.user.parent2Verified;
          this.credentials.email = this.user.parent2Email;
        }

        this.credentials.role = "account-holder";
        //now log them out
        if(!this.userVerified){
          this.authService.logout();
        }

      }
      console.log("user:", this.user);
      //load the license from s3
      this.activationService.getLicense('customer')
      .then(res => {
        this.licenseText = res;
      }).catch(err => {
        console.log("err fetching license:", err);
      })


      this.dataLoaded = true;
    })
    .catch(err => {
      console.log("err:", err);
    })
  }


  submit(){
    console.log("validating credentials:", this.credentials);
    if(!this.credentials.validateCredentials()){
      this.dialogsService.alert("Invalid credentials!", "Make sure you've entered a valid email and password and that your passwords match. Your password must be at least 8 characters long");
      return;
    }

    console.log("custnum:", this.custNum);

    var isParent2 = (this.custNum == 2)? true : false;

    this.authService.createUser(this.credentials, isParent2)
    .then(res => {
      console.log("res:", res);
      this.cognitoUser = res.user;
      console.log("cognito user:", this.cognitoUser);
      //this.showVerifyText = true;
      this.userVerified = true;
      this.userService.setUserVerified(this.user.email, this.custNum, this.user.userId, this.user.organizationId)
      .then(res => {
        console.log("the response from setting user to verified", res);
        var body = JSON.parse(res['_body']);
        console.log("this should be the body " ,body);
        console.log("Looking for this:",body.message);
        console.log("Looking for this:",body.message.Attributes);
        console.log("Looking for this:",body.message.Attributes.parent2Verified);
        if(body.message.Attributes.parent2Verified){
          this.num = 2;
        }
        else {
          this.num = 1;
        }
        if(body.status == '200'){
          console.log("Sending first email to parents");
          this.userService.getOrgInfo(this.user.organizationId)
          .then(res =>{
            console.log(res)
            var response = JSON.stringify(res);
            var body = JSON.parse(response);
            var data = body._body;
            var dataItem = JSON.parse(data);
            var newData = dataItem.message.Items[0];
            console.log("Data from organization", newData);
            var orgId = newData.organizationId;
            this.userService.sendFirstEmailAfterVerification(this.user, newData, this.custNum, orgId, this.num)

          })
        }
      })
    }).catch(err => {
      console.log("create user err:", err);
      if(err.code == "UsernameExistsException"){
        this.dialogsService.alert("Account exists!", "Either login or create a new account");
      }else{
        this.dialogsService.alert("Processing Error", "Please verify you've entered a valid email and password");
      }
    })
  }


/* pass in verification credentials */
verifyAccount(){
  this.authService.verifyAccount(this.credentials, this.verifyText)
  .then(res => {
    console.log("verify account res:", res);
    this.dialogsService.alert("Account confirmed!", "You can now log in normally");
    this.showVerifyText = false;
    this.userVerified = true;
    this.userService.setUserVerified(this.user.email, this.custNum, this.user.userId, this.user.organizationId)
    .then(res => {
      console.log("set user verified res:",res);
    })
    .catch(err => {
      console.log("set user verified err:", err);
    })
  }).catch(err => {
    console.log("verify account err:", err);
    this.dialogsService.alert("Processing Error","Make sure you have entered the correct code");
  })

}

/*Send a new email w/ verification code*/
resendVerificationCode(){
  this.authService.resendCode(this.credentials)
  .then(res => {
    console.log("resend code res:", res);
    if(res == "SUCCESS"){
      this.dialogsService.alert("Email sent","We have sent new credentials to your account");
    }
  }).catch(function(err){
    console.log("resendcode err:", err);
    this.dialogsService.alert("Processing Error","Could not re-send confirmaion code");
  })
}

login(){
  console.log("calling login");
  console.log("auth credentials", this.credentials);
  if(this.credentials.password != null && this.credentials.password != ""){
    this.authService.login(this.credentials)
    .then(res => {
      this.router.navigate(['home']);
    }).catch( err => {
      console.log("could not log in!");
      //this.router.navigate(['auth'])
    })
  }else{
    this.router.navigate(['auth']);
  }
}



}
