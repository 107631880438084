//providers/services
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { OrganizationService } from './services/organization.service';
import { LingoService } from './services/lingo.service';
import { ContributorService } from './services/contributor.service';
import { CustomerSettingsService } from './services/customer-settings.service';
import { SquareService } from './services/square.service';
import { DialogsService } from './dialog/dialog.service';
import { LoadingService } from './services/loading.service';
import { OrganizationCampersService } from './services/organization-campers.service';
import { EmployeeService } from './services/employee.service';
import { ActivationService } from './services/activation.service';
import { TutorialService } from './services/tutorial.service';
// import { CsvService } from 'angular2-json2csv';
import { CarePackagesService } from './services/care-packages.service';
import { InventoryService } from './services/inventory.service';
import { InventoryCodeService } from './services/inventory-code.service';
import { InventoryPurchaseService } from './services/inventory-purchase.service';
import { OrganizationSettingsService } from './services/organization-settings.service';
import { ReportsService } from './services/reports.service';
import { TransactionsService } from './services/transactions.service';
import { VendorService } from './services/vendor.service';

// Modules
import { MatSidenavModule, MatInputModule, MatIconModule, MatMenuModule, MatCheckboxModule, MatOptionModule,
  MatSelectModule, MatDialogModule, MatButtonModule, MatFormField, MatFormFieldModule, MatTabsModule,
  MatListModule, MatRadioModule, MatTooltipModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgGridModule  } from 'angular2-grid';
import { Ng2ImgMaxModule } from 'ng2-img-max'; // <-- import the module

// import { NG2OverlayModule } from "ng2-overlay";
import { Ng2OverlayModule } from '../../node_modules/ng2-overlay/dist';
import { PopoverModule } from 'ngx-popover';
import { Ng2FileDropModule } from 'ng2-file-drop';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DropdownModule } from 'ngx-dropdown';
import { PipeModule } from './pipes/pipe.module';
import { QuillModule } from 'ngx-quill';

// Components
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { FfSidenavComponent } from './ff-sidenav/ff-sidenav.component';
import { UsersComponent } from './users/users.component';
import { EmployeesComponent } from './employees/employees.component';
import { ActivateComponent } from './activate/activate.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { PaymentSettingsComponent } from './payment-settings/payment-settings.component';
import { RedirectComponent } from './redirect/redirect.component';
import { AlertDialog } from './dialog/alert-dialog.component';
import { BarcodeDialog } from './dialog/barcode-dialog.component';
import { CarePackageItemDialog } from './dialog/care-package-item-dialog.component';
import { ChangePasswordDialog } from './dialog/change-password-dialog.component';
import { ConfirmDialog } from './dialog/confirm-dialog.component';
import { ConfirmAddDialog } from './dialog/confirm-add-dialog.component';
import { InputDialog } from './dialog/input-dialog.component';
import { ItemFieldsDialog } from './dialog/item-type-fields.component';
import { ModifierDetailDialog } from './dialog/modifier-detail.component';
import { SelectDialog } from './dialog/select-dialog.component';
import { SelectCampersDialog } from './dialog/select-campers-dialog.component';
import { TransactionSearchDialog } from './dialog/transaction-search-dialog.component';
import { UploadImageDialog } from './dialog/upload-image-dialog.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerService} from './services/customer.service';
import { OrganizationCampersComponent } from './organization-campers/organization-campers.component';
import { OrganizationSettingsComponent } from './organization-settings/organization-settings.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { ReportsComponent } from './reports/reports.component';
import { RemainingFundsComponent } from './remainingFunds/remainingFunds.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryPurchaseDetailComponent } from './inventory/purchasedetail.component';
import { InventoryPurchaseOrderComponent } from './inventory/purchaseorder.component';
import { CarePackagesComponent } from './care-packages/care-packages.component';
import { CarePackageOrdersComponent } from './care-package-orders/care-package-orders.component';



declare var AWSCognito: any;
declare var AWS: any;

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    // MaterialModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatListModule,
    MatRadioModule,
    MatTooltipModule,
    FlexLayoutModule,
    AppRoutingModule,
    Ng2OverlayModule,
    PopoverModule,
    Daterangepicker,
    Ng2FileDropModule,
    JsonpModule,
    UiSwitchModule,
    NgGridModule,
    DropdownModule,
    Ng2ImgMaxModule,
    QuillModule.forRoot(),
    PipeModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    FfSidenavComponent,
    UsersComponent,
    EmployeesComponent,
    ActivateComponent,
    ContributorsComponent,
    CustomerSettingsComponent,
    PaymentSettingsComponent,
    RedirectComponent,
    AlertDialog,
    BarcodeDialog,
    CarePackageItemDialog,
    ConfirmDialog,
    ConfirmAddDialog,
    ChangePasswordDialog,
    InputDialog,
    ItemFieldsDialog,
    ModifierDetailDialog,
    SelectDialog,
    SelectCampersDialog,
    TransactionSearchDialog,
    UploadImageDialog,
    OrganizationsComponent,
    UserInfoComponent,
    CustomersComponent,
    OrganizationCampersComponent,
    OrganizationSettingsComponent,
    TransactionsComponent,
    ReportsComponent,
    ReportsComponent,
    RemainingFundsComponent,
    ResetPasswordComponent,
    InventoryComponent,
    InventoryPurchaseDetailComponent,
    InventoryPurchaseOrderComponent,
    CarePackagesComponent,
    CarePackageOrdersComponent
  ],
  providers: [
    AuthService,
    UserService,
    OrganizationService,
    LingoService,
    ContributorService,
    CustomerSettingsService,
    SquareService,
    DialogsService,
    LoadingService,
    CustomerService,
    OrganizationCampersService,
    EmployeeService,
    ActivationService,
    TutorialService,
//    CsvService,
    CarePackagesService,
    InventoryService,
    InventoryCodeService,
    InventoryPurchaseService,
    OrganizationSettingsService,
    ReportsService,
    TransactionsService,
    VendorService
  ],
  entryComponents: [
      AlertDialog,
      BarcodeDialog,
      CarePackageItemDialog,
      ChangePasswordDialog,
      ConfirmDialog,
      ConfirmAddDialog,
      InputDialog,
      ItemFieldsDialog,
      ModifierDetailDialog,
      SelectDialog,
      SelectCampersDialog,
      TransactionSearchDialog,
      UploadImageDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
