import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription }   from 'rxjs/Subscription';
import { ActivatedRoute, Params } from '@angular/router';
import { SortPipe } from '../pipes/sort-pipe';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { OrganizationService } from '../services/organization.service';
import { LingoService } from '../services/lingo.service';
import { FfSidenavComponent } from '../ff-sidenav/ff-sidenav.component';
import { DialogsService } from '../dialog/dialog.service';
import { ContributorService } from '../services/contributor.service';
import { LoadingService } from '../services/loading.service';
import { SquareService } from '../services/square.service';
import { TutorialService } from '../services/tutorial.service';
import { CustomerService } from '../services/customer.service';
import { OrganizationCampersService } from '../services/organization-campers.service';
import { OfflineMessage } from '../../app/models/offline-message';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public user: any = null;
  public cognitoUser: any = null;
  public userRole: string = null;
  public organization: any = null;
  public organizationType: string = null;
  public newOrganization: any = null;
  public orgId: string = null;
  public showAddOrganization: boolean = false;
  public idToken: string = '';
  public loadCompleted: boolean = false;
  public authorizedUsers: any[] = [];
  public cust = [];
  public allTransactions = [];
  public currentUserTransactions = [];
  public userSubscription: Subscription;
  public screenWidth: number = 0;
  public orgTotalSales: number = 0;
  public numCustomers: number = null;
  public totalSales: number = 245.23;
  public orgLingo: { [id: string] : string} = {};
  public totalFunds: number = 0;
  public recentT = [];
  public allOrganizations = [];
  public selectedOrganization = {};
  public myContribution = [];
  public allOrgUsers = [];
  public  adminEmail = '';
  public adminFirst = '';
  public adminLast = '';
  public warningMessage: OfflineMessage = null;

  //tutorial
  public popover1 = null;
  public showTutorial = false;
  public showPopover1 = false;
  public showPopover2 = false;
  public bypassSubscriptionCheck: boolean = false;


  public tokenSubscription: Subscription;
  public toggleSubscription: Subscription;
  public forceAccountHolder: boolean = false;
  public warningSubscription: Subscription;


  /* dummy data for populating home page */
  transactions = [
  /*  { name: 'Austin', date: '7/22/2016', amount: '$2.40', items: 'Hotdog, popcorn', location: 'Snack Bar' },
    { name: 'Dany', date: '7/8/2016', amount: '$7.50', items: 'Soda, grilled cheese', location: 'Snack Bar' },
    { name: 'Molly', date: '7/6/2016', amount: '$15.23', items: 'Sweatshirt', location: 'Bookstore' },
  */];
  columns = [
    { prop: 'name' },
    { name: 'Date' },
    { name: 'Amount' }
  ];

  //Declare all services to be injected
  constructor(
    public userService: UserService,
    public authService: AuthService,
    public organizationService: OrganizationService,
    public router: Router,
    public lingoService: LingoService,
    public dialogsService: DialogsService,
    public contributorService: ContributorService,
    public loadingService: LoadingService,
    public squareService: SquareService,
    public customerService: CustomerService,
    public tutorialService: TutorialService,
    public route: ActivatedRoute,
    public organizationCampersService: OrganizationCampersService
  ) {
    this.tokenSubscription = authService.tokenSubscription$.subscribe(
      token => {
        console.log('Update token home!');
       /// console.log(token);
        this.idToken = token;
      }
    )


    this.toggleSubscription = organizationService.toggleSubscription$.subscribe(
      show => {
        console.log('receiving value:', show);
        this.forceAccountHolder = show;
        this.bypassSubscriptionCheck = true;
        this.allTransactions = [];
        this.currentUserTransactions = [];
        this.ngOnInit();
      }
    )


  }

  ngOnInit() {

    //get the current toggle setting
    if(!this.bypassSubscriptionCheck){
      this.forceAccountHolder = this.organizationService.forceAccountHolder;
   }

   this.warningMessage = this.userService.getWarningMessage();
   console.log('warning message:', this.warningMessage);

   this.warningSubscription = this.userService.warningMessageSubscription$
      .subscribe( (warningMessage: OfflineMessage) => {
        this.warningMessage = warningMessage;
        console.log('Got warning message!');
      });


    //load the current user
    this.loadingService.toggleLoadingScreen(true);
    this.getUser()
      .then(res => {
        this.user = res;
        this.totalFunds = this.user.currentBalance;
        //updates the sidenav with the new user that's logged in
        if(res.role == 'system-administrator'){
          this.adminEmail = this.user.email;
          this.adminFirst = this.user.firstName;
          this.adminLast = this.user.lastName;
          //
          var adminUser = {
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName
          }
          var adminString = JSON.stringify(adminUser);

          window.localStorage.setItem('adminUser', adminString);
          


          //get all of the organizations..
            this.userService.getAllOrganizations(this.idToken)
            .then(res => {
              console.log('get all organizations res:', res);
              this.allOrganizations = JSON.parse(res._body).message.Items.sort( (a, b) => {
                if (a.organizationName > b.organizationName) {
                  return 1;
                }  else {
                  return -1;
                }
              });
              console.log('all organizations are:', this.allOrganizations);
            })
        }else{
          //check local storage...
          var adminString = window.localStorage.getItem('adminUser');
          if(adminString != undefined && adminString != null && adminString != null){
            var adminUserObj = JSON.parse(adminString);
            this.adminEmail = adminUserObj.email;
            this.adminFirst = adminUserObj.firstName;
            this.adminLast = adminUserObj.lastName;
            
            //get all of the organizations..
            this.userService.getAllOrganizations(this.idToken)
            .then(res => {
              console.log('get all organizations res:', res);
              this.allOrganizations = JSON.parse(res._body).message.Items;
              console.log('all organizations are:', this.allOrganizations);
            })
          }
        }


        
        if(res != null){
          this.getCustomer();
          this.getUserTransaction();
        }
        this.getMyAuthorizedUsers()
        .then(res => {
          this.loadCompleted = true;
          //this.loadContributions();
          this.loadingService.toggleLoadingScreen(false);
        }).catch(err => {
          this.loadingService.toggleLoadingScreen(false);
        })
        // this.getCustomers();
        //update the transactions table text
        //TUTORIAL PROCESS begin
        if( this.route.snapshot.params['showTutorial'] || (this.user.role == 'account-holder' && (this.user.viewedTutorial == undefined || !this.user.viewedTutorial))){
          this.launchTutorial();
        }


        if(this.transactions.length == 0){
          setTimeout( () => {
            var x = document.getElementsByClassName('empty-row');
            console.log('x:', x);
            try{
              x[0].innerHTML = 'No transactions to display';
            }catch(err){
              console.log('error:', err);
            }
          },100)


        }
        this.userService.refreshUserDynamo(res);
        if (this.user && this.user.role) {
          this.userRole = this.user.role;
        }
        this.loadOrganization()
        .then(res => {
          //everything is loaded now
          this.loadCompleted = true;
          console.log('gonna load sales..?');
          console.log(this.organization);
          this.organization.itemsColumnEnabled = (this.organization.itemsColumnEnabled != undefined)? this.organization.itemsColumnEnabled : true;
          if (this.userRole == 'organization-manager' || this.userRole == 'organization-employee' || this.userRole == 'organization-counselor' || this.userRole == 'store-manager') {
            this.getTotalSales()
          }
        })
        .catch(err => {
          this.loadCompleted = true;
        })
        
        //now load the lingo
        this.lingoService.loadLingo(this.idToken, 'camp')
          .then(res => {
            var body = JSON.parse(res._body);
            body.message.Items.forEach(item => {
              this.orgLingo[item.term] = item.item_name.charAt(0).toUpperCase() + item.item_name.slice(1);
            })
            this.lingoService.refreshLingo(this.orgLingo);
            console.log('lingo:', this.orgLingo);
            //lastly, check if they need to be re-directed
            var rechargeUserId = this.squareService.getRechargeUserId();
            if(rechargeUserId != null){
              console.log('attempting to navigate!');
              this.router.navigate(['payment-settings/' + rechargeUserId]);
            }

          })
          .catch(err => {
            console.log('lingo err:', err);
          })
      })
      .catch(err => {
        console.log('getcognito user err:', err);
        this.loadingService.toggleLoadingScreen(false);
      })
  }

  logout() {
    var signoutRes = this.authService.logout();
    if (signoutRes) {
      this.router.navigate(['auth']);
    }
  }

  getUser(): Promise<any> {
    return new Promise((resolve,reject) => {
      this.userService.getUser()
        .then(res => {
          console.log('Get-USer:', res);
          this.idToken = res.idToken;
          this.orgId = res.organizationId;
          resolve(res);
        })
        .catch(err => {
          console.log('get user err:', err);
          reject(err);
        });
    })
  }


  /*go to users page*/
  goToUsers() {
    this.router.navigate(['users']);
  }

  goToOrgUsers(){
    this.router.navigate(['organization-campers']);
  }

  goToParents(){
    this.router.navigate(['customers']);
  }
  /* go to the payments page */
  goToPayments(){
    console.log('go to pamyents');
   this.router.navigate(['payment-settings']);
  }
  goToTransactions(){
    this.router.navigate(['transactions']);
  }

  /* grab organization managed by manager */
  loadOrganization(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.organizationService.getOrganizationById(this.idToken, this.user.organizationId)
        .then(res => {
          console.log('got organization:', res);
          var body = JSON.parse(res._body);
          if (body.status == '200') {
            this.organization = body.message.Items[0];
            this.organizationType = this.organization.organizationType;
            console.log('org:', this.organization);
          }
          resolve(this.organization);

        })
        .catch(err => {
          console.log('get organization err:', err);
          reject(err);
       })
    });
  }


  openNewOrganization() {
    this.showAddOrganization = !this.showAddOrganization;
  }

  createOrganization(name: string) {
    console.log('name:', name);
    if (name == '') {
      this.dialogsService.alert('Processing error','You must enter a name for your organization!');
      return;
    }

    this.organizationService.createOrganization(this.newOrganization)

      .then(res => {
        console.log('create org res:', res);
      })
      .catch(err => {
        console.log('create org err:', err);
      })
  }



  /* get all authorized users on their account */
  getMyAuthorizedUsers(): Promise<any>{
    var userId = this.user.userId;
    //if the user is a contributor, get the users associated with the accountHolder
    if(this.user.role == 'account-contributor'){
      userId = this.user.accountHolder;
    }
    return new Promise((resolve,reject) => {
      this.userService.getAuthorizedUsers(this.idToken,userId)
      .then(res => {
          console.log('get auth users res:', res);
          var body = JSON.parse(res._body);
          if(body.status == '200'){
            this.authorizedUsers = body.message.Items;
            console.log('authorized users:', this.authorizedUsers);
            var authUserIds = [];
            this.authorizedUsers.forEach(user => {
              authUserIds.push(user.userId);
            });

            this.userService.getAuthorizedUserBalances(this.idToken, authUserIds)
                .then(res => {
                  var body = JSON.parse(res._body);
                  if (body.message.Items && body.message.Items.length > 0) {
                    console.log('get auth users balances:', body.message.Items);
                    body.message.Items.forEach(item => {
                      if (item.contributorId == this.user.userId) {
                        this.myContribution[item.authUserId] = item.currentBalance;
                      }
                    })
                  }
                })

            






            resolve(true);
          }
        })
        .catch(err => {
          console.log('get auth users err:', err);
          reject(false);
        })
    })

  }




  /* Link directly to managing funds for a specific user */
  goToManageFunds(user){
    console.log('go to manage funds for user:', user);
    this.userService.setManageFundsUser(user);
    this.router.navigate(['users']);
  }


  getCustomer(){
    var organizationId = this.user.organizationId;
    console.log('Organization Id:' , organizationId);
    if(this.cust.length > 0 ){
      this.cust = [];
    }
    this.organizationCampersService.getAllCampers(organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var campers = body.items;

        this.cust = campers;
        this.numCustomers = this.cust.length;

      });
    
    /*this.customerService.getAllCustomer(organizationId)
      .then(res => {
        var body = JSON.parse(res._body);
        var customer = body.message.Items;

        customer.forEach(user => {
          if(user.role == 'account-holder'){
            this.cust.push(user)
          }
        })
        console.log(this.cust);
        this.numCustomers = this.cust.length;
        // this.customers = customer;
      }).catch(err => {
      console.log("Get All Customer Err", err);
    })*/
  }


  launchTutorial(){
    //check the screen size
    if(screen.width < 1100){
      this.dialogsService.alert('Could not launch tutorial', 'The tutorial cannot be displayed on smaller screen sizes. Please log into a desktop or tablet to view the tutorial');
      return;
    }
    this.tutorialService.startTutorial();
    this.showTutorial = true;
    this.showPopover1 = true;
    this.tutorialService.showOverlay();
    console.log('remove subscriptions');
    this.tutorialService.removeExistingTutorialSubscriptions();
    setTimeout( () => {
      console.log('popover1:', document.getElementById('popover1'));
      try{
        document.getElementById('popover1').click();
      }catch(err){
        console.log('tutorial error:', err);
      }
    },500);

  }

  nextStep(popoverName){
    if(popoverName == 'popover1'){
      this.showPopover1 = false;
      this.showPopover2 = true;
      setTimeout( () => {
        try{
          document.getElementById('popover2').click();
        }catch(err){
          console.log('tutorial error:', err);
        }
      },250);
    }else{
      this.showPopover2 = false;
      //need to trigger the next popover on the left sidenav
      this.tutorialService.triggerSideNavPopover('popover3');
    }


  }

  skipSteps(){
    this.showTutorial = false;
    this.tutorialService.skipSteps(this.idToken, this.user.email, this.user.organizationId);
  }


  //show / hide the sidenav menu
  toggleMenu(){
    this.loadingService.toggleSideMenu();
  }

  getTotalSales(lastEvalKey = null){
    this.recentT.length = 20;
    console.log(this.orgId);

    var timeZoneOffset = -4;
    if(this.organization.timeZoneOffset != null){
      timeZoneOffset = this.organization.timeZoneOffset;
    }


    console.log('getting transactions for today...');

    this.userService.getTodaysTransactions(this.idToken, this.orgId, timeZoneOffset, lastEvalKey)
    .then( res => {
      console.log('got today\'s transactions:', res);
      var sales = 0;
      var response = JSON.stringify(res);
      var body = JSON.parse(response);
      var data = body._body;
      var dataItem = JSON.parse(data);

      if(dataItem.message.hasOwnProperty('LastEvaluatedKey')){
        console.log('go get more...');
        console.log(dataItem.message.LastEvaluatedKey);
        console.log(dataItem.message.Items);
        if(this.allTransactions.length == 0){
          this.allTransactions = dataItem.message.Items;
        }else{
          this.allTransactions.push.apply(this.allTransactions,dataItem.message.Items);
        }
        
        console.log('all transactions now:', this.allTransactions);
        if (this.allTransactions.length > 0) {
           console.log('l > 0 so proceed');
           console.log(this.allTransactions.length);
           this.getTotalSales(dataItem.message.LastEvaluatedKey);
        }
        

      }else{
        if(this.allTransactions.length == 0){
          this.allTransactions = dataItem.message.Items;
        }else{
          this.allTransactions.push.apply(this.allTransactions,dataItem.message.Items);
        }
        var sales = 0;
        var numTotal = 0;
        this.allTransactions.forEach(transaction =>{
          if(transaction.status == 'complete' && transaction.amount != undefined && (transaction.type == undefined || transaction.type == 'transaction')){
            numTotal++;
            sales += Math.abs(transaction.amount);
          }
        })
        console.log('evaluating a total of:', numTotal);
        this.allTransactions.sort(function(x,y){
          return y.timeStamp - x.timeStamp
        })
        this.orgTotalSales = sales;
        console.log('Sales:');
        console.log(sales);
        console.log(this.allTransactions);
      }
      
      



    });
    


    // this.userService.getOrganizationTotalSales(this.idToken, this.orgId)
    // .then(res => {
    //   console.log("Org transactions");
    //   console.log(res);
    //   var sales = 0;
    //   var response = JSON.stringify(res);
    //   var body = JSON.parse(response);
    //   var data = body._body;
    //   var dataItem = JSON.parse(data);
    //   var transactionsData = dataItem.message.Items;
    //   this.allTransactions = transactionsData;
    //   // this.recentT = transactionsData;
    //   console.log("Data from organization transactions", transactionsData);
    //   transactionsData.forEach(transaction =>{
    //     if(transaction.status == "complete" && transaction.amount != undefined && (transaction.type == undefined || transaction.type == 'transaction')){
    //       // console.log(transaction);
    //       sales += transaction.amount;
    //     }
    //   })
    //   this.allTransactions.sort(function(x,y){
    //     return y.timeStamp - x.timeStamp
    //   })
    //   //this.allTransactions.reverse();
    //   this.allTransactions = this.allTransactions.slice(0,10);
    //   this.orgTotalSales = sales;
    //   console.log(sales);
    //   console.log(this.allTransactions);
    // })

  }

  getUserTransaction(){
    console.log(this.user);
    this.userService.getUserTransaction(this.idToken, this.user.userId)
    .then(res => {
      console.log('Response from User Transaction', res);
      var body= JSON.parse(res._body);
      var message = body.message.Items;
      this.currentUserTransactions = message;
      console.log('transactions:');
      console.log(this.currentUserTransactions);
      //this.currentUserTransactions.reverse();
      this.currentUserTransactions.sort(function(x,y){
        return y.timeStamp - x.timeStamp
      })
      //this.currentUserTransactions.splice(0,20);
    })

  }


  ngOnDestroy() {
    this.warningSubscription.unsubscribe();
  }


  selectOrganization(orgId){
    console.log(this.selectedOrganization)
    this.allOrganizations.forEach( organization => {
      if(organization.organizationId == orgId){
        this.selectedOrganization = organization;
        this.organizationType = organization.organizationType;
      }
    })

    console.log('selected org is:', this.selectedOrganization);
    console.log('DISABLED ENDPOINT!!');
    // this.loadingService.toggleLoadingScreen(true);
    // //now grab all the users
    // this.userService.getAllUsersByOrganization(this.idToken, orgId.target.value)
    // .then(res => {
    //   console.log('res:', res);
    //   this.loadingService.toggleLoadingScreen(false);
    //   this.allOrgUsers = JSON.parse(res._body).message.Items;
    //   this.allOrgUsers = this.allOrgUsers.sort( (a,b) => {
    //     if (a.role === 'organization-manager' && b.role !== 'organization-manager') {
    //       return -1;
    //     } else if (b.role === 'organization-manager' && a.role !== 'organization-manager') {
    //       return 1;
    //     }


    //     if(a.lastName >= b.lastName){
    //       return 1;
    //     }else{
    //       return -1;
    //     }
    //   })
    //   console.log('all org users:', this.allOrgUsers);
    // })

  }

  alphabetSort(a, b) {
    if (!a.lastName) {
      return 1;
    } else if (!b.lastName) {
      return -1;
    }
    if (a.lastName.toLowerCase() >= b.lastName.toLowerCase()) {
      return 1;
    } else {
      return -1;
    }
  }

  clearSelectedUser(){
    window.localStorage.setItem('actingAsUser', null);
    this.ngOnInit();
  }


  selectUser(user){
    console.log('selecting user:', user.target.value);
    var email = user.target.value;
    window.localStorage.setItem('actingAsUser', email);
    //this.userService.refreshUserDynamo(this.user);
    this.ngOnInit();

  }



}


