import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
// import { Vendor } from '../inventory/purchaseorder.component';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import { BaseEndpoint, } from "../app.constants";
import { TokenService } from './token.service';

@Injectable()
export class VendorService {

  constructor(public http: Http) {
  }

  listVendors(idToken: string, organizationId: string): Promise<any>{
      let options = TokenService.getHeaders();

      return this.http
        .get(BaseEndpoint + "/v3/profile/vendors?orgid=" + organizationId, options)
        .toPromise();
  }

}